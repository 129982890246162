import { useEffect } from "react";
import { Tooltip, Typography, Row,  Col, Card, Space, Collapse } from 'antd';
import { Link, useSearchParams } from "react-router-dom";
import { ArrowRightOutlined, DownOutlined } from '@ant-design/icons';

import { WorkflowList } from '../../components/WorkflowList';
import { NamedWorkflowList } from '../../components/NamedWorkflowList';
import { WorkflowExecutionsList } from '../../components/WorkflowExecutionsList';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'
import { TypeIcon } from "antd/es/message/PurePanel";

export function MyWorkflows() {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {document.title = 'PyroPrompts | My Workflows'}, [])

    const account = useAppSelector(selectAccount);
    console.log("account", {account})

    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>My Workflows</Typography.Title>
                <div className="App-page-content">
                    <Space direction="vertical">
                        <Typography.Paragraph>
                            Save time and establish exact repeatable, automated AI procedures for consistent quality with No-Code Workflows. <Link to={"/projects"}>Create Workflows in a Project</Link>.
                        </Typography.Paragraph>

                    {account && (
                        <>
                            <WorkflowList showCreate={false} />
                            <WorkflowExecutionsList show_workflow_link={true} />
                        </>
                    )}

                    {!account && (
                        <>
                            <Typography.Paragraph><a onClick={() => {
                                searchParams.set("auth", "signup")
                                setSearchParams(searchParams)
                            }}>Sign up or Log in</a> to set up your first Workflow in a Project for free.</Typography.Paragraph>
                            <Typography.Paragraph>Projects contain all your AI Workflows (processes) and reusable snippets for your project. For example, if you're running a blog, you could have Snippets for explaining your brand, audience and SEO Keywords and reuse those across multiple prompts at a click of a button.</Typography.Paragraph>
                            <Typography.Paragraph>Start automating for free in less than 20 minutes without writing any code. <a onClick={() => {
                                searchParams.set("auth", "signup")
                                setSearchParams(searchParams)
                            }}>Sign up or Log in</a> to start.</Typography.Paragraph>
                        </>
                        )}
                    </Space>
                    
                </div>
            </div>
        </div>
    );
}
