import type { SelectProps } from 'antd';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography, message } from 'antd';
import {InfoCircleOutlined, PlusOutlined} from '@ant-design/icons'
import { useState, useEffect } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { updatePrompt, createPrompt } from '../services/signalApi';
import { CopyToClipboard } from './CopyToClipboard';
import { Prompt as PromptType } from '../types/types'
import { Link } from "react-router-dom";

// import {Prompt as PromptType} from '../types/types'

import { useAppSelector, useIsSmallScreen } from '../app/hooks';
const { Option, OptGroup } = Select;


interface PromptModalParams {
    prompt: PromptType
    isOpen: boolean
    onCancel: () => void
    onEdit: (prompt: PromptType | null) => void
}

// interface NewPrompt {
//     content: string
//     id: string
//     name?: string
// }


export function EditPromptModal({
    prompt,
    isOpen,
    onCancel,
    onEdit,
}: PromptModalParams) {
    const [form] = Form.useForm();
    const promptContent = Form.useWatch('content', form);
    const isSmallScreen = useIsSmallScreen()


    const account = useAppSelector(selectAccount);
    const [isCreateNew, setIsCreateNew] = useState(false);
    const [inFlight, setInFlight] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    // const [isUpdating, setIsUpdating] = useState(false);
    const [updatedPrompt, setUpdatedPrompt] = useState<PromptType | null>(null);

    console.log('EditPromptModal', { isUpdating, inFlight})

    const handleCancel = () => {
        form.resetFields()
        setUpdatedPrompt(null)
        setIsUpdating(false)
        setIsCreateNew(false)
        onCancel()
    }

    const handleReset = () => {
        form.resetFields()
        setUpdatedPrompt(null)
        setIsUpdating(false)
    }

    const onEditPromptFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onEditPromptSubmit = (values: any) => {
        console.log('Success:', {values, account, isCreateNew});
        onEditPrompt(values)
    };

    const onEditPrompt = (values: any) => {
        if (account) {
            console.log("onEditPrompt values", {values})
            setIsUpdating(true)
            const body = {content: values.content, name: values.name, description: values.description, is_public: values.is_public ? 1 : 0, platforms: values.platforms}
            if (isCreateNew) {
                createPrompt({account_id: account.id, ...body}).then((res) => {
                    console.log("created", {res})
                    if (res.status === 201) {
                        const response_data = res.data
                        const prompt_id = response_data.id
                        const prompt_content = response_data.content
                        setIsUpdating(false)
                        setUpdatedPrompt(response_data)
                        onEdit(response_data)
                        // setNewPrompt({id: prompt_id, content: prompt_content})
                    } else {
                        message.error(res.data)
                        setIsUpdating(false)
                    }
                }).catch((err) => {
                    message.error(err)

                    onEdit(null)
                }).finally(() => {
                    setIsUpdating(false)
                })
            } else {
                updatePrompt(prompt.id, body).then((res) => {
                    console.log("updated", {res})
                    if (res.status === 200) {
                        const response_data = res.data
                        const prompt_id = response_data.id
                        const prompt_content = response_data.content
                        setIsUpdating(false)
                        setUpdatedPrompt(response_data)
                        onEdit(response_data)
                        // setUpdatedPrompt({id: prompt_id, content: prompt_content})
                    } else if (res.status === 404) {
                        console.log("404 err")
                        message.error("Not able to update")
                        setIsUpdating(false)
                    } else {
                        message.error(res.data)
                        setIsUpdating(false)
                    }
                }
                ).catch((err) => {
                    message.error(err)
                    onEdit(null)
                }).finally(() => {
                    setIsUpdating(false)
                })
            }
        }
    }

    const updatedPromptInfo = (prompt: PromptType) => {
        return <>
            <Typography.Paragraph>Prompt Edited!</Typography.Paragraph>
            <>
                <Typography.Paragraph>Prompt: <Link to={`/prompt/${prompt.id}`}>{prompt.name ? prompt.name : prompt.id}</Link></Typography.Paragraph>
                <Typography.Paragraph>Content:</Typography.Paragraph>
                <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{prompt.content}</Typography.Paragraph>
                <Typography.Paragraph></Typography.Paragraph>
            </>
            <Space>
                <Button type="primary" onClick={handleCancel}>
                    Okay
                </Button>
            </Space>

        </>

    }

    const newPromptInfo = (prompt: PromptType) => {
        return <>
            <Typography.Paragraph>Prompt Created!</Typography.Paragraph>
            <>
                <Typography.Paragraph>Prompt: <Link to={`/prompt/${prompt.id}`}>{prompt.name ? prompt.name : prompt.id}</Link></Typography.Paragraph>
                <Typography.Paragraph>Content:</Typography.Paragraph>
                <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{prompt.content}</Typography.Paragraph>
                <Typography.Paragraph></Typography.Paragraph>
            </>

            <Space>
                <Button type="primary" onClick={handleCancel}>
                    Okay
                </Button>

                <Button type="primary" onClick={handleReset}>
                    Create another
                </Button>
            </Space>
        </>

    }

    const categoryOptions: SelectProps['options'] = [
        '#SocialMedia',
        '#finance',
        '#SelfHelp',
        '#Learning'
    ].map((i) => ({value: i, label: i}))

    const modelOptions: SelectProps['options'] = [
        'GPT',
        'GPT-3.5',
        'GPT-4',
        'GPT-4o',
        'GPT-4 Vision',
        'GPT-4 Code Interpreter',
        'DALL-E 2',
        'Stable Diffusion',
        'Midjourney',
        "Llama2",
        "Llama3",
        // '#Learning'
    ].map((i) => ({value: i, label: i}))
    console.log("edit prompt", {prompt})

    const formBody = (
        <Form
            name="basic"
            form={form}
            layout="vertical"
            // labelCol={{ span: 4 }}
            // wrapperCol={{ span: 20 }}
            initialValues={prompt ? {
                content: prompt.content,
                description: prompt.description,
                is_public: prompt.is_public,
                name: prompt.name,
                platforms: prompt.tags?.filter((tag) => tag.category == 'Platform').map((tag) => tag.name)
            } : {}}
            onFinish={onEditPromptSubmit}
            onFinishFailed={onEditPromptFailed}
            autoComplete="off"
            requiredMark={"optional"}

            >
                
                {(isUpdating && (
                    <Typography.Paragraph>Loading...</Typography.Paragraph>
                ))}
                {account && (
                    <Typography.Paragraph>
                        Changing your Prompt's content or the name and description so you can easily find and use it later or share it with others. The name and description can be automatically populated if you leave them blank.
                    </Typography.Paragraph>
                )}
                {!account && (
                    <Typography.Paragraph>
                        Log in to submit a Prompt to your Prompt Library so you can find and use it later or share it with others.
                    </Typography.Paragraph>
                )}

                    <>
                    <Form.Item label="Name" style={{marginBottom: '0'}}
                        tooltip={{ title: 'Some name for the prompt. If left unfilled, PyroPrompts will attempt to generate one.', icon: <InfoCircleOutlined /> }}
                    >
                        <Row gutter={24}>
                            <Col span={12} xs={24} md={12}>
                                <Form.Item
                                    name="name"
                                    // style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                    // extra={<>Name your prompt so you can easily identify it.</>}

                                >
                                    <Input placeholder='ex. Business SWOT Analysis v1, News Article Analysis, Weekly Meal Planner'/>
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} md={12}>
                            <Form.Item name="is_public" valuePropName="checked"
                                tooltip={{ title: 'Some name for the prompt. If left unfilled, PyroPrompts will attempt to generate one.', icon: <InfoCircleOutlined /> }}

                                    // style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                    // extra={<> Allow all other people to see this. You can make this public later.</>}

                            >
                                <Checkbox> Publicly Viewable</Checkbox>
                            </Form.Item>
                            </Col>
                        </Row>

                    </Form.Item>
                        {/* <Form.Item
                            label="Name"
                            name="name"
                            wrapperCol={{ span: 8, offset: 0 }}
                            // extra={<>Name your prompt so you can easily identify it.</>}

                        >
                            <Input placeholder='ex. Business SWOT Analysis v1, News Article Analysis, Weekly Meal Planner'/>
                        </Form.Item> */}

                        <Form.Item
                            label="Prompt"
                            name="content"
                            extra={"Include Square Brackets for text you want to be able to fill in to a reusable Prompt Template."}
                            required
                            tooltip={{ title: 'The text of the prompt. What you would paste into the AI tool.', icon: <InfoCircleOutlined /> }}
                            
                        >
                            <Input.TextArea
                                showCount
                                maxLength={account?.prompt_length_cap || 5000}
                                style={{ height: 120 }}
                                // onChange={onChange}
                                placeholder="ex. Summarize the following text: [Text to Summarize]"
                            />
                        </Form.Item>


                        <Row gutter={24}>
                            <Col span={12} xs={24} md={12}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    tooltip={{ title: 'Some description for the prompt. If left unfilled, PyroPrompts will attempt to generate one.', icon: <InfoCircleOutlined /> }}


                                >
                                    <Input.TextArea
                                        showCount
                                        maxLength={1600}
                                        style={{ height: 80 }}
                                        // onChange={onChange}
                                        placeholder="A description of what the prompt does, which platform or model to use it with, why it is useful, if any ChatGPT plugins are needed, or any other specific notes about it"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} md={12}>
                                <Form.Item
                                    label="Platform(s)"
                                    name="platforms"
                                    tooltip={{ title: 'Which platforms or models the prompt is applicable to', icon: <InfoCircleOutlined /> }}
                                >
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder="GPT, Bard, Stable Diffusion..."
                                        // onChange={handleChange}
                                        options={modelOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item wrapperCol={isSmallScreen? {} : { span: 4, offset: 20 }} label={account ? undefined : <>You must be logged in to update a Prompt</>}>
                            <Space style={{float: 'right'}}>
                            <Button type="default" style={{ float: 'right'}} htmlType="button" onClick={() => {
                                setIsCreateNew(true);
                                form.submit()
                            }} disabled={
                                !account || isUpdating || updatedPrompt !== null || !promptContent}>
                                <PlusOutlined /> Create as New Prompt
                            </Button>
                            <Button type="primary" style={{ float: 'right'}} htmlType="submit" disabled={
                                !account || isUpdating || updatedPrompt !== null || !promptContent || account.id !== prompt.account.id}>
                                Save
                            </Button>

                            </Space>
                        </Form.Item>
                    </>
            </Form>
    )

    return (
            <Modal title="Edit Prompt" open={isOpen} footer={null} onCancel={handleCancel} width={isSmallScreen ? '100%': '62%'}>
                {!updatedPrompt && (formBody)}
                {updatedPrompt && !isCreateNew && (updatedPromptInfo(updatedPrompt))}
                {updatedPrompt && isCreateNew && (newPromptInfo(updatedPrompt))}
                
            </Modal>
    )

    
}
