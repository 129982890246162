import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector, useIsBigScreen } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';

import { CheckOutlined, DeleteOutlined, ShoppingCartOutlined, SketchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, List, Space, Tooltip, Typography, Badge, Alert, Card, Divider } from 'antd';
import { Link } from "react-router-dom";
import { default as BoltIcon } from '../../images/bolt'
import { default as BankNotesIcon } from '../../images/banknotes'

import { AccountBillingMethod, StoreCartItem } from '../../types/types';
import { deleteStoreCartItem, updateStoreCartItem, getAccountBillingMethod, getStripeCheckout, getLemonSqueezyCheckout } from '../../services/signalApi';
import { effectiveMonthlyUnitPrice, effectiveYearUnitPrice, cartItemUnitPrice, cartItemTotalPrice, cartTotalPrice } from '../../utils'
import { StoreFront } from '../../components/StoreFront';


import {
    closeCart, initCart, openCart, selectCartId,
    selectCartItems,
    selectCartOpen,
    refreshCart,
} from './cartSlice';

interface QuantityUpdate {
    cart_item_id: string
    new_quantity: number
}

export function Cart({
    defaultText='Store'
}: {defaultText?: string}) {

    const dispatch = useAppDispatch();
    const account = useAppSelector(selectAccount);
    const open = useAppSelector(selectCartOpen);
    const cartId = useAppSelector(selectCartId);
    const cartItems = useAppSelector(selectCartItems);
    const [quantityUpdates, setQuantityUpdates] = useState<QuantityUpdate[]>([])
    const [showBillingMethod, setShowBillingMethod] = useState<boolean>(false)
    const [showCreditsDrawer, setShowCreditsDrawer] = useState<boolean>(false)
    const [billingMethod, setBillingMethod] = useState<AccountBillingMethod | null>(null)
    const [checkoutUrl, setCheckoutUrl] = useState<string | null>(null)
    const isBigScreen = useIsBigScreen()


    const showDrawer = () => {
        dispatch(openCart());
    };

    const closeDrawer = () => {
        setShowBillingMethod(false)
        dispatch(closeCart());
    };

    useEffect(() => {
        dispatch(initCart())
    }, [account?.id])

    useEffect(() => {
        if (account && checkoutUrl && billingMethod === null) {
            getAccountBillingMethod(account.id).then((res) => {
                // console.log("getAccountBillingMethod", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results) {
                        const results = response_data.results.filter((billing_method) => billing_method.billing_method !== "stripe")
                        if (results.length > 0) {
                            const accountBilling = response_data.results[0]
                            setBillingMethod(accountBilling)
                            return accountBilling
                        }

                    }
                }
            })
        }
    }, [checkoutUrl, account?.id])

    useEffect(() => {
        // console.log("show billing method useEffect")
        if (showBillingMethod) {
            if (account && cartId) {

                getAccountBillingMethod(account.id).then((res) => {
                    // console.log("getAccountBillingMethod", {res})
                    if (res.status === 200) {
                        const response_data = res.data
                        if (response_data.results) {
                            const results = response_data.results.filter((billing_method) => billing_method.billing_method !== "stripe")
                            if (results.length > 0) {
                                const accountBilling = response_data.results[0]
                                setBillingMethod(accountBilling)
                                return accountBilling
                            }
                        }
                    }
                }).then((accountBilling) => {
                    if (accountBilling) {
                        if (accountBilling.billing_method === 'stripe') {
                            return getStripeCheckout(cartId)
                        } else if (accountBilling.billing_method === 'lemonsqueezy') {
                            return getLemonSqueezyCheckout(cartId)
                        }else {
                            console.log("other billing method")
                        }
                    } else {

                        return getLemonSqueezyCheckout(cartId)
                    }
                }).then((res) => {
                    // console.log("getCheckoutCallback", {res})
                    if (res && res.status === 200) {
                        const response_data = res.data
                        if (response_data.url) {
                            setCheckoutUrl(response_data.url)
                        }
                    }
                })
            }
        } else {
            setCheckoutUrl(null)
        }
    }, [showBillingMethod])

    const deleteCartItem = (cartItem: StoreCartItem) => {
        deleteStoreCartItem(cartItem.id).then(() => {
            dispatch(refreshCart())
            setCheckoutUrl(null)
            setShowBillingMethod(false)
        })
    }

    const updateCartItemQuantity = (cartItem: StoreCartItem) => {
        const existingUpdates = quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id === cartItem.id)
        if (existingUpdates.length > 0) {
            updateStoreCartItem(cartItem.id, {quantity: existingUpdates[0].new_quantity}).then(() => {
                const newQuantityUpdates = [...quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id !== cartItem.id)]
                setQuantityUpdates(newQuantityUpdates)
                dispatch(refreshCart())
                setCheckoutUrl(null)
                setShowBillingMethod(false)
            })
        }

    }

    const handleQuantitySelectionChange = (cartItem: StoreCartItem, quantity: number | undefined | null) => {
        // const existingUpdate = quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id === cartItem.id)
        if (quantity) {
            const newQuantityUpdates = [...quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id !== cartItem.id), {cart_item_id: cartItem.id, new_quantity: quantity}]
            setQuantityUpdates(newQuantityUpdates)
        }

    }

    const quantitySelectionChanged = (cartItem: StoreCartItem) => {
        const existingUpdates = quantityUpdates.filter((unsavedUpdate) => unsavedUpdate.cart_item_id === cartItem.id)
        if (existingUpdates.length === 0){
            return false
        }
        if (existingUpdates[0].new_quantity !== cartItem.quantity) {
            return true
        }
        return false

    }

    const itemList = (cartItems: StoreCartItem[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="store-cart-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={cartItems === null ? [] : cartItems}
            renderItem={(cartItem: StoreCartItem) => (
                <List.Item
                    key={cartItem.id}
                    // onClick={() => {showSignalDetails(signal)}}
                    actions={[
                        ...(cartItem.item.multiple_disallowed === 1 ? [] : [
                            <Space key={'quantity'}>
                                <Input key={cartItem.quantity} type='number' defaultValue={cartItem.quantity} style={{width: '80px'}} onChange={(e) => {handleQuantitySelectionChange(cartItem, parseInt(e.target.value, 10))}} />
                                <Tooltip placement='right' title="Update Quantity">
                                    <Button disabled={!quantitySelectionChanged(cartItem)} icon={<CheckOutlined />} onClick={() => {updateCartItemQuantity(cartItem)}}/>

                                </Tooltip>
                            </Space>
                        ])

                    ]}
                    extra={
                        <Tooltip placement='bottom' title="Remove from Cart">
                            <Button icon={<DeleteOutlined />} onClick={() => {deleteCartItem(cartItem)}}/>

                        </Tooltip>
                        // <span>${(cartItem.quantity * (cartItem.item.month_price_cents || 0) / 100).toFixed(2)}</span>
                    }
                >
                    <List.Item.Meta
                        key={cartItem.id}
                        title={<span>{cartItem.item.name}</span>}
                        description={
                            <>
                                <Typography.Paragraph>{cartItem.quantity} x ${cartItemUnitPrice(cartItem)} = ${cartItemTotalPrice(cartItem)} {cartItem.recurring !== 'lifetime' ? `/ ${cartItem.recurring}` : ''}</Typography.Paragraph>
                                <Typography.Paragraph>{cartItem.item.description}</Typography.Paragraph>
                            </>
                        }
                    />

                </List.Item>
            )}
        />
    }

    const cartTotal = (cartItems: StoreCartItem[]) => {
        return <Typography.Paragraph style={{textAlign: 'right'}}>
            Total: ${cartTotalPrice(cartItems)} {cartItems[0]?.recurring !== 'lifetime' ? `/ ${cartItems[0]?.recurring}` : ''}
        </Typography.Paragraph>
    }

    const onCheckoutClick = () => {
        setShowBillingMethod(true)
    }

    const cantCheckoutReason = () => {
        const allYearRecurring = (cartItems || []).filter((cartItem) => cartItem.recurring === 'year')
        const allMonthRecurring = (cartItems || []).filter((cartItem) => cartItem.recurring === 'month')
        if (allYearRecurring.length > 0 && allMonthRecurring.length > 0) {
            return "Can not mix subscription durations in a single checkout. Please remove either the month or year subscriptions, you may add them in a separate checkout."
        }
        return null
    }

    return <>
        {/* <span onClick={showDrawer}>Cart</span> */}
        {/* {!open && ( */}
        {account && (
            <>
                <Button type="text" icon={<BankNotesIcon style={{fontSize: "1.4em"}} height={"0.7em"} />} onClick={() => setShowCreditsDrawer(true)}>
                    {account?.current_credits ? (
                        <>&nbsp;{account.current_credits}</>
                    ) : (
                        <>&nbsp;0 Credits</>
                    )}
                </Button>
                <Button type="text" icon={<ShoppingCartOutlined />} onClick={showDrawer}>
                    {cartItems && cartItems.length > 0 ? `Cart (${cartItems.length})` : defaultText}
                </Button>
            </>
        )}
        {!account && (
            <Link to={"/store"}>
                <Button type="text" icon={<ShoppingCartOutlined />}>
                    {defaultText}
                </Button>
            </Link>
        )}





        {/* {cartItems && cartItems.length > 0 ? (
            <Badge count={cartItems.length} offset={[-22, 4]} size='small'>
                <Button type="text" style={{color: 'rgba(255, 255, 255, 0.85)', marginRight: '12px'}} icon={<ShoppingCartOutlined />} onClick={showDrawer}>
                    Cart ({cartItems.length})
                </Button>
            </Badge>
        ) : (

        )} */}

        {/* <Badge count={cartItems && cartItems.length > 0 ? cartItems.length : }>
                <Button type="text" style={{color: 'rgba(255, 255, 255, 0.85)'}} icon={<ShoppingCartOutlined />} onClick={showDrawer}>
                    Cart
                </Button>
            </Badge> */}

        {/* )} */}

        <Drawer title="Credits" placement="right" onClose={() => setShowCreditsDrawer(false)} open={showCreditsDrawer} mask={false}
            footer={
                <Typography.Text style={{fontSize: "0.8rem"}}>See <Link to={'/terms_and_conditions#credits'}>Terms and Conditions</Link></Typography.Text>
            }
        >
            {account && (
                <>
                    <Typography.Paragraph>You have <Typography.Text strong>{account?.current_credits}</Typography.Text> credits</Typography.Paragraph>
                    <Typography.Paragraph>Credits are used to run Workflows on PyroPrompts.</Typography.Paragraph>
                    <Space>
                        <Link to="/account">See Credit History</Link>
                        <Divider type='vertical' />
                        <Link to="/store">Buy Credits</Link>
                    </Space>
                </>
            )}
            {!account && (
                <>
                    <Typography.Paragraph>You are not logged in, so you have no credits.</Typography.Paragraph>
                    <Typography.Paragraph>Credits are used to run Workflows on PyroPrompts.</Typography.Paragraph>
                    <Space>
                        <Link to="/store">Buy Credits</Link>
                    </Space>
                </>
            )}

            <Typography.Title level={4}>Get Credits</Typography.Title>
            <Typography.Paragraph>We send credits regularly to our <Link to='/newsletter'>newsletter</Link> subscribers.</Typography.Paragraph>
            <Typography.Paragraph>Share your experience with PyroPrompts on 𝕏, tag <a href='https://x.com/pyroprompts' target='_blank' rel="noreferrer">@pyroprompts</a> and we'll send you some.</Typography.Paragraph>
            <Typography.Title level={5}>Buy Credits</Typography.Title>

            {(cartItems || []).length == 0 && (
            // {cartItems?.find((cartItem) => cartItem.item.sku === "credits_100") === undefined && (
                <StoreFront style='list' skus={["credits_100"]} cardStyle={{boxShadow: 'none'}} includeDescription={false}/>
            )}




        </Drawer>

        <Drawer title="Cart" placement="right" onClose={closeDrawer} open={open} mask={false}>
            {(!cartId || (cartItems || []).length === 0) && (
                <Typography.Paragraph>{'There is nothing in your cart.'}</Typography.Paragraph>
            )}
            <Typography.Paragraph style={{textAlign: 'right'}}>
                {/* <Link to={`/store`}><Button type={"text"} onClick={closeDrawer}>Go to the Store</Button></Link> */}
                <Link to={`/store`} onClick={closeDrawer}>Go to the Store</Link>
            </Typography.Paragraph>
            {(cartItems || []).length > 0 && (
                <Space direction='vertical' style={{width: '100%'}}>
                    {cartTotal(cartItems || [])}
                    {itemList(cartItems || [])}
                    {cartTotal(cartItems || [])}
                    {cantCheckoutReason() && (
                        <Alert message={cantCheckoutReason()} type="error" />
                    )}
                    {!showBillingMethod && (
                        <Button style={{width: '100%'}} size='large' disabled={cantCheckoutReason() !== null} onClick={() => {onCheckoutClick()}}>Proceed to Checkout</Button>
                    )}
                    {/* {(checkoutUrl || (showBillingMethod && billingMethod?.billing_method === 'stripe')) && (
                        <>
                            <Typography.Paragraph style={{fontSize: '0.75em'}}>I authorize PyroPrompts to charge me automatically until I cancel my subscription. I acknowledge that refunds are made according to the <a href='/terms_and_conditions' target='blank'>Terms of Use</a>. The subscription can be canceled at any time from the Billing section in the Account settings. I have read, and agree to, PyroPrompts's <a href='/terms_and_conditions' target='blank'>Terms of Use</a> and <a href='/privacy_policy' target='blank'>Privacy Policy</a>.</Typography.Paragraph>
                            <Button style={{width: '100%'}} size='large' {...(checkoutUrl === null ? {disabled: true} : {href: checkoutUrl})} target='blank'>Pay with Stripe</Button>
                        </>

                    )} */}
                    {(checkoutUrl || (showBillingMethod && billingMethod?.billing_method === 'lemonsqueezy')) && (
                        <>
                            <Typography.Paragraph style={{fontSize: '0.75em'}}>I authorize PyroPrompts to charge me automatically until I cancel my subscription. I acknowledge that refunds are made according to the <a href='/terms_and_conditions' target='blank'>Terms of Use</a>. The subscription can be canceled at any time from the Billing section in the Account settings. I have read, and agree to, PyroPrompts's <a href='/terms_and_conditions' target='blank'>Terms of Use</a> and <a href='/privacy_policy' target='blank'>Privacy Policy</a>.</Typography.Paragraph>
                            <Button style={{width: '100%'}} size='large' {...(checkoutUrl === null ? {disabled: true} : {href: checkoutUrl})} target='blank'>Pay with LemonSqueezy</Button>
                        </>

                    )}

                </Space>
            )}
            {(cartItems || []).length == 0 && (
            // {cartItems?.find((cartItem) => cartItem.item.sku === "credits_100") === undefined && (
                <StoreFront style='list' skus={["credits_100"]} cardStyle={{boxShadow: 'none'}}/>
            )}

        </Drawer>
    </>
    // return <>Cart</>
}
