import React, { useState } from 'react';
import { Form, Modal, Checkbox, Input, Typography, Button, Select, Alert } from 'antd';
import { subscribeDataSet, unsubscribeDataSet } from '../services/signalApi'

import { selectAccount } from '../features/login/loginSlice'
import { DataProvider, DataSet, DataSetSubscptionMethod } from '../types/types';

import { DiscordLink } from './DiscordLink'
import { useAppSelector, useAppDispatch } from '../app/hooks';
const { Option, OptGroup } = Select;


interface DataSetSubscribeModalParams {
    subscribingDataSet: DataSet
    subscriptionMethod?: DataSetSubscptionMethod
    isOpen: boolean
    onCancel: () => void
    onCreate: () => void
}


export function DataSetSubscribeModal({
    subscribingDataSet,
    subscriptionMethod,
    isOpen,
    onCancel,
    onCreate,
}: DataSetSubscribeModalParams) {
    const [form] = Form.useForm();
    const subscriptionTerms = Form.useWatch('terms', form);

    const account = useAppSelector(selectAccount);
    const [isCreating, setIsCreating] = useState(false);
    const [submissionError, setSubmissionError] = useState<string | null>(null);


    console.log('CreateSignalModal', {subscribingDataSet, isCreating})

    const handleCancel = () => {
        form.resetFields()
        setIsCreating(false)
        onCancel()
    }

    const onSubscribeSubmitFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onSubmit = (values: any) => {
        console.log('Success:', {values, account});
        if (subscribingDataSet.subscribed){
            return onUnsubscribe(values)
        } else {
            return onSubscribe(values)
        }
    }

    const onSubscribe = (values: any) => {
        if (account) {
            console.log("onCreateSignal values", {values})
            setIsCreating(true)
            setSubmissionError(null)
            let subscriptionCall;
            if (subscriptionMethod){
                subscriptionCall = subscribeDataSet(account.id, subscribingDataSet.id, subscriptionMethod.id)
            } else {
                subscriptionCall = subscribeDataSet(account.id, subscribingDataSet.id)
            }

            subscriptionCall.then((res) => {
                console.log("created", {res})
                if (res.status === 201 ) {
                    const response_data = res.data
                    setIsCreating(false)
                    onCreate()

                } else if (res.status === 400) {
                    const response_data = res.data
                    setSubmissionError(response_data)
                } else {
                    console.log("failed", {res})
                }

            }
            ).catch((res) => {
                console.log("failed catch", {res})

            }).finally(() => {
                setIsCreating(false)
                // onCreate()
                form.resetFields()
            })
        }
    }

    const onUnsubscribe = (values: any) => {
        if (account) {
            console.log("onCreateSignal values", {values})
            setIsCreating(true)
            setSubmissionError(null)

            unsubscribeDataSet(account.id, subscribingDataSet.id).then((res) => {
                console.log("created", {res})
                if (res.status === 201 ) {
                    const response_data = res.data
                    setIsCreating(false)

                } else if (res.status === 400) {
                    const response_data = res.data
                    setSubmissionError(response_data)
                } else {
                    console.log("failed", {res})
                }
            }
            ).catch((res) => {
                console.log("failed catch", {res})

            }).finally(() => {
                setIsCreating(false)
                onCreate()
                form.resetFields()
            })
        }
    }

    const ownedDataSet = (account && subscribingDataSet.data_provider.account_id === account.id)


    return (
        <Modal title="Subscribe to Dataset" open={isOpen} footer={null}  onCancel={handleCancel}>
        <Form
            name="basic"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            onFinishFailed={onSubscribeSubmitFailed}
            autoComplete="off"
            >
                {subscribingDataSet !== null && (
                    <>
                        <Typography.Paragraph>Name: {subscribingDataSet.name}</Typography.Paragraph>
                        {(ownedDataSet && subscribingDataSet.is_public == 0) && (
                            <Typography.Paragraph>Not public. This is your dataset.</Typography.Paragraph>
                        )}
                        {(!ownedDataSet && subscriptionMethod && subscribingDataSet.is_public == 0) && (
                            <Typography.Paragraph>Not public. You used a code to access this dataset. You might not be able to use this code to subscribe later.</Typography.Paragraph>
                        )}
                        {(!ownedDataSet && !subscriptionMethod && !subscribingDataSet.subscribed && subscribingDataSet.is_public == 0) && (
                            <Typography.Paragraph>Not public. You have special access to this dataset. You might not be able to subscribe later.</Typography.Paragraph>
                        )}
                        {(!ownedDataSet && !subscriptionMethod && subscribingDataSet.subscribed && subscribingDataSet.is_public == 0) && (
                            <Typography.Paragraph>Private dataset. You have special access to this.</Typography.Paragraph>
                        )}
                        {(ownedDataSet && subscribingDataSet.is_public == 1) && (
                            <Typography.Paragraph>Public data set. This is your dataset.Everyone can see this dataset and subscribe to it.</Typography.Paragraph>
                            )}
                        {(subscribingDataSet.is_public == 1) && (
                            <Typography.Paragraph>Public data set. Everyone can see this dataset and subscribe to it.</Typography.Paragraph>
                        )}

                        {(subscribingDataSet.is_published == 0) && (
                            <Alert message='This dataset is not published. Expect inconsistency.' type="warning" />
                            )}
                        {(subscribingDataSet.subscribed && subscribingDataSet.is_public == 0 && !ownedDataSet) && (
                            <Alert message='This dataset is private. Re-subscribing might not be possible later if you unsubscribe. Are you sure?' type="warning" />
                        )}
                        
                    <Typography.Paragraph></Typography.Paragraph>
                    </>
                

                )}
                

                {submissionError && (
                    <>Error: {submissionError}</>
                )}

                {(subscribingDataSet?.subscribed) && (
                    <Form.Item wrapperCol={{ span: 16 }}>
                        <Button type="primary" htmlType="submit">
                        Unsubscribe
                        </Button>
                    </Form.Item>
                )}

                {(!subscribingDataSet?.subscribed) && (
                    <>
                        <Form.Item name="terms" valuePropName="checked" wrapperCol={{ span: 16 }}>
                            <Checkbox>Agree to <a href='/terms_and_conditions' target='blank'>Terms</a></Checkbox>
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 16 }}>
                            <Button type="primary" htmlType="submit" disabled={!subscriptionTerms}>
                                Subscribe
                            </Button>
                        </Form.Item>
                    </>
                )}

            </Form>
        </Modal>
    )

    
}
