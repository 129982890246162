import {createElement} from 'react';
import { Space, Tooltip } from 'antd';
import { Prompt as PromptType, Account as AccountType } from '../types/types'
import { PromptMarker } from './PromptMarker' 
import { MinusCircleOutlined, StarOutlined, EyeOutlined, LikeOutlined, MessageOutlined, UploadOutlined, CopyOutlined, FireOutlined, RightOutlined, EyeInvisibleOutlined, BookFilled, BookOutlined, CheckCircleFilled, StarFilled, DownloadOutlined, GlobalOutlined } from '@ant-design/icons';

import { IconText } from './IconText'
import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getSignals, getSignalsLoggedOut, getPrompts, getPromptsLoggedOut, createAccountPromptMark, deleteAccountPromptMark } from '../services/signalApi';


interface PromptSaveMarkerParams {
    prompt: PromptType
    optimisticOnClick?: boolean
    include_text_in_count?: boolean,
    onClick?: (prompt: PromptType) => void
}



export function PromptSaveMarker({
  prompt,
  optimisticOnClick=false,
  include_text_in_count=false,
  onClick
}: PromptSaveMarkerParams) {

  const account = useAppSelector(selectAccount)

  const onSaveToLibrary = (prompt: PromptType) => {
    console.log("onSaveToLibrary", {account, prompt})
    if (account) {
        if (optimisticOnClick && onClick) {
          onClick(prompt)
        }
        if (prompt.saved) {
            deleteAccountPromptMark({
                account_id: account?.id,
                prompt_id: prompt.id,
                mark_type: 'save'
            }).then(() => {
              if (!optimisticOnClick && onClick) {
                onClick(prompt)
              }
            })
        } else {
            createAccountPromptMark({
                account_id: account?.id,
                prompt_id: prompt.id,
                mark_type: 'save'
            }).then(() => {
              if (!optimisticOnClick && onClick) {
                onClick(prompt)
              }
            })
        }
    }
  }


  return <PromptMarker
    account={account}
    count_field='save_count'
    prompt_marked_field='saved'
    prompt={prompt}
    icon={BookOutlined}
    icon_marked={BookFilled}
    onClick={onSaveToLibrary}
    tooltip='Save to Library'
    text='Save'
    tooltip_logged_out='Log in to Save'
    tooltip_marked='Saved to Library'
    include_text_in_count={include_text_in_count}
  />

};
