import type { MenuProps } from 'antd';
import { Button, Col, ConfigProvider, Divider, Empty, List, Row, Space, Spin, Tooltip, Typography } from 'antd';
import { ReactElement, useEffect, useState } from 'react';

// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { CopyOutlined, EyeInvisibleOutlined, GlobalOutlined, LoadingOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useIsBigScreen } from '../app/hooks';

import type { RadioChangeEvent } from 'antd';

import { DateTime } from 'luxon';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { DateFilter, Prompt, Tag as TagType } from '../types/types';
// import styles from './SignalList.module.css';
import { default as BearIcon } from '../images/bear';
import { default as BullIcon } from '../images/bull';
import { AccountLink } from './AccountLink';
import { CopyToClipboard } from './CopyToClipboard';
import { CreatePrompt } from './CreatePrompt';
import { IconText } from './IconText';
import { Loading } from './Loading';
import { PromptBrowserFilter } from './PromptBrowserFilter';
import { PromptDetailsModal } from './PromptDetailsModal';
import { PromptSaveMarker } from './PromptSaveMarker';
import { PromptStarMarker } from './PromptStarMarker';
import { PromptTag } from './PromptTag';
import { UserAvatar } from './UserAvatar';


import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectTheme } from '../features/lightDarkSwitch/lightDarkSwitchSlice';
import { selectAccount } from '../features/login/loginSlice';
import { getPrompts, getPromptsLoggedOut } from '../services/signalApi';
import { getThemeGreen, getThemeRed } from '../themeRegistry';
// import {populatePrompt} from '../features/aiChat/aiChatSlice'


interface PromptListParams {
    prompts: Prompt[]
    limit: number | undefined
    promptsCount: number
    onLoadMore: () => void
    isLoadingMore: boolean
    onStar?: (prompt: Prompt) => void
    onSaveToLibrary?: (prompt: Prompt) => void
    getPromptAvatar?: (prompt: Prompt) => ReactElement
    onSelect?: (prompt: Prompt) => void
    includeActions?: boolean

}


const loading = <LoadingOutlined spin />;
const spin_loading = <Spin indicator={loading} />

export function PromptList({
    prompts,
    limit,
    promptsCount,
    onLoadMore,
    isLoadingMore,
    onStar,
    onSaveToLibrary,
    getPromptAvatar,
    onSelect,
    includeActions=true,
}: PromptListParams) {
    const account = useAppSelector(selectAccount)
    const dispatch = useAppDispatch();
    const keyedPrompts = prompts
    return (
        <ConfigProvider renderEmpty={() => <Empty description={account ? "No Prompts Found" : "No Prompts found, consider logging in or changing filters."} image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
        <List
        className="prompt-stream"
        // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
        itemLayout={'vertical'}
        // size="large"
        loadMore={limit === undefined && prompts && promptsCount > prompts.length && prompts.length > 0 ? (
            <div
                style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore} disabled={isLoadingMore}>{isLoadingMore ? <>{spin_loading} &nbsp;&nbsp;Loading</> : 'Load more'}</Button>
            </div>
        ) : null}
        loading={keyedPrompts === null}
        dataSource={keyedPrompts === null ? [] : keyedPrompts}
        renderItem={(prompt: Prompt) => (
            <List.Item
                key={prompt.id}
                // onClick={() => {showPromptDetails(prompt)}}
                actions={includeActions ? [
                    <Space wrap>
                        <PromptStarMarker prompt={prompt} onClick={onStar} include_text_in_count={true} />
                        <Divider type='vertical' />
                        <PromptSaveMarker prompt={prompt} onClick={onSaveToLibrary} include_text_in_count={true} />
                        <Divider type='vertical' />
                        <CopyToClipboard text={prompt.share_url} tooltip='Copy Share URL' onCopy={() => ReactGA.event("copy_prompt_url", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-stream'})}  content={<IconText icon={UploadOutlined} text="Share URL" />} key="list-vertical-share-o"/>
                        <Divider type='vertical' />
                        <CopyToClipboard text={prompt.content || ''} tooltip='Copy Prompt Text' onCopy={() => ReactGA.event("copy_prompt", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-stream'})} content={<IconText icon={CopyOutlined} text="Copy" />} key="list-vertical-copy-o"/>
                        <Divider type='vertical' />
                        {/* <IconText icon={FireOutlined} text="Prompt" onClick={() => {
                            ReactGA.event("trigger_prompt", {prompt_name: prompt.name, prompt_id: prompt.id, placement:'prompt-stream'})
                            dispatch(populatePrompt({prompt: prompt.content || ''}))
                        }} key="list-vertical-prompt-o" tooltip="Start ChatGPT Conversation" />
                        <Divider type='vertical' /> */}
                        <Link to={`/prompt/${prompt.id}/`}>See Details</Link>
                    </Space>,             
                ] : []}
            >
                <List.Item.Meta
                    // have avatar show dataset icon  or data provider ?
                    key={prompt.id}
                    avatar={getPromptAvatar ? getPromptAvatar(prompt) : undefined}

                    title={
                        <>
                        <Space wrap>

                            {onSelect && (
                                <>
                                    {prompt.name && (
                                        <a onClick={() => onSelect(prompt)}>{prompt.name}</a>
                                    )}
                                    {!prompt.name && (
                                        <a onClick={() => onSelect(prompt)}>Prompt</a>
                                    )}
                                </>

                            )}
                            {!onSelect && (
                                <>
                                    {prompt.name && (
                                        <Link to={`/prompt/${prompt.id}/`}>{prompt.name}</Link>
                                    )}
                                    {!prompt.name && (
                                        <Link to={`/prompt/${prompt.id}/`}>Prompt</Link>
                                    )}
                                </>
                            )}
                        </Space>
                        </>
                    }
                    description={
                        <Space direction='vertical'>
                            <Space wrap style={{marginBottom: '4px'}}>
                                <Tooltip placement="top" title={prompt.is_public ? 'Public, anyone can see this prompt' : 'Private, only you can see this prompt'} >
                                    <span  style={{fontSize: "0.8rem", color: "#888"}}>
                                        {prompt.is_public ? <GlobalOutlined />: <EyeInvisibleOutlined />}
                                    </span>
                                </Tooltip>
                                <AccountLink account={prompt.account} />
                                {(prompt.tags || []).map((tag, i) => (
                                    <PromptTag tag={tag} key={tag.id}/>
                                ))}
                                {/* {(prompt.tags || []).map((tag, i) => (
                                    <PromptTag tag={tag} />
                                ))} */}
                            </Space>
                            <Typography.Paragraph style={{whiteSpace: 'pre-line'}} ellipsis={{ rows: 3, expandable: true }}>{prompt.content}</Typography.Paragraph>
                        </Space>
                    }
                />
            </List.Item>
        )}
    />
    </ConfigProvider>
    )
    
}

  
interface PromptQueryParams {
    include_public?: boolean
    // context_filter?: string
}


interface PromptStreamParams {
    name?: string
    account_id?: string
    account_name?: string
    // data_set_id?: string
    // symbol?: string
    showButtons?: boolean
    addContextColumns?: boolean
    queryParams?: PromptQueryParams
    addButtons?: ReactElement
    showCreate?: boolean
    defaultDateFilter?: DateFilter
    ordering?: string
    show_field_as_value?: string
    inverse_bull_bear?: boolean
    limit?: number
    tag_filter_list?: TagType[]
}


const getDateFilterValue = (dateFilter: DateFilter) => {
    const dateFilterValueMapping = {
        '30_days': 30,
        '7_days': 7,
        'today': 1,
    }
    const days = (dateFilterValueMapping[dateFilter] || 7)
    const dt = DateTime.now();
    return dt.minus({days}).plus({hours: 1}).toISODate()
}


export function PromptStream({
    name,
    account_id,
    account_name,
    // data_set_id,
    // symbol,
    showButtons=true,
    queryParams,
    addButtons,
    showCreate=false,
    defaultDateFilter,
    ordering="-created_date",
    limit,
    tag_filter_list,
}: PromptStreamParams) {
    const account = useAppSelector(selectAccount)
    const dispatch = useAppDispatch();
    const [reloadedLast, setReloadedLast] = useState('never');
    const [dateFilter, setDateFilter] = useState<DateFilter>(defaultDateFilter ? defaultDateFilter : '7_days');
    const [contentFilter, setContentFilter] = useState<string | null>(null);
    const [savedFilter, setSavedFilter] = useState<boolean>(false);
    const [starredFilter, setStarredFilter] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [detailPrompt, setDetailPrompt] = useState<Prompt | null>(null);
    const [prompts, setPrompts] = useState<Prompt[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [promptsCount, setPromptsCount] = useState<number>(1);
    const selectedTheme = useAppSelector(selectTheme)
    const isBigScreen = useIsBigScreen()

    const pageSize = limit === undefined ? 10 : limit
    // const effectiveDateFilter = dateFilter ? dateFilter : defaultDateFilter;


    const showPromptDetails = (prompt: Prompt) => {
        setDetailPrompt(prompt)
    }

    const handleDateFilterRadioChange = (e: RadioChangeEvent) => {
        setDateFilter(e.target.value)
        
    }

    const handleDateFilterSelectChange = (value: DateFilter) => {
        setDateFilter(value)
    }


    console.log("PromptStream", {account_id, name, showButtons, queryParams, defaultDateFilter, ordering})

    // const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (defaultDateFilter) {
            setDateFilter(defaultDateFilter)
        }
    }, [defaultDateFilter])

    useEffect(() => {
        const getPromptsFn = account ? getPrompts : getPromptsLoggedOut;
        setTimeout(() => {
        getPromptsFn({
            ...(account_id ? {account: account_id} : {}),
            ...(account_name ? {username: account_name} : {}),
            ...((tag_filter_list || []).filter((t) => t.category == 'Platform').length > 0 ? {
                platform_tags: (tag_filter_list || []).filter((t) => t.category == 'Platform').map((t) => t.id).join(','),
            }: {} ),
            ...((tag_filter_list || []).filter((t) => t.category == 'Domain').length > 0 ? {
                domain_tags: (tag_filter_list || []).filter((t) => t.category == 'Domain').map((t) => t.id).join(',')
            } : {} ),
            // ...(data_set_id ? {data_set: data_set_id} : {}),
            // ...(symbol ? {symbol: symbol} : {}),
            ...(contentFilter ? {content__icontains: contentFilter} : {}),
            ...(savedFilter ? {saved: true} : {}),
            ...(starredFilter ? {starred: true} : {}),
            // ...(dateFilter ? {updated_date__gt: getDateFilterValue(dateFilter)} : {}),
            // include_nested_data_set: true,
            limit: pageSize,
            ...(page > 1 ? {offset: (page - 1) * pageSize}: {}),
            ...queryParams,
            ordering,
        }).then((res) => {
            console.log("prompts", {res})

            const response_data = res.data.results
            if (isLoadingMore && prompts && prompts.length > 0){
                setPrompts([...prompts, ...(response_data || [])])
                setIsLoading(false)
                setIsLoadingMore(false)
            } else {
                setPrompts(response_data || [])
                setIsLoading(false)
                setIsLoadingMore(false)
            }
            // setPrompts(response_data || [])
            if (res.data.count) {
                setPromptsCount(res.data.count)
            }
        }
        ).catch(() => {
            setPrompts([])
        }).finally(() => {
            setIsLoading(false)
            setIsLoadingMore(false)
        })
    }, Math.floor(Math.random() * 100))
    // }, 5000)
    }, [JSON.stringify({account, tag_filter_list, queryParams}), reloadedLast, dateFilter, page, contentFilter, savedFilter, starredFilter, ordering, account_id, account_name, limit])

    const reloadData = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    const createButton = (showCreate && account) ? <CreatePrompt onCreate={reloadData} /> : undefined
    const buttonsToAdd = createButton ? (addButtons ? <>{addButtons}{createButton}</> : createButton) : (addButtons ? addButtons : undefined)

    const head = (
        name ? <Typography.Title level={1}>{name}</Typography.Title> : <></>
    )

    // const up = <UpCircleOutlined  style={{ color: '#389e0d' }} />
    // const up = <ArrowUpOutlined  />
    const up = <BullIcon />
    // const down = <DownCircleOutlined  style={{ color: '#cf1322' }} />
    // const down = <ArrowDownOutlined />
    const down = <BearIcon />
    const flat = <MinusCircleOutlined style={{ color: '#1765ad' }} />


    const bullish_color = getThemeGreen(selectedTheme)
    const bearish_color = getThemeRed(selectedTheme)

    const getPromptAvatar = (prompt: Prompt) => {
        // const context_field = show_field_as_value !== undefined ? show_field_as_value : 'forecast'
        // const value = prompt.context[context_field] || prompt.context?.last_move || prompt.value
        // const bull_bear_value = (inverse_bull_bear && Number(value)) ? Number(value) * -1 : value
        // const icon = bull_bear_value === 0 ? flat : bull_bear_value > 0 ? up : down
        // const style = bull_bear_value === 0 ? {} : bull_bear_value > 0 ? { backgroundColor: bullish_color } : { backgroundColor: bearish_color }
        // const text = bull_bear_value === 0 ? 'Sideways' : bull_bear_value > 0 ? 'Bullish' : 'Bearish'
        return <Tooltip placement="left" title={"avatar title"}>
            <UserAvatar account={prompt.account} />
            {/* <Avatar size='large'icon={<BullIcon />} /> */}
        </Tooltip>

    }

    // const getPromptValue = (prompt: Prompt) => {
    //     if (show_field_as_value && prompt.context && prompt.context[show_field_as_value]) {
    //         return prompt.context[show_field_as_value]
    //     }
    //     return prompt.value
    // }

    const onSearch = (value: string) => {
        console.log("onSearch", {value})
        setContentFilter(value)
    }

    const updatePromptState = (prompt: Prompt, updateParams: {saved?: boolean, starred?: boolean, star_count?: number, save_count?: number}) => {
        if (prompts) {
            const i = prompts.findIndex(p => p.id == prompt.id)
            if (i === -1) {
                return
            }
            const newPrompts = [
                ...prompts.slice(0, i),
                Object.assign({}, prompts[i], updateParams),
                ...prompts.slice(i+1),
            ]
            setPrompts(newPrompts)
            console.log("updatePromptState", {prompts, newPrompts, prompt, updateParams})


        }
    }

    const onSaveToLibrary = (prompt: Prompt) => {
        console.log("onSaveToLibrary", {account, prompt})
        if (account) {
            if (prompt.saved) {
                updatePromptState(prompt, {saved: false, save_count: prompt.save_count ? prompt.save_count - 1: undefined})
                // deleteAccountPromptMark({
                //     account_id: account?.id,
                //     prompt_id: prompt.id,
                //     mark_type: 'save'
                // })
            } else {
                updatePromptState(prompt, {saved: true, save_count: prompt.save_count ? prompt.save_count + 1: 1})
                // createAccountPromptMark({
                //     account_id: account?.id,
                //     prompt_id: prompt.id,
                //     mark_type: 'save'
                // })
            }
        }
    }

    const onStar = (prompt: Prompt) => {
        console.log("onStar", {account, prompt})
        if (account) {
            if (prompt.starred) {
                updatePromptState(prompt, {starred: false, star_count: prompt.star_count ? prompt.star_count - 1: undefined})
                // deleteAccountPromptMark({
                //     account_id: account?.id,
                //     prompt_id: prompt.id,
                //     mark_type: 'star'
                // })
            } else {
                updatePromptState(prompt, {starred: true, star_count: prompt.star_count ? prompt.star_count + 1: 1})
                // createAccountPromptMark({
                //     account_id: account?.id,
                //     prompt_id: prompt.id,
                //     mark_type: 'star'
                // })
            }
        }
    }

    const onLoadMore = () => {
        setIsLoadingMore(true)
        setPage(page + 1)
    }

    const menu = (prompt: Prompt): MenuProps['items'] => {
        return [
            {
                label: <a onClick={() => {showPromptDetails(prompt)}}>See more</a>,
                key: '0',
            },
            // {
            //     label: <span>Add to Watchlist</span>,
            //     key: '1',
            // },
            // {
            //     label: <span>Add Label to Symbol</span>,
            //     key: '2',
            // },
            // {
            //     label: <span>Delete</span>,
            //     key: '3',
            // },
            // {
            //     label: <span>Share</span>,
            //     key: '4',
            // },

        ]
    }

    const content = () => {

        return <Space direction='vertical' style={{width: '100%'}}>
            {showButtons && (
                <div style={{paddingTop: '12px', paddingBottom: '12px'}}>
                    <PromptBrowserFilter
                        // symbol={symbol}
                        dateFilter={dateFilter}
                        contentFilter={contentFilter}
                        savedFilter={savedFilter}
                        starredFilter={starredFilter}
                        addButtons={buttonsToAdd}
                        reloadData={reloadData}
                        setDateFilter={setDateFilter}
                        setContentFilter={setContentFilter}
                        setSavedFilter={setSavedFilter}
                        setStarredFilter={setStarredFilter}
                    />
                </div>
            )}

            {isLoading && (
                <Loading message='Prompts' description='Loading Prompts' />
            )}

            {!isLoading && (
                <PromptList
                    prompts={prompts || []}
                    limit={limit}
                    promptsCount={promptsCount}
                    onLoadMore={onLoadMore}
                    isLoadingMore={isLoadingMore}
                    onStar={onStar}
                    onSaveToLibrary={onSaveToLibrary}
                    getPromptAvatar={getPromptAvatar}
                />
            )}

        </Space>

        
    }




    return (
        <>
            {head}
            <Row>
            {/* <Col xs={{span: 24, offset: 0}} md={{span: 22, offset: 1}} lg={{span: 20, offset: 2}} xl={{span: 16, offset: 4}} xxl={{span: 12, offset: 6}}> */}
            <Col xs={{span: 24, offset: 0}}>
                    {content()}
                </Col>
            </Row>
            
            <PromptDetailsModal onCancel={() => {setDetailPrompt(null)}} prompt={detailPrompt}/>

        </>
    )
    
    
}
