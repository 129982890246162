import { Col, Row, Typography, Card, Button } from 'antd';
import React, { useEffect } from 'react';

import { Link } from "react-router-dom";
import { QAndAItem, QAndAList } from '../../components/QAndAList';
import { StoreFront } from '../../components/StoreFront';

import { useIsBigScreen, useIsSmallScreen, useIsXLargeScreen } from '../../app/hooks';

const centeredTextStyling: React.CSSProperties = {
    textAlign: 'center'
}

const storeFaq: QAndAItem[] = [
    {
        q: "Can I have Monthly and Annual Subscriptions?",
        a: "Yes! And their billing schedule doesn't need to be aligned. In a single order and what is loaded in your cart, all items must have the same subscription duration currently. If you want an annual subscription for a package, but monthly for more workflows, complete the annual purchase first and then you can buy the monthly after.",
    },
    {
        q: "How do Credits work?",
        a: "Credits are used to execute workflows. A Credit can be used for any enabled model. Credit rates can fluctuate depending on AI costs and promotional offers. You may buy Credits through the store or or earn Credits through promotional offers. You can see your Credit Transaction History at any time in your Account Page. If a Workflow uses fewer resources than the maximum, you will only be charged for the Credits used."
    },
    {
        q: "Can I purchase subscriptions with Cryptocurrency?",
        a: "Yes! To reduce on fees, this option is encouraged for annual subscriptions only. Put what you'd like in a cart and then contact us for instructions on submitting a Cryptocurrency payment. We accept Bitcoin, Bitcoin Cash, Ethereum, Dogecoin, Litecoin and may, at our discretion, accept others (ask us!). This policy and cryptocurrencies accepted is subject to change."
    },
    {
        q: "When I buy a subscription, will my price change?",
        a: "No! Your price will always be locked in for what you purchase. If we increase prices in the future, you will be grandfathered in at your current price (sweet!). If your subscription lapses or you cancel and you wish to renew it, you will be able to purchase at the current prices.",
    },
    {
        q: "Can you tell me more about a specific subscription or feature?",
        a: "Yes, contact us and we'd be happy to answer any questions you have.",
    },
    {
        q: "What happens if I cancel a subscription?",
        a: "If you cancel a subscription for a feature, you won't have access to that feature anymore. Any excess workflows will be paused. They will still exist, but you will be unable to run the paused workflows or create any additional ones until you delete to get under your cap.",
    },
    {
        q: "Where can I see what I already have bought?",
        a: "Go to your Account and Billing page to see all your purchases and when they expire and your current applied caps and features.",
    },
    {
        q: "I previously saw an offer that I no longer see here, what gives?",
        a: "PyroPrompts changes offerings frequently, some offers are only available for a limited time. If you want something you don't see, contact us and we'll see what we can do.",
    },
    {
        q: "How do I cancel my subscription?",
        a: "If you paid with Stripe, go to your Account and Billing page and click on \"Manage Your Subscription\" to prevent future billings.",
    },
    {
        q: "Can you build X feature?",
        a: "We love hearing what our customers want to see more of. Please contact us so we can get your ask on our backlog.",
    },
    {
        q: "Can I try X feature before buying?",
        a: "Probably! Contact us with some information about yourself and what you want to do and how long you want to try it for and we'll see what we can do.",
    },
    {
        q: "Can I buy something for someone else?",
        a: "Yes! You can gift a purchase to someone else. Please contact us before purchasing so we can make sure you're not buying them something they already have.",
    }
]


export function Store() {

    const isBigScreen = useIsBigScreen()
    useEffect(() => {document.title = 'PyroPrompts | Plans and Pricing'}, [])

    return (
        <div className="App">
            <div className="App-content">
                <div className="App-page-content">
                    <Row>
                        <Col span={24} style={centeredTextStyling} >
                            <Typography.Title level={1}>PyroPrompts Store</Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={centeredTextStyling} >
                            <Typography.Paragraph>One-Time, Lifetime, and Subscription Packages for all levels of AI adoption.</Typography.Paragraph>
                        </Col>
                    </Row>

                    <StoreFront
                        highlightFeatured={true}
                        storeSections={[
                            {
                                header: "Subscriptions",
                                sub: "Get access to all the AI models and features you need to grow your business.",
                                categoryFilter: "Subscriptions",
                                // post: "All subscriptions are billed monthly or annually and can be canceled at any time."
                                post: <Row style={{ marginTop: '64px', marginBottom: '30px' }}>
                                    <Col span={isBigScreen ? 14 : 24} offset={isBigScreen ? 5 : 0}>
                                        <Card>
                                            <Typography.Title level={2} style={{marginTop: 0}}>Enterprise Solutions</Typography.Title>
                                            <Typography.Paragraph>
                                                Looking for full control with a dedicated PyroPrompts instance? Our Enterprise package offers:
                                            </Typography.Paragraph>
                                            <ul>
                                                <li>Whitelabeled or Co-Branded Deployment</li>
                                                <li>Included Consulting Hours</li>
                                                <li>Dedicated Workflow Throughput and Volume</li>
                                                <li>Custom Security Protocols</li>
                                                <li>Bulk Pricing</li>
                                                <li>Prioritized Support</li>
                                            </ul>
                                            <Typography.Paragraph>
                                                Explore how we can help your company adopt AI effectively.
                                            </Typography.Paragraph>
                                            <Link to="/enterprise">

                                            <Button type="primary" size="large">
                                                Learn More About Enterprise
                                            </Button>
                                            </Link>
                                        </Card>
                                    </Col>
                                </Row>
                            },

                            // a la carte
                            {
                                header: "A La Carte",
                                sub: "Get credit boosters, prompt packs, and access to specialized AI models and features.",
                                categoryFilter: "One Offs",
                                post: "All purchases are one-time and do not expire."
                            }
                        ]}
                    />

                    <Row>
                        <Col span={24} style={{marginTop: '30px', ...centeredTextStyling}} >
                            <Typography.Title level={1}>Frequently Asked Questions</Typography.Title>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={{offset: 0, span: 24}} md={{offset: 4, span: 16}} lg={{offset: 6, span: 12}}>
                            <QAndAList qas={storeFaq} />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
