
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import { Typography } from 'antd';
import { DiscordLink } from './DiscordLink';
import { CopyToClipboard } from './CopyToClipboard';
import { useIsBigScreen } from '../app/hooks'
import { ReactNode } from 'react'
import { TooltipPlacement } from 'antd/es/tooltip';


interface MarkdownContentParams {
    markdown: string
    style?: React.CSSProperties
    linkHeaders?: boolean
}

// const commonStyles = {maxWidth: "1200px"}
// const regularStyles = {fontSize: '1.0rem', ...commonStyles}


const RouterLink = (props: any) => {
    if (props.href === 'pp_discord') {
        return <DiscordLink text={props.children}/>
    }
    if (props.href === 'copy') {
        return <>
            <CopyToClipboard text={props.children} content={<a>{props.children}</a>} />
        </>
    }
    const id_parts = props.href.match(/^(#[a-zA-Z0-9\-]+)/)
    // console.log("Router Link ", {href: props.href, id_parts})
    if (id_parts && id_parts.length > 0) {
        
        return <a
            href={props.href}
            onClick={(e) => {
                const elem = document.getElementById(id_parts[0].substring(1))
                if (elem) {
                    e.preventDefault();
                    elem.scrollIntoView({ behavior: 'smooth' });}
                }

            }
        >{props.children}</a>
    }

    return (
        props.href.match(/^(https?:)?\/\//)
          ? <a href={props.href} target='blank'>{props.children}</a>
          : <Link to={props.href}>{props.children}</Link>
      )
}

const LinkableHeader = (props: any, size: number) => {
    const id = props.children.toString().toLowerCase().replace(/[^a-z0-9]/g,"-")
    const cleaned_page = window.location.toString().split('#')[0]
    const page = `${cleaned_page}#${id}`
    if (size === 1) {
        return <Typography.Title level={1} id={id}><CopyToClipboard text={page} placement='top' tooltip='Copy Link' content={props.children} /></Typography.Title>
    } else if (size === 2) {
        return <Typography.Title level={2} id={id}><CopyToClipboard text={page} placement='top' tooltip='Copy Link' content={props.children} /></Typography.Title>
    } else if (size === 3) {
        return <Typography.Title level={3} id={id}><CopyToClipboard text={page} placement='top' tooltip='Copy Link' content={props.children} /></Typography.Title>
    }
    return <Typography.Title level={4} id={id}><CopyToClipboard text={page} placement='top' tooltip='Copy Link' content={props.children} /></Typography.Title>
}

const LinkableH2 = (props: any) => LinkableHeader(props, 2)
const LinkableH3 = (props: any) => LinkableHeader(props, 3)

const UnlinkableH1 = (props: any) => <Typography.Title level={1}>{props.children}</Typography.Title>
const UnlinkableH2 = (props: any) => <Typography.Title level={2}>{props.children}</Typography.Title>
const UnlinkableH3 = (props: any) => <Typography.Title level={3}>{props.children}</Typography.Title>

const StyledImage = (props: any) => {
    const isBigScreen = useIsBigScreen()

    return (
        <img style={{maxWidth: isBigScreen ? '800px' : '100%'}} src={props.src} alt={props.alt} title={props.title} />
    )
}

const StyledPre = (props: any) => {
    return (
        <pre style={{border: '1px solid #555', padding: '10px', fontSize: '0.8rem'}}>{props.children}</pre>
    )
}

const StyledParagraph = (props: any) => {
    return (
        <Typography.Paragraph>{props.children}</Typography.Paragraph>
    )
}

const StyledCode = (props: any) => {
    return (
        <code style={{border: '1px solid #555', padding: '10px', fontSize: '0.8rem'}}>{props.children}</code>
    )
}

const StyledUL = (props: any) => {
    return (
        <ul style={{listStyleType: 'circle'}}>{props.children}</ul>
    )
}


export function MarkdownContent({
    markdown,
    style = {},
    linkHeaders=false,
}: MarkdownContentParams) {

    return (
        <div style={style}>

            <ReactMarkdown
            
            components={{
                a: RouterLink, img: StyledImage,
                h1: UnlinkableH1,
                ul: StyledUL,
                ...(linkHeaders ? {h2: LinkableH2, h3: LinkableH3} :  {h2: UnlinkableH2, h3: UnlinkableH3}),
                pre: StyledPre,
                p: StyledParagraph}}>{markdown}</ReactMarkdown>
        </div>

    )
}
