import React, { useState, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import type { MenuProps } from 'antd';
import { Col, Divider, Row, Space, Button, Checkbox, Form, Input, Modal, Select, message, Alert, Avatar, Dropdown, Menu, Typography, Tag  } from 'antd';

import {
    log_out,
    authenticateAsync,
    registerAsync,
    registerConfirmAsync,
    selectLoggedIn,
    selectUsername,
    selectStatus,
    selectAccount,
    attemptAuthFromTokens,
    selectLoginAttempted,
    selectLoginMessage,
    clear_message,
    resetPasswordAsync,
    submitResetPasswordAsync,
    selectMessage,
    selectMessageLevel,
    selectLastSuccessfulAction,
} from './loginSlice';
import { useIsBigScreen } from '../../app/hooks'
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserOutlined, LoadingOutlined, LogoutOutlined, ApiOutlined, BulbOutlined, SettingOutlined, MailOutlined, LockOutlined, SafetyOutlined } from '@ant-design/icons';
import type { SelectProps } from 'antd';
import { LightDarkSwitch } from '../../features/lightDarkSwitch/LightDarkSwitch'
import {selectLight} from '../../features/lightDarkSwitch/lightDarkSwitchSlice'

import styles from './Login.module.css';

interface RegisterConfirmFormParams {
    username: string
    code: string
}

const signUpButtonColor = 'linear-gradient(120deg, #e25501 0%, #e26801 25%, #e27b01 75%, #f9b320 100%)';
const signUpButtonStyle = {borderRadius: '8px', background: signUpButtonColor, border: 0}

export function Login() {
    const loggedIn = useAppSelector(selectLoggedIn);
    const username = useAppSelector(selectUsername);
    const status = useAppSelector(selectStatus);
    const account = useAppSelector(selectAccount);
    const loginAttempted = useAppSelector(selectLoginAttempted);
    const login_message = useAppSelector(selectLoginMessage);
    const asyncMessage = useAppSelector(selectMessage);
    const messageLevel = useAppSelector(selectMessageLevel);
    const lastSuccessfulAction = useAppSelector(selectLastSuccessfulAction);

    const dispatch = useAppDispatch();
    const [loginForm] = Form.useForm();
    const [registerForm] = Form.useForm();
    const [confirmForm] = Form.useForm();
    const [forgotPasswordForm] = Form.useForm();
    const [forgotPasswordSubmitForm] = Form.useForm();
    const registerTerms = Form.useWatch('terms', registerForm);
    
    const isBigScreen = useIsBigScreen()
    const light = useAppSelector(selectLight)
    const [searchParams, setSearchParams] = useSearchParams();
    const activeAuthModal = searchParams.get("auth")
    const allParams = searchParams.entries()

    useEffect(() => {
        if (searchParams.get("auth") === "signup" && lastSuccessfulAction === "register") {
            searchParams.set("auth", "register_confirm")
            setSearchParams(searchParams)
            // resetForms()
            registerForm.resetFields()
        }

        if (searchParams.get("auth") === "register_confirm" && lastSuccessfulAction === "register_confirm") {
            searchParams.set("auth", "login")
            setSearchParams(searchParams)
            // resetForms()
            confirmForm.resetFields()
        }
    }, [lastSuccessfulAction])

    // console.log("login", {allParams, activeAuthModal, status})

    const signUpModalOpen = activeAuthModal === 'signup' && !account && status !== 'loading'
    const isLoginModalVisible = activeAuthModal === 'login' && !account && status !== 'loading'
    const isForgotPasswordModalVisible = activeAuthModal === 'forgot_password' && !account && status !== 'loading'
    const isRegisterConfirmModalVisible = activeAuthModal === 'register_confirm' && !account && status !== 'loading'
    const isForgotPasswordSubmitModalVisible = activeAuthModal === 'forgot_password_submit' && !account && status !== 'loading'

    // console.log("Login", {loggedIn, username, account, status})

    const signUpOptions: SelectProps["options"] = [
        {
            label: 'Notifications',
            value: 'notifications',
        },
        {
            label: 'Automated Trading',
            value: 'trading',
        },
        {
            label: 'Discord Bot',
            value: 'discord',
        },
        {
            label: 'Selling Data',
            value: 'data_provider',
        },
        {
            label: 'Affiliate Program',
            value: 'affiliate',
        },
    ]

    if (login_message) {
        console.log(login_message)
        message.error(login_message)
        dispatch(clear_message())

    
    }

    const showLoginModal = () => {
        searchParams.set("auth", "login")
        setSearchParams(searchParams)
        dispatch(clear_message())
    }

    const showSignUpModal = () => {
        searchParams.set("auth", "signup")
        setSearchParams(searchParams)
        dispatch(clear_message())
    }

    const showForgotPasswordModal = () => {
        searchParams.set("auth", "forgot_password")
        setSearchParams(searchParams)
        dispatch(clear_message())
    }

    const showRegisterConfirmModal = () => {
        searchParams.set("auth", "register_confirm")
        setSearchParams(searchParams)
        dispatch(clear_message())
    }

    const showForgotPasswordSubmitModal = () => {
        searchParams.set("auth", "forgot_password_submit")
        setSearchParams(searchParams)
        dispatch(clear_message())
    }

    const resetForms = () => {
        loginForm.resetFields()
        registerForm.resetFields()
        confirmForm.resetFields()
        forgotPasswordForm.resetFields()
        forgotPasswordSubmitForm.resetFields()
        dispatch(clear_message())

    }

    const handleCancel = () => {
        resetForms()

        searchParams.delete("auth")
        setSearchParams(searchParams)

        dispatch(clear_message())

    }

    const log_out_click = () => {
        dispatch(log_out())
    }

    if (!loggedIn && status === 'idle' && !loginAttempted) {
        dispatch(attemptAuthFromTokens())
    }

    const onAuthenticateFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(authenticateAsync({username: values.username, password: values.password, remember_me: values.remember}))
    };

    const onAuthenticateFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };



    const onRegisterFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(registerAsync({username: values.username, email: values.email, password: values.password, newsletter: values.newsletter ? '1' : '0'}))
        // searchParams.set("auth", "register_confirm")
        // setSearchParams(searchParams)
        // resetForms()
        confirmForm.setFieldsValue({username: values.username})

    };

    const onRegisterFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onRegisterConfirmFinish = (values: RegisterConfirmFormParams) => {
        console.log('Success:', values);
        const workflow_execution_id = searchParams.get("workflow_execution_id")
        dispatch(registerConfirmAsync({username: values.username, code: values.code, ...(workflow_execution_id ? { workflow_execution_id } : {})}))
        // searchParams.set("auth", "login")
        // setSearchParams(searchParams)
        //
        // resetForms()
        loginForm.setFieldsValue({username: values.username})

    };

    const onRegisterConfirmFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onForgotPasswordFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(resetPasswordAsync({username: values.username}))
        searchParams.set("auth", "forgot_password_submit")
        setSearchParams(searchParams)

        resetForms()
        forgotPasswordSubmitForm.setFieldsValue({username: values.username})
    };

    const onForgotPasswordFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onForgotPasswordSubmitFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(submitResetPasswordAsync({username: values.username, code: values.code, password: values.password}))
        searchParams.set("auth", "login")
        setSearchParams(searchParams)
        resetForms()
        loginForm.setFieldsValue({username: values.username})
    };

    const onForgotPasswordSubmitFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if (loggedIn && account && isLoginModalVisible){
        resetForms()
        searchParams.delete("auth")
        setSearchParams(searchParams)
    }

    const AsyncMessage = () => {
        if (asyncMessage) {
            if (messageLevel) {
                return (
                    <>
                        <Alert message={asyncMessage} type={messageLevel} />
                        <p></p>
                    </>
                )
            } else {
                return (
                    <>
                        <Alert message={asyncMessage} type="info" />
                        <p></p>
                    </>
                )
            }
        } else {
            return <></>
        }
    }

    const menu: MenuProps['items'] = [
        {
            label: account ? <Link to={`/u/${account.name}/`}>{account.name}</Link> : <>{username}</>,
            icon: <UserOutlined />,
            key: '0',
        },
        // {
        //     label: <LightDarkSwitch />,
        //     icon: <BulbOutlined />,
        //     key: '1',
        // },
        {
            label: <Link to={`/integrations`}>Integrations</Link>,
            icon: <ApiOutlined />,
            key: '2',
        },
        {
            label: <Link to={`/account`}>Account & Billing</Link>,
            icon: <SettingOutlined />,
            key: '3',
        },
        // Settings
        // Billing
        {
            type: 'divider',
        },
        {
            label: <a onClick={log_out_click}>Log Out</a>,
            icon: <LogoutOutlined />,
            key: '4',
        },
    ]


    return (
        <>
            <Space>
                {/* <Row justify="end"> */}
                {(status === 'loading') && (
                    <Typography.Paragraph>Loading...</Typography.Paragraph>
                )}
                {/* {(status === 'failed') && (
                    <Typography.Paragraph>Failed. <a className='App-link' onClick={showLoginModal}>Log In</a></Typography.Paragraph>
                )} */}
                {(status === 'idle' && account && loggedIn ) && (
                    <>
                        {/* <Tag color="magenta">Upgrade</Tag> */}

                        {/* <Link to={`/upgrade`}><Button type={"text"} style={{color: 'rgba(231, 0, 212, 0.85)'}}>Upgrade to Pro</Button></Link> */}
                        
                        <Dropdown menu={{items: menu}} trigger={['hover']} placement="bottomRight">
                            {(account && account.profile_pic_path) ? (
                                // <UserAvatar account={account} />
                                <Avatar src={`https://assets.pyroprompts.com/${account.profile_pic_path}`} />
                            ) : (
                                <Avatar style={{ color: '#dddddd', backgroundColor: '#666666' }} icon={<UserOutlined />} />
                            )}
                        </Dropdown>
                    </>
                    
                )}
                {((status === 'idle' || status === 'failed') && (account === null || account === undefined || !loggedIn)) && (
                    <>
                        {/* <LightDarkSwitch mode='icon' /> */}
                        {isBigScreen && (
                            <Button type='text' style={{...signUpButtonStyle}} onClick={showSignUpModal}>Sign Up</Button>
                        )}
                        {/* <Button type="primary" onClick={showSignUpModal}>Sign Up</Button> */}
                        <Button type={light && !isBigScreen ? "primary" : "default"} onClick={showLoginModal} style={{borderRadius: '8px'}}>Log In</Button>
                        {/* {isBigScreen && (
                            <Button type="default" onClick={showLoginModal}>Log In</Button>
                        )} */}
                    </>
    
                )}
            </Space>
            {/* </Row> */}
            <Modal title="Login" open={isLoginModalVisible} footer={null} onCancel={handleCancel} zIndex={1200}>
                <Form
                    name="basic"
                    form={loginForm}
                    layout='vertical'
                    initialValues={{ remember: false }}
                    onFinish={onAuthenticateFinish}
                    onFinishFailed={onAuthenticateFinishFailed}
                    autoComplete="off"
                    requiredMark={"optional"}
                >
                    <AsyncMessage />
                    <Typography.Paragraph>
                    Welcome back!
                    </Typography.Paragraph>
                    <Form.Item
                    // label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username' }]}
                    >
                        <Input size="large" addonBefore={<UserOutlined />} placeholder={"username"} />
                    </Form.Item>

                    <Form.Item
                    // label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password' }]}
                    >
                        <Input.Password size="large" addonBefore={<LockOutlined />} placeholder={"password"} />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" style={{float: 'right', width: 'unset'}} htmlType="submit" disabled={status === 'loading'}>
                            {status === 'loading' ? <LoadingOutlined /> : 'Submit' }
                        </Button>
                    </Form.Item>
                </Form>
                {/* <Typography.Paragraph>Username</Typography.Paragraph>
                <Typography.Paragraph>Password</Typography.Paragraph> */}
                <Space split={<Divider type="vertical" />} wrap>
                    <Button type='text' onClick={showSignUpModal}>Sign Up</Button>
                    <Button type='text' onClick={showForgotPasswordModal}>Forgot Password</Button>
                </Space>

                {/* <Typography.Paragraph>Or: <a onClick={showSignUpModal}>Sign Up</a> or <a onClick={showForgotPasswordModal}>Forgot Password</a></Typography.Paragraph> */}
                {/* <Typography.Paragraph><Button onClick={authenticate_click}>Submit</Button></Typography.Paragraph> */}
            </Modal>
            <Modal title="Sign Up" open={signUpModalOpen} footer={null} onCancel={handleCancel} zIndex={1200}>
                <Form
                    name="basic"
                    layout='vertical'
                    form={registerForm}
                    initialValues={{ terms: false, newsletter: true }}
                    onFinish={onRegisterFinish}
                    onFinishFailed={onRegisterFinishFailed}
                    autoComplete="off"
                    requiredMark={"optional"}
                >
                    
                    <AsyncMessage />
                    <Typography.Paragraph>
                        Creating an Account is free and allows you to create prompts, workflows and more.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        After submitting, you'll receive an email with a verification code.
                    </Typography.Paragraph>

                    <Form.Item
                        // label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your desired username' }]}
                    >
                        <Input size="large" addonBefore={<UserOutlined />} placeholder={"username"} />
                    </Form.Item>

                    <Form.Item
                        // label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email' }]}
                    >
                        <Input size="large" addonBefore={<MailOutlined />} placeholder={"email"} />
                    </Form.Item>

                    <Form.Item
                        // label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password' }]}
                    >
                        <Input.Password size="large" addonBefore={<LockOutlined />} placeholder={"password"} />
                    </Form.Item>

                    {/* <Form.Item
                        label="Interested In"
                        name="interests"
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder=""
                            defaultValue={[]}
                            // onChange={handleChange}
                            options={signUpOptions}
                        />
                    </Form.Item> */}

                    <Form.Item name="terms" valuePropName="checked" style={{marginBottom: 0}}>
                        <Checkbox>Agree to <a href='/terms_and_conditions' target='blank'>Terms &amp; Conditions</a></Checkbox>
                    </Form.Item>
                    
                    <Form.Item name="newsletter" valuePropName="checked">
                        <Checkbox>Join the <a href='/newsletter' target='blank'>Newsletter</a> for workflow templates, automation credits and weekly AI inspiration.</Checkbox>
                    </Form.Item>
                    {/* <Row style={{marginBottom: '12px'}}>
                            <Col xs={{offset: 0, span: 24}} md={{offset: 16, span:8}}>
                                <Button style={{float: 'right', width: 'unset'}} onClick={() => addTemplateVars({})} block icon={<PlusOutlined />}>
                                    {text}
                                </Button>
                            </Col>
                        </Row> */}

                    <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" style={{float: 'right', width: 'unset'}} htmlType="submit" disabled={!registerTerms}>
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Space split={<Divider type="vertical" />} wrap>
                    <Button type='text' onClick={showLoginModal}>Log In</Button>
                    <Button type='text' onClick={showRegisterConfirmModal}>Verify Email</Button>
                </Space>
            </Modal>
            <Modal title="Confirm Sign Up" open={isRegisterConfirmModalVisible} footer={null} onCancel={handleCancel} zIndex={1200}>
                <Form
                    name="basic"
                    layout='vertical'
                    form={confirmForm}
                    initialValues={{}}
                    onFinish={onRegisterConfirmFinish}
                    onFinishFailed={onRegisterConfirmFinishFailed}
                    autoComplete="off"
                    requiredMark={"optional"}
                >
                    <AsyncMessage />
                    <Typography.Paragraph>
                        We sent you an email with a verification code. Enter that here.
                    </Typography.Paragraph>

                    {/* <Typography.Paragraph>
                        Didn't receive an email? <a>Click here.</a>
                    </Typography.Paragraph> */}
                    
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username' }]}
                    >
                        <Input size="large" addonBefore={<UserOutlined />} placeholder={"username"} />
                    </Form.Item>

                    <Form.Item
                        // label="Code"
                        name="code"
                        rules={[{ required: true, message: 'Please input code sent to your email' }]}
                    >
                        <Input size='large' addonBefore={<SafetyOutlined />} placeholder={"code"} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" htmlType="submit" style={{float: 'right', width: 'unset'}}>
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Space split={<Divider type="vertical" />} wrap>
                    <Button type='text' onClick={showLoginModal}>Log In</Button>
                    <Button type='text' onClick={showSignUpModal}>Sign Up</Button>
                </Space>
            </Modal>
            <Modal title="Forgot Password" open={isForgotPasswordModalVisible} footer={null} onCancel={handleCancel} zIndex={1200}>
                <Typography.Paragraph>If you forgot your password or need an email verification email sent again, enter your username below.</Typography.Paragraph>
                <Form
                    name="basic"
                    form={forgotPasswordForm}
                    layout='vertical'
                    initialValues={{}}
                    onFinish={onForgotPasswordFinish}
                    onFinishFailed={onForgotPasswordFinishFailed}
                    autoComplete="off"
                    requiredMark={"optional"}
                >
                    <AsyncMessage />
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please enter your username' }]}
                    >
                        <Input size='large' addonBefore={<UserOutlined />} placeholder={"username"} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" style={{float: 'right', width: 'unset'}} htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Space split={<Divider type="vertical" />} wrap>
                    <Button type='text' onClick={showLoginModal}>Log In</Button>
                    <Button type='text' onClick={showForgotPasswordSubmitModal}>Forgot Password Code</Button>
                </Space>
            </Modal>
            <Modal title="Forgot Password Submission" open={isForgotPasswordSubmitModalVisible} footer={null} onCancel={handleCancel} zIndex={1200}>
                <Typography.Paragraph>We sent you an email with a verification code. Enter your username, that code and your new password.</Typography.Paragraph>
                <Form
                    name="basic"
                    form={forgotPasswordSubmitForm}
                    layout='vertical'
                    initialValues={{}}
                    onFinish={onForgotPasswordSubmitFinish}
                    onFinishFailed={onForgotPasswordSubmitFinishFailed}
                    autoComplete="off"
                    requiredMark={"optional"}
                >
                    <AsyncMessage />
                    <Form.Item
                        // label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please enter your username' }]}
                    >
                        <Input size='large' addonBefore={<UserOutlined />} placeholder={"username"} />
                    </Form.Item>

                    <Form.Item
                        // label="Code"
                        name="code"
                        rules={[{ required: true, message: 'Please input code sent to your email' }]}
                    >
                        <Input size='large' addonBefore={<SafetyOutlined />} placeholder={"code"} />
                    </Form.Item>

                    <Form.Item
                        // label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password' }]}
                    >
                        <Input.Password size='large' addonBefore={<LockOutlined />} placeholder={"new password"} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" style={{float: 'right', width: 'unset'}} htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Space split={<Divider type="vertical" />} wrap>
                    <Button type='text' onClick={showLoginModal}>Log In</Button>
                    <Button type='text' onClick={showForgotPasswordModal}>Forgot Password</Button>
                </Space>
            </Modal>
        </>

    )
}
