// A mock function to mimic making an async request for data
// import {getDashboards} from '../../services/signalApi'

export interface Widget {
  num: number
}

export interface Dashboard {
    id: string
    widgets: Widget[]
    name: string
}

export interface DashboardListResponse {
    results: Dashboard[]
}

export function dashboardList() {
    const dashboards: Dashboard[] = [
        {id: "1", name: "Economic Macro", widgets: [{num: 1}]}
    ]
    return new Promise<{ data: DashboardListResponse }>((resolve) =>
      setTimeout(() => resolve({ data: {results: dashboards} }), 500)
    );
}

export function dashboard(id: string) {
  const dashboard: Dashboard = {id: id, name: "Economic Macro", widgets: [{num: 1}]}
  return new Promise<{ data: Dashboard }>((resolve) =>
    setTimeout(() => resolve({ data: dashboard }), 500)
  );
}
