import { DefinedWorkflowModal } from './DefinedWorkflowModal';
import { useState } from 'react';

interface DefinedWorkflowLinkParams {
    workflowName: string
    text?: string
    includeCreditsTextIfNoCredits?: boolean
    onComplete?: () => void
    style?: React.CSSProperties
}

export function DefinedWorkflowLink({
    workflowName,
    text='Edit Prompt',
    includeCreditsTextIfNoCredits=true,
    onComplete,
    style,
}: DefinedWorkflowLinkParams) {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const showModal = () => {
        setIsModalOpen(true)
    }

    const onModalComplete = () => {
        if (onComplete) {
            onComplete()
        }
    }

    return <>
        <div>
        <a onClick={showModal} style={style ? style : undefined}>
            {text}
        </a>
        </div>
        <DefinedWorkflowModal includeCreditsTextIfNoCredits={includeCreditsTextIfNoCredits} workflowName={workflowName} isOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} onComplete={onModalComplete} />
    </>

}
