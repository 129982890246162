import { Collapse, Typography } from 'antd';


export interface QAndAItem {
    q: string
    a: string
}

export interface QAndAListParams {
    qas: QAndAItem[]
}

export function QAndAList({qas}: QAndAListParams) {
    return <Collapse defaultActiveKey={qas.map((qa, i) => i)} items={qas.map((qa, i) => (
        {
            key:i,
            label: qa.q,
            children: <Typography.Paragraph>{qa.a}</Typography.Paragraph>,

        }
    ))}>
    </Collapse>
}
