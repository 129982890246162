
import { Avatar, Button, Col, Descriptions, Divider, Input, List, Radio, Row, Select, Space, Tag, Tooltip, Typography, Collapse } from 'antd';
import { MinusCircleOutlined, ReloadOutlined, SearchOutlined, BookOutlined, StarOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';

import { createElement, ReactElement, useEffect, useState } from 'react';
import { DateFilter } from '../types/types'

import { useIsBigScreen, useIsSmallScreen, useAppSelector } from '../app/hooks'
import { selectAccount } from '../features/login/loginSlice';

interface PromptBrowserFilterParams {
    // name?: string
    // data_set_id?: string
    symbol?: string
    // showButtons?: boolean
    // addContextColumns?: boolean
    dateFilter: DateFilter
    contentFilter: string | null
    savedFilter: boolean
    starredFilter: boolean
    addButtons?: ReactElement
    reloadData: () => void
    setDateFilter: (date: DateFilter) => void
    setContentFilter: (symbol: string) => void
    setSavedFilter: (filter: boolean) => void
    setStarredFilter: (filter: boolean) => void
    style?: React.CSSProperties
}

type signalSelection = 'table' | 'stream'

export function PromptBrowserFilter({
    // name,
    // data_set_id,
    symbol,
    // showButtons=true,
    // addContextColumns=false,
    dateFilter,
    contentFilter,
    savedFilter,
    starredFilter,
    addButtons,
    reloadData,
    setDateFilter,
    setContentFilter,
    setSavedFilter,
    setStarredFilter,
    style,
}: PromptBrowserFilterParams) {

    // const [reloadedLast, setReloadedLast] = useState('never');
    // const [dateFilter, setDateFilter] = useState<DateFilter>('30_days');
    const [searchOpen, setSearchOpen] = useState<boolean>(false);
    const account = useAppSelector(selectAccount)

    const isSmallScreen = useIsSmallScreen()

    const handleDateFilterSelectChange = (value: DateFilter) => {
        setDateFilter(value)
    }

    const handleDateFilterRadioChange = (e: RadioChangeEvent) => {
        // setLocalDateFilter(e.target.value)
        setDateFilter(e.target.value)
    }

    const onSearch = (value: string) => {
        console.log("onSearch", {value})
        setContentFilter(value)
    }

    const toggleSearch = () => {
        setSearchOpen(!searchOpen)
    }

    const toggleSaved = () => {
        setSavedFilter(!savedFilter)
    }

    const toggleStarred = () => {
        setStarredFilter(!starredFilter)
    }

    const genExtra = () => (
        <Button
            type="default"
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                reloadData()
            }}
            // onClick={reloadData}
            >
                <ReloadOutlined />Reload
        </Button>
        // <SettingOutlined
        //   onClick={(event) => {
        //     // If you don't want click extra trigger collapse, you can prevent this:
        //     event.stopPropagation();
        //   }}
        // />
      );
    const dateFilterOptions = [
        { label: 'Today', value: 'today'},
        { label: 'This Week', value: '7_days'},
        { label: 'This Month', value: '30_days'},
        { label: 'All Time', value: 'all_time'},
    ]

    const smallFilters = () => {
        return (
            <div style={style ? style : {}}>
                <Space direction={'horizontal'} >
                    <Button type="default" onClick={reloadData}><ReloadOutlined /></Button>

                    {/* <Radio.Group options={dateFilterOptions} defaultValue={dateFilter} value={dateFilter} optionType="button" onChange={handleDateFilterRadioChange} /> */}

                    {!symbol && (
                        <Button onClick={toggleSearch} type={ searchOpen ? "primary" : "default"}><SearchOutlined /></Button>

                    )}
                    {account && (
                        <>
                            <Button type={starredFilter ? "primary" : "default"} icon={<StarOutlined />} onClick={toggleStarred} />
                            <Button type={savedFilter ? "primary" : "default"} icon={<BookOutlined />} onClick={toggleSaved} />
                        </>
                    )}
                    {!account && (
                        <Typography.Text style={{fontSize: '14px'}}>Log in to Save and Create Prompts</Typography.Text>
                    )}
                    
                    {addButtons && (
                        <>
                            {addButtons}
                        </>
                    )}

                    {/* <Divider type="vertical" />
                    <Radio.Group>
                        <Radio.Button value="positive">Positive</Radio.Button>
                        <Radio.Button value="neutral">Neutral</Radio.Button>
                        <Radio.Button value="negative">Negative</Radio.Button>
                    </Radio.Group> */}
                    {/* <Button type="primary" onClick={clear_click}>Clear</Button> */}
                </Space>

                {searchOpen && (
                    <div>
                        {/* <Divider type="horizontal" /> */}
                        <Input.Search autoFocus placeholder="search..." allowClear onSearch={onSearch} style={{ width: '100%', marginTop: '6px' }} bordered={false} />
                    </div>
                )}
            </div>
            
        )

    }

    const bigFilters = () => {
        return (
            <Space direction={'horizontal'} style={style ? style : {}} wrap>
                {/* <Button type="default" onClick={reloadData}><ReloadOutlined /></Button> */}
                {/* <Button type="default"><ReloadOutlined /></Button> */}

                <Button type="default" onClick={reloadData}><ReloadOutlined /></Button>

                {!isSmallScreen && (
                    <Divider type='vertical' />
                )}

                {!symbol && (
                    <Input.Search placeholder="search..." allowClear onSearch={onSearch} style={{ width: 250 }} />
                )}
                {account && (
                    <>
                        <Button type={starredFilter ? "primary" : "default"} icon={<StarOutlined />} onClick={toggleStarred}>Starred</Button>
                        <Button type={savedFilter ? "primary" : "default"} icon={<BookOutlined />} onClick={toggleSaved}>Saved</Button>
                    </>
                )}
                {!account && (
                    <Typography.Text style={{fontSize: '14px'}}>Log in to Save and Create Prompts</Typography.Text>
                )}

                {addButtons && (
                    <>
                        {!isSmallScreen && (
                            <Divider type='vertical' />
                        )}
                        {addButtons}
                    </>
                )}
            </Space>
        )
    }

    return isSmallScreen ? smallFilters() : bigFilters()

}
