import React, { useState, useEffect } from 'react';
import { Account as AccountType, AccountBio as AccountBioType, NamedWorkflow } from '../../types/types'
import { getAccountBios, createAccountBio } from '../../services/signalApi'
import ReactMarkdown from 'react-markdown'
import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined, UploadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { Descriptions, Typography, Empty, Spin, Alert, Button, message, Space, Row, Statistic, Tag, Col, Tabs, Avatar, Dropdown, Card } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';

import { DefinedWorkflowRunner } from '../../components/DefinedWorkflowRunner'
import { MarkdownContent } from '../../components/MarkdownContent'
import { NewsLetterRegister } from '../../components/NewsLetterRegister'
import { useAppSelector, useIsBigScreen } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';



const regularStyles = {fontSize: '1.1rem', lineHeight: '1.8em'}

export function Newsletter() {
  const account = useAppSelector(selectAccount)
  const isBigScreen = useIsBigScreen()

  useEffect(() => {document.title = 'PyroPrompts | Newsletter'}, [])

  const markdown_content_2 = `
### Curated AI Prompts
Handpicked purpose-driven prompts designed to solve real problems.

### Automation Case Studies
Highlight successful automations our customers run.

### AI News Insights
Digest developments in AI with context for the broader picture and future implications.

### Exclusive Deals
Advanced access to our newest offerings

### Freebies
Free credits, free information, and more.


The PyroPrompts newsletter is not just a newsletter; it's a source of ongoing learning and a catalyst for your AI-driven endeavors.

**Sign up for the PyroPrompts newsletter today** and let's set the AI world ablaze, together!
`

  const markdown_content = `
Every week, you'll get:

- **Curated AI Prompts:** Handpicked purpose-driven prompts designed to solve real problems.

- **Automation Case Studies:** Highlight successful automations our customers run.

- **AI News Insights:** Digest developments in AI with context for the broader picture and future implications.

- **Exclusive Deals:** Advanced access to our newest offers and seasonal deals.

- **Freebies:** Free credits, free information, and more.

Learn how AI can benefit your life or your business every week.

**Sign up for the PyroPrompts newsletter today.**
`
//   const card_markdown_content = `Spark up your AI creativity with the PyroPrompts newsletter!
  
//   You'll get:
//   - **The Best Prompts:** Kickstart projects with unique, curated prompts.
//   - **AI Workflows:** Streamline your processes with expert-designed workflows.
//   - **New Feature Updates:** Stay ahead with the latest tools and features.
//   - **Exclusive Contests and Deals:** Dive into challenges and win exciting rewards.
//   - **AI Automation Case Studies:** Enhance your AI journey with applied learnings.

// ### AI moves fast, so should you. Join today.

//`
const card_markdown_content = `### AI moves fast, so should you. Join today.

`
  return (
    <div className="App">
      <div className="App-content">
        {/* Data Set: {dataset.name} */}
        <Typography.Title level={1}></Typography.Title>
        <div className="App-page-content">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} md={12}>
                  <Typography.Title level={1} style={{marginTop: 0}}>PyroPrompts Weekly Newsletter</Typography.Title>
                  <MarkdownContent markdown={markdown_content} style={{fontSize: "1.2rem"}}/>
                </Col>
                <Col xs={24} md={{span: 10, offset: 2}}>
                <Card
                        cover={
                            <img
                                alt="example"
                                src={`https://assets.pyroprompts.com/img/newsletter-1-520x200.png`}
                            />
                        }
                    >
                      <Typography.Title level={3} style={{marginTop: 0}}>AI moves fast, so should you. Join today.</Typography.Title>
                      <Typography.Paragraph>Enter your email to get the hottest newsletter in AI</Typography.Paragraph>
                      <NewsLetterRegister sign_up_button_text='Subscribe'/>
                    </Card>
                  {/* <Image
                    preview={false}
                    style={{borderRadius: '4px'}}
                    src={`https://assets.pyroprompts.com/img/fire_boy.gif`}
                  /> */}


                  {/* {news()} */}

                   
                </Col>

            </Row>


         
        </div>
      </div>
    </div>
  );
}
