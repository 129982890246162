import { Button, Divider, Tooltip, Typography, Space, Table, Popconfirm, message, Input, List } from 'antd';
import { useState, useRef, useEffect } from 'react';
import type { InputRef } from 'antd';

import { Link } from "react-router-dom";
import { DeleteOutlined, CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { DateTime } from 'luxon';

import { PaginatedTable } from './PaginatedTable';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getProjects, deleteProject } from '../services/signalApi'; // Adjust this to your actual API endpoints
import { CreateProjectModal } from '../components/CreateProjectModal';
import { DefinedWorkflowButton } from '../components/DefinedWorkflowButton';

import { Project } from '../types/types';
import { loadProjectsAsync, selectProjects, selectStatus as selectProjectsStatus } from '../features/projects/projectsSlice';
import { Loading } from './Loading';

interface ProjectListParams {}

export function ProjectList({}: ProjectListParams) {
    const account = useAppSelector(selectAccount);
    const inputRef = useRef<InputRef>(null);
    const dispatch = useAppDispatch();
    const projects = useAppSelector(selectProjects);
    const projectsStatus = useAppSelector(selectProjectsStatus);
    const [reloadedLast, setReloadedLast] = useState('never');
    const [showCreateProject, setShowCreateProject] = useState(false);
    const [projectCopySource, setProjectCopySource] = useState<Project | null>(null);
    const [projectNameFilter, setProjectNameFilter] = useState<string | null>(null);


    
    const [projectCount, setProjectCount] = useState<number | null>(null);
    // const [reloads, setReloads] = useState(0);

    const showProjects = projectNameFilter ? (projects || []).filter((p) => p.name.toLowerCase().indexOf(projectNameFilter.toLowerCase()) !== -1) : projects

    useEffect(() => {
        if (projectsStatus === "idle" && projects === null) {
            dispatch(loadProjectsAsync())
        }
    }, [projectsStatus, JSON.stringify({ projects })])

    const createProjectClick = () => {
        setProjectCopySource(null);
        setShowCreateProject(true);
    }

    const handleCreateCancel = () => {
        setShowCreateProject(false);
        setProjectCopySource(null);
    }

    const handleCreateProject = () => {
        // setReloads(reloads + 1);
        dispatch(loadProjectsAsync())
    }

    const delete_project = (project: Project) => {
        deleteProject(project.id).then((res) => {
            if (res.status === 204) {
                // Handle successful delete
            } else {
                console.log("Failed to delete project:", res);
            }
        }).finally(() => {

            dispatch(loadProjectsAsync())
            // setReloads(reloads + 1);
        });
    }

    const copy_project = (project: Project) => {
        setProjectCopySource(project);
        setShowCreateProject(true);
    }

    const content_list = () => {
        if (projectsStatus === 'loading') {
            return <Loading message='Projects' description="Loading Projects" />
        }
        return <List
            // size="large"
            // footer={<div>Footer</div>}
            // bordered
            dataSource={showProjects || []}
            renderItem={(item) => <List.Item
                actions={[
                    <Tooltip placement="top" title="Delete">
                        <Popconfirm title="Are you sure you want to remove this project?" okText="Delete" cancelText="Cancel" onConfirm={() => delete_project(item)}>
                            <DeleteOutlined />
                        </Popconfirm>
                    </Tooltip>
                ]}
                >
                    <List.Item.Meta
                        title={<Link to={`/projects/${item.id}/`}>{item.name}</Link>}
                        description={item.description}
                    />
                </List.Item>
            }
        />
    }

    const triggerReload = () => {
        const dt = DateTime.now();
        dispatch(loadProjectsAsync())
        setReloadedLast(dt.toISO());
    }

    const onSearchSubmit = (value: string) => {
        console.log("onSearch", {value})
        setProjectNameFilter(value)
        // setReloads(reloads + 1);
    }

    const newButtonComponent = <Button type="primary" onClick={createProjectClick}>+ New</Button>;

    const buttons = (
        <Space style={{marginBottom: '6px'}} wrap>
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
            {newButtonComponent}
            <Input.Search
                placeholder="Project name..."
                allowClear
                enterButton
                onSearch={onSearchSubmit}
                ref={inputRef}
            />
            <DefinedWorkflowButton workflowName={'project-suggestion'} buttonText='Get a Suggestion' buttonType='text' />
        </Space>
    );

    return (
        <>
            {buttons}
            {content_list()}
            <CreateProjectModal
                isOpen={showCreateProject}
                onCancel={handleCreateCancel}
                onCreate={handleCreateProject}
                projectCopySource={projectCopySource ? projectCopySource : undefined}
            />
        </>
    );
}
