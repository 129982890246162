import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StoreItem, StoreCartItem, SubscriptionRecurring } from '../../types/types';
import { isNullish } from '../../utils'


export interface AiChatMessage {
    id: string
    from: string
    content: string
}

export interface AiChatState {
    open: boolean
    messages?: AiChatMessage[]
    wip_prompt?: string
}

export interface PopulatePromptAction {
    prompt: string
}

// Below is a copy from cartsllice
export interface CartState {
    open: boolean
    cart_id?: string
    cart_items?: StoreCartItem[]
    local_cart_items?: StoreCartItem[]
}

export interface AddToCartAction {
    item: StoreItem,
    recurring?: SubscriptionRecurring
    quantity?: number
}


const initialState: AiChatState = {open: false};


export const populatePrompt = createAsyncThunk(
  'ai_chat/populate_prompt',
  async (userData: PopulatePromptAction, _) => {
    return {prompt: userData.prompt}
  }
);

export const cartSlice = createSlice({
  name: 'ai_chat',
  initialState,
  reducers: {

    openAiChat: (state) => {
        state.open = true
    },
    closeAiChat: (state) => {
        state.open = false
    }

  },

  extraReducers: (builder) => {
    builder
      .addCase(populatePrompt.fulfilled, (state, action) => {
        console.log("populatePrompt")
        const maybe_prompt = action.payload.prompt
        if (!isNullish(maybe_prompt)) {
            state.wip_prompt = maybe_prompt
        }
        state.open = true
      })
  },
});

export const { closeAiChat, openAiChat } = cartSlice.actions;

export const selectAiChatOpen = (state: RootState) => state.ai_chat.open;
export const selectAiChatWipPrompt = (state: RootState) => state.ai_chat.wip_prompt;


export default cartSlice.reducer;
