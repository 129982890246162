import { Descriptions, Typography, Empty } from 'antd';
import { useParams } from 'react-router-dom';

export function DataProvider() {
  const params = useParams()
  const id = params.id;
  
  if (id === undefined) {
    return (
      <div className="App">
        <div className="App-content">
          <Empty />
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <div className="App-content">
        Data Provider: {id}
        <Descriptions title="Data Provider">
            <Descriptions.Item label="Id"><Typography.Text code>{id}</Typography.Text></Descriptions.Item>
            <Descriptions.Item label="Name">Foobar Data Provider</Descriptions.Item>
            <Descriptions.Item label="Number of signals">500k</Descriptions.Item>
            <Descriptions.Item label="Subscribers">243</Descriptions.Item>
            <Descriptions.Item label="Quality">A</Descriptions.Item>
        </Descriptions>
        {/* <div className="App-page-content"> */}
        {/* </div> */}
      </div>
    </div>
  );
}
