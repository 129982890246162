import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { Dashboard, dashboardList, dashboard } from './dashboardAPI';

export interface DashboardListState {
  dashboards: Dashboard[];
  dashboard?: Dashboard
  status: 'idle' | 'loading' | 'failed';
}

const initialState: DashboardListState = {
  dashboards: [],
  status: 'idle',
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadDashboardsAsync = createAsyncThunk(
  'dashboard/fetch',
  async () => {
    const response = await dashboardList();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const loadDashboardAsync = createAsyncThunk(
  'dashboard/get',
  async (id: string) => {
    const response = await dashboard(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log("clear")
      state.dashboards = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadDashboardsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadDashboardsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dashboards = action.payload.results
      })
      .addCase(loadDashboardsAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(loadDashboardAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadDashboardAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dashboard = action.payload
      })
      .addCase(loadDashboardAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { clear } = dashboardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectDashboards = (state: RootState) => state.dashboard.dashboards;
export const selectDashboard = (state: RootState) => state.dashboard.dashboard;
export const selectStatus = (state: RootState) => state.dashboard.status;

export default dashboardSlice.reducer;
