import type { SelectProps } from 'antd';
import { Button, Form, Input, Modal, Select, Typography, message, Radio } from 'antd';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { selectAccount } from '../features/login/loginSlice';
import { loadProjectsAsync, selectProjects, selectStatus as selectProjectsStatus } from '../features/projects/projectsSlice';
import { createWorkflow, createProjectSnippet, getProjectSnippets } from '../services/signalApi';
import { extractBracketContents, extractSingleLineBracketContents, replaceBracketContents, replaceBracketContentsWithNode } from '../utils'
import { Workflow, Prompt as PromptType, WorkflowConfig as WorkflowConfigType } from '../types/types';
import { WorkflowLink } from './WorkflowLink'
import { useAppSelector, useAppDispatch } from '../app/hooks';


const get_timed_workflow_config_for_prompt = (prompt: PromptType): WorkflowConfigType => {
    // determine params for prompt, those should become project snippets, referenced in the execute_prompt step
    const tokens = prompt.content ? extractBracketContents(prompt.content) : []

    return {
        triggers: [
            {
                trigger_type: "timed",
                args: {
                    // schedule: {type: "daily"},
                    schedule: {type: "weekly", week_days: ["Mo"]},
                    schedule_hour: 4,
                    submission_params: [],
                },
            },
        ],
        step_definition: {
            schema_version: "1",
            args: {
                block_steps: [
                    {
                        block_step_type: "execute_prompt",
                        args: {
                            prompt_id: prompt.id,
                            output_method: {
                                output_method_type: "whole_prompt_response",
                                args: {
                                    set_ref: "response"
                                },
                            },
                            model: "gpt-4o-mini",
                            set_template_vars: tokens.map((token) => {
                                return {
                                    field: token,
                                    upstream_ref: token
                                }
                            }),
                        },
                    },
                    {
                        block_step_type: "set_result",
                        args: {
                            result_template: '[response]',
                            set_template_vars: [
                                {
                                    field: "response",
                                    upstream_ref: "response",
                                }

                            ]
                        },
                    },
                    {
                        block_step_type: "send_email",
                        args: {
                            subject_template: prompt.name,
                            content_markdown_template: `A Workflow was run: ${prompt.name}\nResponse:\n\n[response]`,
                            set_template_vars: [
                                {
                                    field: "response",
                                    upstream_ref: "response",
                                }

                            ]
                        },
                    },
                ]
            },
        },
    }
}

const get_manual_workflow_config_for_prompt = (prompt: PromptType): WorkflowConfigType => {
    // determine params for prompt, those should become submission params here and passed through as template vars
    const tokens = prompt.content ? extractBracketContents(prompt.content) : []
    const whole_line_tokens = prompt.content ? extractSingleLineBracketContents(prompt.content) : []

    return {
        triggers: [
            {
                trigger_type: "form_submit",
                args: {
                    submission_params: tokens.map((token) => {
                        return {
                            field: token,
                            field_type: whole_line_tokens.includes(token) ? 'text_area' : 'input'
                        }
                    }),
                },
            },
        ],
        step_definition: {
            schema_version: "1",
            args: {
                block_steps: [
                    {
                        block_step_type: "execute_prompt",
                        args: {
                            prompt_id: prompt.id,
                            output_method: {
                                output_method_type: "whole_prompt_response",
                                args: {
                                    set_ref: "response"
                                },
                            },
                            model: "gpt-4o-mini",
                            set_template_vars: tokens.map((token) => {
                                return {
                                    field: token,
                                    upstream_ref: token
                                }
                            }),
                        },
                    },
                    {
                        block_step_type: "set_result",
                        args: {
                            result_template: "[response]",
                            set_template_vars: [
                                {
                                    field: "response",
                                    upstream_ref: "response",
                                }

                            ]
                        },
                    }
                ]
            },
        },
    }
}

interface WorkflowFormSubmission {
    project: string
    name: string
    workflow_type: 'timed' | 'manual'
}


interface WorkflowModalFromPromptParams {
    isOpen: boolean
    prompt: PromptType
    project_id?: string
    onCancel: () => void
    onCreate: () => void
}


export function CreateWorkflowFromPromptModal({
    isOpen,
    prompt,
    project_id,
    onCancel,
    onCreate,
}: WorkflowModalFromPromptParams) {
    const [form] = Form.useForm();
    // const workflowName = Form.useWatch('name', form);

    const account = useAppSelector(selectAccount);
    const projects = useAppSelector(selectProjects);
    
    const projectsStatus = useAppSelector(selectProjectsStatus);
    const [inFlight, setInFlight] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [newWorkflow, setNewWorkflow] = useState<Workflow | null>(null);
    const dispatch = useAppDispatch();


    const projectOptions: SelectProps['options'] = (projects || []).map((i) => ({value: i.id, label: i.name}))

    useEffect(() => {
        if (projects && projects.length > 0){
            form.setFieldsValue({ project: projects[0].id})
        }
    }, [isOpen, prompt, JSON.stringify({ projects })]);

    useEffect(() => {
        if (projectsStatus === "idle" && projects === null) {
            dispatch(loadProjectsAsync())
        }
    }, [projectsStatus, JSON.stringify({ projects })])

    console.log('CreateWorkflowFromPromptModal', {isCreating, inFlight, prompt})

    const handleCancel = () => {
        form.resetFields()
        setNewWorkflow(null)
        setIsCreating(false)
        onCancel()
    }

    const onCreateWorkflowFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateWorkflowSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateWorkflow(values)
    };

    const onCreateWorkflow = (values: WorkflowFormSubmission) => {
        if (account) {
            console.log("onCreateWorkflow values", {values})
            const config = values.workflow_type === 'timed' ? get_timed_workflow_config_for_prompt(prompt) : get_manual_workflow_config_for_prompt(prompt)
            setIsCreating(true)
            getProjectSnippets({
                project_id: values.project,
                ordering: 'name'
            }).then((res) => {
                let snippets = res.data.results || []
                let p = Promise.resolve()
                if (values.workflow_type === 'timed') {
                    // need to create non-existant snippets
                    const tokens = prompt.content ? extractBracketContents(prompt.content) : []
                    const project_snippets = (snippets || []).filter((snippet) => snippet.project_id === values.project && tokens.includes(snippet.name))
                    const project_snippet_names = project_snippets.map((snippet) => snippet.name)
                    const tokens_without_snippet = tokens.filter((token) => !project_snippet_names.includes(token))
                    console.log("creating snippets", {snippets, tokens, project_snippets, project_snippet_names, tokens_without_snippet})
                    tokens_without_snippet.forEach((token) => {
                        p = p.then(async () => {
                            return createProjectSnippet(values.project, token, "").then((projectSnippetResponse) => {

                            })
                        })
                    })
                    
                }
                return p
            }).then(async () => {
                return createWorkflow(
                    account.id,
                    values.name,
                    config,
                    values.project,
                ).then((res) => {
                    console.log("created", {res})
                    if (res.status === 201) {
                        const response_data = res.data
                        setNewWorkflow(response_data)
                        setIsCreating(false)
                    } else {
                        console.log("err", {res})
                        message.error(res.data)
                        setIsCreating(false)
                    }
                    
                }
                ).catch((err) => {
                    console.log("err", {err})
                }).finally(() => {
                    setIsCreating(false)
                    onCreate()
                })
            })
            // TODO - allow starting with a template? wizard?
            
        }
    }
    

    const workflowFormBody = (
        <Form
        name="basic"
        form={form}
        layout='vertical'
        initialValues={{name: prompt.name, workflow_type: "timed"}}
        onFinish={onCreateWorkflowSubmit}
        onFinishFailed={onCreateWorkflowFailed}
        autoComplete="off"
        requiredMark={"optional"}
        >
            
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}

            <Typography.Paragraph>Automate actions and notifications {project_id ? 'within your project ' : ''}based on configured rules.</Typography.Paragraph>
            <Typography.Paragraph>Give your Workflow a name and then you can configure it in the next step.</Typography.Paragraph>

            {((newWorkflow === null) && (
                <>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Name your Workflow' }]}
                    >
                        <Input placeholder='workflow name: Create Blog Post' />
                    </Form.Item>

                    <Form.Item
                        label="Project"
                        name="project"
                        extra={<>You can view and create new Projects <Link to={'/projects'}>here</Link>.</>}
                        rules={[{ required: true, message: 'Associate a Project with your Workflow' }]}
                    >
                        <Select
                            // addonBefore={<LockOutlined />}
                            // showSearch
                            // style={{ width: '100%' }}
                            placeholder="project"
                            // optionFilterProp="children"
                            // onSelect={onSelect}
                            // onSearch={onSearch}
                            // filterOption={(input, option) =>
                            //     (option?.label && typeof option.label === 'string' ? option.label : '').toLowerCase().includes(input.toLowerCase())
                            // }
                            // onChange={handleChange}
                            options={projectOptions}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Workflow Type"
                        name="workflow_type"
                        extra={<>You can always change this later.</>}
                        rules={[{ required: true, message: 'Choose a type of Workflow to create this Prompt in.' }]}
                    >
                        <Radio.Group size="large">
                            <Radio.Button value="timed">Run on a Schedule</Radio.Button>
                            <Radio.Button value="manual">Run Manually</Radio.Button>
                        </Radio.Group>
                    </Form.Item>                

                    <Form.Item
                        wrapperCol={{ offset: 0, span: 24 }}
                    >
                        <Button type="primary" htmlType="submit" style={{float: 'right', width: 'unset'}} disabled={
                            isCreating || newWorkflow !== null
                        }>
                            Create Workflow
                        </Button>
                    </Form.Item>
                </>
            ))}

            {(newWorkflow && (
                <>
                    <Typography.Paragraph>Workflow Created: <WorkflowLink workflow={newWorkflow}>{newWorkflow.name}</WorkflowLink></Typography.Paragraph>

                    <Button type="primary" onClick={handleCancel}>
                        Okay
                    </Button>
                </>

            ))}

        </Form>
    )

    return (
            <Modal title={"Create Workflow" } open={isOpen} footer={null} onCancel={handleCancel}>
            {workflowFormBody}
            {/* {formBody} */}
            </Modal>
    )

    
}
