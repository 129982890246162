import type { SelectProps } from 'antd';
import { Button, Form, Input, message, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { createWorkflow } from '../services/signalApi';
import { Workflow } from '../types/types';
import { WorkflowLink } from './WorkflowLink';


import { useProjects } from '../app/apiQueries';
import { useAppSelector } from '../app/hooks';


interface CreateWorkflowFormParams {
    project_id?: string
    showProjectSelection?: boolean
    onCancel: () => void
    onCreate: () => void
    workflowCopySource?: Workflow
}


export function CreateWorkflowForm({
    project_id,
    showProjectSelection=false,
    onCancel,
    onCreate,
    workflowCopySource,
}: CreateWorkflowFormParams) {
    const [form] = Form.useForm();
    const workflowName = Form.useWatch('name', form);

    const account = useAppSelector(selectAccount);
    const [isCreating, setIsCreating] = useState(false);
    const [newWorkflow, setNewWorkflow] = useState<Workflow | null>(null);

    const {projects} = useProjects(account);


    // todo - fix
    const projectOptions: SelectProps['options'] = (projects || []).map((i) => ({value: i.id, label: i.name}))

    useEffect(() => {
        if (workflowCopySource) {
            form.setFieldsValue({ name: `${workflowCopySource.name} copy`})
        } else {
            form.setFieldsValue({ name: ''})

        }
    }, [JSON.stringify({workflowCopySource: workflowCopySource})]);

    console.log('CreateWorkflowModal', {isCreating, workflowCopySource})

    const handleCancel = () => {
        form.resetFields()
        setNewWorkflow(null)
        setIsCreating(false)
        onCancel()
    }

    const onCreateWorkflowFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateWorkflowSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateWorkflow(values)
    };

    const onCreateWorkflow = (values: any) => {
        if (account) {
            console.log("onCreateWorkflow values", {values})
            setIsCreating(true)
            // TODO - allow starting with a template? wizard?
            const use_project_id = values.project ? values.project : project_id
            if (use_project_id) {
                createWorkflow(
                    account.id,
                    values.name,
                    workflowCopySource ? workflowCopySource.config : {triggers: []},
                    values.project ? values.project : project_id
                ).then((res) => {
                    console.log("created", {res})
                    if (res.status === 201) {
                        const response_data = res.data
                        setNewWorkflow(response_data)
                        setIsCreating(false)
                    } else {
                        console.log("err", {res})
                        message.error(res.data)
                        setIsCreating(false)
                    }
                }
                ).catch((err) => {
                    console.log("err", {err})
                }).finally(() => {
                    setIsCreating(false)
                    onCreate()
                })
            } else {
                message.error("A Project must be specified")
            }
        }
    }
    

    const workflowFormBody = (
        <Form
            name="basic"
            form={form}
            layout='vertical'
            initialValues={project_id ? {project: project_id} : workflowCopySource ? {project: workflowCopySource?.project_id} : {}}
            onFinish={onCreateWorkflowSubmit}
            onFinishFailed={onCreateWorkflowFailed}
            autoComplete="off"
            requiredMark={"optional"}
        >
            
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            {workflowCopySource && (
                <Typography.Paragraph>Copying: {workflowCopySource.name}</Typography.Paragraph>
            )}
            <Typography.Paragraph>Automate actions and notifications {project_id ? 'within your project ' : ''}based on configured rules.</Typography.Paragraph>
            <Typography.Paragraph>Give your Workflow a name and then you can configure it in the next step.</Typography.Paragraph>

            {((newWorkflow === null) && (
                <>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Name your Workflow' }]}
                    >
                        <Input placeholder='Create Blog Post' />
                    </Form.Item>

                    {showProjectSelection && (
                        <Form.Item
                            label="Project"
                            name="project"
                            rules={[{ required: true, message: 'Associate a Project with your Workflow' }]}
                            extra={workflowCopySource ? <>You may clone your workflow to another project.</> : <>You may create a workflow in another project.</>}
                        >
                            <Select
                                options={projectOptions}
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        wrapperCol={{ offset: 0, span: 24 }}
                    >
                        <Button type="primary" htmlType="submit" style={{float: 'right', width: 'unset'}} disabled={
                            isCreating || newWorkflow !== null
                        }>
                            Create Workflow
                        </Button>
                    </Form.Item>
                </>
            ))}

            {(newWorkflow && (
                <>
                    <Typography.Paragraph>Workflow Created: <WorkflowLink workflow={newWorkflow}>{newWorkflow.name}</WorkflowLink></Typography.Paragraph>

                    <Button type="primary" onClick={handleCancel}>
                        Okay
                    </Button>
                </>

            ))}

        </Form>
    )

    return workflowFormBody

    
}
