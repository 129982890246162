import { Button, Space, Typography, Card, Dropdown, Divider, Form, Select, Input, Radio, Checkbox } from 'antd';
import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip, TimeScale
} from 'chart.js';
import type { MenuProps } from 'antd';
import ReactMarkdown from 'react-markdown';

import { Bar, Line } from 'react-chartjs-2';
import RGL, {WidthProvider} from "react-grid-layout";
import { Dashboard as DashboardType, DateFilter, Widget as WidgetType } from '../types/types'
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { SignalStream } from './SignalStream';
import { SignalList } from './SignalList';
import { SignalTextSummary } from './SignalTextSummary';
import { ReactElement, useEffect, useState } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { EditOutlined, EllipsisOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';

import {
    loadDataSetsAsync, selectDataSets,
} from '../features/dataSetList/dataSetSlice';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { red, green, yellow } from '@ant-design/colors';
const GridLayout = WidthProvider(RGL);


interface DashboardWidgetFormSubmissionValues {
    widget_type: string
    name: string
    ordering?: string
    limit?: string
    data_set_id?: string | null
    show_field_as_value?: string
    context_filter?: string
    default_date_filter?: DateFilter
    content?: string
    add_buttons?: boolean
    inverse_bull_bear?: boolean
    add_context_columns?: boolean
}


interface DashboardWidgetEditParams {
    // id: string
    // can_edit?: boolean
    // show_name?: boolean
    widget: WidgetType
    onSave: (widget: WidgetType) => void
    onPreview?: () => void
    onCancel: () => void
    // index: number
    // cloneWidget: (widget: WidgetType) => void
    // deleteWidget: (index: number) => void
    // style?: React.CSSProperties
}

export function DashboardWidgetEdit({
    widget,
    onSave,
    onPreview,
    onCancel,
    // index,
    // cloneWidget,
    // deleteWidget,
    // style,
}: DashboardWidgetEditParams) {
    const [form] = Form.useForm();
    const account = useAppSelector(selectAccount)
    const dataSets = useAppSelector(selectDataSets);
    const dispatch = useAppDispatch();
    const widget_type = Form.useWatch('widget_type', form);

    useEffect(() => {
        if (dataSets === null) {
            dispatch(loadDataSetsAsync())
        }
    }, [account?.id])

    const onDashboardWidgetSave = (values: DashboardWidgetFormSubmissionValues) => {
        console.log('Success:', {values, });
        const newWidget: WidgetType = JSON.parse(JSON.stringify(widget))

        if (values.name) {
            newWidget.name = values.name
        }

        if (values.widget_type) {
            newWidget.widget_config.widget_type = values.widget_type
        }

        if (newWidget.widget_config.widget_type === "signal_stream") {
            newWidget.widget_config.widget_args = {}
            newWidget.widget_config.widget_args.ordering = values.ordering
            if (values.limit !== undefined && values.limit !== "") {
                newWidget.widget_config.widget_args.limit = parseInt(values.limit, 10)
            }
            if (values.data_set_id !== null) {
                newWidget.widget_config.widget_args.data_set_id = values.data_set_id
            }
            newWidget.widget_config.widget_args.default_date_filter = values.default_date_filter
            newWidget.widget_config.widget_args.show_field_as_value = values.show_field_as_value
            newWidget.widget_config.widget_args.query_params = {}
            newWidget.widget_config.widget_args.query_params.context_filter = values.context_filter
            newWidget.widget_config.widget_args.add_buttons = values.add_buttons
            newWidget.widget_config.widget_args.inverse_bull_bear = values.inverse_bull_bear
        }

        if (newWidget.widget_config.widget_type === "signal_table") {
            newWidget.widget_config.widget_args = {}
            newWidget.widget_config.widget_args.ordering = values.ordering
            if (values.limit !== undefined && values.limit !== "") {
                newWidget.widget_config.widget_args.limit = parseInt(values.limit, 10)
            }
            if (values.data_set_id !== null) {
                newWidget.widget_config.widget_args.data_set_id = values.data_set_id
            }
            newWidget.widget_config.widget_args.default_date_filter = values.default_date_filter
            newWidget.widget_config.widget_args.query_params = {}
            newWidget.widget_config.widget_args.query_params.context_filter = values.context_filter
            newWidget.widget_config.widget_args.add_buttons = values.add_buttons
            newWidget.widget_config.widget_args.add_context_columns = values.add_context_columns
        }

        if (newWidget.widget_config.widget_type === "text") {
            newWidget.widget_config.widget_args = {content: values.content}
        }

        if (newWidget.widget_config.widget_type === "dynamic_text") {
            newWidget.widget_config.widget_args = {}
            newWidget.widget_config.widget_args.ordering = values.ordering
            if (values.limit !== undefined && values.limit !== "") {
                newWidget.widget_config.widget_args.limit = parseInt(values.limit, 10)
            }
            if (values.data_set_id !== null) {
                newWidget.widget_config.widget_args.data_set_id = values.data_set_id
            }
            newWidget.widget_config.widget_args.default_date_filter = values.default_date_filter
            newWidget.widget_config.widget_args.query_params = {}
            newWidget.widget_config.widget_args.query_params.context_filter = values.context_filter
            newWidget.widget_config.widget_args.content = values.content
        }

        onSave(newWidget)
    };

    const onDashboardWidgetFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const contentExtra = (widget_type: string): ReactElement => {
        if (widget_type === 'dynamic_text') {
            return <>You can use {'{SYMBOLS}'} and {'{SYMBOL_CASHTAGS}'} to substitute in the list of symbols.</>
        }
        return <></>
    }

    const contentPlaceholder = (widget_type: string): string => {
        if (widget_type === 'text') {
            return "Some markdown or text to show in the text widget..."
        }
        if (widget_type === 'dynamic_text') {
            return "Some markdown or text to use as a template for the dynamic text widget..."
        }
        return "Some markdown or text to show in the text widget..."
    }

    return (
        <Form
            name="basic"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
                widget_type: (widget.widget_config.widget_type === "blank" ? "signal_stream" : widget.widget_config.widget_type),
                name: widget.name,
                ...(
                    widget.widget_config.widget_type === "signal_stream" ? {
                        default_date_filter: widget.widget_config.widget_args.default_date_filter,
                        data_set_id: widget.widget_config.widget_args.data_set_id || null,
                        show_field_as_value: widget.widget_config.widget_args.show_field_as_value,
                        ordering: widget.widget_config.widget_args.ordering,
                        limit: widget.widget_config.widget_args.limit,
                        context_filter: widget.widget_config.widget_args.query_params?.context_filter,
                        add_buttons: widget.widget_config.widget_args.add_buttons,
                        inverse_bull_bear: widget.widget_config.widget_args.inverse_bull_bear,
                    } : {}),
                ...(
                    widget.widget_config.widget_type === "signal_table" ? {
                        default_date_filter: widget.widget_config.widget_args.default_date_filter,
                        data_set_id: widget.widget_config.widget_args.data_set_id || null,
                        ordering: widget.widget_config.widget_args.ordering,
                        limit: widget.widget_config.widget_args.limit,
                        context_filter: widget.widget_config.widget_args.query_params?.context_filter,
                        add_buttons: widget.widget_config.widget_args.add_buttons,
                        add_context_columns: widget.widget_config.widget_args.add_context_columns,
                    } : {}),
                ...(
                    widget.widget_config.widget_type === "text" ? {
                        content: widget.widget_config.widget_args.content,
                    } : {}),
                ...(
                    widget.widget_config.widget_type === "dynamic_text" ? {
                        default_date_filter: widget.widget_config.widget_args.default_date_filter,
                        data_set_id: widget.widget_config.widget_args.data_set_id || null,
                        ordering: widget.widget_config.widget_args.ordering,
                        limit: widget.widget_config.widget_args.limit,
                        context_filter: widget.widget_config.widget_args.query_params?.context_filter,
                        content: widget.widget_config.widget_args.content,
                    } : {})
            }}
            onFinish={onDashboardWidgetSave}
            onFinishFailed={onDashboardWidgetFailed}
            autoComplete="off"
            >

                <Form.Item
                    label="Name"
                    name={'name'}
                >
                    <Input />
                </Form.Item>
                
                <Form.Item
                    label="Type"
                    name="widget_type"
                    >
                        <Select>
                            <Select.Option value="signal_stream">Signal Stream</Select.Option>
                            <Select.Option value="signal_table">Signal Table</Select.Option>
                            <Select.Option value="dynamic_text">Dynamic Text</Select.Option>
                            <Select.Option value="text">Text Block</Select.Option>
                        </Select>
                </Form.Item>


                {(["signal_stream", "signal_table", "dynamic_text"].includes(widget_type)) && (
                    <>


                        <Form.Item
                            label="Recency"
                            name="default_date_filter"
                            >
                                <Radio.Group>
                                    <Radio.Button value="today">1d</Radio.Button>
                                    <Radio.Button value="7_days">7d</Radio.Button>
                                    <Radio.Button value="30_days">30d</Radio.Button>
                                </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="Data Set"
                            name={'data_set_id'}
                            extra={<>Optional filter to a specific data set.</>}
                        >
                            <Select>
                                <Select.Option key='none' value={null}>All Data Sets</Select.Option>
                                {dataSets?.filter((dataSet) => dataSet.subscribed).map((dataSet) => (
                                    <Select.Option key={dataSet.id} value={dataSet.id}>
                                        {dataSet.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Ordering"
                            name={'ordering'}
                            // wrapperCol={{ span: 6 }}
                            extra={<>Optional ordering to apply. Example: -value</>}
                            // wrapperCol={{ span: 16, offset: 1 }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Context Filter"
                            name={'context_filter'}
                            // wrapperCol={{ span: 6 }}
                            extra={<>Optional context filter to apply. Format of: key__gte:0.25,key2__lte:0.15</>}
                            // wrapperCol={{ span: 16, offset: 1 }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Limit"
                            name={'limit'}
                            // wrapperCol={{ span: 6 }}
                            extra={<>Only show the top signals up to the limit.</>}
                            // wrapperCol={{ span: 16, offset: 1 }}
                        >
                            <Input type='number' />
                        </Form.Item>

                        {["signal_stream", "signal_table"].includes(widget_type) && (
                            <Form.Item label="Include Filters" name="add_buttons" valuePropName="checked">
                                <Checkbox/>
                            </Form.Item>
                        )}
                        {["signal_stream"].includes(widget_type) && (
                            <Form.Item
                                label="Value Field"
                                name={'show_field_as_value'}
                                // wrapperCol={{ span: 6 }}
                                extra={<>Optionaly show one of the context fields as the value.</>}
                                // wrapperCol={{ span: 16, offset: 1 }}
                            >
                                <Input />
                            </Form.Item>

                        )}

                        {["signal_stream"].includes(widget_type) && (
                            <Form.Item label="Inverse Bull/Bear Icon" name="inverse_bull_bear" valuePropName="checked">
                                <Checkbox/>
                            </Form.Item>

                        )}


                        {(widget_type === "signal_table") && (
                            <Form.Item label="Include Context" name="add_context_columns" valuePropName="checked">
                                <Checkbox/>
                            </Form.Item>
                        )}

                        {/* 
                        <Form.Item
                            label="Limit"
                            name={'limit'}
                            // wrapperCol={{ span: 6 }}
                            extra={<>Optional maximum number of elements to show in stream</>}
                            // wrapperCol={{ span: 16, offset: 1 }}
                        >
                            <Input />
                        </Form.Item> */}



                    </>
                )}

                {["text", "dynamic_text"].includes(widget_type) && (
                    <Form.Item
                        label="Content"
                        name="content"
                        extra={contentExtra(widget_type)}
                    >
                        <Input.TextArea
                            showCount
                            maxLength={1600}
                            style={{ height: 120 }}
                            // onChange={onChange}
                            placeholder={contentPlaceholder(widget_type)}
                        />
                    </Form.Item>
                )}
                
                {/* {widget_type} */}

                <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
                    <Space>
                        <Button type="default" onClick={onCancel}>
                            Cancel
                        </Button>
                        {onPreview && (
                            <Button type="default">
                                Preview
                            </Button>
                        )}

                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Space>

                </Form.Item>


            </Form>
    )
}



interface DashboardWidgetParams {
    // id: string
    // can_edit?: boolean
    // show_name?: boolean
    widget: WidgetType
    can_edit?: boolean
    cloneWidget: (widget: WidgetType) => void
    updateWidget: (widget: WidgetType) => void
    deleteWidget: (widget: WidgetType) => void
    style?: React.CSSProperties
}


export function DashboardWidget({
    // id,
    // show_name = true,
    widget,
    can_edit = false,
    cloneWidget,
    updateWidget,
    deleteWidget,
    style,

}: DashboardWidgetParams) {
    const [inEditMode, setInEditMode] = useState<boolean>(false);

    if (widget.widget_config.widget_type === "blank" && !inEditMode) {
        setInEditMode(true)
    }

    const showEditWidget = () => {
        setInEditMode(true)


        // setIsDirty(true)
    }

    const cancelEditWidget = () => {
        setInEditMode(false)


        // setIsDirty(true)
    }

    const onSaveEditWidget = (widget: WidgetType) => {
        updateWidget(widget)
        setInEditMode(false)
    }

    const menu: MenuProps['items'] = [
        {
            label: <a onClick={() => showEditWidget()}>Edit</a>,
            key: '0',
            icon: <EditOutlined />,
        },
        {
            label: <a onClick={() => cloneWidget(widget)}>Clone</a>,
            key: '1',
            icon: <CopyOutlined />,
        },
        {
            label: <a onClick={() => deleteWidget(widget)}>Delete</a>,
            key: '2',
            icon: <DeleteOutlined />,
        },
    ]
    return (
        // <Col className="gutter-row" span={12} key={widget.id}>
        // <div key={widget.id}>
            <Card
                title={widget.name}
                key={widget.id}
                // extra={<a href="#">Editas</a>}
                extra={can_edit ? (<Space>
                    <Dropdown menu={{items: menu}} trigger={['click']} placement="bottomRight">
                        <EllipsisOutlined />
                    </Dropdown>
                    
                </Space>) : <></>}
                style={{...style, background: "none"}}
                // bordered={light ? true : false}
                bordered={false}
            >
            
            {inEditMode && (
                <div>
                    <Typography.Title level={4} style={{marginTop: '0'}}>Edit Widget</Typography.Title>
                    <DashboardWidgetEdit widget={widget} onCancel={cancelEditWidget} onSave={onSaveEditWidget} />
                    {/* <Space>
                        <Button type="default" onClick={() => cancelEditWidget(widget)}>Cancel</Button>
                        <Button type="primary" onClick={() => cancelEditWidget(widget)}>Save</Button>
                    </Space> */}
                    <Divider/>

                </div>
                
                // <Card type="inner" title="Editing" actions={[
                //     <Button type="default" onClick={() => cancelEditWidget(widget)}>Cancel</Button>,
                //     <Button type="primary" onClick={() => cancelEditWidget(widget)}>Save</Button>,
                // ]}>
                //     <p>Type: {widget.widget_config.widget_type}</p>
                // </Card>
                // <div>
                //     Editing
                //     <Space>
                //         <Button type="primary" onClick={() => cancelEditWidget(widget)}>Save</Button>
                //         <Button type="default" onClick={() => cancelEditWidget(widget)}>Cancel</Button>
                //     </Space>
                // </div>
            )}

            
            {/* <div style={{...styles.widget, ...widget.layout}}> */}
                {/* <Typography.Title level={2}>{widget.name}</Typography.Title> */}
                {widget.widget_config.widget_type === "signal_stream" && (
                    <SignalStream
                        key={widget.id}    
                        showButtons={widget.widget_config.widget_args.add_buttons ? true : false}
                        addContextColumns={false}
                        {...(widget.widget_config.widget_args.data_set_id ? {data_set_id: widget.widget_config.widget_args.data_set_id} : {})}
                        {...(widget.widget_config.widget_args.ordering ? {ordering: widget.widget_config.widget_args.ordering} : {})}
                        {...(widget.widget_config.widget_args.query_params ? {queryParams: widget.widget_config.widget_args.query_params} : {})}
                        {...(widget.widget_config.widget_args.default_date_filter ? {defaultDateFilter: widget.widget_config.widget_args.default_date_filter} : {})}
                        {...(widget.widget_config.widget_args.limit ? {limit: widget.widget_config.widget_args.limit} : {})}
                        {...(widget.widget_config.widget_args.show_field_as_value ? {show_field_as_value: widget.widget_config.widget_args.show_field_as_value} : {})}
                        {...(widget.widget_config.widget_args.inverse_bull_bear ? {inverse_bull_bear: widget.widget_config.widget_args.inverse_bull_bear} : {})}
                    />
                )}

                {widget.widget_config.widget_type === "signal_table" && (
                    <SignalList
                        key={widget.id}
                        showButtons={widget.widget_config.widget_args.add_buttons ? true : false}
                        addContextColumns={widget.widget_config.widget_args.add_context_columns ? true : false}
                        {...(widget.widget_config.widget_args.data_set_id ? {data_set_id: widget.widget_config.widget_args.data_set_id} : {})}
                        {...(widget.widget_config.widget_args.ordering ? {ordering: widget.widget_config.widget_args.ordering} : {})}
                        {...(widget.widget_config.widget_args.query_params ? {queryParams: widget.widget_config.widget_args.query_params} : {})}
                        {...(widget.widget_config.widget_args.default_date_filter ? {defaultDateFilter: widget.widget_config.widget_args.default_date_filter} : {})}
                        {...(widget.widget_config.widget_args.limit ? {limit: widget.widget_config.widget_args.limit} : {})}
                    />
                )}

                {widget.widget_config.widget_type === "text" && (
                    <ReactMarkdown>{widget.widget_config.widget_args.content || ''}</ReactMarkdown>
                )}

                {widget.widget_config.widget_type === "dynamic_text" && (
                    <SignalTextSummary
                        key={widget.id}
                        addContextColumns={false}
                        template={widget.widget_config.widget_args.content || ''}
                        {...(widget.widget_config.widget_args.data_set_id ? {data_set_id: widget.widget_config.widget_args.data_set_id} : {})}
                        {...(widget.widget_config.widget_args.ordering ? {ordering: widget.widget_config.widget_args.ordering} : {})}
                        {...(widget.widget_config.widget_args.query_params ? {queryParams: widget.widget_config.widget_args.query_params} : {})}
                        {...(widget.widget_config.widget_args.default_date_filter ? {defaultDateFilter: widget.widget_config.widget_args.default_date_filter} : {})}
                        {...(widget.widget_config.widget_args.limit ? {limit: widget.widget_config.widget_args.limit} : {})}
                    />
                )}

                {widget.widget_config.widget_type === "blank" && (
                    <div>
                        <p style={{textAlign: "center"}}>Select the parameters of this widget to save it.</p>
                    </div>
                )}
            {/* </div> */}
            </Card>
        // </div>
        // </Col>
    )
    
}
