import { Button } from 'antd';
import { TwitterOutlined } from '@ant-design/icons';

interface Dictionary<T> {
    [Key: string]: T;
}

interface TweetThisParams {
    text: string
    url?: string
    related?: string
}

export function TweetThis({text, url, related='pyroprompts'}: TweetThisParams) {
    const tweet_query_params: Dictionary<string> = {
        text: encodeURIComponent(text).toString(),
        ...(url ? {url}: {}),
        ...(related ? {related}: {}),
    }
    const tweet_query_param_str = Object.keys(tweet_query_params).map(key => key + '=' + tweet_query_params[key]).join('&');
    return <Button icon={<TwitterOutlined />} href={`https://twitter.com/intent/tweet?${tweet_query_param_str}`} target='blank'>Tweet this</Button>
}
