import type { InputRef } from 'antd';
import { Button, Empty, Form, Input, Modal, Space, Typography } from 'antd';
import { Link } from "react-router-dom";

import { PlusOutlined } from '@ant-design/icons';
import { getDataSets, getPrompts } from '../services/signalApi';
import { DataSet as DataSetType, Prompt as PromptType } from '../types/types';
import { Loading } from './Loading';
import { CreatePromptModal } from './CreatePromptModal';

import { useRef, useState } from 'react';
const { Search } = Input;
interface CreatePromptParams {
    buttonText?: string
    buttonType?: 'primary' | 'default' | 'text'
    onCreate? : (prompt: PromptType | null) => void
}

export function CreatePrompt({
    buttonText='Create Prompt',
    buttonType='default',
    onCreate
}: CreatePromptParams) {

    // const [apiStatus, setApiStatus] = useState<ApiStatus | null>(null);
    // const inputRef = useRef<InputRef>(null);
    const [isPromptModalOpen, setIsPromptModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // const [dataSetResults, setDataSetResults] = useState<DataSetType[] | null>(null);
    const [promptResults, setPromptResults] = useState<PromptType[] | null>(null);

    const showCreatePrompt = () => {
        setIsPromptModalOpen(true)
        // setTimeout(() => {
        //     inputRef.current!.focus({
        //         cursor: 'all',
        //       });
        // }, 200)

    }

    const onCreatePrompt = (prompt: PromptType | null) => {
        console.log("CreatePromptModal.onCreate")
        if (onCreate) {
            onCreate(prompt)
        }
    }

    const closeCreatePrompt = () => {
        setIsPromptModalOpen(false)
    }

    // const onSelection = () => {
    //     setIsPromptModalOpen(false)
    //     if (onSelect) {
    //         onSelect()
    //     }
    // }

    // const onSearchSubmitFailed = (errorInfo: any) => {
    //     console.log('Failed:', errorInfo);
    // };

    // const onSearchSubmit = (searchValue: string) => {
    //     console.log('Success:', {searchValue});
    //     if (searchValue === "") {
    //         console.log("clearing results")
    //         // setDataSetResults(null)
    //         setPromptResults(null)
    //     } else {
    //         console.log("searching results...")
    //         setLoading(true)
    //         getPrompts({content__icontains: searchValue, include_public: true}).then((response) => {
    //             // handle success
    //             if (response.status === 200) {
    //                 const response_data = response.data
    //                 if (response_data.results) {
    //                     setPromptResults(response_data.results)
    //                 }

    //             }
    
    //           }).catch(function (error) {
    //             // handle error
    //             console.log(error);
    //             setPromptResults([])
    //           })
    //           .then(function () {
    //             // resolve({ data: {results: []} })
    //             setLoading(false)
    //           });
    //     }
    //     // onCreateDashboard(values)
    // };

    return <>
        <div>
        {/* <Button type="text" style={{color: 'rgba(255, 255, 255, 0.85)'}} icon={<SearchOutlined />} onClick={showSearch}> */}
        <Button type={buttonType} icon={<PlusOutlined />} onClick={showCreatePrompt}>
            {buttonText}
        </Button>
        </div>
        <CreatePromptModal isOpen={isPromptModalOpen} onCancel={closeCreatePrompt} onCreate={onCreatePrompt} />
        {/* <Modal title={ "Search" } open={isSearchModalOpen} footer={null} onCancel={closeCreatePrompt}>
            <Space direction='vertical' style={{width: '100%'}}>
                <Search
                    placeholder="Prompt Content or Username..."
                    allowClear
                    enterButton
                    size="large"
                    onSearch={onSearchSubmit}
                    ref={inputRef}
                />
                {loading && (
                    <Loading message='Searching...' />

                )}
                {(promptResults && promptResults.length === 0) && (
                    <Empty description={"No Results Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {promptResults && promptResults.length > 0 && (
                    <div>
                        <Typography.Title level={4}>Prompts</Typography.Title>
                        <ul>
                            {promptResults.map((prompt) => {
                                return <li>{prompt.content}</li>
                            })}
                        </ul>
                    </div>
                )}

            </Space>
            
            
        </Modal> */}
    </>

}
