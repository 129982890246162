import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { datasetList } from './dataSetAPI';
import { DataSet } from '../../types/types';
import { subscribeDataSet, unsubscribeDataSet } from '../../services/signalApi'


export interface DataSetListState {
  datasets: DataSet[] | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: DataSetListState = {
  datasets: null,
  status: 'idle',
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadDataSetsAsync = createAsyncThunk(
  'dataset/fetch',
  async () => {
    const response = await datasetList();
    // The value we return becomes the `fulfilled` action payload
    console.log('loadDataSetsAsync', response)
    return response.data;
  }
);

export interface SubscribeDataSetData {
    account_id: string
    data_set_id: string
  }

export const subscribeDataSetAsync = createAsyncThunk(
    'dataset/subscribe',
    async (subscribeData: SubscribeDataSetData, thunkApi) => {
      const response = await subscribeDataSet(subscribeData.account_id, subscribeData.data_set_id);
      thunkApi.dispatch(loadDataSetsAsync())
      // The value we return becomes the `fulfilled` action payload
      // console.log(response)
      // console.log(response.data)
      // console.log(response.data.access_token)
      if (response.status !== 200) {
        return response.data
      }
      return {
        ...response.data
      }
    }
  );

export const unsubscribeDataSetAsync = createAsyncThunk(
    'dataset/unsubscribe',
    async (subscribeData: SubscribeDataSetData, thunkApi) => {
      const response = await unsubscribeDataSet(subscribeData.account_id, subscribeData.data_set_id);
      thunkApi.dispatch(loadDataSetsAsync())
      // The value we return becomes the `fulfilled` action payload
      // console.log(response)
      // console.log(response.data)
      // console.log(response.data.access_token)
      if (response.status !== 200) {
        return response.data
      }
      return {
        ...response.data
      }
    }
  );

export const datasetSlice = createSlice({
  name: 'dataset',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log("clear")
      state.datasets = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadDataSetsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadDataSetsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.datasets = action.payload.results
      })
      .addCase(loadDataSetsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { clear } = datasetSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectDataSets = (state: RootState) => state.datasets.datasets;
export const selectStatus = (state: RootState) => state.datasets.status;

export default datasetSlice.reducer;
