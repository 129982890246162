import { CheckOutlined, CloseOutlined, FireOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import { Alert, Col, Image, List, Flex, Row, Space, Typography, Carousel } from 'antd';
import React, { useEffect, useState } from "react";
import { useAppSelector, useIsBigScreen, } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';

import { QAndAList } from '../../components/QAndAList';
import { SignUpButton } from '../../components/SignUpButton';
import OpenAILogo from '../../images/openai';
import GroqLogo from '../../images/groq';
import AWSLogo from '../../images/aws';
import XAIIcon from '../../images/xai'
import PerplexityIcon from '../../images/perplexity';

import { StoreFront } from '../../components/StoreFront';
import { selectLight, selectTheme } from '../../features/lightDarkSwitch/lightDarkSwitchSlice';
import { getThemeGreen, getThemeRed } from '../../themeRegistry';








// const subSectionStyle: React.CSSProperties = {border: '1px solid red'}
const subSectionStyle: React.CSSProperties = {}

const signUpButtonColor = 'linear-gradient(120deg, #e25501 0%, #e26801 25%, #e27b01 75%, #f9b320 100%)';
const signUpButtonStyle = {borderRadius: '16px', fontSize: '1.6rem', background: signUpButtonColor, border: 0}
interface TitleDesc {
  title: string
  desc: string | React.ReactElement
  imageUrl?: string
}

export function ShortHomeList({
    list,
    icon,
    style,
}: {
  list:string[]
  icon: React.ReactElement
  style?: React.CSSProperties
}) {
    return <Row style={{...subSectionStyle}}>
        <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12, offset: 6}}>


            <List
                itemLayout="horizontal"
                dataSource={list}
                split={false}
                bordered={true}
                style={style ? style : {}}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<div style={{marginTop: '1px'}}>{icon}</div>}
                            title={<Typography.Text style={{marginTop: 0, fontSize: '18px', lineHeight: "0.9"}}>{item}</Typography.Text>}
                        />
                    </List.Item>
                )}
            />
        </Col>

    </Row>
}

export function GoodBadHomeList({
    good_list,
    good_header,
    bad_list,
    bad_header,
}: {
  good_list:string[]
  good_header: string
  bad_list:string[]
  bad_header: string
}) {
    const selectedTheme = useAppSelector(selectTheme)

    const green_color = getThemeGreen(selectedTheme)
    const red_color = getThemeRed(selectedTheme)
    return <Row style={{marginTop: '100px', marginBottom: '20px', ...subSectionStyle}}>
        <Col xs={{span: 24}} md={{span: 11}} lg={{span: 8, offset: 3}}>

            <Typography.Title level={2} style={{textAlign: 'center'}}>{bad_header}</Typography.Title>
            <List
                itemLayout="horizontal"
                dataSource={bad_list}
                split={false}
                bordered={true}
                style={{background: 'rgba(255,0,0,0.07)', padding: '24px 0', borderColor: 'rgba(255,0,0,0.4)'}}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<div style={{marginTop: '1px'}}><CloseOutlined style={{color: red_color, fontSize: '18px'}} /></div>}
                            title={<Typography.Text style={{marginTop: 0, fontSize: '18px', lineHeight: "0.9"}}>{item}</Typography.Text>}
                        />
                    </List.Item>
                )}
            />
        </Col>
        <Col xs={{span: 24}} md={{span: 11, offset: 2}} lg={{span: 8, offset: 2}}>
            <Typography.Title level={2} style={{textAlign: 'center'}}>{good_header}</Typography.Title>

            <List
                itemLayout="horizontal"
                dataSource={good_list}
                split={false}
                bordered={true}
                style={{background: 'rgba(0,255,0,0.07)', padding: '24px 0', borderColor: 'rgba(0,255,0,0.4)'}}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<div style={{marginTop: '1px'}}><CheckOutlined style={{color: green_color, fontSize: '18px'}} /></div>}
                            title={<Typography.Text style={{marginTop: 0, fontSize: '18px', lineHeight: "0.9"}}>{item}</Typography.Text>}
                        />
                    </List.Item>
                )}
            />
        </Col>

    </Row>
}


export function HomeList({
    list,
    icon,
    style,
}: {
  list:TitleDesc[]
  icon: React.ReactElement
  style?: React.CSSProperties
}) {
    return <Row style={{...subSectionStyle}}>
        <Col xs={{span: 24}} md={{span: 24}} lg={{span: 12, offset: 6}}>


            <List
                itemLayout="horizontal"
                dataSource={list}
                split={false}
                bordered={true}
                style={style ? style : {}}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<div style={{marginTop: '1px'}}>{icon}</div>}
                            title={<Typography.Text style={{marginTop: 0, fontSize: '18px', lineHeight: "0.9"}}>{item.title}</Typography.Text>}
                            description={item.desc}
                        />
                    </List.Item>
                )}
            />
        </Col>

    </Row>
}

export function CenteredHeader({
    title,
    style,
}: {
  title: string
  style?: React.CSSProperties
}) {
    return <Row style={style ? style : {marginTop: '100px', marginBottom: '20px', ...subSectionStyle}}>
        <Col span={24}>
            <Typography.Title level={2} style={{textAlign: 'center'}}>{title}</Typography.Title>

        </Col>
    </Row>
}


export function HomeAlternatingList({
    list,
    order=1,
    // style,
}: {
  list: TitleDesc[]
  order?: 0 | 1
  // style?: React.CSSProperties
}) {
    const isBigScreen = useIsBigScreen()

    const light = useAppSelector(selectLight);

    const featuredColor = light ? featuredColorLight : featuredColorDark

    return <Row style={{...subSectionStyle}} gutter={[{xs:8, sm: 12, md: 32}, {xs: 8, sm: 12, md: 32}]}>
        {list.map((elem, i) => (
            <React.Fragment key={i}>

                {/* {i % 2 === 1 && ( */}
                <Col key={i * 2} xs={{ span: 24, order: i * 2 }} md={{ span: 24, order: i * 2 }} lg={{ span: 12, order: i % 2 === order ? (i * 2) : ((i * 2) + 1) }} style={{...(isBigScreen ? {height: '400px'} : {}), overflow: 'hidden'}}>
                    {/* <Col span={12} style={{height: '400px', overflow: 'hidden'}}> */}
                    <Image
                        preview={false}
                        width={'100%'}
                        style={{ width: '100%', borderRadius: '4px'}}
                        // src={`https://assets.pyroprompts.com/img/fire_boy.gif`}
                        // src={`https://assets.pyroprompts.com/img/boy-shooting-fire-620x620.png`}
                        src={elem.imageUrl ? elem.imageUrl : `https://assets.pyroprompts.com/img/fire-bird-620x620.png`}
                        // src={`https://assets.pyroprompts.com/img/fire-dancer-620x620.png`}
                        // src={`https://assets.pyroprompts.com/img/fire-dancer-620x620.png`}
                        // src={`https://assets.pyroprompts.com/img/robot-fire-hand-abstract-1-620x620.png`}

                    />

                </Col>
                {/* )} */}
                <Col key={(i * 2) + 1} xs={{ span: 24, order: (i * 2) + 1 }} md={{ span: 24, order: (i * 2) + 1 }} lg={{ span: 12, order: i % 2 === order ? ((i * 2) + 1) : (i * 2) }}>
                    {/* <Col span={12}> */}
                    <Typography.Title level={3} style={{marginTop: '12px', color: featuredColor}}>{elem.title}</Typography.Title>

                    { typeof elem.desc === 'function' ? (
                        <>{elem.desc}</>
                    ): (
                        <Typography.Paragraph style={{fontSize: "1.1rem", marginBottom: '64px'}}>{elem.desc}</Typography.Paragraph>
                    )}


                </Col>

            </React.Fragment>
        ))}
    </Row>
}

const titles = [
    "Marketing Content",
    "Project Management",
    "Inspiration",
    "Meal Planning",
    "Newsletter Content",
    "Workout Planning",
    "Activity Planning",
    "News Summaries",
    "Travel Planning",
    "Proposal Writing",
]

const featuredColorLight = '#e27b01'
const featuredColorDark = '#f9b320'


export function Home() {

    const account = useAppSelector(selectAccount);
    const isBigScreen = useIsBigScreen()
    const light = useAppSelector(selectLight);

    const pFontSizeStyle = {fontSize: "1.10rem"}

    useEffect(() => {document.title = 'PyroPrompts | Home'}, [])
    
    // automate complex multi-step tasks
    // reuse saved tasks
    // schedult tasks to run anytime
    // start quickly with prompt library and workflow templates

    const featuredColor = light ? featuredColorLight : featuredColorDark

    return (
        <div className="App">
            <div className="App-content">
                <div className="App-page-content">

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{'marginTop': '40px'}}>
                        <Col xs={24} md={14}>

                            {/* <Typography.Paragraph style={{fontSize: "1.0rem", opacity: "0.8"}}>#1 AI Automation Tool of 2024</Typography.Paragraph> */}
                            <Carousel effect="fade" autoplay={true} autoplaySpeed={6000} dots={false} speed={800} pauseOnHover={false}>
                                {titles.map((title, index) => (
                                    <div key={index}>
                                        <Typography.Title level={1} style={{marginTop: 0, marginBottom: '4px', fontFamily: "'Titillium Web', 'Noto Sans', proxima-nova, sans-serif", ...(isBigScreen ? {fontSize: "3.2rem"} : {fontSize: "2.4rem"})}}>Automate <span style={{color: featuredColor }}>{title}</span></Typography.Title>
                                    </div>
                                ))}
                            </Carousel>
                            <Typography.Paragraph style={{fontSize: "1.2rem",  opacity: '0.8'}}>AI with no conversations, no code and no hassle.</Typography.Paragraph>

                            <Row style={{marginBottom: '36px'}}>
                                <Col xs={24} md={24} lg={10}>
                                    <SignUpButton text={"Get Started"} icon={<FireOutlined />} style={{width: '100%', marginTop: '24px', marginBottom: '8px', height: '64px', ...signUpButtonStyle}} />
                                    <Typography.Paragraph style={{fontSize: "0.8rem", opacity: '0.8'}}>Begin automating with 21 credits when you sign up, enough for three weeks of daily automation. <br/> <Link to={'/store'}>Buy more Automation Credits</Link></Typography.Paragraph>
                                </Col>
                            </Row>
                            
                            <Typography.Paragraph style={{marginLeft: '8px', fontSize: "1.1rem"}}><FireOutlined style={{color: featuredColor}} /> Create workflows once, use them over and over.</Typography.Paragraph>
                            <Typography.Paragraph style={{marginLeft: '8px', fontSize: "1.1rem"}}><FireOutlined style={{color: featuredColor}} /> Schedule tasks to run day or night.</Typography.Paragraph>
                            <Typography.Paragraph style={{marginLeft: '8px', fontSize: "1.1rem"}}><FireOutlined style={{color: featuredColor}} /> Start quickly with prompt library or project templates.</Typography.Paragraph>



                            {/* <Typography.Paragraph style={{fontSize: "1.2rem"}}>Save time and establish repeatable, automated AI systems without code</Typography.Paragraph> */}
                            {/* <Typography.Paragraph style={{fontSize: "1.2rem"}}>Create and automate the best AI prompts</Typography.Paragraph> */}
                            {/* <Guide /> */}
                            {/* {workflows()} */}


                        </Col>
                        <Col xs={24} md={{span: 9, offset: 1}}>
                            <Image
                                preview={false}
                                style={{borderRadius: '4px', ...(isBigScreen ? {} : {marginTop: '24px'})}}
                                // src={`https://assets.pyroprompts.com/img/fire_boy.gif`}
                                // src={`https://assets.pyroprompts.com/img/boy-shooting-fire-620x620.png`}
                                // src={`https://assets.pyroprompts.com/img/fire-bird-620x620.png`}
                                // src={`https://assets.pyroprompts.com/img/fire-dancer-620x620.png`}
                                // src={`https://assets.pyroprompts.com/img/fire-dancer-620x620.png`}
                                src={`https://assets.pyroprompts.com/img/robot-fire-hand-abstract-1-620x620.png`}

                            />

                        </Col>
                    </Row>

                    <Row style={{marginTop: '48px', ...subSectionStyle}}>
                        <Col xs={{span: 20, offset: 2}} md={{span: 18, offset: 3}} lg={{span: 16, offset: 4}}>
                        <Typography.Paragraph style={{fontSize: "0.7rem", opacity: '0.50', textAlign: 'center'}}>INTEGRATED SERVICES</Typography.Paragraph>
                        <Flex wrap={'wrap'} justify='center' gap={8} align='center'>
                            <Space split={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>} wrap>
                                <OpenAILogo width={"140px"} style={{fontSize: '4em'}}/>
                                <GroqLogo width={"90px"} style={{fontSize: '4em'}}/>
                                <img src={ light ? '/img/meta.png' : '/img/meta_white.png'} width={'160px'}/>
                                <XAIIcon width={"40px"} style={{fontSize: '2.5em'}} />
                                <PerplexityIcon width={"40px"} style={{fontSize: '3em'}} />
                                <AWSLogo width={"60px"} style={{fontSize: '4em'}} />
                            </Space>
                        </Flex>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '48px', ...subSectionStyle}}>
                        <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 16, offset: 4}}>

                            <Typography.Paragraph style={{fontSize: "1.1rem", opacity: '0.8'}}>Why PyroPrompts?</Typography.Paragraph>
                            <Typography.Paragraph style={{fontSize: "1.30rem"}}>Schedule your prompts to run against LLMs, like ChatGPT, so you can <Typography.Text strong style={{fontSize: "1.45rem", color: featuredColor}}>set it and forget it</Typography.Text>, meaning no more needing to remember to run a prompt, no more copying prompts and filling in the blanks, and no more needing to initiate with a lazy AI to weave it into your life.</Typography.Paragraph>
                            <Typography.Paragraph style={{fontSize: "1.30rem"}}>AI should work for you while you sleep, we help you do that.</Typography.Paragraph>
                        </Col>
                    </Row>

                    <CenteredHeader title='How It Works' />

                    <HomeList
                        list={[
                            {
                                title: '1. Create a Prompt (Optional)',
                                desc: 'Or skip this entirely and use our library of great prompts, freely available.',
                            },
                            {
                                title: '2. Create a Workflow in a Project',
                                desc: 'Create a Workflow from a Prompt in two clicks. Choose between a Timed Workflow or a Form-Triggered Workflow. Multiple Workflows exist in a Project, which also contains any Snippets that you and AI maintain.',
                            },
                            {
                                title: '3. Check PyroPrompts or your Email',
                                desc: 'Workflows can just save updates to Snippets for you to review whenever. Or, email the results to yourself.',
                            },
                        ]}
                        icon={<FireOutlined style={{color: featuredColor, fontSize: '18px'}} />}
                        style={{padding: '24px 0', borderColor: featuredColor}}
                    />

<Row style={{marginTop: '100px'}}>
                        <Col xs={{span: 22, offset: 1}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xl={{span: 10, offset: 7}}>

                            <SignUpButton text={"Automate Away!"} style={{width: '100%', marginTop: '4px', height: '64px', ...signUpButtonStyle}} />


                        </Col>
                    </Row>


                    <Row style={{marginTop: '60px', ...subSectionStyle}}>
                        <Col xs={{span: 22, offset: 1}} md={{span: 18, offset: 3}} lg={{span: 14, offset: 5}}>


                            <Typography.Paragraph style={{fontSize: "1.2rem", opacity: '0.8', textAlign: 'right'}}>Who are we?</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>Hey, I'm <a href='https://heyferrante.com?ref=pyroprompts-home-letter' target='blank'>Matt Ferrante</a>!</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>I started automating my AI because I thought there could be a better way to adopt AI in my life than conversing with ChatGPT.</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>Right now, you have initiate with AI, as if you want AI taking more of your time instead of giving it back. You can't control the finer details, you're at the mercy of ChatGPT, making repeatable quality difficult.</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>AI should work for you, not the other way around. You don't want to copy and paste prompts and fill in placeholders like some data-entry task rabbit. You want AI to follow instructions like the hardest working employee you've ever met would so you can focus on defining systems and frameworks or enjoying time with friends and family.</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>I've been automating in advertising tech, marketing tech and financial tech for over 15 years and thought AI tech could use a bit of love as well.</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>So, I made PyroPrompts for simple, smart, and automated AI with nothing to deploy.</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>I hope you enjoy it!</Typography.Paragraph>
                            <Typography.Paragraph style={pFontSizeStyle}>- Matt</Typography.Paragraph>

                        </Col>
                    </Row>

                    


                    <CenteredHeader title="You + PyroPrompts" />

                    <HomeAlternatingList
                        list={[
                            {
                                title: 'Reuse your Prompts',
                                imageUrl: 'https://assets.pyroprompts.com/img/reuse1-620x354.png',
                                desc: 'You spent time crafting a great prompt. Save it, fill it and use it again in seconds. Or, plug it into your automations in two clicks.',
                            },
                            {
                                title: 'Scheduled AI',
                                imageUrl: 'https://assets.pyroprompts.com/img/multi-prompt-1-620x354.png',
                                desc: 'Have PyroPrompts run your prompts, filling in snippets you have saved, and save or email you the results so you don\'t need to start a conversation with AI every time you want to use it.',
                            },
                            {
                                title: 'AI that Learns',
                                imageUrl: 'https://assets.pyroprompts.com/img/ai-learn-1-620x354.png',
                                desc: 'Projects keep Snippets that allow you to use, set and update context, so you can control and see the memory AI references.',
                            },
                            {
                                title: 'Multi-Step Prompting',
                                imageUrl: 'https://assets.pyroprompts.com/img/multi-step-1-620x354.png',
                                desc: 'Prompts do a really good job at single tasks. They begin to lose their ability to respond consistently with high quality as they are asked to handle more tasks. Multi-Step Prompting, or Chaining Prompts, in Workflows lets you run each step of a process.',
                            },
                        ]}
                    />

                    <Row style={{marginTop: '100px'}}>
                        <Col xs={{span: 22, offset: 1}} md={{span: 22, offset: 1}} lg={{span: 8, offset: 8}}>

                            <SignUpButton text={"Start Automating"} style={{width: '100%', marginTop: '4px', height: '64px', ...signUpButtonStyle}} />


                        </Col>
                    </Row>






                    <CenteredHeader title='Case Studies' />

                    <HomeAlternatingList
                        order={0}
                        list={[
                            {
                                title: 'Business and Market Research',
                                imageUrl: 'https://assets.pyroprompts.com/img/business-1-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>We applied AI to business and marketing frameworks to speed up the offer ideation phase.</Typography.Paragraph>
                                    <Typography.Paragraph style={pFontSizeStyle}>
                                        <a href={'https://blog.pyroprompts.com/post/4ea7057c-how-to-conduct-market-research-with-ai/'} target='blank'>
                    See How to Run Market Research with AI
                                        </a>
                                    </Typography.Paragraph>

                                </>,
                            },
                            {
                                title: 'Personalized Meal Planning',
                                imageUrl: 'https://assets.pyroprompts.com/img/food-1-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>Discover new meals like the ones you already love, create a grocery list and create a weekly meal plan with AI.</Typography.Paragraph>
                                    <Typography.Paragraph style={pFontSizeStyle}>
                                        <a href={'https://blog.pyroprompts.com/post/4ffbc286-personalized-meal-plan-with-ai/'} target='blank'>
                    Personalize a Meal Plan with AI
                                        </a>
                                    </Typography.Paragraph>

                                </>,
                            },
                        ]}
                    />

                    <Row style={{marginTop: '100px'}}>
                        <Col xs={{span: 22, offset: 1}} md={{span: 22, offset: 1}} lg={{span: 8, offset: 8}}>
                            <SignUpButton text={"Create a Free Account"} style={{width: '100%', marginTop: '4px', height: '64px', ...signUpButtonStyle}} />


                        </Col>
                    </Row>

                    <CenteredHeader title='Pricing' />

                    <Row>
                        <Col xs={{span: 22, offset: 1}} md={{span: 22, offset: 1}} lg={{span: 16, offset: 4}}>
                            <Typography.Paragraph style={{fontSize: "1.2rem", textAlign: 'center'}}>Creating an account to manage prompts is completely free. For Automations, we have a Credit system and you can purchase  one-time purchase options as well as subscriptions.</Typography.Paragraph>
                            <Typography.Paragraph style={{fontSize: "1.2rem", textAlign: 'center'}}><Link to={'/store'}>See all purchase options</Link></Typography.Paragraph>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={{offset: 0, span: 24}} md={{offset: 0, span: 24}} lg={{offset: 1, span: 22}}>
                            <StoreFront highlightFeatured style={isBigScreen ? 'cards': 'list'} skus={["credits_100", "package_basic", "package_pro"]}/>

                        </Col>
                    </Row>



                    <CenteredHeader title='Questions?' />

                    <Row>
                        <Col sm={{offset: 0, span: 24}} md={{offset: 4, span: 16}} lg={{offset: 6, span: 12}}>
                            <QAndAList
                                qas={[
                                    {
                                        q: "Do I need a Subscription to automate?",
                                        a: "No! We have a one-time credit purchase option and those credits are used to execute workflows. The 100 Credits option is enough to run the most simple AI every day for 100 days."
                                    },
                                    {
                                        q: "Why should I use this instead of LangChain?",
                                        a: "If you like to code (or have ChatGPT code for you), LangChain is a great option. You can run it locally on your computer or deploy it to a server to run. This probably gives you ultimate control but may be overkill. This can be expensive, too. Hosting isn't cheap and developers aren't cheap. Consider what you're building and see if PyroPrompts could be a cheaper alternative to build a minimum viable product."
                                    },
                                    {
                                        q: "Can you build a specific feature for me?",
                                        a: "We love hearing what our customers want to see more of. Please contact us so we can get your ask on our backlog. We generally will build something that multiple people will get to enjoy.",
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Row style={{marginTop: '100px'}}>
                        <Col xs={{span: 22, offset: 1}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xl={{span: 10, offset: 7}}>

                            <SignUpButton text={"Join a Prompting Paradise"} style={{width: '100%', marginTop: '4px', height: '64px', ...signUpButtonStyle}} />


                        </Col>
                    </Row>

                    {/* <CenteredHeader title='But I heard...' /> */}
                    {/* <CenteredHeader title='Automate Your Life' /> */}

                    <GoodBadHomeList
                        bad_header='Without PyroPrompts'
                        bad_list={[
                            'Searching through ChatGPT for a prompt',
                            'Going to ChatGPT every day to prompt',
                            'Pasting in multiple prompts',
                            'Deploying and Hosting your own AI',
                            'No Control over your AI',
                            'Training on your conversations',
                            'Glorified Data Entry'
                        ]}
                        good_header='With PyroPrompts'
                        good_list={[
                            'Saved and searchable prompts',
                            'Scheduled AI sends you an email',
                            'Chain together multiple prompts',
                            'Hosted and Managed AI',
                            'Full AI Control, see the Memory',
                            'We never train on your data',
                            'AI that just works'
                        ]}

                    />



                    <CenteredHeader title='Hot Resources' />

                    <HomeAlternatingList
                        list={[
                            {
                                title: 'Massive Prompt Library',
                                imageUrl: 'https://assets.pyroprompts.com/img/book-brain-1-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>Browse the hundreds of task-driven, ideation-focused and convo-starter Prompts published and categorized in the Prompt Library.
                                    </Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/prompts'}>
                    Visit the Prompt Library
                                    </Link></Typography.Paragraph>

                                </>,
                            },
                            {
                                title: 'Public Workflows',
                                imageUrl: 'https://assets.pyroprompts.com/img/workflows-3-620x354.png',

                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>Our published Workflows help you execute specific AI tasks by just filling out a form.</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/workflows'}>
                    See the Workflows
                                    </Link></Typography.Paragraph>

                                </>,
                            },
                            {
                                title: 'Newsletter',
                                imageUrl: 'https://assets.pyroprompts.com/img/newsletter-2-620x354.png',

                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>The Weekly PyroPrompts Newsletter contains exclusive prompts, workflows and offers. Keep an eye out for free credits!</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/newsletter'}>
                    Subscribe to the Newsletter
                                    </Link></Typography.Paragraph>
                                </>,
                            },
                            {
                                title: 'Browser Extension',
                                imageUrl: 'https://assets.pyroprompts.com/img/extension-2-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>The PyroPrompts Chrome Extension gives you access to your Prompts and Snippets anywhere on the internet at a keystroke.</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/extension'}>
                    Get the Extension
                                    </Link></Typography.Paragraph>
                                </>,
                            },
                            {
                                title: 'Scheduled Workflows',
                                imageUrl: 'https://assets.pyroprompts.com/img/schedule-2-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>Scheduled AI Emails frees up your time and requires no discipline. Just set it and forget it.</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/workflow_scheduling'}>
                    Start Scheduling
                                    </Link></Typography.Paragraph>
                                </>,
                            },
                            {
                                title: 'Prompt Guide',
                                imageUrl: 'https://assets.pyroprompts.com/img/prompt-guide-2-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>Prompting 101. The Comprehensive Prompt Guide contains the foundation of what a Prompt Engineer should know.</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/prompt_guide_2023'}>
                    Get the Free Prompt Guide
                                    </Link></Typography.Paragraph>
                                </>,
                            },
                            {
                                title: 'Free AI Consult',
                                imageUrl: 'https://assets.pyroprompts.com/img/consulting-1-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>Tell us about yourself and we'll outline some automation options.</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/w/automation-suggestion'}>
                    Learn about Automations
                                    </Link></Typography.Paragraph>
                                </>,
                            },

                            {
                                title: 'Custom Work',
                                imageUrl: 'https://assets.pyroprompts.com/img/ai-custom-1-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>We help businesses build and deploy successful projects that leverage AI.</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/consulting'}>
                    Learn more
                                    </Link></Typography.Paragraph>
                                </>,
                            },
                            {
                                title: 'Contact',
                                imageUrl: 'https://assets.pyroprompts.com/img/contact-2-620x354.png',
                                desc: <>
                                    <Typography.Paragraph style={pFontSizeStyle}>We love to hear from our customers and friends. We're here to help.</Typography.Paragraph><Typography.Paragraph style={pFontSizeStyle}><Link to={'/contact_us'}>
                    Contact us
                                    </Link></Typography.Paragraph>
                                </>,
                            },
                        ]}
                    />


                    <CenteredHeader title='Get AI Working For You' />
                    <Row>
                        <Col xs={{span: 22, offset: 1}} md={{span: 22, offset: 1}} lg={{span: 12, offset: 6}}>



                            <Typography.Paragraph style={{fontSize: "1.2rem", textAlign: 'center'}}>No more spending time running AI, let it run while you sleep.</Typography.Paragraph>

                        </Col>
                    </Row>
                    <Row style={{marginBottom: '100px'}}>
                        <Col xs={{span: 20, offset: 2}} md={{span: 20, offset: 2}} lg={{span: 6, offset: 9}}>
                            <SignUpButton text={"Join Now"} style={{width: '100%', marginTop: '4px', height: '64px', ...signUpButtonStyle}} />


                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
