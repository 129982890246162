import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useAppSelector } from '../../app/hooks';
import { PromoCodeSubmitForm } from '../../components/PromoCodeSubmitForm';
import { SignUpButton } from '../../components/SignUpButton';
import { selectAccount } from '../../features/login/loginSlice';

const centeredTextStyling: React.CSSProperties = {
    textAlign: 'center'
}

export function PromoCodeRedeem() {
    const account = useAppSelector(selectAccount);

    useEffect(() => {document.title = 'PyroPrompts | Redeem Promo Code'}, [])

    return (
        <div className="App">
            <div className="App-content">
                <div className="App-page-content">
                    <Row>
                        <Col span={24} style={centeredTextStyling} >
                            <Typography.Title level={1}>Promo Code Redemption</Typography.Title>
                            <Typography.Paragraph>Use a Promo Code for Free Stuff. We regularly send promo codes to our <Link to={`/newsletter`}>newsletter</Link> subscribers.</Typography.Paragraph>
                        </Col>
                    </Row>
                    
                    {account && (
                        <Row>
                            <Col xs={{offset: 0, span: 24}} sm={{offset: 2, span: 20}} md={{offset: 5, span: 14}} lg={{offset: 7, span: 10}} xl={{offset: 8, span: 8}} xxl={{offset: 8, span: 8}} >
                                <PromoCodeSubmitForm />
                            </Col>

                        </Row>
                    )}

                    {!account && (
                        <Row>
                            <Col style={centeredTextStyling} xs={{offset: 0, span: 24}} sm={{offset: 2, span: 20}} md={{offset: 5, span: 14}} lg={{offset: 7, span: 10}} xl={{offset: 8, span: 8}} xxl={{offset: 8, span: 8}} >
                                <Typography.Paragraph>You must be signed in to redeem a code.</Typography.Paragraph>
                                <SignUpButton />
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </div>
    );
}
