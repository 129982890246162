import { Col, Divider, Row, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { WorkflowExecutionResult } from './WorkflowExecutionResult';
import { WorkflowFormSubmit } from './WorkflowFormSubmit';
import { WorkflowLink } from './WorkflowLink';

import { SignUpButton } from './SignUpButton';

import ReactGA from "react-ga4";
import { Workflow as WorkflowType } from '../types/types';

// import {Prompt as PromptType} from '../types/types'

import { useAppSelector, useIsSmallScreen } from '../app/hooks';
const { Option, OptGroup } = Select;


interface ConfiguredWorkflowRunnerParams {
    workflow: WorkflowType | undefined
    includeHead?: boolean
    includeOwnershipLink?: boolean
    onComplete: () => void
    allowAsDryRun?: boolean
}


export function ConfiguredWorkflowRunner({
    workflow,
    includeHead=true,
    includeOwnershipLink=true,
    onComplete,
    allowAsDryRun=false,
}: ConfiguredWorkflowRunnerParams) {
    const account = useAppSelector(selectAccount);
    const [workflowNotFound, setWorkflowNotFound] = useState(false)
    const [triggeredWorkflowExecutionId, setTriggeredWorkflowExecutionId] = useState<string | null>(null)

    const isSmallScreen = useIsSmallScreen()

    useEffect(() => {
        setTriggeredWorkflowExecutionId(null)
    }, [workflow?.name])

    const onSubmit = (workflowExecutionId?: string) => {
        if (workflowExecutionId) {
            if (workflow) {
                ReactGA.event("workflow_trigger", {workflow_name: workflow.name})
            }
            setTriggeredWorkflowExecutionId(workflowExecutionId)
        }
        onComplete()
    }

    if (workflow === undefined) {
        return <>Not Found</>

    }

    const triggers = workflow?.config.triggers
    let trigger = null
    if (triggers && triggers.length > 0 ) {
        trigger = triggers[0] || undefined

    }
    if (trigger === null) {
        return <>Not Configured</>

    }

    const formSubmitComponent = (trigger.trigger_type === 'timed') ? (
        <WorkflowFormSubmit includeHead={includeHead} workflow_id={workflow.id} credit_price={workflow.credit_price} enabled={workflow.enabled === 1} onSubmit={onSubmit} allowAsDryRun={allowAsDryRun} workflow_form={{
            form_title: "Timed Run",
            form_description: "Manually trigger the timed run for testing purposes",
            submission_params: (trigger.args.submission_params || []).map((sp) => ({
                field: sp.field,
                default_value: sp.value,
                field_type: 'input',
            }))
        }} />
    ) : (
        <WorkflowFormSubmit includeHead={includeHead} workflow_id={workflow.id} credit_price={workflow.credit_price} enabled={workflow.enabled === 1} onSubmit={onSubmit} allowAsDryRun={allowAsDryRun} workflow_form={trigger.args} />
    )
    return (
        <>
            {workflowNotFound && (
                <>
                    <Typography.Title level={3}>Not found</Typography.Title>
                    <Typography.Paragraph>Workflow not found, sorry!</Typography.Paragraph>
                </>
            )}
            {workflow && (
                <div>
                    {formSubmitComponent}
                    {triggeredWorkflowExecutionId && (
                        <>
                            {/* <Typography.Paragraph>Triggered {triggeredWorkflowExecutionId}</Typography.Paragraph> */}
                            <WorkflowExecutionResult workflow_execution_id={triggeredWorkflowExecutionId} />
                            {!account && (

                                <>
                                    <Typography.Title level={4} style={{textAlign: 'center'}}>Sign Up for PyroPrompts</Typography.Title>
                                    <Typography.Paragraph style={{textAlign: 'center'}}>Save this response and automate your own AI</Typography.Paragraph>
                                    <Row>
                                        <Col xs={{offset: 0, span: 24}} sm={{offset: 0, span: 24}} md={{offset: 0, span: 24}} lg={{offset: 2, span: 20}} xl={{offset: 4, span: 16}} xxl={{offset: 5, span: 14}}>
                                            <SignUpButton text={"Create a FREE PyroPrompts Account"} style={{width: '100%', marginTop: '4px', height: '48px'}} />
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {/* <WorkflowExecutionsList workflow_id={workflow.workflow_id} workflow_execution_id={triggeredWorkflowExecutionId} /> */}
                        </>
                    )}
                    {account && workflow.account_id === account.id && includeOwnershipLink && (
                        <>
                            <Divider />
                            <Typography.Paragraph><Typography.Text strong italic>Note: </Typography.Text>You own this workflow. <WorkflowLink workflow={workflow}>See configuration</WorkflowLink>.</Typography.Paragraph>

                        </>
                    )}
                </div>
            )}
        </>
    )
}
