import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined, TagOutlined, UploadOutlined } from '@ant-design/icons';
import { Tooltip, Typography, Row, Col, Space, Empty, Avatar, Card } from 'antd';
import { useEffect, useState } from "react";
import { PageHeader } from '../../components/PageHeader'
import { useParams } from 'react-router-dom';

import { PromptStream } from '../../components/PromptStream'
import { Loading } from '../../components/Loading'
import { CopyToClipboard } from '../../components/CopyToClipboard'
import { IconText } from '../../components/IconText'

import { Tag as TagType } from '../../types/types'
import { getTags } from '../../services/signalApi';

export function Tag() {

  const params = useParams()
  const tag_category = params.tag_category;
  const tag_name = (params.tag_name || '').split('+').join(' ');
  const [loadedTag, setLoadedTag] = useState<TagType | null>(null);
  const [attemptedLoad, setAttemptedLoad] = useState<boolean>(false);

  useEffect(() => {document.title = 'PyroPrompts | Tag'}, [JSON.stringify({loadedTag})])

  useEffect(() => {
    if (loadedTag === null || loadedTag === undefined) {
      document.title = 'PyroPrompts | Tag'
      document.getElementsByName("twitter:title")[0].setAttribute('content', `PyroPrompts Tag ${tag_category} / ${tag_name}`);
      document.getElementsByName("twitter:description")[0].setAttribute('content', `PyroPrompts Tag ${tag_category} / ${tag_name}`);
    } else {
      document.title = `PyroPrompts | Tag: ${loadedTag.name}`
      document.getElementsByName("twitter:title")[0].setAttribute('content', `PyroPrompts Tag: ${loadedTag.category} / ${loadedTag.name}`);
      document.getElementsByName("twitter:description")[0].setAttribute('content', loadedTag && loadedTag.description ? loadedTag.description : `PyroPrompts Tag: ${loadedTag.category} / ${loadedTag.name}`);
    }
  }, [JSON.stringify({loadedTag})])


  useEffect(() => {
    setLoadedTag(null)
    setAttemptedLoad(false)
    getTags({
      // category__iexact: encodeURIComponent(tag_category || ''),
      category__iexact: tag_category,
      // name__iexact: encodeURIComponent(tag_name),
      name__iexact: tag_name,
    }).then((res) => {
      setAttemptedLoad(true)
          console.log("prompts", {res})
          const response_data = res.data.results
          if ((response_data || []).length > 0){
            setLoadedTag((response_data || [])[0])
          } else {
            getTags({
              // category__iexact: encodeURIComponent(tag_category || ''),
              category__iexact: tag_category,
              name__iexact: encodeURIComponent(tag_name),
              // name__iexact: tag_name,
            }).then((res) => {
              console.log("prompts", {res})
              const response_data = res.data.results
              if ((response_data || []).length > 0){
                setLoadedTag((response_data || [])[0])
              }
            }).catch(() => {
              setAttemptedLoad(true)
              setLoadedTag(null)
            }).finally(() => {
            })
          }
      }
      ).catch(() => {
        setAttemptedLoad(true)
        setLoadedTag(null)
      }).finally(() => {
      })
  }, [tag_category, tag_name])

  return (
    <div className="App">
      <div className="App-content">
        <div className="App-page-content">
        <Row gutter={24} style={{marginTop: '24px'}}>
            <Col xs={24} md={8}>
              <Card
                  title={<Space size={'large'}><Avatar icon={<TagOutlined />} /><span>Tag</span></Space>}
                  style={{marginBottom: '24px'}}
              >
                {loadedTag && (
                  <Space direction='vertical' style={{width: '100%'}}>
                      <Typography.Title level={3} style={{marginTop: 0}}>{loadedTag.name}</Typography.Title>
                    {loadedTag && loadedTag.description && (
                      <Typography.Paragraph>{loadedTag.description}</Typography.Paragraph>
                    )}
                    <Row>
                    <Col span={12}>
                      {loadedTag.share_url && (
                        <CopyToClipboard text={loadedTag.share_url} tooltip='Copy Share URL' content={<IconText icon={UploadOutlined} text="Copy Share URL" />} key="list-vertical-share-o"/>
                      )}
                    </Col>
                    <Col span={12}>
                    </Col>
                  </Row>
                  </Space>

                )}

                {!loadedTag && attemptedLoad && (
                  <Empty description={"No Tag Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}

                {!loadedTag && !attemptedLoad && (
                  <Loading message='Tag' description='Loading Tag' />
                )}
                
              </Card>
            </Col>
            <Col xs={24} md={16}>
              {loadedTag && (
                <PromptStream tag_filter_list={[loadedTag]}/>
              )}
            </Col>

          </Row>

        </div>
      </div>
    </div>
  );
}
