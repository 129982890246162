import { SignalList } from '../../components/SignalList';

export function Forecast() {

  return (
    <div className="App">
      <div className="App-content">
        <SignalList/>
      </div>
    </div>
  );
}
