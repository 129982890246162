import { Button } from 'antd';
import React, { useState } from 'react';

import { Form, Modal, Checkbox, Input, Typography, Descriptions, Table } from 'antd';
import { createApiIntegration } from '../services/signalApi'
import { selectAccount } from '../features/login/loginSlice'
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Signal } from '../types/types'
import { SymbolLink } from './SymbolLink'
import { SignalHistoryChart } from './SignalHistoryChart'
import { Link } from "react-router-dom";


interface SignalDetailsModalParams {
    signal: Signal | null
    onCancel: () => void
}


export function SignalDetailsModal({
    signal,
    onCancel,
}: SignalDetailsModalParams) {

    const handleCancel = () => {
        onCancel()
    }

    const contextTable = () => {
        const dataSource = Object.keys(signal?.context || {}).map((context_key) => {
            return {key: context_key, name: context_key, value: signal?.context[context_key] || ''}
        })
        console.log("dataSource", {dataSource})
        const columns = [
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              ellipsis: true
            },
            {
              title: 'Value',
              dataIndex: 'value',
              key: 'value',
              ellipsis: true
            },
          ];
          return <Table dataSource={dataSource} columns={columns} size='small' pagination={false} />;
    }
    
    return (
            <Modal title="Signal" open={signal !== null} footer={null} onCancel={handleCancel} width={800}>
                {signal !== null && (
                    <>
                        <Descriptions>
                            <Descriptions.Item label="Symbol"><SymbolLink symbol={signal.symbol} /></Descriptions.Item>
                            <Descriptions.Item label="Value">{signal.value}</Descriptions.Item>
                            <Descriptions.Item label="TTL Minutes">{signal.ttl_minutes}</Descriptions.Item>
                            <Descriptions.Item label="Data Set"><Link to={`/data_sets/${signal.data_set_id}/`}>{signal.data_set.name}</Link></Descriptions.Item>
                            <Descriptions.Item label="Data Provider">{signal.data_set.data_provider.name}</Descriptions.Item>
                        </Descriptions>
                        {signal.context && Object.keys(signal.context).length !== 0 && (
                            <>
                                <Typography.Title level={3}>Context:</Typography.Title>
                                {contextTable()}
                                
                                {/* <pre style={{border: '1px solid #555', padding: '10px'}}>{JSON.stringify(signal.context, null, 2)}</pre> */}
            
                            </>

                        )}
                        <SignalHistoryChart key={`${signal.symbol}${signal.data_set_id}`} symbol={signal.symbol} data_set_id={signal.data_set_id} />
                    </>
                    
                )}
                
            </Modal>
    )

    
}
