import type { SelectProps } from 'antd';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography, message } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons'
import { useState } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { createPrompt } from '../services/signalApi';
import { CopyToClipboard } from './CopyToClipboard';
import { DefinedWorkflowRunner } from './DefinedWorkflowRunner';
import { Prompt as PromptType } from '../types/types'
import { Link } from "react-router-dom";

// import {Prompt as PromptType} from '../types/types'

import { useAppSelector, useIsSmallScreen } from '../app/hooks';
const { Option, OptGroup } = Select;


interface DefinedWorkflowModalParams {
    workflowName: string
    isOpen: boolean
    includeCreditsTextIfNoCredits?: boolean
    onCancel: () => void
    onComplete: () => void
}


export function DefinedWorkflowModal({
    workflowName,
    isOpen,
    includeCreditsTextIfNoCredits=true,
    onCancel,
    onComplete,
}: DefinedWorkflowModalParams) {
    const isSmallScreen = useIsSmallScreen()

    const handleCancel = () => {
        onCancel()
    }


    return (
        <Modal open={isOpen} footer={null} onCancel={handleCancel} width={isSmallScreen ? '100%': '48%'}>
            <DefinedWorkflowRunner includeCreditsTextIfNoCredits={includeCreditsTextIfNoCredits} workflowName={workflowName} onComplete={onComplete} includePageLink={true} />
        </Modal>
    )

    
}
