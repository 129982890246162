import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginReducer from '../features/login/loginSlice';
import dashboardReducer from '../features/dashboardList/dashboardSlice';
import datasetReducer from '../features/dataSetList/dataSetSlice';
import apiIntegrationReducer from '../features/apiIntegrationsList/apiIntegrationsSlice';
import dataProviderReducer from '../features/dataProviderList/dataProviderListSlice';
import lightDarkSwitchReducer from '../features/lightDarkSwitch/lightDarkSwitchSlice';
import cartReducer from '../features/cart/cartSlice';
import aiChatReducer from '../features/aiChat/aiChatSlice';
import projectsReducer from '../features/projects/projectsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    login: loginReducer,
    dashboard: dashboardReducer,
    datasets: datasetReducer,
    api_integration: apiIntegrationReducer,
    data_provider: dataProviderReducer,
    theme_select: lightDarkSwitchReducer,
    cart: cartReducer,
    ai_chat: aiChatReducer,
    projects: projectsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
