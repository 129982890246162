
import { ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popover, Space, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { Link } from "react-router-dom";
import { TradingViewMiniChart } from './TradingViewComponents';


interface SymbolLinkParams {
    symbol: string
    symbol_type?: string
}

function StockSymbolLink({symbol}: SymbolLinkParams){
    const menu: MenuProps['items'] = [
        {
            label: <Link to={`/symbols/${symbol.replace('/', '-')}/`}>See {symbol} on PyroPrompts</Link>,
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: <a href={`https://www.tradingview.com/chart/?symbol=${symbol}`} target='blank'>See {symbol} on Trading View <ArrowRightOutlined /></a>,
            key: '1',
        },
        {
            label: <a href={`https://finviz.com/quote.ashx?t=${symbol}&a=514316409`} target='blank'>See {symbol} on Finviz <ArrowRightOutlined /></a>,
            key: '2',
        },
        {
            label: <a href={`https://finance.yahoo.com/quote/${symbol}`} target='blank'>See {symbol} on Yahoo <ArrowRightOutlined /></a>,
            key: '3',
        },
        {
            label: <a href={`https://www.reuters.com/companies/${symbol}.N`} target='blank'>See {symbol} on Reuters  <ArrowRightOutlined /></a>,
            key: '4',
        },
        {
            label: <a href={`https://www.marketwatch.com/investing/stock/${symbol}`} target='blank'>See {symbol} on Marketwatch <ArrowRightOutlined /></a>,
            key: '5',
        },
        {
            label: <a href={`https://www.google.com/finance/quote/${symbol}:NYSE`} target='blank'>See {symbol} on Google <ArrowRightOutlined /></a>,
            key: '6',
        },
    ]

    
    const popoverContent = (
        <div style={{width: "500px"}}>
            {/* <TradingViewChartWidget symbol={symbol} /> */}

            <TradingViewMiniChart symbol={symbol} />
        </div>
    );

    return (
        <Space>
            <Popover content={popoverContent} mouseEnterDelay={0.8} placement='right'>
                <Link to={`/symbols/${symbol.replace('/', '-')}/`}>{symbol}</Link>
            </Popover>
            <Dropdown menu={{items: menu}} trigger={['click']}>
                <a onClick={e => e.preventDefault()}>
                    <Space>
                        <Tooltip placement="top" title="Symbol Options">
                            <DownOutlined />

                        </Tooltip>
                    </Space>
                </a>
            </Dropdown>
        </Space>
    )
}

export function SymbolLink({
    symbol,
    symbol_type = 'stock',
}: SymbolLinkParams) {
    if (symbol_type === "stock"){
        return <StockSymbolLink symbol={symbol} />
    } else {
        return (
            <Space>
                <Link to={`/symbols/${symbol.replace('/', '-')}/`}>{symbol}</Link>
            </Space>
        )
    }
    
}
