import { SVGAttributes, ReactElement } from 'react'
// https://svg2jsx.com/

type Props = SVGAttributes<SVGElement>

function BullIcon(props: Props): ReactElement {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      version="1.1"
      viewBox="0 0 40 40"
      {...props}
    >
      <image
        width="40"
        height="40"
        x="0"
        y="0"
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAABlElEQVRIx92VO0sDQRDHf7NJTogW PkCRYKFYiAQEsfATWCgoNpZWlkIKbcTGjyCICIKV4CewCZYWPlAsBF8IPjBa2Nn4iJex8JXonZrL VO5Ut7P325n5z+7CPxySScTCva5sXpquvG8XXoxj6bfMd5acXe3a2UaZsIG1yToFlA3kczaeLP76 O6yTLQooyiml+i6UiUp6DJFF3+zceSXuMbbLiauGKa4+YEruCy7No/R+/y/uUnQWWkDaFZC8Xsi9 W/P7dI76kpVH0sDNx3YpXWdHN4NC8YuieLfngDnlUnoAaCaLz7NrCs5Ny7A7GeVVbZ/BsGJpBPMZ Dq9+FNyA5eUAt1UHP7mjpLwndbZAZTdRawtUybqEKRCVZWMgKtPGQFRGjIE80W0LVK5diy1Q2aiu sgUqi0X3KGrz6FRylr+PGcuUH2S8MlHmXQpAHDE8PC9RSdv4MvN7MX8S5UQy2koHjXTImR6zwv7v BQ2P515aoygUfk4no0keBjysjpsCpS9qUwYDV6N3eRAuH0ubAmUpOu4FbLmAvlPfh3cAAAAASUVO RK5CYII="
      ></image>
      <path
        fill="#000"
        d="M0 26V12h5.533c5.85 0 13.62-3.116 18.353-7.36L26.27 2.5 25.007 5c-.695 1.375-1.943 3.28-2.774 4.231-1.212 1.391-1.266 2.025-.27 3.224 1.093 1.318 1.572 1.32 4.088.019 1.567-.81 3.308-2.261 3.869-3.224.56-.962 1.033-1.222 1.05-.577.016.646-1.142 2.567-2.575 4.27l-2.605 3.095 4.705 5.508c3.872 4.532 4.489 5.769 3.483 6.98-.673.811-2.765 1.474-4.65 1.474-1.885 0-4.763.69-6.396 1.535-1.632.844-4.184 1.407-5.67 1.25-2.225-.235-3.063.378-4.733 3.462-1.947 3.596-2.242 3.748-7.279 3.75L0 40z"
      ></path>
    </svg>
  )
}

export default BullIcon
