import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
export function Billing() {

    const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
    const waiting = <Tooltip title="Awaiting Data"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
    const blocked = <Tooltip title="Awaiting Data"  placement="left"><PlayCircleOutlined  style={{ color: '#d32029' }} /></Tooltip>
    const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />

  return (
    <div className="App">
      <div className="App-content">
        <Typography.Title level={1}>Billing</Typography.Title>
        <div className="App-page-content">
            <Typography.Paragraph>
            Billing
            </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
}
