import { Tag } from 'antd';
import { Tag as TagType } from '../types/types';
import { Link } from "react-router-dom";




interface PromptTagParams {
    tag: TagType
}

export function PromptTag({
  tag,
}: PromptTagParams){
    const safe_name = encodeURI(tag.name.toLowerCase().split(' ').join('+'))
    const tag_path = tag.category ? `/tags/${encodeURI((tag.category).toLowerCase())}/${safe_name}` : `/tags/${safe_name}`

    return (
      <Link to={tag_path}><Tag color={tag.color ? tag.color : 'gray'} key={tag.id}>{tag.category ? `${tag.category}: ` : ''}{tag.name}</Tag></Link>
    )

}
