import { ConfigProvider, Empty, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
// import TableProps from 'antd/lib/table/Table';
import { useEffect, useState } from 'react';


interface TableParams<RowType extends object> {
    pagination?: TablePaginationConfig;
    sorter?: SorterResult<RowType> | SorterResult<RowType>[];
    filters?: Record<string, FilterValue | null>;
}

interface DataResponse<RowType extends object> {
    results: RowType[]
    count: number
}

type GetRowKey<RowType extends object> = (row: RowType) => string
type FetchData<RowType extends object> = (params: TableParams<RowType>) => Promise<DataResponse<RowType>>
type UpdateColumnsFromData<RowType extends object> = (columns: ColumnsType<RowType>, data: RowType[]) => ColumnsType<RowType>

export interface FilterValues {
    [key: string]: string | number | undefined
}


interface PaginatedTableParams<RowType extends object> extends TableProps<RowType> {
    columns: ColumnsType<RowType>
    rowKey: string | GetRowKey<RowType>
    fetchData: FetchData<RowType>
    filterValues?: FilterValues
    updateColumnsFromData?: UpdateColumnsFromData<RowType>
    size?: 'small' | 'middle' | 'large'
    emptyDescription?: string
    includePagination?: boolean
    pageSize?: number
}

export function PaginatedTable<RowType extends object>({
    columns,
    rowKey,
    fetchData,
    filterValues,
    size='middle',
    updateColumnsFromData,
    emptyDescription="No Data",
    includePagination=true,
    pageSize=10
}: PaginatedTableParams<RowType>) {
    const [data, setData] = useState<RowType[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams<RowType>>({
        pagination: {
            current: 1,
            pageSize: pageSize,
            hideOnSinglePage: true,
        },
    });

    console.log("PaginatedTable", {})

    const fetchDataInternal = () => {
        console.log("PaginatedTable.fetchDataInternal", {})
        setLoading(true);
        fetchData(tableParams).then(res => {
            const { results, count } = res;
            setData(results);
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: count,
                }
            })
        })
      };
    
    useEffect(() => {
      // if (data === null && !loading) {
      //   fetchDataInternal();
      // }
      console.log("PaginatedTable.running...", {tableParams, filterValues})
      fetchDataInternal();
    }, [JSON.stringify({current: tableParams.pagination?.current, pageSize: tableParams.pagination?.pageSize}), JSON.stringify(filterValues), JSON.stringify(tableParams.sorter)]);
    // }, [JSON.stringify({...{}, ...filterValues})]);
    

    const rows = data === null ? [] : data;

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<RowType> | SorterResult<RowType>[],
      ) => {
        setTableParams({
          pagination,
          filters,
          sorter,
          // ...sorter,
        });
      };

      const columnsUsed = updateColumnsFromData && data ? updateColumnsFromData(columns, data) : columns;

      const customizeRenderEmpty = () => (
        <Empty description={emptyDescription} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )

      return (
        <ConfigProvider
          renderEmpty={emptyDescription ? customizeRenderEmpty : undefined}
          theme={{
            token: {
              "borderRadius": 0
            }
          }}
        >
          <Table
            columns={columnsUsed}
            rowKey={rowKey}
            dataSource={rows}
            pagination={includePagination ? tableParams.pagination : false}
            loading={loading}
            onChange={handleTableChange}
            size={size}
            scroll={{x: true}}
            // bordered={true}

          //   dataSource={keyedSignals} rowKey='id' columns={columns} loading={inFlight} pagination={{ hideOnSinglePage: true }} size="small" scroll={{x: true}}
          />
        </ConfigProvider>
        
      );
}
