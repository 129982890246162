import { Button, ConfigProvider, Empty, Typography, List, Space, Tooltip, Form, Input, message, Alert, Popconfirm } from 'antd';
import { useState, useEffect } from 'react';
import { ReloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { Link } from "react-router-dom";

// Assuming you have these imports, you can modify based on your actual use case.
import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getProjectSnippets, createProjectSnippet, editProjectSnippet, deleteProjectSnippet } from '../services/signalApi';
import { toTitleCase } from '../utils';
import { CopyToClipboard } from '../components/CopyToClipboard';
import { AudioFileUpload } from '../components/AudioFileUpload';
import { ProjectSnippet as ProjectSnippetType } from '../types/types';


export function NewSnippetForm({
    project_id,
    onCancel,
    onCreate,
}: {
    project_id: string
    onCancel: () => void
    onCreate: () => void
}) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isCreating, setIsCreating] = useState(false);
    const [newProjectSnippet, setNewProjectSnippet] = useState<ProjectSnippetType | null>(null);

    const handleCancel = () => {
        form.resetFields();
        setNewProjectSnippet(null);
        setIsCreating(false);
        onCancel();
    }

    const onCreateProjectSnippetFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateProjectSnippetSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateProjectSnippet(values);
    };

    const onCreateProjectSnippet = (values: any) => {
        if (account) {
            setIsCreating(true);
            createProjectSnippet(
                project_id,
                values.name,
                values.content
            ).then((res) => {
                if (res.status === 201) {
                    const response_data = res.data;
                    setNewProjectSnippet(response_data);
                    setIsCreating(false);
                    form.resetFields()
                } else {
                    message.error(res.data);
                    setIsCreating(false);
                }  
            }).catch((err) => {
                console.log("err", {err});
            }).finally(() => {
                setIsCreating(false);
                onCreate();
            });
        }
    }

    return <>
        {(newProjectSnippet && (
            <Alert message={`Snippet Created: ${newProjectSnippet.name}`} type="success" closable onClose={handleCancel} style={{marginBottom: '6px'}}/>
        ))}

        <Form
            name="snippet"
            form={form}
            layout={'vertical'}
            initialValues={{}}
            onFinish={onCreateProjectSnippetSubmit}
            onFinishFailed={onCreateProjectSnippetFailed}
            autoComplete="off"
            requiredMark={"optional"}
        >
            <>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Name your Snippet' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Content"
                    name="content"
                >
                    <Input.TextArea showCount style={{height: 120}} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>

                    <Space style={{float: 'right', width: 'unset'}} wrap>
                        {account && account.name === "ferrants" && (
                            <AudioFileUpload transcribe onComplete={(uploadInfo) => form.setFieldsValue({ content: uploadInfo.transcription })} />
                        )}
                        <Button type="primary" htmlType="submit" disabled={
                            isCreating
                        }>
                            Create Snippet
                        </Button>
                        <Button type="default" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            </>


        </Form>
    
    </>
}
