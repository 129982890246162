import type { InputRef } from 'antd';
import { Button, Empty, Form, Input, Modal, Space, Typography } from 'antd';
import { Link } from "react-router-dom";
import ReactGA from "react-ga4"

import { SearchOutlined } from '@ant-design/icons';
import { getDataSets, getPrompts, getPromptsLoggedOut } from '../services/signalApi';
import { DataSet as DataSetType, Prompt as PromptType } from '../types/types';
import { Loading } from './Loading';
import { PromptList } from './PromptStream';
import { useAppSelector, useIsSmallScreen } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';


import { useRef, useState } from 'react';
const { Search } = Input;
interface GlobalSearchParams {
    includeTitle? : boolean
    onSelect? : () => void
}

export function GlobalSearch({
    includeTitle=false,
    onSelect
}: GlobalSearchParams) {

    // const [apiStatus, setApiStatus] = useState<ApiStatus | null>(null);
    const inputRef = useRef<InputRef>(null);
    const account = useAppSelector(selectAccount)
    const isSmallScreen = useIsSmallScreen()

    // const [form] = Form.useForm();
    const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // const [dataSetResults, setDataSetResults] = useState<DataSetType[] | null>(null);
    const [promptResults, setPromptResults] = useState<PromptType[] | null>(null);

    const showSearch = () => {
        setIsSearchModalOpen(true)
        setTimeout(() => {
            inputRef.current!.focus({
                cursor: 'all',
              });
        }, 200)

    }

    const closeSearch = () => {
        setIsSearchModalOpen(false)
    }

    const onSelection = () => {
        setIsSearchModalOpen(false)
        if (onSelect) {
            onSelect()
        }
    }

    const onSearchSubmitFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onSearchSubmit = (searchValue: string) => {
        ReactGA.event('search', {search_term: searchValue });

        console.log('Success:', {searchValue});
        if (searchValue === "") {
            console.log("clearing results")
            // setDataSetResults(null)
            setPromptResults(null)
        } else {
            console.log("searching results...")
            setLoading(true)
            const getPromptsFn = account ? getPrompts : getPromptsLoggedOut;

            getPromptsFn({content__icontains: searchValue, include_public: true}).then((response) => {
                // handle success
                if (response.status === 200) {
                    const response_data = response.data
                    if (response_data.results) {
                        setPromptResults(response_data.results)
                    }

                }
    
              }).catch(function (error) {
                // handle error
                console.log(error);
                setPromptResults([])
              })
              .then(function () {
                // resolve({ data: {results: []} })
                setLoading(false)
              });
        }
        // onCreateDashboard(values)
    };

    return <>
        <div>
        {/* <Button type="text" style={{color: 'rgba(255, 255, 255, 0.85)'}} icon={<SearchOutlined />} onClick={showSearch}> */}
        <Button type="text" icon={<SearchOutlined />} onClick={showSearch}>
            {includeTitle ? 'Search' : ''}
        </Button>
        </div>
        <Modal title={ "Search" } open={isSearchModalOpen} footer={null} onCancel={closeSearch} width={isSmallScreen ? '100%': '700px'}>
            <Space direction='vertical' style={{width: '100%'}}>
                <Search
                    placeholder="Prompt Content..."
                    allowClear
                    enterButton
                    size="large"
                    onSearch={onSearchSubmit}
                    ref={inputRef}
                />
                {loading && (
                    <Loading message='Searching...' />

                )}
                {(promptResults && promptResults.length === 0) && (
                    <Empty description={"No Results Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {promptResults && promptResults.length > 0 && (
                    <div>
                        {/* <Typography.Title level={4}>Prompts</Typography.Title> */}
                        <PromptList
                            prompts={promptResults || []}
                            limit={promptResults.length}
                            promptsCount={promptResults.length}
                            onLoadMore={() => {}}
                            isLoadingMore={false}
                            includeActions={false}
                        />
                        {/* <ul>
                            {promptResults.map((prompt) => {
                                return <li><Link to={`/prompt/${prompt.id}/`}>{prompt.content}</Link></li>
                            })}
                        </ul> */}
                    </div>
                )}

            </Space>
            
            
        </Modal>
    </>

}
