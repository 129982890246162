import { Space, Typography } from 'antd';
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { NamedWorkflowList } from '../../components/NamedWorkflowList';

import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';

export function Workflows() {
    useEffect(() => {document.title = 'PyroPrompts | Workflow'}, [])

    const account = useAppSelector(selectAccount);
    console.log("account", {account})

    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Workflows&nbsp;
                    {account && (
                        <Typography.Text style={{fontSize: "0.7rem"}}><Link to={`/my_workflows`}>
                                see your workflows&nbsp;&#8594;
                        </Link>
                        </Typography.Text>
                    )
                    }</Typography.Title>
                <div className="App-page-content">

                    <Space direction="vertical">
                        <Typography.Paragraph>
                            Run AI tasks easily, without copying prompts, through our expert-crafted Workflows.
                        </Typography.Paragraph>
                        <NamedWorkflowList />
                    </Space>
                </div>
            </div>
        </div>
    );
}
