import { Button, Divider, Radio, Carousel, Select, Typography } from 'antd';
import { useState, useEffect } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ArrowsAltOutlined, ReloadOutlined, UpCircleOutlined, DownCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';

import { DateTime } from 'luxon';


import { Signal, SignalHistory } from '../types/types';
// import styles from './SignalList.module.css';
import { Loading } from './Loading';

import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getSignalHistory, getSignalHistoryLoggedOut } from '../services/signalApi';
import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, TimeScale
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
  );

type DateFilter = '30_days' | '7_days' | 'today'

interface SignalHistoryChartParams {
    data_set_id?: string
    symbol?: string
    date_filter?: DateFilter
}


const getDateFilterValue = (dateFilter: DateFilter) => {
    const dateFilterValueMapping = {
        '30_days': 30,
        '7_days': 7,
        'today': 1,
    }
    const days = dateFilterValueMapping[dateFilter] || 30
    const dt = DateTime.now();
    return dt.minus({days}).toISODate()
}


export function SignalHistoryChart({
    data_set_id,
    symbol,
    date_filter,
}: SignalHistoryChartParams) {
    console.log("SignalHistoryChart", {data_set_id, symbol, date_filter})
    const account = useAppSelector(selectAccount)
    const [selectedSignalKey, setSelectedSignalKey] = useState<string>('value');
    // const [reloadedLast, setReloadedLast] = useState('never');
    // const [dateFilter, setDateFilter] = useState<DateFilter>('30_days');
    // const [detailSignal, setDetailSignal] = useState<Signal | null>(null);

    const [inFlight, setInFlight] = useState(false);
    const [signals, setSignals] = useState<SignalHistory[] | null>(null);

    useEffect(() => {
        loadSignals()
    }, [account])

    const loadSignals = () => {
        // if (!inFlight) {
            setInFlight(true)
            const getSignalsFn = account ? getSignalHistory : getSignalHistoryLoggedOut;
            getSignalsFn({
                ...(data_set_id ? {data_set_id: data_set_id} : {}),
                ...(symbol ? {symbol: symbol} : {}),
                ...(date_filter ? {created_date__gt: getDateFilterValue(date_filter)} : {}),
                limit: 1000,
            }).then((res) => {
                console.log("signal history", {res})
                const response_data = res.data.results
                setInFlight(false)
                setSignals(response_data || [])
            }
            ).catch(() => {
                setSignals([])
            }).finally(() => {
                setInFlight(false)
            })
        // }
        
    }

    // if (signals === null && !inFlight) {
    //     loadSignals()
    // }

    if (inFlight) {
        return (
            <Loading />
        )
    }

    if (signals === null || signals.length === 0) {
        return (
            <>
            No Historical Data
            </>
          
        )
    }

    const selectOptions = [
        {
            value: 'value',
            label: 'Signal Value',
        },
    ]
    const availableContextKeys: string[] = []
    signals.forEach((signal) => {
        Object.keys(signal.c).forEach((signalContextKey) => {
            if (availableContextKeys.indexOf(signalContextKey) === -1) {
                availableContextKeys.push(signalContextKey)
                selectOptions.push({
                    value: signalContextKey,
                    label: `Context: ${signalContextKey}`
                })
            }
        })
    })
    console.log("availableContextKeys", {availableContextKeys})


    // availableContextKeys.forEach(key)

    const handleSignalFieldChange = (value: string) => {
        setSelectedSignalKey(value)
    }


    const optionsLine = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `${symbol} Signal History`,
            },
        },
        // scales: {
        //     x: {
        //         type: 'time',
        //         // time: { parser: "MM/DD/YYYY" },
        //     }]
        // }
        scales: {
            y: {
                grid: {
                    color: 'rgba(200, 200, 200, 0.1)',
                },
            },
            x: {
                type: 'time' as const,
                time: { 
                    unit: 'day' as const,
                    // displayFormats: {
                    //     hour: 'MMM D'
                    // },
                    // parser: "MM/DD/YYYY" 
                },
                // time: {
                //     unit: 'minute'
                // }
                // display: true,
                // time: {},
                // grid: {
                //     color: 'rgba(200, 200, 200, 0.1)',
                // },
            },
        },
       
    };
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const labels = signals.map((signal) => signal.t)
    // const values = signals.map((signal) => (selectedSignalKey === 'value' ? signal.value : signal.context[selectedSignalKey]))
    const datapoints = signals.map((signal) => ({
        x: signal.t,
        // x: DateTime.fromISO(signal.created_date).toLocaleString(DateTime.DATE_SHORT),
        // x: DateTime.fromISO(signal.created_date).toLocaleString(DateTime.DATETIME_SHORT),
        y: selectedSignalKey === 'value' ? signal.v : signal.c[selectedSignalKey]
    }))
    console.log("datapoints", {datapoints})
    const data = {
        labels,
        datasets: [
          {
            label: 'Value',
            data: datapoints,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 2,
            pointRadius: 1,
          },
        //   {
        //     label: 'Dataset 2',
        //     data: labels.map((l, i) => 200 - 15 * i),
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //     // color: 'rgba(53, 162, 235, 0.5)',
        //     borderColor: 'rgba(53, 162, 235, 0.5)',
        //     borderWidth: 2,
        //     pointRadius: 1,
        //   },
        ],
      };
    // https://www.chartjs.org/docs/latest/axes/
    // https://github.com/reactchartjs/react-chartjs-2/issues/1032



    return (
    <>
        <Typography.Title level={3}>History</Typography.Title>
        <Select
        defaultValue="value"
        style={{ width: '100%' }}
        onChange={handleSignalFieldChange}
        options={selectOptions}
        />
        <Line key={`${symbol}${data_set_id}`}options={optionsLine} data={data} />
        {/* <ul>
            {signals.map((signal, i) => {
                return (
                    <li key={i}>{signal.value} @{signal.created_date}</li>
                )
            })}
        </ul> */}
      
    </>
    )
  }
