import { useEffect } from "react";
import { Tooltip, Typography, Row,  Col, Card, Space, Collapse } from 'antd';
import { Link } from "react-router-dom";

import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'

export function NotFoundPage() {
    useEffect(() => {document.title = 'PyroPrompts | Not Found'}, [])


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Page not found</Typography.Title>
                <div className="App-page-content">
                    <Typography.Paragraph>We couldn't find the page you're looking for. Apologies.</Typography.Paragraph>
                    <Typography.Paragraph><Link to='/'>Return to the Home Page</Link></Typography.Paragraph>
                </div>
            </div>
        </div>
    );
}
