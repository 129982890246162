import type { SelectProps } from 'antd';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space, Typography, message } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons'
import { useState, useEffect } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { getNamedWorkflows, getNamedWorkflowsLoggedOut } from '../services/signalApi';
import { CopyToClipboard } from './CopyToClipboard';
import { WorkflowFormSubmit } from './WorkflowFormSubmit';
import { WorkflowExecutionResult } from './WorkflowExecutionResult';
import { NewsLetterRegister } from './NewsLetterRegister';
import { SignUpButton } from './SignUpButton';


import ReactGA from "react-ga4";
import { Loading } from './Loading';
import { Prompt as PromptType, NamedWorkflow as NamedWorkflowType } from '../types/types'
import { Link, useSearchParams } from "react-router-dom";

// import {Prompt as PromptType} from '../types/types'

import { useAppSelector, useIsSmallScreen } from '../app/hooks';
const { Option, OptGroup } = Select;


interface NamedWorkflowRunnerParams {
    namedWorkflow: NamedWorkflowType | undefined
    includePageLink?: boolean
    onComplete: () => void
}


export function NamedWorkflowRunner({
    namedWorkflow,
    includePageLink=false,
    onComplete,
}: NamedWorkflowRunnerParams) {
    const account = useAppSelector(selectAccount);
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [workflowNotFound, setWorkflowNotFound] = useState(false)
    // const [triggeredWorkflowExecutionId, setTriggeredWorkflowExecutionId] = useState<string | null>(null)
    const triggeredWorkflowExecutionId = searchParams.get("workflow_execution_id")

    const isSmallScreen = useIsSmallScreen()

    // useEffect(() => {
    //     setTriggeredWorkflowExecutionId(null)
    // }, [namedWorkflow?.name])

    const onSubmit = (workflowExecutionId?: string) => {
        if (workflowExecutionId) {
            if (namedWorkflow) {
                ReactGA.event("named_workflow_trigger", {workflow_name: namedWorkflow.name})
            }
            searchParams.set("workflow_execution_id", workflowExecutionId)
            setSearchParams(searchParams)
            // setTriggeredWorkflowExecutionId(workflowExecutionId)
        }
        onComplete()
    }


    return (
        <>
            {isLoading && (
                <Loading message="Workflow" />
            )}
            {workflowNotFound && (
                <>
                    <Typography.Title level={3}>Not found</Typography.Title>
                    <Typography.Paragraph>Workflow not found, sorry!</Typography.Paragraph>
                </>
            )}
            {namedWorkflow && (
                <div>
                    <WorkflowFormSubmit workflow_id={namedWorkflow.workflow_id} credit_price={namedWorkflow.credit_price} workflow_form={namedWorkflow.workflow_form} enabled={true} onSubmit={onSubmit} />
                    {triggeredWorkflowExecutionId && (
                        <>
                            {/* <Typography.Paragraph>Triggered {triggeredWorkflowExecutionId}</Typography.Paragraph> */}
                            <WorkflowExecutionResult workflow_execution_id={triggeredWorkflowExecutionId} />
                            {!account && (
                                <>
                                    <Typography.Title level={4} style={{textAlign: 'center'}}>Sign Up for PyroPrompts</Typography.Title>
                                    <Typography.Paragraph style={{textAlign: 'center'}}>Save this response and automate your own AI</Typography.Paragraph>
                                    <Row>
                                        <Col xs={{offset: 0, span: 24}} sm={{offset: 0, span: 24}} md={{offset: 0, span: 24}} lg={{offset: 2, span: 20}} xl={{offset: 4, span: 16}} xxl={{offset: 5, span: 14}}>
                                            <SignUpButton text={"Create a FREE PyroPrompts Account"} style={{width: '100%', marginTop: '4px', height: '48px'}} />
                                        </Col>
                                    </Row>
                                    
                                    {/* <NewsLetterRegister sign_up_button_text='Get Access' /> */}
                                </>
                            )}
                            {/* <WorkflowExecutionsList workflow_id={namedWorkflow.workflow_id} workflow_execution_id={triggeredWorkflowExecutionId} /> */}
                        </>
                    )}
                    {namedWorkflow.is_mine && (
                        <>
                            <Divider />
                            <Typography.Paragraph><Typography.Text strong italic>Note: </Typography.Text>You own this workflow. <Link to={`/workflows/${namedWorkflow.workflow_id}`}>See configuration</Link>.{includePageLink ? <> This workflow has a <Link to={`/w/${namedWorkflow.name}`}>dedicated page</Link>.</> : <></>}</Typography.Paragraph>

                        </>
                    )}
                    {!namedWorkflow.is_mine && includePageLink && (
                        <>
                            <Divider />
                            <Typography.Paragraph>This workflow has a <Link to={`/w/${namedWorkflow.name}`}>dedicated page</Link>.</Typography.Paragraph>

                        </>
                    )}
                </div>

            )}
        </>
    )

    
}
