import { Button, Calendar, Progress, Space, Statistic, Tag, Typography, Row, Col, Card, Dropdown, message, Tooltip as AntTooltip} from 'antd';
import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip, TimeScale
} from 'chart.js';
import type { MenuProps } from 'antd';

import { Bar, Line } from 'react-chartjs-2';
import RGL, { WidthProvider, Responsive} from "react-grid-layout";
import { Dashboard as DashboardType, Widget as WidgetType } from '../types/types'
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { SignalStream } from './SignalStream';
import { DashboardWidget } from './DashboardWidget';
import { createElement, ReactElement, useEffect, useState } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { Loading } from './Loading'
import {selectLight} from '../features/lightDarkSwitch/lightDarkSwitchSlice'
import { EditOutlined, EllipsisOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { PageHeader } from './PageHeader'
import { CreateDashboardModal } from './CreateDashboardModal'

import { getDashboard, editDashboard } from '../services/signalApi';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
// import {
//     clear, loadDashboardAsync, selectDashboard,
//     selectStatus
// } from './dashboardSlice';
import { red, green, yellow } from '@ant-design/colors';
const GridLayout = WidthProvider(RGL);
const ResponsiveGridLayout = WidthProvider(Responsive);

// import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

// import styles from './Dashboard.module.css';
// TODO - this shouldn't be a feature? should just manage state locally?


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
  );

interface DashboardParams {
    id: string
    set_dashboard?: DashboardType
    can_edit?: boolean
    show_name?: boolean
}

function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


export function Dashboard({
    id,
    set_dashboard,
    can_edit = false,
    show_name = true,
}: DashboardParams) {

    const account = useAppSelector(selectAccount)
    // const [reloadedLast, setReloadedLast] = useState('never');
    const [dashboard, setDashboard] = useState<DashboardType | null>(null);
    const [originalDashboard, setOriginalDashboard] = useState<DashboardType | null>(null);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [showCopyDashboard, setShowCopyDashboard] = useState(false);
    // const [editingWidgets, setEditingWidgets] = useState<string[]>([]);
    const light = useAppSelector(selectLight)

    useEffect(() => {
        if (set_dashboard) {
            setDashboard(set_dashboard)
            setOriginalDashboard(set_dashboard)
            return
        }
        if (!id) {
            return
        }
        getDashboard(id).then((res) => {
            console.log("workflow", {res})
            if (res.status === 200) {
                const response_data = res.data
                setDashboard(response_data)
                setOriginalDashboard(response_data)
            } else {
                setDashboard(null)
                setOriginalDashboard(null)
            }
        }
        ).catch(() => {
            setOriginalDashboard(null)
        })
    }, [id, set_dashboard])

    console.log(dashboard)
    const styles = {
        widget: {
            border: '1px solid #5f5f5f',
            padding: '0 12px 6px',
            // margin: '2px',
            // 'border-radius': '4px'
            /* background: white; */
        }
        
    }

    const saveDashboard = (values: any) => {
        console.log('Received values of form:', values);
        if (dashboard === null) {
            return
        }
        editDashboard(dashboard.id, dashboard).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Saved!")
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };

    if (!dashboard){
        return <Loading message='Dashboard' description={`Loading Dashboard with ID '${id}'`} />
    }

    const right = () => {
        return <Space>
            {can_edit && (
                <Button type="primary" onClick={createBlankWidget}>Add Widget</Button>
            )}
            {account && (
                <Button type="default" onClick={() => setShowCopyDashboard(true)}>Copy Dashboard</Button>
            )}
            {!account && (
                <AntTooltip placement='left' title="Log in to copy">
                    <Button type="default" disabled={true}>Copy Dashboard</Button>
                </AntTooltip>
            )}
            {can_edit && isDirty && (
                <Button type="primary" onClick={saveDashboard}>Save</Button>
            )}
            {can_edit && isDirty && (
                <Button type="default" onClick={revertDashboard}>Cancel</Button>
            )}
            {/* <Button type="primary" onClick={clear_click}>Clear</Button> */}
        </Space>
        // return (
        //   <>
        //     {ownedDataSet && (
        //       <>
        //         <Button type="default" onClick={() => setShowEditDataSetModal(true)}>Edit Data Set</Button>
        //         <Button type="default" onClick={() => setShowCreateSignalModal(true)}>Post Signal</Button>
        //       </>
        //     )}
    
        //     {account && dataset.subscribed && (
        //       <Button key="1" type="default" onClick={(e) => showSubscribeModal(dataset)}>
        //         Unubscribe
        //       </Button>
        //     )}
    
        //     {account && !dataset.subscribed && (
        //       <Button key="1" type="primary" onClick={(e) => showSubscribeModal(dataset)}>
        //       Subscribe
        //     </Button>
        //     )}
    
        //     {!account && (
        //       <Tooltip placement='left' title="Log in to subscribe">
        //         <Button key="1" type="primary" disabled={true}>
        //           Subscribe
        //         </Button>
        //       </Tooltip>
        //     )}
        //   </>
        // )
      }

    const head = () => {
        return <PageHeader
        title={dashboard?.name || 'Dashboard'}
        right={right()}
        style={{margin: '18px 0'}}
        // sub={sub()}
      />
        // return (
        //     <Typography.Title level={1}>{dashboard.name}</Typography.Title>
        // )
    }

    const revertDashboard = () => {
        console.log("revertDashboard", {originalDashboard})
        setDashboard(JSON.parse(JSON.stringify(originalDashboard)))
        setIsDirty(false)

        // setDashboard(originalDashboard)
    }

    const getBlankWidgetTemplate = () => {
        return {
            id: makeid(10),
            "name": "",
            "layout": {
                x: 0,
                y: 500,
                w: 8,
                h: 18,
                // width: "600px"
            },
            "widget_config": {
                "widget_type": "blank",
                "widget_args": {}
            }
        }
    }

    const createBlankWidget = () => {
        dashboard.config.widgets.push(getBlankWidgetTemplate())
        setDashboard(JSON.parse(JSON.stringify(dashboard)))

        // setDashboard({...dashboard})
        setIsDirty(true)
    }

    const content = () => {
          
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                },
                title: {
                    display: true,
                    text: 'Chart.js Bar Chart',
                },
            },
            scales: {
                y: {
                    grid: {
                        color: 'rgba(200, 200, 200, 0.1)',
                    },
                },
                x: {
                    grid: {
                        color: 'rgba(200, 200, 200, 0.1)',
                    },
                },
            },
        };


        const optionsLine = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                },
                title: {
                    display: true,
                    text: 'Chart.js Line Chart',
                },
            },
            scales: {
                y: {
                    grid: {
                        color: 'rgba(200, 200, 200, 0.1)',
                    },
                },
                x: {
                    grid: {
                        color: 'rgba(200, 200, 200, 0.1)',
                    },
                },
            },
           
        };
          
        const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
          
        const data = {
            labels,
            datasets: [
              {
                label: 'Dataset 1',
                data: labels.map((l, i) => 100 + 10 * i),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 0.5)',
                borderWidth: 2,
                pointRadius: 1,
              },
              {
                label: 'Dataset 2',
                data: labels.map((l, i) => 200 - 15 * i),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                // color: 'rgba(53, 162, 235, 0.5)',
                borderColor: 'rgba(53, 162, 235, 0.5)',
                borderWidth: 2,
                pointRadius: 1,
              },
            ],
          };

        const cloneWidget = (widget: WidgetType) => {
            const newWidget = {...widget, id: makeid(10)}
            const widgets = [...dashboard.config.widgets, newWidget]
            dashboard.config.widgets = widgets
            console.log("cloneWidget", {widget, widgets})
            setDashboard(JSON.parse(JSON.stringify(dashboard)))
            setIsDirty(true)
        }

        const updateWidget = (widget: WidgetType) => {
            const widgets = [...dashboard.config.widgets.map((existingWidget) => {
                return (widget.id === existingWidget.id ? widget : existingWidget)
            })]
            // const newWidget = {...widget, id: makeid(10)}
            dashboard.config.widgets = widgets
            console.log("updateWidget", {widget, widgets})
            setDashboard(JSON.parse(JSON.stringify(dashboard)))

            setIsDirty(true)
        }

        const deleteWidget = (widget: WidgetType) => {
            const widgets = [...dashboard.config.widgets.filter((existingWidget) => existingWidget.id !== widget.id )]
            dashboard.config.widgets = widgets
            console.log("deleteWidget", {widget, widgets})
            setDashboard(JSON.parse(JSON.stringify(dashboard)))
            setIsDirty(true)
        }


        const renderWidget = (widget: WidgetType, index: number) => {
            return <div key={widget.id} style={{overflowY: "scroll", border: light ? '1px solid #f0f0f0' : '1px solid #414141'}}>
                <DashboardWidget key={widget.id} widget={widget} can_edit={can_edit} cloneWidget={cloneWidget} updateWidget={updateWidget} deleteWidget={deleteWidget} />

            </div> 
        }

        const otherWidgets = () => {
            return (
                <>
                    <Col className="gutter-row" span={12}>
                        <div style={{...styles.widget}}>
                            <Typography.Title level={2}>Billing</Typography.Title>

                            <div>
                                <Typography.Paragraph>Usage</Typography.Paragraph>
                                <Progress percent={70} steps={5} strokeColor={[green[6], green[6], green[6], yellow[5]]} />
                                {/* <Progress percent={100} steps={5} size="small" strokeColor={green[6]} /> */}
                                {/* <Progress percent={90} steps={5} strokeColor={green[6]} /> */}
                                {/* <Progress percent={10} steps={5} strokeColor={green[6]} /> */}
                            </div>
                            <div>
                                <Typography.Paragraph>Credits</Typography.Paragraph>
                                <Progress percent={90} steps={5} strokeColor={green[6]} />

                            </div>
                        </div>
                    </Col>

                    <Col className="gutter-row" span={12}>
                        <div style={{...styles.widget}}>
                            <Typography.Title level={2}>Demo</Typography.Title>

                            <Tag color="magenta">Pro</Tag>
                            <Statistic title="Credits Remaining" value={1128} prefix={'B '} />
                            <Progress percent={53} strokeColor='#1765ad'/>
                            <div>

                                <Progress percent={70} steps={5} strokeColor={[green[6], green[6], green[6], yellow[5]]} />
                                <Progress percent={100} steps={5} size="small" strokeColor={green[6]} />
                                <Progress percent={90} steps={5} strokeColor={green[6]} />
                                <Progress percent={10} steps={5} strokeColor={green[6]} />
                            </div>
                            <div>

                                <Progress type="circle" percent={75} strokeColor='#17adaa'  />
                                <Progress type="dashboard" percent={75} strokeColor='#17adaa'  />
                            </div>
                        </div>
                    </Col>

                    <Col className="gutter-row" span={12}>
                        <div style={{...styles.widget}}>
                            <Typography.Title level={2}>Demo</Typography.Title>
                            <Bar options={options} data={data}/>
                        </div>
                    </Col>

                    <Col className="gutter-row" span={12}>
                        <div style={{...styles.widget}}>
                            <Typography.Title level={2}>Demo</Typography.Title>
                            <Line options={optionsLine} data={data} />
                        </div>
                    </Col>

                    <Col className="gutter-row" span={12}>
                        <div style={{...styles.widget}}>
                            <Typography.Title level={2}>Demo</Typography.Title>
                            <Calendar />
                        </div>
                    </Col>
                </>
            )
        }

        return (
            // https://react-chartjs-2.js.org/examples
            // https://www.chartjs.org/docs/latest/charts/line.html
            <>
                
                {/* <Row gutter={[16, 24]}> */}
                    {/* {dashboard.config.widgets.map(renderWidget)} */}
                    {/* {otherWidgets()} */}
                {/* </Row> */}

                <ResponsiveGridLayout
                // <GridLayout
                    className="layout dashboard-grid"
                    // layout={dashboard.config.widgets.map((widget) => ({i: widget.id, ...widget.layout}))}
                    // cols={8}

                    layouts={{
                        md: dashboard.config.widgets.map((widget) => ({i: widget.id, ...widget.layout})),
                        // sm: dashboard.config.widgets.map((widget) => ({i: widget.id, ...widget.layout, x: 0, w: 1})),
                        sm: dashboard.config.widgets.map((widget) => ({i: widget.id, ...widget.layout})),
                    }}
                    breakpoints={{ md: 800, sm: 0}}
                    cols={{ md: 8, sm: 1 }}

                    rowHeight={30}
                    // width={800}
                    // resizeHandles={["se"]}
                    onLayoutChange={(newLayout) => {
                        console.log("newLayout", {newLayout, dashboard})
                        dashboard.config.widgets.map((widget) => {
                            const newWidgetLayout = newLayout.find((layout) => layout.i === widget.id)
                            if (newWidgetLayout) {
                                widget.layout.x = newWidgetLayout.x
                                widget.layout.h = newWidgetLayout.h
                                widget.layout.y = newWidgetLayout.y
                                widget.layout.w = newWidgetLayout.w
                            }
                        })
                        setDashboard(JSON.parse(JSON.stringify(dashboard)))
                        setIsDirty(true)
                    }}
                    isDraggable={can_edit}
                    isResizable={can_edit}
                    containerPadding={[0, 0]}
                    draggableCancel=".ant-card-body, .ant-card-actions, .ant-card-extra"
                    draggableHandle=".ant-card-head"
                >
                    {dashboard.config.widgets.map(renderWidget)}
                </ResponsiveGridLayout>

                {can_edit && (
                    <Card style={{marginTop: '10px', cursor: 'pointer'}} onClick={createBlankWidget}>
                        <div style={{textAlign: 'center'}}>Add Widget</div>
                    </Card>
                )}
            </>
        )

    }

    const modals = (
        <CreateDashboardModal
            isOpen={showCopyDashboard}
            onCancel={() => setShowCopyDashboard(false)}
            onCreate={() => {}}
            dashboardCopySource={dashboard ? dashboard : undefined}
        />
    )

    const buttons = () => {
        return (
            <Space>
                <Button type="primary">Add Widget</Button>
                <Button type="primary">Save</Button>
                <Button type="default" onClick={revertDashboard}>Cancel</Button>
                {/* <Button type="primary" onClick={clear_click}>Clear</Button> */}
            </Space>
        )

    }

    return (
        <>
            {(show_name) && (head())}
            {/* {(can_edit && isDirty) && (buttons())} */}
            {content()}
            {modals}
        </>
    )

    
}
