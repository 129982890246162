import { CopyToClipboard as CopyToClipboardOriginal } from 'react-copy-to-clipboard';
import { message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { DataProvider } from '../types/types'
import { ReactNode } from 'react'
import { TooltipPlacement } from 'antd/es/tooltip';


interface CopyToClipboardParams {
    text: string
    tooltip?: string | null
    content?: string | ReactNode
    post_content?: string | ReactNode
    placement?: TooltipPlacement
    onCopy?: () => void
    style?: React.CSSProperties
}


export function CopyToClipboard({
    text,
    tooltip = 'Copy',
    content = <CopyOutlined />,
    post_content = "",
    placement = 'top',
    onCopy,
    style,
}: CopyToClipboardParams) {

    const copy_component = <CopyToClipboardOriginal
        text={text}
        onCopy={() => {
            if (onCopy) {
                onCopy()
            }
            message.info("Copied!")
        }}
    >
        <span style={{cursor: 'pointer', ...(style ? style : {})}}>{content}{post_content}</span>
    </CopyToClipboardOriginal>

    if (tooltip === null) {
        return copy_component
    }

    return (
        <Tooltip placement={placement} title={tooltip}>
            {copy_component}
        </Tooltip>
    )
}
