import { Link } from 'react-router-dom';

import { ReactNode } from 'react'


import {
    Workflow as WorkflowType
} from '../types/types';


export function WorkflowLink({
    workflow,
    children,
}: {workflow: WorkflowType, children: string | ReactNode}) {
    const url = workflow.project_id ? `/projects/${workflow.project_id}/?v=${workflow.id}` : `/workflows/${workflow.id}`
    return <Link to={url}>{children}</Link>
    
}
