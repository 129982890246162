import { Button, ConfigProvider, Empty, Typography, List, Space, Tooltip, Form, Input, message, Alert, Popconfirm } from 'antd';
import { useState, useEffect } from 'react';
import { ReloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { Link } from "react-router-dom";

// Assuming you have these imports, you can modify based on your actual use case.
import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getProjectSnippets, createProjectSnippet, editProjectSnippet, deleteProjectSnippet } from '../services/signalApi';
import { toTitleCase } from '../utils';
import { CopyToClipboard } from '../components/CopyToClipboard';
import { NewSnippetForm } from '../components/NewSnippetForm';

import { ProjectSnippet as ProjectSnippetType } from '../types/types';



export function UpdateSnippetForm({
    snippet,
    onCancel,
    onUpdate,
}: {
    snippet: ProjectSnippetType
    onCancel: () => void
    onUpdate: () => void
}) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isEditing, setIsEditing] = useState(false);

    const handleCancel = () => {
        form.resetFields();
        setIsEditing(false);
        onCancel();
    }

    const onCreateProjectSnippetFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateProjectSnippetSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateProjectSnippet(values);
    };

    const onCreateProjectSnippet = (values: any) => {
        if (account) {
            setIsEditing(true);
            editProjectSnippet(
                snippet.id,
                values
            ).then((res) => {
                if (res.status === 200) {
                    const response_data = res.data;
                    setIsEditing(false);
                } else {
                    message.error(res.data);
                    setIsEditing(false);
                }  
            }).catch((err) => {
                console.log("err", {err});
            }).finally(() => {
                setIsEditing(false);
                onUpdate();
            });
        }
    }

    return <Form
        name="snippet"
        form={form}
        layout='vertical'
        initialValues={{
            name: snippet.name,
            content: snippet.content
        }}
        onFinish={onCreateProjectSnippetSubmit}
        onFinishFailed={onCreateProjectSnippetFailed}
        autoComplete="off"
        requiredMark={"optional"}
    >
        {(isEditing && (
            <Typography.Paragraph>Loading...</Typography.Paragraph>
        ))}
        
        <>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Name your Snippet' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Content"
                name="content"
            >
                <Input.TextArea showCount style={{height: 120}} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Space style={{float: 'right', width: 'unset'}}>
                    <Button type="primary" htmlType="submit" disabled={isEditing}>
                        Save
                    </Button>
                    <Button type="default" onClick={onCancel}>
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </>

    </Form>
}


export function SnippetListItem({
    snippet,
    triggerReload,
}: {snippet: ProjectSnippetType, triggerReload: () => void}) {

    const account = useAppSelector(selectAccount);

    const [editMode, setEditMode] = useState(false);
    const [showSaved, setShowSaved] = useState(false);

    const onDeleteProjectSnippet = () => {
        if (account) {
            deleteProjectSnippet(
                snippet.id
            ).then((res) => {
                if (res.status === 204) {
                    const response_data = res.data;
                    triggerReload();
                } else {
                    message.error(res.data);
                }  
            })
        }
    }

    return <List.Item
        key={snippet.id}
        >
        <List.Item.Meta
            key={snippet.id}
            title={
                <>
                    {!editMode && (
                        <Space wrap>
                            <span>{toTitleCase(snippet.name)}</span>
                        </Space>
                    )}
                    <Space style={{float: 'right', fontSize: '0.9rem'}}>
                        <a><CopyToClipboard text={snippet.content || ''} tooltip='Copy to Clipboard' /></a>
                        <Tooltip placement="top" title="Edit">
                            <a onClick={() => {setEditMode(!editMode)}}><EditOutlined /></a>

                        </Tooltip>
                        <Tooltip placement="top" title="Delete">
                            <Popconfirm title={<Typography.Paragraph>Are you sure you want to remove this Snippet?</Typography.Paragraph>} okText="Delete" cancelText="Cancel" onConfirm={() => {onDeleteProjectSnippet()}}>
                                <a>
                                    <DeleteOutlined />
                                </a>
                            </Popconfirm>
                        </Tooltip>
                    </Space>

                </>
                
            }
            
            description={
                <Space direction='vertical' style={{ width: '100%' }}>

                    {!editMode && (
                        <>
                            {snippet.description && (
                                <Typography.Text>{snippet.description}</Typography.Text>

                            )}
                            <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }} style={{whiteSpace: 'pre-line'}}>{snippet.content}</Typography.Paragraph>
                        </>
                    )}
                    {showSaved && (
                        <Alert message="Snippet Saved" type="success" closable onClose={() => setShowSaved(false)} />
                    )}
                    {editMode && (
                        <UpdateSnippetForm snippet={snippet} onCancel={() => setEditMode(false)} onUpdate={() => {
                            setShowSaved(true)
                            setEditMode(false)
                            triggerReload()
                        }} />
                    )}
                    
                </Space>
            }
        />
    </List.Item>

}



interface ProjectSnippetListParams {
    project_id?: string
}

export function ProjectSnippetsList({
    project_id
}: ProjectSnippetListParams) {
    console.log("ProjectSnippetList")
    const account = useAppSelector(selectAccount)
    const [loadedProjectSnippets, setLoadedProjectSnippets] = useState<ProjectSnippetType[] | null>(null);
    const [reloadedLast, setReloadedLast] = useState('never');
    const [showNewSnippetForm, setShowNewSnippetForm] = useState(false);

    useEffect(() => {
        // Uncomment and modify this to fetch data as per your API structure
        getProjectSnippets({
            project_id: project_id,
            ordering: 'name'
        }).then((res) => {
            console.log("ProjectSnippetList", {res})
            if (res.status === 200) {
                setLoadedProjectSnippets(res.data.results || [])
            } else {
                setLoadedProjectSnippets([])
            }
        }).catch(() => {
            setLoadedProjectSnippets([])
        })
    }, [account, reloadedLast]);

    const triggerReload = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    const content_list = () => {
        const keyedProjectSnippets = loadedProjectSnippets?.map((snippet) => ({ key: snippet.id, ...snippet })) || null;
        return <ConfigProvider renderEmpty={() => <Empty description={"No Project Snippets"} image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
            <List
                className="project-snippet-list"
                itemLayout={'vertical'}
                loading={keyedProjectSnippets === null}
                dataSource={keyedProjectSnippets === null ? [] : keyedProjectSnippets}
                renderItem={(snippet: ProjectSnippetType) => (
                    <SnippetListItem snippet={snippet} triggerReload={triggerReload} />
                )}
            />
        </ConfigProvider>
    }



    const buttons = (
        <Space style={{ marginBottom: '6px', float: 'right' }}>
            <Button type="default" onClick={() => {setShowNewSnippetForm(!showNewSnippetForm)}} disabled={false}>+ New Snippet</Button>
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
        </Space>
    )

    return (
        <>
            {buttons}
            <Typography.Title level={3} style={{marginTop: 0}}>Snippets</Typography.Title>
            <Typography.Paragraph>Snippets can be used in any Prompt or in any Step in a Workflow in a Project</Typography.Paragraph>
            {project_id && showNewSnippetForm && (
                <NewSnippetForm project_id={project_id} onCancel={() => {setShowNewSnippetForm(false)}} onCreate={triggerReload} />
            )}
            {content_list()}
        </>
    )
}
