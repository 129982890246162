
import { Modal,  Typography, Descriptions, Table } from 'antd';
import { Prompt } from '../types/types'
import { Link } from "react-router-dom";


interface PromptDetailsModalParams {
    prompt: Prompt | null
    onCancel: () => void
}


export function PromptDetailsModal({
    prompt,
    onCancel,
}: PromptDetailsModalParams) {

    const handleCancel = () => {
        onCancel()
    }

    return (
            <Modal title="Prompt" open={prompt !== null} footer={null} onCancel={handleCancel} width={800}>
                {prompt !== null && (
                    <>
                        <Descriptions>
                            <Descriptions.Item label="Name">{prompt.name}</Descriptions.Item>
                            <Descriptions.Item label="Account"><Link to={`/user/${prompt.account_id}/`}>{prompt.account.name}</Link></Descriptions.Item>
                        </Descriptions>
                        <Typography.Paragraph>{prompt.content}</Typography.Paragraph>
                    </>
                    
                )}
                
            </Modal>
    )

    
}
