
import { EyeOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { Link } from "react-router-dom";
import { DataProvider } from '../types/types'


interface DataProviderLinkParams {
    data_provider: DataProvider
}


export function DataProviderLink({
    data_provider,
}: DataProviderLinkParams) {
    return <>{data_provider.name}</>
    // return <Link to={`/data_providers/${data_provider.id}/`}>{data_provider.name}</Link>
}
