
import { Button } from 'antd';
import { useState } from 'react';

import { SignalList } from './SignalList';
import { SignalStream } from './SignalStream';
import { TableOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useIsBigScreen, useIsSmallScreen } from '../app/hooks'


interface SignalQueryParams {
    include_public?: boolean
}


interface SignalListParams {
    name?: string
    data_set_id?: string
    symbol?: string
    showButtons?: boolean
    addContextColumns?: boolean
    queryParams?: SignalQueryParams
}

type signalSelection = 'table' | 'stream'

export function SignalSelectableBrowser({
    name,
    data_set_id,
    symbol,
    showButtons=true,
    addContextColumns=false,
    queryParams,
}: SignalListParams) {
    const isSmallScreen = useIsSmallScreen()
    const [signalBrowserSelected, setSignalBrowserSelected] = useState<signalSelection | null>(null);
    if (signalBrowserSelected === null) {
        const storedSignalBrowserSelection = localStorage.getItem("SIGNAL_BROWSER_SELECTION")
        if (storedSignalBrowserSelection && (storedSignalBrowserSelection === 'table' || storedSignalBrowserSelection === 'stream')) {
            setSignalBrowserSelected(storedSignalBrowserSelection)
        }
    }
    const effectiveBrowser: signalSelection = (signalBrowserSelected === null || signalBrowserSelected === 'table') ? 'table' : 'stream'
    const toggleFn = () => {
        const newBrowser = effectiveBrowser === 'table' ? 'stream' : 'table';
        localStorage.setItem("SIGNAL_BROWSER_SELECTION", newBrowser)
        setSignalBrowserSelected(newBrowser)

    }
    const bigToggle = <Button onClick={toggleFn}>View as {effectiveBrowser === 'table' ? 'Stream' : 'Table'}</Button>
    const smallToggle = <Button onClick={toggleFn}>{effectiveBrowser === 'table' ? <UnorderedListOutlined /> : <TableOutlined />}</Button>
    const toggle = isSmallScreen ? smallToggle : bigToggle
    // const toggle = bigToggle


    // const kwargs = {name, data_set_id, symbol, showButtons, addContextColumns, queryParams}
    if (effectiveBrowser === 'table'){
        return <SignalList name={name} data_set_id={data_set_id} symbol={symbol} showButtons={showButtons} addContextColumns={addContextColumns} queryParams={queryParams} addButtons={toggle} />
    } else {
        return <SignalStream name={name} data_set_id={data_set_id} symbol={symbol} showButtons={showButtons} addContextColumns={false} queryParams={queryParams} addButtons={toggle} />
    }
    

}
