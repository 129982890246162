import type { GetProp, UploadFile, UploadProps } from 'antd';
import axios, { AxiosPromise } from 'axios'
// type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];


export const uploadSignedFileToS3 = (
    url: string,
    file: File,
    content_type: string,
    headers?: {}
): AxiosPromise =>
    axios.put(url, file, {
        headers: {
            'Content-Type': content_type,
            ...headers,
        },
    })
