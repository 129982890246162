import { useQuery } from '@tanstack/react-query';
import { Col, Row, Empty, Typography } from 'antd';
// import { Button, Card, Col, Input, Radio, Row, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Loading } from '../components/Loading';
import { StoreItemElement } from '../components/StoreItemElement';
import { selectAccount } from '../features/login/loginSlice';
import { COMMON_EMPTY_RESPONSE } from '../utils';

import {
    addToCart,
    selectCartItems
} from '../features/cart/cartSlice';
import { getAccountItemPurchases, getStoreItems } from '../services/signalApi';
import { StoreItem, SubscriptionRecurring } from '../types/types';

const centeredTextStyling: React.CSSProperties = {
    textAlign: 'center'
}
interface StoreSection {
    header: string | React.ReactNode
    sub: string | React.ReactNode
    categoryFilter?: string
    functionalFilter?: (x: StoreItem) => boolean
    post: string | React.ReactNode
}


export interface StoreFrontParams {
    skus?: string[]
    style?: 'cards' | 'list'
    cardStyle?: React.CSSProperties,
    storeRowStyle?: React.CSSProperties,
    includeDescription?: boolean
    featureSkus?: string[]
    highlightFeatured?: boolean
    storeSections?: StoreSection[]
}


export function StoreFront({
    skus,
    style='cards',
    cardStyle,
    storeRowStyle,
    includeDescription=true,
    featureSkus,
    highlightFeatured=false,
    storeSections,
}: StoreFrontParams) {
    const account = useAppSelector(selectAccount);
    const dispatch = useAppDispatch();
    const cartItems = useAppSelector(selectCartItems);
    const [searchParams, setSearchParams] = useSearchParams();

    const cartAction = searchParams.get("action")
    const cartActionItemId = searchParams.get("item_id")
    const cartActionItemRecurring = searchParams.get("recurring")
    const cartActionItemQuantity = searchParams.get("quantity")

    const {
        data: { data: { count: storeItemCount, results: storeItems}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchStoreItems,
        isFetching: isFetchingStoreItems,
    } = useQuery({
        queryKey: ['store', skus],
        staleTime: 1000 * 60 * 15,
        queryFn: () => {
            return getStoreItems({
                ...(skus ? {sku__in: skus.join(',')} : {} ),
            })
        }
    })
    // console.log("StoreFront", {storeItemCount, storeItems})

    const {
        data: { data: { count: accountItemPurchasesCount, results: accountItemPurchases}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchAccountItemPurchases,
        isFetching: isFetchingAccountItemPurchases,
    } = useQuery({
        queryKey: ['account_item_purchases'],
        staleTime: 1000 * 60 * 15,
        queryFn: () => {
            return getAccountItemPurchases()
        }
    })

    useEffect(() => {

        if (account && (!cartItems || cartItems.length < 1) && cartAction === "add_to_cart" && cartActionItemId && cartActionItemRecurring !== undefined && cartActionItemRecurring !== null && ["lifetime", "year", "month"].includes(cartActionItemRecurring) && cartActionItemQuantity) {
            const storeItem = (storeItems || []).find((item) => item.id === cartActionItemId)
            if (storeItem) {
                searchParams.delete("action")
                searchParams.delete("item_id")
                searchParams.delete("recurring")
                searchParams.delete("quantity")
                setSearchParams(searchParams)
                dispatch(addToCart({item: storeItem, recurring: cartActionItemRecurring as SubscriptionRecurring, quantity: parseInt(cartActionItemQuantity, 10)}))
            }
        }

    }, [account?.id, JSON.stringify(storeItems)]);

    const existingCartSubscriptionTimeframe = (cartItems || [])[0]?.recurring
    if (isFetchingStoreItems) {

        return (
            <Row
                style={storeRowStyle ? storeRowStyle : {marginTop: '30px'}}
                gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 16, sm: 16, md: 24, lg: 32 }]}
                // justify="space-around"
                justify="start"
                align="top"
            >

                <Col span={24}>
                    <Loading message='Store' description='Loading Store Items...' style={{}}/>
                </Col>
            </Row>
        )

    }

    const makeStoreSection = (storeSection: StoreSection, theseStoreItems: StoreItem[]) => {
        const filteredStoreItems = storeSection.categoryFilter ? theseStoreItems.filter((storeItem) => storeItem.category === storeSection.categoryFilter) : storeSection.functionalFilter ? theseStoreItems.filter(storeSection.functionalFilter) : []
        return <>
            <Row style={{marginTop: '24px'}}>
                <Col span={24} style={centeredTextStyling} >
                    <Typography.Title level={3}>{storeSection.header}</Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={centeredTextStyling} >
                    <Typography.Paragraph>{storeSection.sub}</Typography.Paragraph>
                </Col>
            </Row>
            {renderStoreRow(filteredStoreItems)}
            {typeof storeSection.post === "string" && (
                <Row>
                    <Col span={24} style={{marginTop: '12px', ...centeredTextStyling}} >
                        <Typography.Paragraph>{storeSection.post}</Typography.Paragraph>
                    </Col>
                </Row>
            )}
            {typeof storeSection.post !== "string" && storeSection.post} 
        </>
    }

    const renderStoreRow = (theseStoreItems: StoreItem[]) => {

        return (
            <Row
                style={storeRowStyle ? storeRowStyle : {marginTop: '30px'}}
                gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 16, sm: 16, md: 24, lg: 32 }]}
                // justify="space-around"
                justify="start"
                align="top"
            >
                {theseStoreItems?.map((storeItem, idx) => (
                    <Col
                        key={storeItem.id}
                        span={style === 'cards' ? 8 : 24}
                        xs={style === 'cards' ? 24 : 24}
                        sm={style === 'cards' ? 24 : 24}
                        md={style === 'cards' ? 12 : 24}
                        lg={style === 'cards' ? 8 : 24}
                        xl={style === 'cards' ? 8 : 24}
                    >
                        <StoreItemElement
                            key={idx}
                            storeItem={storeItem}
                            forceDisable={(
                                cartItems || []).length > 0 &&
                                (cartItems || []).filter((item) => item.item_id === storeItem.id).length === 0
                            }
                            existingCartSubscriptionTimeframe={existingCartSubscriptionTimeframe}
                            existingAccountItemPurchases={(accountItemPurchases || []).filter((aip) => aip.item_id == storeItem.id)}
                            cardStyle={cardStyle}
                            includeDescription={includeDescription}
                            isFeatured={(featureSkus && storeItem.sku ? featureSkus.includes(storeItem.sku) : false)}
                            showFeaturedTag={highlightFeatured}
                        />
                    </Col>
                ))}
            </Row>
        );
    }

    if (!storeItems || storeItems.length == 0) {
        return <Empty description={"No Results Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }
    
    if (storeSections && storeItems) {
        return <>
            {storeSections.map((storeSection) => (
                <>
                {makeStoreSection(storeSection, storeItems)}
                </>
            ))}
            </>
    }

    return renderStoreRow(storeItems)
}
