
import { ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popover, Space, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { Link } from "react-router-dom";
import { Account } from '../types/types';


interface AccountLinkParams {
    account: Account
    symbol_type?: string
}


export function AccountLink({
    account,
}: AccountLinkParams) {
    return <Link className='account-link' to={`/u/${account.name}/`}>{account.name}</Link>
    
}
