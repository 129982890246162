import React, { useState } from 'react';
// import { DataSet as DataSetType, Dashboard as DashboardType } from '../../types/types'
// import { getDataSet, getDataSetDashboards } from '../../services/signalApi'
import { EyeInvisibleOutlined, GlobalOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Form, Input, Row, Space, Typography, Tag,Tooltip } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
// import { PageHeader } from '@ant-design/pro-layout';
import 'react-quill/dist/quill.snow.css';
import { Loading } from '../../components/Loading';
import { UploadDataSetFileModal } from '../../components/UploadDataSetFileModal';
import { DateTime } from 'luxon';


import { useAppSelector } from '../../app/hooks';
import { ToggleToMarkdown } from '../../components/ToggleToMarkdown';
import { selectAccount } from '../../features/login/loginSlice';

import { CopyToClipboard } from '../../components/CopyToClipboard';
import { IconText } from '../../components/IconText';
import { UserAvatar } from '../../components/UserAvatar';
import { MarkdownContent } from '../../components/MarkdownContent';

import { getTagColor } from '../../utils';



import { Link } from "react-router-dom";

import { useQuery } from '@tanstack/react-query';
import { dataSetQuery, getDataSet, getDataSetLoggedOut } from '../../services/signalApi';


export function DataSet() {
    const params = useParams()


    const account = useAppSelector(selectAccount)


    const [queryResponse, setQueryResponse] = useState<string | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const action = searchParams.get("action")

    const id = params.id;

    const {
        data: {data: dataSet} = {data: null},
        refetch: refetchDataSet,
        // isFetched: isFetchedDataSet,
        isFetching: isFetchingDataSet,
    } = useQuery({
        queryKey: ['account', account?.id, 'data_set', id],
        staleTime: 1000 * 60 * 60,
        queryFn: () => {
            // const prompt_ids_needed = prompt_ids.filter((id) => id && !workflowDataSetIds.includes(id))
            if (id) {
                const getDataSetsFn = account ? getDataSet : getDataSetLoggedOut;
                return getDataSetsFn(id)
            }

        }
    })



    console.log('dataSet', {dataSet})
    if (!id) {
        return <Typography.Paragraph>No ID</Typography.Paragraph>
    }

    if (dataSet && dataSet.detail === 'Not found.') {
        return <Typography.Paragraph>Not found</Typography.Paragraph>
    }


    const handleUploadCancel = () => {
        searchParams.delete("action")
        setSearchParams(searchParams)
    }

    const onDataSetQueryFinish = (values: {query: string}) => {
        console.log('Success:', {values, account});
        if (account && dataSet) {
            dataSetQuery(account?.id, dataSet.id, values.query).then(res => {
                if (res.status === 200) {
                    const response = res.data.response
                    if (response) {
                        setQueryResponse(response)
                    } else {
                        setQueryResponse(null)
                    }
                }
            })
        }
    };


    // }
    const ownedDataSet = (account && dataSet && dataSet.account_id === account.id)



    return (

        <div className="App">
            <div className="App-content">

                {/* </Descriptions> */}
                <div className="App-page-content">
                    {isFetchingDataSet && (
                        <Loading message='Data Set' description='Loading Data Set' style={{marginTop: '12px'}} />
                    )}
                    {dataSet && (
                        <Row gutter={24} style={{marginTop: '12px'}}>
                            <Col xs={24} md={8}>
                                <Card
                                    title={
                                        <>Data Set&nbsp;
                                            <Typography.Text style={{fontSize: "0.7rem"}}>
                                                <Link to={`/data_sets`}>see all Data Sets&nbsp;&#8594;</Link>
                                            </Typography.Text>
                                        </>
                                    }
                                    style={{marginBottom: '12px'}}
                                >
                                    <Space direction='vertical' style={{width: '100%'}}>
                                        <Typography.Title level={3} style={{marginTop: 0}}>{dataSet.name}</Typography.Title>
                                        {dataSet && dataSet.img_path && (<img
                                            alt={dataSet.name}
                                            style={{width: '100%'}}
                                            src={`https://assets.pyroprompts.com/img/${ dataSet.img_path }`}
                                        />)}
                                        <Space>
                                            <UserAvatar account={dataSet.account} />
                                            <Link to={`/u/${dataSet.account.name}/`}>{dataSet.account.name}</Link>
                                        </Space>
                                        <Space.Compact>
                                            {dataSet.is_public === 1 && (
                                                <Tooltip title="Public, everyone can see this.">
                                                    <Tag><GlobalOutlined /></Tag>
                                                </Tooltip>
                                            )}
                                            {dataSet.is_public === 0 && (
                                                <Tooltip title="Private, only visible to you and those you've shared with.">
                                                    <Tag><EyeInvisibleOutlined /></Tag>
                                                </Tooltip>
                                            )}
                                            {dataSet.tags && (
                                                <>
                                                    {dataSet.tags.split(",").map((tag, i) => (
                                                        <Tag key={i} color={getTagColor(tag)}>{tag.trim()}</Tag>
                                                    ))}
                                                </>
                                            )}
                                        </Space.Compact>

                                        <Space direction='horizontal'>
                                            <Typography.Text>Id:</Typography.Text>
                                            <Typography.Text code>{id}</Typography.Text>
                                            <CopyToClipboard text={id} />

                                        </Space>

                                        {dataSet.s3_source_url_updated_date && (
                                            <Typography.Paragraph style={{fontSize: '0.8rem', color: 'rgba(255, 255, 255, 0.45)'}}>Last Updated: {DateTime.fromISO(dataSet.s3_source_url_updated_date).toLocaleString(DateTime.DATETIME_SHORT)}</Typography.Paragraph>
                                        )}

                                        {dataSet.description && (
                                            <MarkdownContent markdown={dataSet.description} />

                                        )}
                                    </Space>
                                </Card>

                            </Col>
                            <Col xs={24} md={16}>
                                <Card
                                    title={'Explore Data Set'}
                                    style={{marginBottom: '24px'}}
                                    extra={
                                        <Space direction='horizontal' size={'large'}>
                                            {ownedDataSet && (

                                                <IconText icon={UploadOutlined} text="Upload" onClick={() => {
                                                    searchParams.set("action", "bulk-upload")
                                                    setSearchParams(searchParams)
                                                }} key="list-vertical-bulk-upload" />
                                            )}
                                        </Space>
                                    }

                                >
                                    {!account && (
                                        <Empty description={"Log In to explore Data Set"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    )}

                                    {account && dataSet.is_populated && (
                                        <>
                                            <Form
                                                name="basic"
                                                // form={form}
                                                layout='vertical'
                                                // labelCol={{ span: 6 }}
                                                // wrapperCol={{ span: 16 }}
                                                initialValues={{}}
                                                onFinish={onDataSetQueryFinish}
                                                // onFinishFailed={onCreateDataSetRecordFailed}
                                                autoComplete="off"
                                                requiredMark={"optional"}

                                            >

                                                <Form.Item
                                                    // label="Question"
                                                    name="query"
                                                    rules={[{ required: true, message: 'Provide a query term.' }]}
                                                >
                                                    <Input.TextArea
                                                        showCount
                                                        maxLength={1600}
                                                        style={{ height: 120 }}
                                                        // onChange={onChange}
                                                        placeholder="Ask one-off questions of the dataset. Like: find me all the stories about heroism."
                                                    />
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button type="primary" style={{float: 'right'}} htmlType="submit">
                                        Go
                                                    </Button>
                                                </Form.Item>


                                            </Form>

                                            {queryResponse && (
                                                <>
                                                    <Typography.Title level={3}>Response</Typography.Title>
                                                    <ToggleToMarkdown markdown={queryResponse} />
                                                </>
                                            )}
                                        </>
                                    )}

                                    {account && !dataSet.is_populated && dataSet.account_id !== account.id && (
                                        <Empty description={"Data Set is Empty"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    ) }

                                    {account && !dataSet.is_populated && dataSet.account_id === account.id && (
                                        <Empty description={"Data Set is Empty. Begin by uploading a CSV, JSON, or SQLite Database File."} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    ) }

                                </Card>
                            </Col>
                        </Row>
                    )}



                    {dataSet && (
                        <UploadDataSetFileModal isOpen={ownedDataSet === true && action === "bulk-upload"} onCancel={handleUploadCancel} onCreate={() => {refetchDataSet()}} dataset={dataSet} />
                    )}

                </div>
            </div>
        </div>
    );

}
