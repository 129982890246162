import { Form, Input, Button, Typography, Alert } from 'antd';
import { useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { redeemCode } from '../services/signalApi';
import { SignUpButton } from './SignUpButton';
import { useSearchParams } from 'react-router-dom';





interface PromoCodeSubmitFormParams {
}

export function PromoCodeSubmitForm({}: PromoCodeSubmitFormParams){
    const [isCreating, setIsCreating] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const account = useAppSelector(selectAccount);
    const [searchParams, setSearchParams] = useSearchParams();

    const promo_code = searchParams.get("code")



    const onCodeSubmitFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCodeSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onRedeemCode(values)
    };

    const onRedeemCode = (values: any) => {
        if (account) {
            console.log("onRedeemCode values", {values})
            setIsCreating(true)
            setErrorMessage(null)
            setIsSuccess(false)
            redeemCode(account.id, values.code).then((res) => {
                console.log("created", {res})
                if (res.status === 200) {
                    const response_data = res.data

                    setIsCreating(false)
                    setIsSuccess(true)
                } else {
                    if (res.data.message) {
                        setErrorMessage(res.data.message)
                    } else {
                        setErrorMessage("Unable to apply code")
                    }
                    setIsCreating(false)
                }
              
            }
            ).catch(() => {
                // onCreate(null)

            }).finally(() => {
                setIsCreating(false)
            })
        }
    }

    if (!account) {
        return <div>
            <Typography.Paragraph>You must be logged in to redeem a code.</Typography.Paragraph>
            <SignUpButton />
        </div>
    }


    return (
        <Form
            name="promo-code"
            // form={form}
            initialValues={promo_code === null ? {} : {code: promo_code}}
            layout='vertical'
            onFinish={onCodeSubmit}
            onFinishFailed={onCodeSubmitFailed}
            autoComplete="off"
            requiredMark={"optional"}
        >
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}

            <Form.Item
                label="Code"
                name="code"
                rules={[{ required: true, message: 'Enter the Code you want to redeem.' }]}
            >
                <Input placeholder='FREECREDITS10'/>
            </Form.Item>

            {errorMessage && (
                <Alert message={errorMessage} type="error" style={{marginBottom: "12px"}} />
            )}

            {isSuccess && (
                <Alert message={"Code Successfully Applied!"} type="info" style={{marginBottom: "12px"}} />
            )}
        
            <Form.Item
                wrapperCol={{ offset: 0, span: 24 }}
            >
                <Button type="primary" htmlType="submit" style={{float: 'right', width: 'unset'}} disabled={
                    isCreating
                }>
                Submit
                </Button>
            </Form.Item>



        </Form>
    )

}
