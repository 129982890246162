import type { SelectProps } from 'antd';


import { MinusCircleOutlined, PlusOutlined, CheckCircleOutlined, ArrowRightOutlined, UpCircleOutlined, BlockOutlined, RightCircleOutlined, InfoCircleOutlined, SearchOutlined, BranchesOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Form, FormListFieldData, Input, InputNumber, message, Select, Space, Tag, Tabs, Tooltip, FormInstance, Popover, Typography, Row, Card, Checkbox, Timeline } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';
import { DateTime } from 'luxon';

import { useEffect, useState, useRef } from 'react';
// import 'react-quill/dist/quill.snow.css';
import { Link, useParams } from 'react-router-dom';
import { editWorkflow, getIntegrations, getWorkflow, triggerWorkflow, getPrompts, getProjectSnippets, getPromptsLoggedOut } from '../../services/signalApi';
import {  Workflow as WorkflowType, Prompt as PromptType, ProjectSnippet } from '../../types/types';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Loading } from '../../components/Loading';
import { PageHeader } from '../../components/PageHeader';
import { SignalList } from '../../components/SignalList';
import { PromptSearchSelect } from '../../components/PromptSearchSelect';
import { CreatePrompt } from '../../components/CreatePrompt';
import { EditPrompt } from '../../components/EditPrompt';
import { WorkflowDiagram } from '../../components/WorkflowDiagram';
import { WorkflowPreview } from '../../components/WorkflowPreview';
import { WorkflowConfigurationForm, modelLabelsByValue } from '../../components/WorkflowConfigurationForm';


import { WorkflowFormSubmit } from '../../components/WorkflowFormSubmit';

import { extractBracketContents, extractSingleLineBracketContents, replaceBracketContents } from '../../utils'

import type { InputRef } from 'antd';

import { selectAccount, selectIsWorkflowDiscordUsernamePermitted } from '../../features/login/loginSlice';
import {
    clear, loadDataSetsAsync, selectDataSets,
    selectStatus
} from '../../features/dataSetList/dataSetSlice';
// import { WorkflowSubscribeModal } from '../../components/WorkflowSubscribeModal'
import { WorkflowExecutionsList } from '../../components/WorkflowExecutionsList';
// import { WorkflowEditModal } from '../../components/WorkflowEditModal'
// import 'draft-js/dist/Draft.css';
import { Prompt } from '../Prompt/Prompt';
const { TextArea } = Input;

// import Editor from "rich-markdown-editor";

// function MyEditor() {
//   const [editorState, setEditorState] = React.useState(
//     () => EditorState.createEmpty(),
//   );

//   return (
//     <>
//       Editor:
//       <Button onClick={() => {}}>Bold</Button>
//       <Editor editorState={editorState} onChange={setEditorState} />
//     </>
//   )
// }



const localUpdateWorkflowConfig = (workflow: WorkflowType, isWorkflowDiscordUsernamePermitted: boolean): WorkflowType => {
    // if (!isWorkflowDiscordUsernamePermitted) {
    //     workflow.config.actions = workflow.config.actions.map((action) => {
    //         if (action.action_type === 'discord_webhook_send_message') {
    //             if (action.args.username) {
    //                 action.args.username = undefined
    //             }
    //         }
    //         return action
    //     })
    // }
    return workflow
}


export function UpdateWorkflowForm({
    workflow,
    onCancel,
    onUpdate,
}: {
    workflow: WorkflowType
    onCancel: () => void
    onUpdate: (workflow: WorkflowType) => void
}) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isEditing, setIsEditing] = useState(false);

    const handleCancel = () => {
        form.resetFields();
        setIsEditing(false);
        onCancel();
    }

    const onEditWorkflowFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onEditWorkflowSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onEditWorkflow(values);
    };

    const onEditWorkflow = (values: any) => {
        if (account) {
            setIsEditing(true);
            editWorkflow(
                workflow.id,
                {name: values.name}
            ).then((res) => {
                if (res.status === 200) {
                    const response_data = res.data;
                    setIsEditing(false);
                    onUpdate(response_data);
                } else {
                    message.error(res.data);
                    setIsEditing(false);
                }
            }).catch((err) => {
                console.log("err", {err});
            }).finally(() => {
                setIsEditing(false);
            });
        }
    }

    return <Form
        name="workflow"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
            name: workflow.name,
        }}
        onFinish={onEditWorkflowSubmit}
        onFinishFailed={onEditWorkflowFailed}
        autoComplete="off"
    >
        {(isEditing && (
            <Typography.Paragraph>Loading...</Typography.Paragraph>
        ))}
 
        <>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Name your Workflow' }]}
            >
                <Input />
            </Form.Item>


            <Form.Item
                wrapperCol={{ span: 16, offset: 6 }}
            >
                <Space wrap>
                    <Button type="primary" htmlType="submit" disabled={isEditing}>
                        Save
                    </Button>
                    <Button type="default" onClick={onCancel}>
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </>

    </Form>
}




export function WorkflowFormSubmitWrapper({
    workflow,
    onSuccess,
}: {workflow: WorkflowType, onSuccess?: () => void}) {
    const account = useAppSelector(selectAccount)
    // const [isSubmitting, setIsSubmitting] = useState<boolean>(false)



    // const onWorkflowFormTrigger = (values: any) => {
    //     console.log("onWorkflowFormTrigger", {values})
    //     if (!account) {
    //         return
    //     }
    //     setIsSubmitting(true)
    //     triggerWorkflow(account.id, workflow.id, values).then(() => {
    //         if (onSuccess) {
    //             onSuccess()
    //         }
    //         setIsSubmitting(false)
    //     })
    // }
    const onSubmit = () => {
        if (onSuccess) {
            onSuccess()
        }
    }
    // const triggers = workflow.config.triggers.filter((trigger) => trigger.trigger_type == "form_submit")
    const triggers = workflow.config.triggers
    const trigger = triggers[0]
    if (trigger === undefined) {
        return <>Not Configured</>

    }
    if (trigger.trigger_type === 'timed'){
        return <WorkflowFormSubmit includeHead={true} workflow_id={workflow.id} credit_price={workflow.credit_price} enabled={workflow.enabled === 1} onSubmit={onSubmit} workflow_form={{
            form_title: "Timed Run",
            form_description: "Manually trigger the timed run for testing purposes",
            submission_params: (trigger.args.submission_params || []).map((sp) => ({
                field: sp.field,
                default_value: sp.value,
                field_type: 'input',
            }))
        }} />
    }
    return <WorkflowFormSubmit includeHead={true} workflow_id={workflow.id} credit_price={workflow.credit_price} enabled={workflow.enabled === 1} onSubmit={onSubmit} workflow_form={trigger.args} />
}

export function WorkflowActivityTab({
    workflow
}: {workflow: WorkflowType}) {
    const [reloads, setReloads] = useState(0);
    const triggers = workflow.config.triggers
    const trigger = triggers[0]

    return (
        <div>
            {trigger !== undefined && (
                <WorkflowFormSubmitWrapper workflow={workflow} onSuccess={() => {setReloads(reloads + 1)}}/>
            )}
            <WorkflowExecutionsList workflow_id={workflow.id} key={reloads} title_size={3} />

        </div>

    )
}

export function WorkflowResourcesTab({
    workflow
}: {workflow: WorkflowType}) {
    return (
        <Typography.Paragraph>List of resources this workflow uses or depends on</Typography.Paragraph>
    )
}




export interface WorkflowConfigurationTabParams {
    workflow: WorkflowType
    workflowPrompts: PromptType[]
    projectSnippets: ProjectSnippet[]
    onSave: () => void
}

export function WorkflowConfigurationTab({
    workflow,
    workflowPrompts,
    projectSnippets,
    onSave,
}: WorkflowConfigurationTabParams) {

    return <WorkflowConfigurationForm workflow={workflow} workflowPrompts={workflowPrompts} projectSnippets={projectSnippets} onSave={onSave} />
}


const snippetPopoverContent = (projectSnippet: ProjectSnippet) => (
    <div style={{width: "500px"}}>
        {/* <TradingViewChartWidget symbol={symbol} /> */}
        <>
            <Typography.Paragraph>Name: {projectSnippet.name}</Typography.Paragraph>
            <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{projectSnippet.content || ''}</Typography.Paragraph>
        </>
    </div>
);

export function Workflow() {
    const params = useParams()
    const account = useAppSelector(selectAccount)
    
    // const [inFlight, setInFlight] = useState(false);
    const [attemptedLoad, setAttemptedLoad] = useState(false);
    const [showCreateSignalModal, setShowCreateSignalModal] = useState(false);
    const [showEditWorkflowModal, setShowEditWorkflowModal] = useState(false);
    const [workflow, setWorkflow] = useState<WorkflowType | null>(null);
    const [workflowPrompts, setWorkflowPrompts] = useState<PromptType[] | null>(null);
    const [projectSnippets, setProjectSnippets] = useState<ProjectSnippet[] | null>(null);
    const [editMode, setEditMode] = useState(false);

    const [reloads, setReloads] = useState(0);
    const [subscribingWorkflow, setSubscribingWorkflow] = useState<WorkflowType | null>(null);
    // const [dataSets, setDataSets] = useState<DataSet[] | null>(null);
    const dataSets = useAppSelector(selectDataSets);
    const dispatch = useAppDispatch();
    console.log("workflowPrompts", {workflowPrompts})
    const workflowPromptIds = (workflowPrompts || []).map((p) => p.id)

    useEffect(() => {
        if (workflow === null) {
            document.title = 'PyroPrompts | Workflow'
        } else {
            document.title = `PyroPrompts | Workflow: ${workflow.name}`
        }
    }, [workflow?.id])

    const id = params.id;

    useEffect(() => {
        if (!id) {
            return
        }
        const getPromptsFn = account ? getPrompts : getPromptsLoggedOut;

        getWorkflow(id).then((res) => {
            setAttemptedLoad(true)
            console.log("workflow", {res})
            if (res.status === 200) {
                const workflow_data = res.data
                setWorkflow(workflow_data)
                if (workflow_data.project_id && projectSnippets === null) {
                    getProjectSnippets({project_id: workflow_data.project_id}).then((res) => {
                        if (res.status === 200) {
                            const response_data = res.data
                            setProjectSnippets(response_data.results || [])
                        }
                    })
                }
                const prompt_ids = (workflow_data.config.step_definition?.args.block_steps || []).map((s => s.block_step_type === 'execute_prompt' ? s.args.prompt_id : null)).filter((s => s !== null))
                const prompt_ids_needed = prompt_ids.filter((id) => id && !workflowPromptIds.includes(id))
                if (prompt_ids_needed.length > 0) {
                    getPromptsFn({id__in: prompt_ids_needed.join(',')}).then((res) => {
                        console.log("prompts", {prompt_ids, prompt_ids_needed, res})
                        if (res.status === 200) {
                            const prompts = res.data.results || []
                            setWorkflowPrompts([...(workflowPrompts ? workflowPrompts : []), ...prompts ])
                        }
                    })
                }
                
            } else {
                setWorkflow(null)

            }
        }).catch(() => {
            setWorkflow(null)
        })
        
    }, [id, reloads, account?.id])

    if (!id) {
        return <Typography.Paragraph>No ID</Typography.Paragraph>
    }

    if (id === undefined || workflow === undefined || workflow === null) {
        return (
        <div className="App">
            <div className="App-content">
            {attemptedLoad && (
                <Typography.Paragraph>Workflow Not Found. Maybe you need to log in.</Typography.Paragraph>
            )}
            {!attemptedLoad && (
                <Loading message='Workflow' description='Loading Workflow'  style={{marginTop: '12px'}}/>

            )}
            </div>
        </div>
        );
    }

    console.log("workflow", {workflow})

    const onEnable = () => {
        editWorkflow(workflow.id, {enabled: 1}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Enabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error(`Failure: ${res.data}`)
            }
        })
    };

    const onDisable = () => {
        editWorkflow(workflow.id, {enabled: 0}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Disabled!")
                setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };

    // const triggers = workflow.config.triggers.filter((trigger) => trigger.trigger_type == "form_submit")
    const triggers = workflow.config.triggers
    const trigger = triggers[0]

    const project_snippet_names = (projectSnippets || []).map((projectSnippet) => projectSnippet.name)

    const content = (
        <>
            <Row gutter={24} style={{marginTop: '12px'}}>
                <Col xs={24} md={8}>
                    <Card
                        title={workflow.project ? 'Project / Workflow' : 'Workflow'}
                        style={{marginBottom: '12px'}}
                        extra={
                        <Space>
                            {workflow.enabled === 1 && (
                                <Tooltip placement='left' title="Click to Disable Workflow" key={"enable"}>
                                    <Button key="1" onClick={onDisable}>
                                        <CheckCircleOutlined /> Enabled
                                    </Button>
                                </Tooltip>
                            )}
                            {workflow.enabled !== 1 && (
                                <Tooltip placement='left' title="Enable Workflow" key={"disable"}>
                                    <Button key="1" type="primary" onClick={onEnable}>
                                        Enable
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>}

                    >
                        <Space direction='vertical' style={{width: '100%'}}>

                            <Typography.Title level={3} style={{marginTop: 0}}><BranchesOutlined />&nbsp; {workflow.name} <Tooltip placement="top" title="Rename">
                                <span style={{fontSize: "0.6em", cursor: 'pointer'}} onClick={() => {setEditMode(!editMode)}}><EditOutlined /></span>
                            </Tooltip></Typography.Title>
                            {editMode && (<>
                                <UpdateWorkflowForm workflow={workflow} onCancel={() => setEditMode(false)} onUpdate={(workflow) => {
                                    setEditMode(false)
                                    setWorkflow(workflow)
                                    // triggerReload()
                                }} />
                            </>)}
                            {workflow.project && (
                                <>
                                    <Card>
                                    <Typography.Title level={4} style={{marginTop: 0}}><Link to={`/projects/${workflow.project_id}/`}><BlockOutlined />&nbsp;{workflow.project.name}</Link></Typography.Title>
                                    {projectSnippets && (
                                        <>
                                            <Typography.Title level={5} style={{marginTop: 0}}>Snippets from Project</Typography.Title>
                                            <Space wrap>
                                                {projectSnippets.map((projectSnippet) => (
                                                    <Popover key={projectSnippet.id} content={snippetPopoverContent(projectSnippet)} mouseEnterDelay={0.8} placement='right'>
                                                        <Tag>{projectSnippet.name}</Tag>
                                                    </Popover>
                                                    
                                                ))}
                                            </Space>
                                        </>
                                        
                                    )}
                                    </Card>
                                </>
                                
                            )}
                            <Space style={{fontSize:'0.85em' }}><span>Private to just you</span><Divider type='vertical'/><span>Created {DateTime.fromISO(workflow.created_date).toRelative()}</span></Space>

                            <Divider style={{marginTop: 0}} />
                            <WorkflowPreview workflow={workflow} prompts={workflowPrompts || []} />
                            {/* <WorkflowDiagram workflow={workflow} prompts={workflowPrompts || []} /> */}
                      </Space>
                    </Card>
                </Col>
                <Col xs={24} md={16}>
                    <Card>
                    <Tabs
                        defaultActiveKey={workflow.enabled ? 'activity' : 'config'}
                        // onChange={onChange}
                        items={[
                            {
                                label: `Activity`,
                                key: 'activity',
                                children: <WorkflowActivityTab workflow={workflow} />,
                            },
                            // {
                            //     label: `Resources`,
                            //     key: 'resources',
                            //     children: <WorkflowResourcesTab workflow={workflow}  />,
                            // },
                            {
                                label: `Configuration`,
                                key: 'config',
                                // children: workflowConfiguration(),
                                children: <WorkflowConfigurationTab workflow={workflow} workflowPrompts={workflowPrompts || []} projectSnippets={projectSnippets || []} onSave={() => setReloads(reloads + 1)} />,
                            },
                        // {
                        //     label: `Dependencies`,
                        //     key: 'dependencies',
                        //     children: "Dependencies wip...",
                        // },
                        ]}
                    />
                    </Card>
                </Col>
            </Row>
        </>
    )



    return (
        <div className="App">
        <div className="App-content">
            <div className="App-page-content">
                {content}
            </div>
        </div>
        </div>
    );
}
