import { SVGAttributes, ReactElement } from 'react'
// https://svg2jsx.com/

type Props = SVGAttributes<SVGElement>

function BearIcon(props: Props): ReactElement {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      version="1.1"
      viewBox="0 0 40 40"
      {...props}
    >
      <image
        width="40"
        height="40"
        x="0"
        y="0"
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAB7ElEQVRYw+2YP2sUURTFzzn3vV2W zTJsI6iFlpZCRFs/gL2VYG9hYyFY+Qm0sVQRC9uAhX4E0UgEGxfFBEQMuCGQVZPsZubaTGRdEjWa 2X3IHLjMH+YxP+67/3hArVq1atX6J4UQzocQ7scYG5X8oNPp8G/XmtklADlJl3StCj6S/AjgBcm3 7r4saS3P8/cA0Gg0tobD4eu9Fkq6DOBeURQsYVe73e7Jfr+/fdiQvp+R/Om6ez/+PP6O5IUqvOiH YSTdzB4nCwjAJeUhhLMxRiUJOAa6EkK4CeBIqoBeZvammT2QdDwpwEnYEMJajPFUkoClJ93MHiUJ OFGyjh4UTpiSSELSU0k3JJ1I0oMTtkTyuqROEoC/gF6PMV7c1/PlxzMRSbg7JHlRFJppDO65df7D N4tJbrGZuaTczM4lG4NmdjXJJCkL+N0kyszkDFnCPcyy7Lc5UHkWk9wBkLv7oqQPJHvu/oTkyzzP dyoDlDSeiZsAXklacvfP7v4uhLAxGo16AD4B2Jj6NCNp28xum9l85eXogIG9JWkhxnhmavXyD/rn N5LPJV1pNpvHptptdmOQJEiuA1hw91WSX9z9jZmttFqt3mAw+DqzjlNOvXfa7fZcckcXJdytZM9W zOxZlmVMFlDS6foI7H/Wd/JNCqvYWd/yAAAAAElFTkSuQmCC"
      ></image>
      <path
        fill="#000"
        strokeWidth="1.093"
        d="M0 24.043V8.086l7.044.476c5.54.374 7.432.055 8.865-1.494 1.714-1.854 1.893-1.761 3.05 1.577.74 2.134 2.392 4.03 4.149 4.762 1.605.668 3.192 2.067 3.526 3.109.335 1.041 2.129 2.652 3.987 3.58 3.857 1.925 4.012 2.55 1.568 6.343-1.339 2.079-3.343 3.01-8.278 3.845-3.582.606-8.162 1.926-10.18 2.932-2.016 1.007-4.746 1.831-6.064 1.831-1.319 0-3.583 1.115-5.032 2.477L0 40z"
      ></path>
    </svg>
    );
}

export default BearIcon
