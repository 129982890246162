import { Alert, Button, ConfigProvider, Flex, Form, Input, theme, Typography } from 'antd';
import { useEffect, useState } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import ReactGA from "react-ga4";
import { DateTime } from 'luxon';

import { useAppSelector } from '../app/hooks';
import type {CheckNewsletterResponseType} from '../types/types';
import { selectLight } from '../features/lightDarkSwitch/lightDarkSwitchSlice';
import { selectAccount } from '../features/login/loginSlice';
import { submitNewsletterEmail, checkNewsletterSubscription } from '../services/signalApi';
import { getThemeConfig } from './../themeRegistry';
import { useQuery } from '@tanstack/react-query'



interface NewsLetterBarParams {
    sign_up_button_text?: string
}


const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export function NewsLetterBar({
    sign_up_button_text='Subscribe'
}: NewsLetterBarParams) {
    const account = useAppSelector(selectAccount)
    const light = useAppSelector(selectLight)

    // const [isLoading, setIsLoading] = useState(false);
    const [enteredEmail, setEnteredEmail] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [successState, setSuccessState] = useState<string | null>(null);
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<string | null>(null);
    // const selectedTheme = useAppSelector(selectTheme);

    useEffect(() => {
        const newsletter_submitted = localStorage.getItem("NEWSLETTER_SUBMITTED")
        if (newsletter_submitted === 'true') {
            setIsClosed(true)
            return
        }

        const closed_newsletter_date = localStorage.getItem("CLOSED_NEWSLETTER_DATE")
        const dt_now = DateTime.now();
        // two weeks
        const threshold = {days: 3}
        // const threshold = {minutes: 1}
        if (closed_newsletter_date) {
            const dt_closed = DateTime.fromISO(closed_newsletter_date);
            if (dt_closed.plus(threshold) > dt_now) {
                setIsClosed(true)
            }
        }
        
    }, [])

    const {
        data: checkNewsletterResponse,
        
    } = useQuery({
        queryKey: ['newsletter_subscription_check', account?.id],
        staleTime: 1000 * 60 * 15,
        queryFn: () => {
            if (account && account.id) {
                return checkNewsletterSubscription().then((res) => {
                    const data: CheckNewsletterResponseType = res.data
                    return data
                })
            }
            return {email: null, submitted: false, verified: false, unsubscribed: false, email_problem: false }
        }
    })
    // console.log("NewsLetterBar", {checkNewsletterResponse})



    const onClose = () => {
        const dt_now = DateTime.now();
        localStorage.setItem("CLOSED_NEWSLETTER_DATE", dt_now.toISO())
        setIsClosed(true)
    }


    const submitEmail = () => {
        // console.log("submitEmail", {enteredEmail})
        if (successState) {
            // console.log("successful skip", {enteredEmail})
            return;
        }
        // setSuccessState(null)
        if (enteredEmail === null || enteredEmail === "") {
            setErrorState("Enter an email")
            return;
        } else if (errorState) {
            setErrorState(null)
        }

        const serchfind = regexp.test(enteredEmail);
        // console.log("serchfind", {serchfind})
        if (!serchfind) {
            setErrorState("Enter a valid email")
            return
        } else if (errorState) {
            setErrorState(null)
        }
        if (enteredEmail) {
            setIsSubmitting(true)
            submitNewsletterEmail(enteredEmail).then((res) => {
                setSuccessState("Check your email to confirm!")
                if (res.status === 200) {
                    ReactGA.event('generate_lead', {currency: 'USD', value: 1.00});
                    localStorage.setItem("NEWSLETTER_SUBMITTED", "true")
                } else {
                    setErrorState("Something went wrong")
                }
                
            }).catch((e) => {
                console.log(e)
                setErrorState("Something went wrong")
                ReactGA.event("error", {location: "newsletter_register"})
            }).finally(() => {
                setIsSubmitting(false)

            })
        }
    }

    if (isClosed || (account && checkNewsletterResponse?.submitted === true)) {
        return <></>
    }
    
    const configProviderConfig = {
        algorithm: light ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
        //   ...sizeSpaceConfig,
        //   ...commonColorConfig,
        //   ...fontConfig,
            ...getThemeConfig(light ? 'pyro-dark' : 'pyro-light'),
        }
    }

    const backgroundColor = light ? '#222' : '#eee'
    const color = light ? '#eee' : '#222'


    return (
        <>
            <ConfigProvider
                theme={configProviderConfig}
            >
                <div>
                    <div style={{padding: '8px', backgroundColor, width: '100%'}}>
                        <Flex wrap={'wrap'} justify='center' gap={8} align='center'>
                            {/* <Space direction='horizontal' style={{margin: '0 auto'}} wrap align='center'> */}
                            <div>
                                <Typography.Text style={{ color }}>Get automation credits, workflow templates and AI inspiration in our free weekly Newsletter.</Typography.Text>
                            </div>
                            <div>

                                <Form
                                    onFinish={submitEmail}
                                >
                                    <Space.Compact  size='middle'>
                                        <Input placeholder="email address" onChange={(e) => setEnteredEmail(e.target.value)} style={{minWidth: '160px', backgroundColor }} />
                                        <Button type="primary" htmlType="submit" aria-disabled={isSubmitting || successState !== null}>{isSubmitting ? <><LoadingOutlined spin />&nbsp;Registering</> : <>{sign_up_button_text}</>}</Button>
                                    </Space.Compact>
                    
                                </Form>
                                {/* </Space> */}

                            </div>
                            <div>
                                <CloseOutlined style={{color}} onClick={onClose}/>
                            </div>

                        </Flex>
                        {errorState && (
                            <Flex wrap={'wrap'} justify='center' gap={8} align='center' style={{marginTop: '8px'}}>
                                <Alert type='error' message={errorState} />

                            </Flex>
                        )}
                        {successState && (
                            <Flex wrap={'wrap'} justify='center' gap={8} align='center'  style={{marginTop: '8px'}}>
                                                
                                <Alert type='success' message={successState} />


                            </Flex>

                        )}
       
                    </div>
                </div>
            </ConfigProvider>

        </>
    )
    
    
    
}
