import { Button, Card, Col, Empty, Input, Row } from 'antd';
import { useRef, useState } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ReloadOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Link } from "react-router-dom";

import { DateTime } from 'luxon';

import { useSearchParams } from "react-router-dom";
import { NamedWorkflow } from '../types/types';
// import styles from './WorkflowList.module.css';
import { Loading } from './Loading';
import { NamedWorkflowModal } from './NamedWorkflowModal';

import type { InputRef } from 'antd';

import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getNamedWorkflows, getNamedWorkflowsLoggedOut } from '../services/signalApi';
import { COMMON_EMPTY_RESPONSE } from '../utils';



interface NamedWorkflowListParams {
    promoted?: boolean
    style?: 'cards' | 'list'
    showButtons?: boolean
    showLink?: boolean
}

const imgs = [
    // "feedback-520x200.png",
    // "focus-group-green-520x200.png",
    // "loom-1-520x200.png",
    // "clock-creation-1-520x200.png",
    "bow-1-520x200.png",
    "bow-2-520x200.png",
    "bow-3-520x200.png",
    "clock-creation-7-520x200.png",
    "clock-creation-8-520x200.png",
    "compass-1-520x200.png",
    // "feedback-520x200.png",
    "loom-2-520x200.png",
    "printing-press-1-520x200.png",
    "rifle-1-520x200.png",
    // "santa-520x200.png",
    "telegram-1-520x200.png",
    "telegram-2-520x200.png",
    "telegram-3-520x200.png",
    "telescope-1-520x200.png",
    "telescope-3-520x200.png",
]

export function NamedWorkflowList({
    promoted=false,
    style='cards',
    showButtons=true,
    showLink=true,
}: NamedWorkflowListParams) {
    console.log("NamedWorkflowList")
    const account = useAppSelector(selectAccount)

    const [searchedWorkflows, setSearchedWorkflows] = useState<NamedWorkflow[] | null>(null);
    const [shownWorkflow, setShownWorkflow] = useState<NamedWorkflow | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const inputRef = useRef<InputRef>(null);

    const {
        data: { data: { count: workflow_count, results: workflows}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchNamedWorkflows,
        isFetching: isFetchingNamedWorkflows,
    } = useQuery({
        queryKey: ['named_workflows', promoted, account?.id || ''],
        staleTime: 1000 * 60 * 15,
        queryFn: () => {
            const getWorkflowsFn = account ? getNamedWorkflows : getNamedWorkflowsLoggedOut;
            return getWorkflowsFn({
                ordering: 'rank',
                ...(promoted ? {promoted: 1} : {})
            })
        }
    })
    console.log("namedWorkflows", {workflow_count, workflows})

    const showWorkflow = (workflow: NamedWorkflow) => {
        setShownWorkflow(workflow)
    }


    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        console.log("onSearch", {value})
        if (value && workflows) {
            const lcValue = value.toLowerCase()
            setSearchedWorkflows(workflows.filter((w) => (
                w.name.toLowerCase().includes(lcValue) ||
                w.workflow_form.form_title?.toLowerCase().includes(lcValue) ||
                w.workflow_form.form_description?.toLowerCase().includes(lcValue)
            )))
        } else {
            setSearchedWorkflows(null)
        }
    }

    const content_cards = () =>{
        if (isFetchingNamedWorkflows) {
            return <Loading message="Workflows" description="Loading Workflows" />
        }
        const useWorkflows = searchedWorkflows !== null ? searchedWorkflows : workflows
        if (useWorkflows?.length === 0) {
            return <Empty description={"No Workflows Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        return <Row
            style={{marginTop: '24px'}}
            gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]}
            // justify="space-around"
            justify="start"
            align="top"
        >
            {useWorkflows?.map((workflow: NamedWorkflow, i: number) => (
                <Col sm={24} md={12} lg={12} xl={8} xs={24} key={workflow.name}>
                    <Card
                        bordered={false}
                        style={{
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            // boxShadow: '0 4px 8px 0 rgba(180, 180, 180, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            borderRadius: '6px',
                        }}
                        cover={
                            <img
                                alt="example"
                                src={`https://assets.pyroprompts.com/img/${workflow.img_path ? workflow.img_path : imgs[i]}`}
                            />
                        }
                        actions={[
                            // <span>Run</span>,
                            ...(showLink ? [<Link key={'seemorelink'} to={`/w/${workflow.name}`}>See more</Link>] : []),
                        ]}
                        // actions={[
                        //     <span>Run</span>,
                        //     <span>Learn More</span>,
                        // ]}
                    >
                        {/* <Typography.Title level={4} style={{marginTop: 0}}>{workflow.workflow_form.form_title || workflow.name}</Typography.Title> */}
                        {/* <Typography.Text>{workflow.workflow_form.form_description || '-'}</Typography.Text> */}
                        <Card.Meta
                            title={<a style={{color: 'unset'}} onClick={() => showWorkflow(workflow)}>{workflow.workflow_form.form_title || workflow.name}</a>   }
                            description={workflow.workflow_form.form_description || '-'}
                            // avatar={<Avatar icon={<BullIcon />} size={'large'} />}
                        />
                        {/* <Space direction='horizontal' wrap style={{marginTop: '12px'}}>
                            <Tag color="magenta">Pro</Tag>
                            <Tag color="blue">Business</Tag>
                            <Tag color="red">Free</Tag>
                        </Space> */}
                        {/* <Typography.Text>{workflow.workflow_form.form_description || '-'}</Typography.Text> */}
                    </Card>

                </Col>
            ))}

        </Row>
    }
    const content_list = () =>{
        if (isFetchingNamedWorkflows) {
            return <Loading message="Workflows" description="Loading Workflows" />
        }
        const useWorkflows = searchedWorkflows !== null ? searchedWorkflows : workflows
        if (useWorkflows?.length === 0) {
            return <Empty description={"No Workflows Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        return <Row
            style={{marginTop: '24px'}}
            gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, { xs: 12, sm: 16, md: 18, lg: 18 }]}
            // justify="space-around"
            justify="start"
            align="top"
        >
            {useWorkflows?.map((workflow: NamedWorkflow, i: number) => (
                <Col sm={24} md={24} lg={24} xl={24} xs={24} key={workflow.name}>
                    <Card
                        onClick={() => showWorkflow(workflow)}
                        bordered={false}
                        style={{
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            // boxShadow: '0 4px 8px 0 rgba(180, 180, 180, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            borderRadius: '6px',
                            cursor: 'pointer'
                        }}
                        // cover={
                        //     <img
                        //       alt="example"
                        //       src={`https://assets.pyroprompts.com/img/${workflow.img_path ? workflow.img_path : imgs[i]}`}
                        //     />
                        // }
                        // actions={[
                        //     <span>Run</span>,
                        //     <span>Learn More</span>,
                        // ]}
                    >
                        {/* <Typography.Title level={4} style={{marginTop: 0}}>{workflow.workflow_form.form_title || workflow.name}</Typography.Title> */}
                        {/* <Typography.Text>{workflow.workflow_form.form_description || '-'}</Typography.Text> */}
                        <Card.Meta
                            title={workflow.workflow_form.form_title || workflow.name}
                            description={workflow.workflow_form.form_description || '-'}
                            // avatar={<Avatar icon={<BullIcon />} size={'large'} />}
                        />
                        {/* <Space direction='horizontal' wrap style={{marginTop: '12px'}}>
                            <Tag color="magenta">Pro</Tag>
                            <Tag color="blue">Business</Tag>
                            <Tag color="red">Free</Tag>
                        </Space> */}
                        {/* <Typography.Text>{workflow.workflow_form.form_description || '-'}</Typography.Text> */}
                    </Card>

                </Col>
            ))}

        </Row>
    }
    const triggerReload = () => {
        const dt = DateTime.now();
        refetchNamedWorkflows();
    }

    const leftButtons = (
        <Space style={{marginBottom: '6px'}} wrap>
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
            <Input.Search
                placeholder="search..."
                allowClear
                enterButton
                onChange={onSearchChange}
                ref={inputRef}
            />
            {/* <DefinedWorkflowButton workflowName={'request-workflow'} buttonText='Request a Workflow' buttonType='text' /> */}
        </Space>
    )

    return (
        <>
            {showButtons && leftButtons}
            {style === "cards" && content_cards()}
            {style === "list" && content_list()}
            <NamedWorkflowModal
                isOpen={shownWorkflow !== null}
                onCancel={() => {
                    searchParams.delete("workflow_execution_id")
                    setSearchParams(searchParams)
                    setShownWorkflow(null)
                }}
                onComplete={() => {}}
                // onCreate={handleCreateWorkflow}
                namedWorkflow={shownWorkflow ? shownWorkflow : undefined}
            />
            {/* {content_list()} */}

        </>
    )



}
