// A mock function to mimic making an async request for data
import {Integration, IntegrationType} from '../../types/types'
import {getApiIntegrations, getIntegrations} from '../../services/signalApi'

export interface Application {
    client_id: string
    name: string
}

export interface ApiIntegration {
    application_id: string
    application: Application
}

export interface ApiIntegrationListResponse {
    results: ApiIntegration[]
}

// export type IntegrationType = "oauth2_api_token" | "simple_api_token"

// export interface Integration {
//     id: string
//     name: string
//     account_id: string
//     created_date: string
//     type: IntegrationType

// }

export interface IntegrationListResponse {
    results: Integration[]
}
 

export function apiIntegrationList() {
    return new Promise<{ data: ApiIntegrationListResponse }>((resolve) => {

        getApiIntegrations().then((response) => {
            // handle success
            console.log("api_integrations", {response});
            resolve({ data: {results: response.data.results} })

          }).catch(function (error) {
            // handle error
            console.log(error);
            resolve({ data: {results: []} })
          })
          .then(function () {
            // resolve({ data: {results: []} })
          });
    }
    );
}


export function integrationList() {
    return new Promise<{ data: IntegrationListResponse }>((resolve) => {

        getIntegrations().then((response) => {
            // handle success
            console.log("api_integrations", {response});
            resolve({ data: {results: response.data.results} })

          }).catch(function (error) {
            // handle error
            console.log(error);
            resolve({ data: {results: []} })
          })
          .then(function () {
            // resolve({ data: {results: []} })
          });
    }
    );
}
