import { FileOutlined, InboxOutlined } from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { Alert, Button, Modal, Space, Typography, Upload, message } from 'antd';
import { useState } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { uploadSignedFileToS3 } from '../services/s3';
import { assetsGetWriteUrl, assetsUpdateDataSetSource } from '../services/signalApi';
import { DataSet } from '../types/types';

import { useAppSelector } from '../app/hooks';
const { Dragger } = Upload;




interface UploadDataSetFileModalParams {
    isOpen: boolean
    dataset: DataSet
    onCancel: () => void
    onCreate: () => void
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

// const getBase64v2 = (img: FileType, callback: (url: string) => void) => {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result as string));
//     reader.readAsDataURL(img);
// };


export function UploadDataSetFileModal({
    isOpen,
    dataset,
    onCancel,
    onCreate,
}: UploadDataSetFileModalParams) {

    const account = useAppSelector(selectAccount);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedFileSuccess, setUploadedFileSuccess] = useState(false);
    const [assetUrl, setAssetUrl] = useState<string | null>(null);

    // const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [uploadedFile, setUploadedFile] = useState<FileType | null>(null);


    console.log('UploadDataSetFileModal', {dataset})

    const handleCancel = () => {
        setIsUploading(false)
        setUploadedFileSuccess(false)
        setAssetUrl(null)
        onCancel()
    }

    // const onCreateDataSetRecordFailed = (errorInfo: any) => {
    //     console.log('Failed:', errorInfo);
    // };

    // const onCreateDataSetRecordSubmit = (values: {key: string, content: string}) => {
    //     console.log('Success:', {values, account});
    //     onCreateDataSetRecord(values)
    // };

    const deleteFile = () => {
        setUploadedFile(null)
    }

    const uploadFile = () => {
        console.log("uploading")
        if (uploadedFile && assetUrl && account) {
            setIsUploading(true)
            uploadSignedFileToS3(assetUrl, uploadedFile, uploadedFile.type).then((res) => {
                console.log("uploadSignedFileToS3", {res})
                if (res.status === 200) {
                    return assetsUpdateDataSetSource(account.id, dataset.id, assetUrl).then((res) => {
                        console.log("assetsUpdateProfilePic", {res})
                        setUploadedFileSuccess(true)
                        setUploadedFile(null)
                        setIsUploading(false)
                        onCreate()
                    })
                    // notify api updateProfilePic endpoint, with the url to it and it post-processes, copies and updates the account object
                    // return assetsUpdateProfilePic(existingBio?.account_id, assetUrl).then((res) => {
                    //     console.log("assetsUpdateProfilePic", {res})
                    // })
                } else {
                    message.error("An error occurred when uploading your new profile pic.")
                }
            }).finally(() => {
                setIsUploading(false)
            })
        }
    }

    // const onCreateDataSetRecord = (values: {key: string, content: string}) => {
    //     if (account) {
    //         console.log("onCreateDataSetRecord values", {values})
    //         setIsCreating(true)
    //         setSubmissionError(null)
    //         createDataSetRecord(dataset.id, values.key, values.content).then((res) => {
    //             console.log("created", {res})
    //             if (res.status === 201 ) {
    //                 const response_data = res.data
    //                 setIsCreating(false)

    //             } else if (res.status === 400) {
    //                 const response_data = res.data
    //                 setSubmissionError(response_data)
    //             } else {
    //                 console.log("failed", {res})
    //             }
    //             // const data_set_id = response_data.id
    //             // const data_set_name = response_data.name
    //             // setNewDataSet({id: data_set_id, name: data_set_name})
    //         }
    //         ).catch((res) => {
    //             console.log("failed catch", {res})

    //         }).finally(() => {
    //             setIsCreating(false)
    //             onCreate()
    //         })
    //     }
    // }

    const isFileCsv = (file: FileType) => file.type === 'text/csv';
    const isFileSqlite = (file: FileType) => file.type === '' && (file.name.endsWith('.sqlite3') || file.name.endsWith('.db'));

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        action: (file: FileType) => "false",
        // action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        beforeUpload(file: FileType) {
            console.log("beforeUpload", {file})
            // do some checks here
            let hasValidType = false;
            const isCsv = isFileCsv(file);
            // name ends with .sqlite3 or .db extension
            const isSqlite = isFileSqlite(file)
            if (isCsv) {
                hasValidType = true;
            }
            if (isSqlite) {
                hasValidType = true;
            }
            if (!hasValidType) {
                message.error('You can only upload a CSV, JSON or Sqlite Database Files');
            }
            if (hasValidType && assetUrl === null && account) {
                assetsGetWriteUrl(account.id, file.name, file.type).then((res: any) => {
                    console.log("assetsGetWriteUrl", {res})
                    if (res.status === 200) {
                        const url = res.data.url
                        if (url) {
                            setAssetUrl(url)
                        }
                    }
                })
            }
            // // do some checks here
            // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            // if (!isJpgOrPng) {
            //     message.error('You can only upload a JPG or PNG Image.');
            // }
            // const isLt2M = file.size / 1024 / 1024 < 2;
            // if (!isLt2M) {
            //     message.error('Image must smaller than 2MB.');
            // }

            // if (!isJpgOrPng || !isLt2M) {
            //     return false;
            // }

            // setLoading(true);
            if (hasValidType) {
                setUploadedFile(file)
            }
            // getBase64v2(file, (url) => {
            //     console.log("url", {url})
            //     setLoading(false);
            //     setImageUrl(url);
            //     // get the upload url from api
            // });
            // getFileText(file, (text) => {
            //     console.log("text", {text})
            //     // const records = text.split("\n");
            //     // console.log("records", {records})
            //     const records = parseCSV(text)
            //     console.log("records", {records})
            //     setCsvRecords(records)

            // });

            return false;
        },
        onChange(info) {
            console.log("onChange", {info})

            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log("done")
            // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log("error")

            // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        // showUploadList: false
        // action={() => {}}
        // beforeUpload={beforeUpload}
        // onChange={handleChange}
    };

    // const isCsv = file.type === 'text/csv';
    // // name ends with .sqlite3 or .db extension
    // const isSqlite = isFileSqlite(file)

    const uploadFileFileType = uploadedFile ? (isFileCsv(uploadedFile) ? 'csv' : (isFileSqlite(uploadedFile) ? 'sqlite' : null)) : null;

    const recordFormBody = (
        <>


            {(isUploading && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            <Typography.Paragraph>
                Creation of Multiple Data Set Records.
            </Typography.Paragraph>

            <Typography.Paragraph>
            Data Set: {dataset.name} ({dataset.id})
            </Typography.Paragraph>

            {uploadedFileSuccess && (
                <div>
                    <Alert type='success' message='File uploaded successfully!' />
                    <Space style={{justifyContent: "space-between", display: "flex", marginTop: '12px'}}>
                        <span></span>
                        <Space direction='horizontal'>
                            <Button type='primary' onClick={handleCancel}>Okay!</Button>
                        </Space>
                    </Space>

                </div>
            )}

            {uploadedFile && (
                <div>
                    <p>File Staged!</p>
                    <Alert type='info' message={<p><FileOutlined />  {uploadedFile.name} ({uploadFileFileType})</p>} />
                    <Space style={{justifyContent: "space-between", display: "flex", marginTop: '12px'}}>
                        <span></span>
                        <Space direction='horizontal'>
                            <Button onClick={deleteFile}>Delete</Button>
                            <Button type='primary' onClick={uploadFile}>Upload</Button>
                        </Space>
                    </Space>

                </div>
            )}

            {!uploadedFileSuccess && !uploadedFile && (
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                    <p className="ant-upload-hint">
                    Upload a CSV of your data set with the columns key, content and 1-4 additional columns for categories.
                    </p>
                </Dragger>
            )}




        </>
    )

    return (
        <Modal title="Upload Data Set" open={isOpen} footer={null} onCancel={handleCancel}>
            {recordFormBody}
        </Modal>
    )


}
