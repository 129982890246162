import { Button } from 'antd';
import { useSearchParams } from "react-router-dom";
import { useAppSelector,  } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { Link } from "react-router-dom";


interface SignUpButtonParams {
    text?: string
    action?: string
    icon?: React.ReactNode
    style?: React.CSSProperties
}

export function SignUpButton({
  text="Sign Up",
  action='signup',
  icon,
  style={},
}: SignUpButtonParams){
    const [searchParams, setSearchParams] = useSearchParams();
    const account = useAppSelector(selectAccount);

    if (account) {
        return <Link to={`/store`}>
            <Button type='primary' size='large' icon={icon ? icon : undefined} style={{...style}}>
        {text}
    </Button>
        </Link>
    }

    return <Button type='primary' size='large' icon={icon ? icon : undefined} style={{...style}} onClick={() => {
        searchParams.set("auth", action)
        setSearchParams(searchParams)
    }}>
        {text}
    </Button>



}
