import {Modal, Select } from 'antd';

import { NamedWorkflowRunner } from './NamedWorkflowRunner';
import { NamedWorkflow as NamedWorkflowType } from '../types/types'

// import {Prompt as PromptType} from '../types/types'

import { useIsSmallScreen, useIsBigScreen } from '../app/hooks';
const { Option, OptGroup } = Select;


interface NamedWorkflowModalParams {
    namedWorkflow: NamedWorkflowType | undefined
    isOpen: boolean
    onCancel: () => void
    onComplete: () => void
}


export function NamedWorkflowModal({
    namedWorkflow,
    isOpen,
    onCancel,
    onComplete,
}: NamedWorkflowModalParams) {
    const isBigScreen = useIsBigScreen()
    // const isSmallScreen = useIsSmallScreen()

    const handleCancel = () => {
        onCancel()
    }


    return (
        <Modal open={isOpen} footer={null} onCancel={handleCancel} width={!isBigScreen ? '100%': '48%'}>
            <NamedWorkflowRunner namedWorkflow={namedWorkflow} onComplete={onComplete} includePageLink={true} />
        </Modal>
    )

    
}
