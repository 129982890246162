import { Modal } from 'antd';

import { Workflow } from '../types/types';
import { CreateWorkflowForm } from './CreateWorkflowForm'


interface WorkflowModalParams {
    isOpen: boolean
    project_id?: string
    onCancel: () => void
    onCreate: () => void
    workflowCopySource?: Workflow
}


export function CreateWorkflowModal({
    isOpen,
    project_id,
    onCancel,
    onCreate,
    workflowCopySource,
}: WorkflowModalParams) {

    return (
            <Modal title={ workflowCopySource ? "Copy Workflow" : project_id ? "Create Workflow in Project" : "Create Workflow" } open={isOpen} footer={null} onCancel={onCancel}>
                <CreateWorkflowForm project_id={project_id} onCancel={onCancel} onCreate={onCreate} workflowCopySource={workflowCopySource} />
            </Modal>
    )

    
}
