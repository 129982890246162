import type { InputRef } from 'antd';
import { Button, Empty, Form, Input, Modal, Space, Typography } from 'antd';
import { Link } from "react-router-dom";

import { SearchOutlined } from '@ant-design/icons';
import { getDataSets, getPrompts, getPromptsLoggedOut } from '../services/signalApi';
import { DataSet as DataSetType, Prompt as PromptType } from '../types/types';
import { Loading } from './Loading';
import { PromptList } from './PromptStream';
import { useAppSelector, useIsSmallScreen } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';

import { useRef, useState } from 'react';
const { Search } = Input;
interface PromptSearchSelectParams {
    includeTitle? : boolean
    isSearchModalOpen?: boolean
    onSelect? : (prompt: PromptType) => void
    onClose? : () => void
}

export function PromptSearchSelect({
    includeTitle=false,
    isSearchModalOpen=false,
    onSelect,
    onClose,
}: PromptSearchSelectParams) {

    // const [apiStatus, setApiStatus] = useState<ApiStatus | null>(null);
    const inputRef = useRef<InputRef>(null);
    const account = useAppSelector(selectAccount)
    const isSmallScreen = useIsSmallScreen()

    // const [form] = Form.useForm();
    // const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // const [dataSetResults, setDataSetResults] = useState<DataSetType[] | null>(null);
    const [promptResults, setPromptResults] = useState<PromptType[] | null>(null);

    // const showSearch = () => {
    //     setIsSearchModalOpen(true)
    //     setTimeout(() => {
    //         inputRef.current!.focus({
    //             cursor: 'all',
    //           });
    //     }, 200)

    // }

    // const closeSearch = () => {
    //     setIsSearchModalOpen(false)
    // }

    // const onSelection = () => {
    //     setIsSearchModalOpen(false)
    //     if (onSelect) {
    //         onSelect()
    //     }
    // }

    // const onSearchSubmitFailed = (errorInfo: any) => {
    //     console.log('Failed:', errorInfo);
    // };

    const onSearchSubmit = (searchValue: string) => {
        console.log('Success:', {searchValue});
        if (searchValue === "") {
            console.log("clearing results")
            // setDataSetResults(null)
            setPromptResults(null)
        } else {
            console.log("searching results...")
            setLoading(true)
            const getPromptsFn = account ? getPrompts : getPromptsLoggedOut;

            getPromptsFn({content__icontains: searchValue}).then((response) => {
                // handle success
                if (response.status === 200) {
                    const response_data = response.data
                    if (response_data.results) {
                        setPromptResults(response_data.results)
                    }

                }
    
              }).catch(function (error) {
                // handle error
                console.log(error);
                setPromptResults([])
              })
              .then(function () {
                // resolve({ data: {results: []} })
                setLoading(false)
              });
        }
        // onCreateDashboard(values)
    };

    return <>
        <Modal title={ "Prompt Search" } open={isSearchModalOpen} footer={null} onCancel={onClose} width={isSmallScreen ? '100%': '700px'}>
            <Space direction='vertical' style={{width: '100%'}}>
                <Search
                    placeholder="Prompt Content..."
                    allowClear
                    enterButton
                    size="large"
                    onSearch={onSearchSubmit}
                    ref={inputRef}
                />
                {loading && (
                    <Loading message='Searching...' />

                )}
                {(promptResults && promptResults.length === 0) && (
                    <Empty description={"No Results Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {promptResults && promptResults.length > 0 && (
                    <div>
                        <PromptList
                            prompts={promptResults || []}
                            limit={promptResults.length}
                            promptsCount={promptResults.length}
                            onLoadMore={() => {}}
                            isLoadingMore={false}
                            includeActions={false}
                            onSelect={(prompt) => onSelect ? onSelect(prompt) : null}
                        />
                        {/* <Typography.Title level={4}>Prompts</Typography.Title>
                        <ul>
                            {promptResults.map((prompt) => {
                                return <li key={prompt.id}><a onClick={() => onSelect ? onSelect(prompt) : null}>{prompt.content}</a></li>
                            })}
                        </ul> */}
                    </div>
                )}

            </Space>
            
            
        </Modal>
    </>

}
