import { EyeInvisibleOutlined, GlobalOutlined } from '@ant-design/icons';
import { Card, Col, Empty, Row, Space, Tag, Tooltip, Typography } from 'antd';
// import { DataProviderList } from '../../features/dataProviderList/DataProviderList'
// import { DataSetList } from '../../features/dataSetList/DataSetList';
// import { ApiIntegrationsList } from '../../features/apiIntegrationsList/ApiIntegrationsList';
import { Link } from "react-router-dom";

import { DateTime } from 'luxon';
import { useDataSets } from '../../app/apiQueries';
import { useAppSelector } from '../../app/hooks';
import { Loading } from '../../components/Loading';
import { MarkdownContent } from '../../components/MarkdownContent';
import { selectAccount } from '../../features/login/loginSlice';
import type { DataSet as DataSetType } from '../../types/types';
import { getTagColor } from '../../utils';


const imgs = [
    // "feedback-520x200.png",
    // "focus-group-green-520x200.png",
    // "loom-1-520x200.png",
    // "clock-creation-1-520x200.png",
    "bow-1-520x200.png",
    "clock-creation-7-520x200.png",
    "compass-1-520x200.png",
    "printing-press-1-520x200.png",
    "bow-2-520x200.png",
    "bow-3-520x200.png",
    "clock-creation-8-520x200.png",
    // "feedback-520x200.png",
    "loom-2-520x200.png",
    "rifle-1-520x200.png",
    // "santa-520x200.png",
    "telegram-1-520x200.png",
    "telegram-2-520x200.png",
    "telegram-3-520x200.png",
    "telescope-1-520x200.png",
    "telescope-3-520x200.png",
]


export function DataSets() {

    const account = useAppSelector(selectAccount)


    const { data_sets: loadedDataSets, isFetchingDataSets} = useDataSets(account);

    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Data Sets</Typography.Title>
                <div className="App-page-content">
                    <Typography.Paragraph>
                      Proprietary, Licensed and Public Data Sets to use in your AI. Use these in your Workflows.
                    </Typography.Paragraph>
                    {isFetchingDataSets && (
                        <Loading message='Loading Data Sets' />
                    )}
                    {!isFetchingDataSets && loadedDataSets && loadedDataSets.length === 0 && (
                        <Empty description="No Data Sets" />
                    )}

                    {!isFetchingDataSets && loadedDataSets && loadedDataSets.length > 0 && (
                        <Row
                            style={{marginTop: '24px'}}
                            gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 }]}
                            // justify="space-around"
                            justify="start"
                            align="top"
                        >
                            {loadedDataSets?.map((dataSet: DataSetType, i: number) => (
                                <Col sm={24} md={12} lg={12} xl={8} xs={24} key={dataSet.id}>
                                    <Card
                                        // onClick={() => showWorkflow(workflow)}
                                        bordered={false}
                                        style={{
                                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                            // boxShadow: '0 4px 8px 0 rgba(180, 180, 180, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                            borderRadius: '6px',
                                            // cursor: 'pointer'
                                        }}
                                        cover={
                                            <img
                                                alt="example"
                                                src={`https://assets.pyroprompts.com/img/${ dataSet.img_path ? dataSet.img_path : imgs[i % imgs.length]}`}
                                            />
                                        }
                                        actions={[
                                            // <span>Run</span>,
                                            <Link key={'seemorelink'} to={`/data_sets/${dataSet.id}`}>See more</Link>,
                                        ]}
                                    >
                                        {/* <Typography.Title level={4} style={{marginTop: 0}}>{workflow.workflow_form.form_title || workflow.name}</Typography.Title> */}
                                        {/* <Typography.Text>{workflow.workflow_form.form_description || '-'}</Typography.Text> */}
                                        <Card.Meta
                                            title={<Link style={{color: 'unset'}} to={`/data_sets/${dataSet.id}`}>{dataSet.name}</Link>}
                                            description={
                                                <>
                                                    {dataSet.s3_source_url_updated_date && (
                                                        <Typography.Text style={{fontSize: '0.8rem', color: 'rgba(255, 255, 255, 0.45)'}}>Last Updated: {DateTime.fromISO(dataSet.s3_source_url_updated_date).toLocaleString(DateTime.DATETIME_SHORT)}</Typography.Text>
                                                    )}
                                                </>
                                              
                                            }
                                            // avatar={<Avatar icon={<BullIcon />} size={'large'} />}
                                        />
                                        <Space direction='vertical'>

                                            <Space.Compact direction='horizontal' style={{marginTop: '12px'}} size={'small'}>
                                                {dataSet.is_public === 1 && (
                                                    <Tooltip title="Public, everyone can see this.">
                                                        <Tag><GlobalOutlined /></Tag>
                                                    </Tooltip>
                                                )}
                                                {dataSet.is_public === 0 && (
                                                    <Tooltip title="Private, only visible to you and those you've shared with.">
                                                        <Tag><EyeInvisibleOutlined /></Tag>
                                                    </Tooltip>
                                                )}
                                                {dataSet.tags && (
                                                    <>
                                                        {dataSet.tags.split(",").map((tag, i) => (
                                                            <Tag key={i} color={getTagColor(tag)}>{tag.trim()}</Tag>
                                                        ))}
                                                    </>
                                                )}
                     
                                            </Space.Compact>
                                            {dataSet.description && (
                                                <MarkdownContent markdown={dataSet.description} />

                                            )}
                                        </Space>

                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}

            

                </div>
            </div>
        </div>
    );
}
