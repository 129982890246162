import {createElement} from 'react';
import { Space, Tooltip } from 'antd';



export function IconText({ icon, text, onClick, tooltip, disabled = false }: { icon: React.FC; text: string | undefined; onClick?: () => void, tooltip?: string, disabled?: boolean }) {
  const style: React.CSSProperties = {}
  if (onClick) {
    style['cursor'] = 'pointer'
  }
  if (disabled) {
    style['cursor'] = 'not-allowed'
  }
  const elem = <Space onClick={onClick && !disabled ? onClick : undefined} style={style} size={'small'}>
    {text ? (<>{createElement(icon)}{text}</>) : (createElement(icon))}
  </Space>
  return tooltip ? <Tooltip placement="top" title={tooltip} >{elem}</Tooltip> : elem
};
