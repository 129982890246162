import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsBigScreen, useIsSmallScreen, useIsXLargeScreen } from '../../app/hooks';

import { Typography, Row, Col, Card, Button } from 'antd';
import { MarkdownContent } from '../../components/MarkdownContent';
import { DefinedWorkflowRunner } from '../../components/DefinedWorkflowRunner';
// Code blocks? https://github.com/rajinwonderland/react-code-blocks
// const commonStyles = {padding: '0 50px 50px', maxWidth: "1200px"}
const commonStyles = {}
const fancyStyles = {fontSize: '0.9rem', fontFamily: 'Noto Serif, serif', ...commonStyles}
const regularStyles = {fontSize: '1.1rem', lineHeight: '1.8em', ...commonStyles}


function PageMarkdownContentWithSideCard({markdown, workflowName, additional_page_content_styles}: {markdown: string, workflowName: string, additional_page_content_styles?: React.CSSProperties}) {
    const isBigScreen = useIsBigScreen()

    const styles = additional_page_content_styles ? additional_page_content_styles : regularStyles

    return (
        <div className="App">
          <div className="App-content">
            <div className="App-page-content" style={styles}>
                <Row gutter={84} style={isBigScreen ? {marginTop: '56px'} : {}}>
                    <Col xs={24} md={14}>
                        <MarkdownContent markdown={markdown} />
                    </Col>
                    <Col xs={24} md={10}>
                        <Card
                            cover={
                                <img
                                  alt="example"
                                  src={`https://assets.pyroprompts.com/img/feedback-520x200.png`}
                                />
                            }
                        >
                            <DefinedWorkflowRunner workflowName={workflowName} onComplete={() => {}} />
                        </Card>
                    </Col>
                </Row>
                
            </div>
          </div>
        </div>
      );
}


function ProjectSuggestion() {
    const markdown = `## Project Suggestion


   `
    useEffect(() => {document.title = 'PyroPrompts | Project Suggestions'}, [])
    return <PageMarkdownContentWithSideCard markdown={markdown} workflowName={'project-suggestion'} />
}

function FocusGroup() {
    const markdown = `## Revolutionize Feedback with AI-Powered Focus Groups
    
- **Immediate Insights**: No more waiting. Get instant feedback tailored to your needs.
- **Cost-Efficient**: Say goodbye to expensive traditional focus groups. AI delivers at a fraction of the cost.
- **Accurate and Reliable**: Built on advanced algorithms, our focus groups reduce bias and provide consistent results.
- **Scale to Your Needs**: From small projects to large-scale surveys, our AI adapts effortlessly.
- **Innovative Approach**: Stay ahead of the competition by harnessing the power of cutting-edge AI technology.

Unlock unparalleled insights now. Dive into the future of focus groups today!
   `
    useEffect(() => {document.title = 'PyroPrompts | Focus Group'}, [])
    return <PageMarkdownContentWithSideCard markdown={markdown} workflowName={'focus-group'} />
}


export const workflow_landing_pages = {
    project_suggestion: ProjectSuggestion,
    focus_group: FocusGroup,
}
