import { Col, Row, Typography, Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { selectAccount } from '../../features/login/loginSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getOauthClient, oauthAuthorize } from '../../services/signalApi';
import { SignUpButton } from '../../components/SignUpButton';

const centeredTextStyling: React.CSSProperties = {
    textAlign: 'center'
}

export function OauthAuthorize() {
    const account = useAppSelector(selectAccount);

    const [searchParams, setSearchParams] = useSearchParams();
    const [clientName, setClientName] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [authorizationError, setAuthorizationError] = useState<boolean>(false)
    const [clientNotFound, setClientNotFound] = useState<boolean>(false)

    const response_type = searchParams.get("response_type")
    const client_id = searchParams.get("client_id")
    const redirect_uri = searchParams.get("redirect_uri")
    const scope = searchParams.get("scope")
    const state = searchParams.get("state")

    useEffect(() => {
        if (client_id) {
            setIsLoading(true)
            getOauthClient(client_id).then((res) => {
                if (res.status === 200) {
                    console.log("res", {res})
                    const data = res.data
                    if ('name' in data) {
                        setClientName(data.name)
                        setIsLoading(false)
                    }
                }
            }).catch(() => {
                setClientNotFound(true)
            })
        }
    }, [client_id])

    useEffect(() => {document.title = 'PyroPrompts | Oauth Authorization'}, [])

    const authorize = () => {
        console.log("authorize", {account_id: account?.id, response_type, client_id, redirect_uri, scope, state})
        if (account && !clientNotFound && clientName && response_type && client_id && redirect_uri && scope && state) {
            oauthAuthorize(account.id, response_type, client_id, redirect_uri, scope, state).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    const data = res.data
                    console.log(data)
                    if ("success_url" in data){
                        window.location.href = data.success_url;
                    }
                } else {
                    setAuthorizationError(true)
                }
            }).catch(() => {
                setAuthorizationError(true)
            })
        }
    }

    return (
        <div className="App">
            <div className="App-content">
                <div className="App-page-content">
                    <Row>
                        <Col span={24} style={centeredTextStyling} >
                            <Typography.Title level={1}>Oauth Authorization</Typography.Title>
                        </Col>
                    </Row>
                    {isLoading && (
                        <Row>
                            <Col span={24} style={centeredTextStyling} >
                                <Typography.Paragraph>Loading...</Typography.Paragraph>
                            </Col>
                        </Row>
                    )}
                    {authorizationError && (
                        <Row>
                            <Col span={24} style={centeredTextStyling} >
                                <Typography.Paragraph>There was an error Authorizing</Typography.Paragraph>
                            </Col>
                        </Row>
                    )}
                    {!isLoading && clientNotFound && (
                        <Row>
                            <Col span={24} style={centeredTextStyling} >
                                <Typography.Paragraph>Client Not Found</Typography.Paragraph>
                            </Col>
                        </Row>
                    )}
                    {!isLoading && !authorizationError && !clientNotFound && clientName && (
                        <Row>
                            <Col span={24} style={centeredTextStyling} >
                            <Typography.Paragraph>Authorize <Typography.Text strong>{clientName}</Typography.Text> to interact with PyroPrompts on your behalf?</Typography.Paragraph>
                            <Typography.Paragraph><Typography.Text strong>{clientName}</Typography.Text> will be able to Read and Manage Prompts, Read and Manage Projects & Workflows, and Execute Workflows</Typography.Paragraph>
                            </Col>
                        </Row>
                    )}
                    

                    {account && !authorizationError && !clientNotFound && clientName && response_type && client_id && redirect_uri && scope && state && (
                        <Row>
                        <Col xs={{offset: 0, span: 24}} sm={{offset: 2, span: 20}} md={{offset: 5, span: 14}} lg={{offset: 7, span: 10}} xl={{offset: 8, span: 8}} xxl={{offset: 8, span: 8}} >
                            <Button type="primary" size='large' style={{width: '100%', marginTop: '12px', height: '48px'}} onClick={authorize}>Yes, Authorize</Button>
                            </Col>

                        </Row>
                    )}

                    {!account && (
                        <Row>
                            <Col xs={{offset: 0, span: 24}} sm={{offset: 2, span: 20}} md={{offset: 5, span: 14}} lg={{offset: 7, span: 10}} xl={{offset: 8, span: 8}} xxl={{offset: 8, span: 8}} >
                                <SignUpButton text='Sign Up or Log In to Authorize' style={{width: '100%', marginTop: '12px', height: '48px'}} />
                            </Col>
                        </Row>
                    )}


                </div>
            </div>
        </div>
    );
}
