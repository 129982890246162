import type { SelectProps } from 'antd';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography, message, Upload, Avatar } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useState, useRef, useEffect } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { createAccountBio, assetsGetWriteUrl, assetsUpdateProfilePic } from '../services/signalApi';
import { uploadSignedFileToS3 } from '../services/s3';
import { CopyToClipboard } from './CopyToClipboard';
import { UserAvatar } from './UserAvatar';
import type { GetProp, UploadFile, UploadProps } from 'antd';

import {AccountBio as AccountBioType} from '../types/types'

import { useAppSelector, useIsSmallScreen } from '../app/hooks';


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];


const { Option, OptGroup } = Select;



const getBase64v2 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};


  

interface AccountBioModalParams {
    isOpen: boolean
    existingBio?: AccountBioType
    onCancel: () => void
    onCreate: () => void
}

// interface NewAccountBio {
//     bio: string
//     id: string
//     name?: string
// }


export function EditAccountBioModal({
    isOpen,
    existingBio,
    onCancel,
    onCreate,
}: AccountBioModalParams) {
    const [form] = Form.useForm();
    // const AccountBioContent = Form.useWatch('content', form);
    // const fileUploader = useRef<HTMLInputElement>(null)
    const [loading, setLoading] = useState(false);
    const [assetUrl, setAssetUrl] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState<string>();
    const [uploadedFile, setUploadedFile] = useState<FileType | null>(null);


    const account = useAppSelector(selectAccount);
    // const [inFlight, setInFlight] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const isSmallScreen = useIsSmallScreen()

    // const [isCreating, setIsCreating] = useState(false);
    // const [newAccountBio, setNewAccountBio] = useState<NewAccountBio | null>(null);

    console.log('CreateAccountBioModal', { isCreating})

    const handleChange: UploadProps['onChange'] = (info) => {
        console.log(beforeUpload)
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64v2(info.file.originFileObj as FileType, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
      };

    const beforeUpload = (file: FileType) => {
        console.log("beforeUpload", {file})
        if (assetUrl === null && existingBio) {
            assetsGetWriteUrl(existingBio?.account_id, file.name, file.type).then((res: any) => {
                console.log("assetsGetWriteUrl", {res})
                if (res.status === 200) {
                    const url = res.data.url
                    if (url) {
                        setAssetUrl(url)
                    }
                }
            })
        }
        // do some checks here
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload a JPG or PNG Image.');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB.');
        }

        if (!isJpgOrPng || !isLt2M) {
            return false;
        }

        setLoading(true);
        setUploadedFile(file)
        getBase64v2(file, (url) => {
            console.log("url", {url})
            setLoading(false);
            setImageUrl(url);
            // get the upload url from api
        });

        return false;
    };


    const handleReset = () => {
        // form.resetFields()
        // setNewAccountBio(null)
        setIsCreating(false)
    }

    const handleCancel = () => {
        handleReset()
        onCancel()
    }



    const onCreateAccountBioFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateAccountBioSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateAccountBio(values)
    };

    const onCreateAccountBio = (values: any) => {
        if (account) {
            console.log("onCreateAccountBio values", {values})
            setIsCreating(true)
            if (uploadedFile && assetUrl && existingBio?.account_id) {
                uploadSignedFileToS3(assetUrl, uploadedFile, uploadedFile.type).then((res) => {
                    console.log("uploadSignedFileToS3", {res})
                    if (res.status === 200) {
                        // notify api updateProfilePic endpoint, with the url to it and it post-processes, copies and updates the account object
                        return assetsUpdateProfilePic(existingBio?.account_id, assetUrl).then((res) => {
                            console.log("assetsUpdateProfilePic", {res})
                        })
                    } else {
                        message.error("An error occurred when uploading your new profile pic.")
                    }
                })
            }
            createAccountBio(account.id, values.bio, values.is_searchable ? 1 : 0).then((res) => {
                setIsCreating(false)
                console.log("created", {res})
                if (res.status === 201) {
                    const response_data = res.data
                    // const account_bio_id = response_data.id
                    // const account_bio_content = response_data.bio
                    // setIsCreating(false)
                    // setNewAccountBio({id: account_bio_id, bio: account_bio_content})
                    message.info("Saved!")
                    // handleReset()
                    setTimeout(onCreate, 200)
                    // onCreate()
                } else {
                    message.error(res.data)
                    setIsCreating(false)
                }
                
            }
            ).catch().finally(() => {
                // setIsCreating(false)
            })
        }
    }


    const formBody = (
        <Form
            name="basic"
            form={form}
            layout="vertical"
            // labelCol={{ span: 4 }}
            // wrapperCol={{ span: 20 }}
            initialValues={{
                ...(existingBio ? {bio: existingBio.bio, is_searchable: existingBio.is_searchable} : {})
            }}
            onFinish={onCreateAccountBioSubmit}
            onFinishFailed={onCreateAccountBioFailed}
            autoComplete="off"
            >
                
                {(isCreating && (
                    <Typography.Paragraph>Loading...</Typography.Paragraph>
                ))}
                <Typography.Paragraph>
                    If you make your account searchable, this is what people will see.
                </Typography.Paragraph>

                

                    <>

                        {/* <Form.Item
                            label="Name"
                            name="name"
                            wrapperCol={{ span: 8, offset: 0 }}
                            // extra={<>Name your account_bio so you can easily identify it.</>}

                        >
                            <Input placeholder='ex. Business SWOT Analysis v1, News Article Analysis, Weekly Meal Planner'/>
                        </Form.Item> */}
                        <Form.Item
                            label="Profile Picture"
                            name="pic"
                        >
                            <Space direction='vertical'>

                            {existingBio?.account.name && !imageUrl && (
                                <UserAvatar account={existingBio?.account} />
                            )}
                            {imageUrl && (
                                <Avatar src={imageUrl} />
                            )}

                            <Upload
                                name="avatar"
                                // listType="picture-circle"
                                // listType="picture-card"
                                // className="avatar-uploader"
                                showUploadList={false}
                                // action={() => {}}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                                {/* {imageUrl ? <Avatar src={imageUrl} style={{ width: '100%', height: "100%" }} /> : uploadButton} */}
                                {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}

                            </Upload>
                            </Space>

                        </Form.Item>
                        

                        <Form.Item
                            label="Bio"
                            name="bio"
                            extra={<> Tell others a bit about yourself and where they can learn more about you and your prompts.</>}
                        >
                            <Input.TextArea
                                showCount
                                maxLength={240}
                                style={{ height: 120 }}
                                // onChange={onChange}
                                placeholder="I am a Prompt Engineer enthusiastic about helping people create prompts to..."
                            />
                        </Form.Item>

                        {/* <Form.Item
                                    label="Twitter Username"
                                    name="twitter_handle"
                                    // style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                    extra={<>You will need to verify your twitter handle to show this in your profile.</>}

                                >
                                    <Input/>
                                </Form.Item> */}
                            <Form.Item name="is_searchable" valuePropName="checked"
                                    // style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                    extra={<> Allow all other people to find and see your bio when you publicly share your prompts. You can enable this later.</>}

                            >
                                <Checkbox> Publicly Viewable</Checkbox>
                            </Form.Item>


                        <Form.Item wrapperCol={isSmallScreen? {} : { span: 4, offset: 20 }}>
                            <Button type="primary" style={{ float: 'right'}} htmlType="submit" disabled={
                                isCreating}>
                                Save
                            </Button>
                        </Form.Item>
                    </>

                

            </Form>
    )

    return (
            <Modal title="Edit Bio" open={isOpen} footer={null} onCancel={handleCancel}>
                {formBody}
                
            </Modal>
    )

    
}
