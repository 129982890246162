import {Modal, Select } from 'antd';

import { ConfiguredWorkflowRunner } from './ConfiguredWorkflowRunner';
import { Workflow as WorkflowType } from '../types/types'

// import {Prompt as PromptType} from '../types/types'

import { useIsSmallScreen } from '../app/hooks';
const { Option, OptGroup } = Select;


interface ConfiguredWorkflowModalParams {
    workflow: WorkflowType | undefined
    isOpen: boolean
    onCancel: () => void
    onComplete: () => void
}


export function ConfiguredWorkflowModal({
    workflow,
    isOpen,
    onCancel,
    onComplete,
}: ConfiguredWorkflowModalParams) {
    const isSmallScreen = useIsSmallScreen()

    const handleCancel = () => {
        onCancel()
    }


    return (
        <Modal open={isOpen} footer={null} onCancel={handleCancel} width={isSmallScreen ? '100%': '48%'}>
            <ConfiguredWorkflowRunner workflow={workflow} onComplete={onComplete} />
        </Modal>
    )

    
}
