import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { Tooltip, Typography, Row, Col, Tag, Space, Card } from 'antd';
import { useEffect, useState } from "react";
import { PromptStream } from '../../components/PromptStream'
import { Tag as TagType } from '../../types/types'
import { getTags } from '../../services/signalApi';
import { useIsBigScreen, useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'
import { useSearchParams } from "react-router-dom";



const { CheckableTag } = Tag;
export function Prompts() {
  const account = useAppSelector(selectAccount);
  const [loadedTags, setLoadedTags] = useState<TagType[] | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTagIdsStr = searchParams.get("t")
  const selectedTagIds = selectedTagIdsStr?.split(',') || []
  const selectedTags = (loadedTags || []).filter((tag) => selectedTagIds.includes(tag.id))

  const isBigScreen = useIsBigScreen()

  useEffect(() => {document.title = 'PyroPrompts | Prompts'}, [])


  useEffect(() => {
    getTags({}).then((res) => {
          console.log("prompts", {res})
          const response_data = res.data.results
          setLoadedTags(response_data || [])
      }
      ).catch(() => {
        setLoadedTags([])
      }).finally(() => {
      })
  }, [])

  const handleSelectTag = (tag: TagType, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t.id !== tag.id);
    if (nextSelectedTags.length > 0) {
      searchParams.set("t", nextSelectedTags.map((t) => t.id).join(','))
    } else {
      searchParams.delete("t")
    }
    setSearchParams(searchParams)
    // setSelectedTags(nextSelectedTags);
  };

  const platformTags = (loadedTags || []).filter((tag) => tag.category === "Platform")
  const domainTags = (loadedTags || []).filter((tag) => tag.category === "Domain")

  const platformSelectedTags = (selectedTags || []).filter((tag) => tag.category === "Platform")
  const domainSelectedTags = (selectedTags || []).filter((tag) => tag.category === "Domain")

  const browser = () => (<>
  {/* <Typography.Title level={2}>Filter</Typography.Title> */}
  <Typography.Title level={3} style={{marginTop: '0'}}>Platform</Typography.Title>
  <Space direction='vertical'>
  {(platformTags || []).map((tag, i) => (
      <CheckableTag
        key={i}
        checked={selectedTags.map((t) => t.id).includes(tag.id)}
        onChange={(checked) => handleSelectTag(tag, checked)}
        style={{fontSize: '0.9rem'}}
      >
          {tag.name}
      </CheckableTag>
  ))}
  </Space>
  <Typography.Title level={3}>Domain</Typography.Title>
  <Space direction='vertical'>
  {(domainTags || []).map((tag, i) => (
      <CheckableTag
        key={i}
        checked={selectedTags.map((t) => t.id).includes(tag.id)}
        onChange={(checked) => handleSelectTag(tag, checked)}
        style={{fontSize: '0.9rem'}}
      >
          {tag.name}
      </CheckableTag>
  ))}
  </Space>
</>)

  const browser_2 = () => {
    return <Collapse defaultActiveKey={isBigScreen ? ['platform', 'domain'] : []} ghost>
        <Collapse.Panel header={platformSelectedTags.length > 0 ? `Platform (${platformSelectedTags.length})` : 'Platform'} key="platform">
          <Space direction='vertical'>
            {(platformTags || []).map((tag, i) => (
                <CheckableTag
                  key={i}
                  checked={selectedTags.map((t) => t.id).includes(tag.id)}
                  onChange={(checked) => handleSelectTag(tag, checked)}
                  style={{fontSize: '0.9rem'}}
                >
                    {tag.name}
                </CheckableTag>
            ))}
          </Space>
        </Collapse.Panel>
        <Collapse.Panel header={domainSelectedTags.length > 0 ? `Domain (${domainSelectedTags.length})` : 'Domain'} key="domain">
          <Space direction='vertical'>
            {(domainTags || []).map((tag, i) => (
                <CheckableTag
                  key={i}
                  checked={selectedTags.map((t) => t.id).includes(tag.id)}
                  onChange={(checked) => handleSelectTag(tag, checked)}
                  style={{fontSize: '0.9rem'}}
                >
                    {tag.name}
                </CheckableTag>
            ))}
          </Space>
        </Collapse.Panel>
    </Collapse>

  }

    console.log("selectedTags", {selectedTags});

  return (
    <div className="App">
      <div className="App-content">
        <Typography.Title level={1}>Prompts</Typography.Title>
        <div className="App-page-content">
          {account && (
            <Typography.Paragraph>
              A collection of public prompts and your private prompts.
            </Typography.Paragraph>
          )}
          {!account && (
            <Typography.Paragraph>
              A collection of public prompts for various platforms. Log in to create your own private prompts.
            </Typography.Paragraph>
          )}
            
            {/* <Typography.Paragraph>{optional} Select Data Sets</Typography.Paragraph>
            <Typography.Paragraph>{optional} Create Data Consumer or API Tokens</Typography.Paragraph>
            <Typography.Paragraph>{optional} Configure Billing</Typography.Paragraph>
            <Typography.Paragraph>{blocked} Go Live</Typography.Paragraph> */}
            {/* <DataSetList subscribed={true} include_buttons={true} /> */}

            <Row gutter={{ xs: 8, sm: 16, md: 24 }} style={{marginTop: '24px'}}>
                <Col span={6} xs={24} md={6} className="gutter-row">
                  <Card
                      title={'Filters'}
                      bodyStyle={{padding: '8px', overflow: 'hidden'}}
                  >
                    {browser_2()}
                  </Card>
                </Col>
                <Col span={18} xs={24} md={18} className="gutter-row">
                  <PromptStream showCreate={true} tag_filter_list={selectedTags}/>

                </Col>
            </Row>

            {/* <PromptStream showCreate={true}/> */}
            {/* <ApiIntegrationsList /> */}
        </div>
      </div>
    </div>
  );
}
