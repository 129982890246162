import { MinusCircleOutlined, PlusOutlined, CheckCircleOutlined, ArrowRightOutlined, EditOutlined, RightCircleOutlined, SearchOutlined, BlockOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Form, FormListFieldData, Input, InputNumber, message, Select, Space, Tag, Tabs, Tooltip, FormInstance, Popover, Typography, Row, Card, Checkbox, Timeline } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';
import { DateTime } from 'luxon';

import { useEffect, useState, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Link, useParams } from 'react-router-dom';
import { editProject, getIntegrations, getProject, getPrompts } from '../../services/signalApi';
import { DataSet, Integration, Project as ProjectType } from '../../types/types';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Loading } from '../../components/Loading';
import { PageHeader } from '../../components/PageHeader';
import { SignalList } from '../../components/SignalList';
import { PromptSearchSelect } from '../../components/PromptSearchSelect';
import { ProjectSnippetsList } from '../../components/ProjectSnippetsList';
import { WorkflowList } from '../../components/WorkflowList';
import { CreatePrompt } from '../../components/CreatePrompt';
import { PromptStream } from '../../components/PromptStream';
import { ProjectDiagram } from '../../components/ProjectDiagram';

import { extractBracketContents, extractSingleLineBracketContents, replaceBracketContents } from '../../utils'

import type { InputRef } from 'antd';

import { selectAccount } from '../../features/login/loginSlice';
import {
    clear, loadDataSetsAsync, selectDataSets,
    selectStatus
} from '../../features/dataSetList/dataSetSlice';
// import { ProjectSubscribeModal } from '../../components/ProjectSubscribeModal'
// import { ProjectEditModal } from '../../components/ProjectEditModal'
import 'draft-js/dist/Draft.css';
import { Prompt } from '../Prompt/Prompt';
const { TextArea } = Input;


export function UpdateProjectForm({
    project,
    onCancel,
    onUpdate,
}: {
    project: ProjectType
    onCancel: () => void
    onUpdate: (project: ProjectType) => void
}) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isEditing, setIsEditing] = useState(false);

    const handleCancel = () => {
        form.resetFields();
        setIsEditing(false);
        onCancel();
    }

    const onCreateProjectFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateProjectSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateProject(values);
    };

    const onCreateProject = (values: any) => {
        if (account) {
            setIsEditing(true);
            editProject(
                project.id,
                values
            ).then((res) => {
                if (res.status === 200) {
                    const response_data = res.data;
                    setIsEditing(false);
                    onUpdate(response_data);
                } else {
                    message.error(res.data);
                    setIsEditing(false);
                }  
            }).catch((err) => {
                console.log("err", {err});
            }).finally(() => {
                setIsEditing(false);
            });
        }
    }

    return <Form
        name="project"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
            name: project.name,
            description: project.description
        }}
        onFinish={onCreateProjectSubmit}
        onFinishFailed={onCreateProjectFailed}
        autoComplete="off"
    >
        {(isEditing && (
            <Typography.Paragraph>Loading...</Typography.Paragraph>
        ))}
        
        <>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Name your Project' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Description"
                name="description"
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                wrapperCol={{ span: 16, offset: 6 }}
            >
                <Space>
                    <Button type="primary" htmlType="submit" disabled={isEditing}>
                        Save
                    </Button>
                    <Button type="default" onClick={onCancel}>
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </>

    </Form>
}




export function Project() {
    const params = useParams()
    const account = useAppSelector(selectAccount)
    
    // const [inFlight, setInFlight] = useState(false);
    const [attemptedLoad, setAttemptedLoad] = useState(false);
    const [showEditProjectModal, setShowEditProjectModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [showSaved, setShowSaved] = useState(false);
    
    const [project, setProject] = useState<ProjectType | null>(null);
    const [reloads, setReloads] = useState(0);
    const [subscribingProject, setSubscribingProject] = useState<ProjectType | null>(null);
    // const [dataSets, setDataSets] = useState<DataSet[] | null>(null);
    const dataSets = useAppSelector(selectDataSets);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (project === null) {
        document.title = 'PyroPrompts | Project'

        } else {
        document.title = `PyroPrompts | Project: ${project.name}`
        }
    }, [project?.id])

    const id = params.id;

    useEffect(() => {
        if (!id) {
            return
        }
        getProject(id).then((res) => {
            // setInFlight(false)
            // setAttemptedLoad(true)
            setAttemptedLoad(true)
            console.log("project", {res})
            if (res.status === 200) {
                const response_data = res.data
                
                // setProject(localUpdateProjectConfig(response_data, isProjectDiscordUsernamePermitted))
                setProject(response_data)
            } else {
                setProject(null)

            }
        }).catch(() => {
            setProject(null)
        })

        
    }, [id, reloads, account?.id])


    if (!id) {
        return <Typography.Paragraph>No ID</Typography.Paragraph>
    }

    if (id === undefined || project === undefined || project === null) {
        return (
        <div className="App">
            <div className="App-content">
            {attemptedLoad && (
                <Typography.Paragraph>Project Not Found. Maybe you need to log in.</Typography.Paragraph>
            )}
            {!attemptedLoad && (
                <Loading message='Project' description='Loading Project' style={{marginTop: '12px'}} />

            )}
            </div>
        </div>
        );
    }


    const content = (
        <>

            <Row gutter={24} style={{marginTop: '12px'}}>
                <Col xs={24} md={8}>
                    <Card
                        title={'Project'}
                        style={{marginBottom: '12px'}}
                        extra={
                            <Tooltip placement="top" title="Edit">
                                <span onClick={() => {setEditMode(!editMode)}}><EditOutlined /></span>
                            </Tooltip>
                        }

                    >
                        <Space direction='vertical' style={{width: '100%'}}>
                            {!editMode && (<>
                                <Typography.Title level={3} style={{marginTop: 0}}><BlockOutlined />&nbsp;{project.name}</Typography.Title>
                                <Typography.Paragraph>{project.description}</Typography.Paragraph>
                                <Space style={{fontSize:'0.85em' }}><span>Private to just you</span><Divider type='vertical'/><span>Created {DateTime.fromISO(project.created_date).toRelative()}</span></Space>
                                <Typography.Paragraph><Link to={`/projects_new/${id}`}>Go to New Project Page &nbsp;&#8594;</Link></Typography.Paragraph>
                            </>)}
                            {editMode && (<>
                                <UpdateProjectForm project={project} onCancel={() => setEditMode(false)} onUpdate={(project) => {
                                    setShowSaved(true)
                                    setEditMode(false)
                                    setProject(project)
                                    // triggerReload()
                                }} />
                            </>)}
                            {/* <ProjectDiagram project={project} workflows={[]} snippets={[]} /> */}
                            
                        </Space>
                    </Card>
                    <Card>
                        <Space direction='vertical' style={{width: "100%"}}>
                            <ProjectSnippetsList project_id={project.id} />
                        </Space>
                    </Card>
                </Col>
                <Col xs={24} md={16}>
                    <Card>
                        <Space direction='vertical' style={{width: "100%"}}>
                            {/* <ProjectSnippetsList project_id={project.id} /> */}
                            <WorkflowList project_id={id} showHead={true}/>
                        </Space>
                    </Card>
                    
                </Col>

            </Row>


        </>
    )



    return (
        <div className="App">
        <div className="App-content">
            <div className="App-page-content">
                {content}
            </div>
        </div>
        </div>
    );
}
