import { Button, Input } from 'antd';
import { Link } from "react-router-dom";

import { EditOutlined } from '@ant-design/icons';
import { DataSet as DataSetType, Prompt as PromptType } from '../types/types';
import { EditPromptModal } from './EditPromptModal';

import { useState } from 'react';
const { Search } = Input;
interface EditPromptParams {
    prompt: PromptType
    buttonText?: string
    buttonType?: 'primary' | 'default' | 'text'
    onEdit? : (prompt: PromptType | null) => void
}

export function EditPrompt({
    prompt,
    buttonText='Edit Prompt',
    buttonType='default',
    onEdit
}: EditPromptParams) {


    const [isPromptModalOpen, setIsPromptModalOpen] = useState<boolean>(false);

    const showEditPrompt = () => {
        setIsPromptModalOpen(true)
    }

    const onEditPrompt = (prompt: PromptType | null) => {
        console.log("EditPromptModal.onEdit")
        if (onEdit) {
            onEdit(prompt)
        }
    }

    const closeEditPrompt = () => {
        setIsPromptModalOpen(false)
    }

    return <>
        <div>
        <Button type={buttonType} icon={<EditOutlined />} onClick={showEditPrompt}>
            {buttonText}
        </Button>
        </div>
        <EditPromptModal prompt={prompt} isOpen={isPromptModalOpen} onCancel={closeEditPrompt} onEdit={onEditPrompt} />
    </>

}
