import { AdvancedRealTimeChart, EconomicCalendar, Ticker, TickerTape, SingleTicker, MiniChart, SymbolInfo, FundamentalData, ForexHeatMap } from "react-ts-tradingview-widgets";
import {selectLight} from '../features/lightDarkSwitch/lightDarkSwitchSlice'
import { useAppSelector } from '../app/hooks';

interface TradingViewChartWidgetProps {
    symbol: string
}

export function TradingViewChartWidget({
    symbol,
}: TradingViewChartWidgetProps) {
    // https://tradingview-widgets.jorrinkievit.xyz/docs/components/AdvancedRealTimeChartWidget/#public-types
    const light = useAppSelector(selectLight)
    return (
        <div style={{height: 500}}>
            <AdvancedRealTimeChart
                theme={light ? 'light' : 'dark'}
                interval="D"
                range="12M"
                symbol={symbol}
                autosize
                hide_top_toolbar
                allow_symbol_change={false}
                studies={["MASimple@tv-basicstudies", "RSI@tv-basicstudies", "MACD@tv-basicstudies"]}
                // disabled_features={['chart_scroll']}
                // height={500}
            />
        </div>
    )
}

export function TradingViewEconomicCalendar() {
    return <EconomicCalendar colorTheme="dark" height={400} width="100%"></EconomicCalendar>
}

export function TradingViewTicker() {
    const light = useAppSelector(selectLight)
    return <Ticker
        colorTheme={light ? 'light' : 'dark'}
        symbols={[
            {
              "proName": "FOREXCOM:SPXUSD",
              "title": "S&P 500"
            },
            {
                "proName": "BITSTAMP:BTCUSD",
                "title": "BTC/USD"
            },
            {
                "proName": "DXY",
                "title": "DXY"
            },
            {
                "proName": "FX_IDC:EURUSD",
                "title": "EUR/USD"
            },
            {
              "proName": "FOREXCOM:NSXUSD",
              "title": "Nasdaq 100"
            },

            {
              "proName": "BITSTAMP:ETHUSD",
              "title": "ETH/USD"
            },

        ]}
    />
}

export function TradingViewTickerTape() {
    const light = useAppSelector(selectLight)
    return <TickerTape
        colorTheme={light ? 'light' : 'dark'}
        isTransparent
        symbols={[
            {
              "proName": "FOREXCOM:SPXUSD",
              "title": "S&P 500"
            },
            {
                "proName": "BITSTAMP:BTCUSD",
                "title": "BTC/USD"
            },
            {
                "proName": "DXY",
                "title": "DXY"
            },
            {
                "proName": "FX_IDC:EURUSD",
                "title": "EUR/USD"
            },
            {
              "proName": "FOREXCOM:NSXUSD",
              "title": "Nasdaq 100"
            },

            {
              "proName": "BITSTAMP:ETHUSD",
              "title": "ETH/USD"
            },

        ]}
    />
}

export function TradingViewSingleTicker({
    symbol,
}: TradingViewChartWidgetProps) {
    const light = useAppSelector(selectLight)
    return <SingleTicker colorTheme={light ? 'light' : 'dark'}  symbol={symbol} width="100%"></SingleTicker>
}

export function TradingViewMiniChart({
    symbol,
}: TradingViewChartWidgetProps) {
    const light = useAppSelector(selectLight)
    return <MiniChart colorTheme={light ? 'light' : 'dark'} symbol={symbol} width="100%"></MiniChart>
}

export function TradingViewSymbolInfo({
    symbol,
}: TradingViewChartWidgetProps) {
    const light = useAppSelector(selectLight)
    return <SymbolInfo colorTheme={light ? 'light' : 'dark'} symbol={symbol} autosize></SymbolInfo>
}

export function TradingViewFundamentalData({
    symbol,
}: TradingViewChartWidgetProps) {
    const light = useAppSelector(selectLight)
    return <FundamentalData colorTheme={light ? 'light' : 'dark'} symbol={symbol} height={800} width="100%"></FundamentalData>
}

export function TradingViewForexHeatMap() {
    const light = useAppSelector(selectLight)
    return <ForexHeatMap colorTheme={light ? 'light' : 'dark'} autosize height={600}></ForexHeatMap>
}
