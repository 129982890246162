import { Button, Checkbox, Form, Input, Modal, Select, Typography, message } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { selectAccount } from '../features/login/loginSlice';
import { createDataProvider, createDataSet, getDataProviders } from '../services/signalApi';
import { DataProvider } from '../types/types';
import { CopyToClipboard } from './CopyToClipboard';
import { isNullish } from '../utils'

import { useAppSelector } from '../app/hooks';
const { Option, OptGroup } = Select;


interface DataSetModalParams {
    isOpen: boolean
    onCancel: () => void
    onCreate: () => void
}

interface NewDataSet {
    name: string
    id: string
}


export function CreateDataSetModal({
    isOpen,
    onCancel,
    onCreate,
}: DataSetModalParams) {
    const [form] = Form.useForm();
    const [providerForm] = Form.useForm();
    const dataSetName = Form.useWatch('name', form);
    const dataSetTerms = Form.useWatch('terms', form);
    const selectedDataProviderId = Form.useWatch('data_provider', form);

    const providerTerms = Form.useWatch('terms', providerForm);

    const account = useAppSelector(selectAccount);
    const [inFlight, setInFlight] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isCreatingDataProvider, setIsCreatingDataProvider] = useState(false);
    const [dataProviders, setDataProviders] = useState<DataProvider[] | null>(null);
    // const [isCreating, setIsCreating] = useState(false);
    const [newDataSet, setNewDataSet] = useState<NewDataSet | null>(null);

    console.log('CreateDataSetModal', {dataProviders, isCreating, isCreatingDataProvider, inFlight})

    const loadDataProviders = () => {
        setInFlight(true)
        getDataProviders().then((res) => {
            console.log("getDataProviders", {res})
            const response_data = res.data.results
            setInFlight(false)
            setDataProviders(response_data)
        }
        ).catch(() => {
            setDataProviders([])
        }).finally(() => {
            setInFlight(false)
        })
    }

    if (dataProviders === null && !inFlight && account) {
        loadDataProviders()
    }

    const handleCancel = () => {
        form.resetFields()
        providerForm.resetFields()
        setNewDataSet(null)
        setIsCreating(false)
        setIsCreatingDataProvider(false)
        onCancel()
    }

    const onCreateDataSetFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateDataSetSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateDataSet(values)
    };

    const onCreateDataSet = (values: any) => {
        if (account) {
            console.log("onCreateDataSet values", {values})
            setIsCreating(true)
            createDataSet(values.data_provider, values.name).then((res) => {
                console.log("created", {res})
                if (res.status === 201) {
                    const response_data = res.data
                    const data_set_id = response_data.id
                    const data_set_name = response_data.name
                    setIsCreating(false)
                    setNewDataSet({id: data_set_id, name: data_set_name})
                } else {
                    message.error(res.data)
                    setIsCreating(false)
                }
                
            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }

    const onCreateDataProviderFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateDataProviderSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateDataProvider(values)
    };

    const onCreateDataProvider = (values: any) => {
        if (account) {
            console.log("onCreateDataProvider values", {values})
            setIsCreating(true)
            createDataProvider(account.id, values.name).then((res) => {
                console.log("created", {res})
                const response_data = res.data
                // const data_provider_id = response_data.id
                // const data_provider_name = response_data.name
                setIsCreating(false)
                loadDataProviders()
            }
            ).catch().finally(() => {
                setIsCreating(false)
            })
        }
    }


    

    const firstDataProvider = dataProviders ? dataProviders[0] : null
    console.log('firstDataProvider', {firstDataProvider, dataProviders})
    const selectedDataProvider = isNullish(selectedDataProviderId) ? firstDataProvider : dataProviders?.find(d => d.id === selectedDataProviderId)

    console.log('selectedDataProvider', {selectedDataProvider})

    const dataProviderFormBody = (
        <Form
        name="basic"
        form={providerForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ name: 'My Data Provider', }}
        onFinish={onCreateDataProviderSubmit}
        onFinishFailed={onCreateDataProviderFailed}
        autoComplete="off"
        >
            
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            <Typography.Paragraph>
                Before creating a Data Set, you need to sign up as a Data Provider. If you plan on allowing others to subscribe to your data, this is how they'll see you. This might be your business name. This can be changed later by <Link to='/contact_us'>contacting us</Link>.
            </Typography.Paragraph>

            <Form.Item
                label="Name"
                name="name"
            >
                <Input />
            </Form.Item>

            <Form.Item name="terms" valuePropName="checked" wrapperCol={{ span: 16, offset: 6 }}
                rules={[{ required: true, message: 'Terms must be accepted' }]}
            >
                <Checkbox> Agree to <a href='/terms_and_conditions' target='blank'>Terms</a></Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
                <Button type="primary" htmlType="submit" disabled={isCreating || !providerTerms}>
                    Create Data Provider
                </Button>
            </Form.Item>

        </Form>
    )

    const selectedProviderNote = () => {
        let note = <></>
        if (selectedDataProvider) {
            if (selectedDataProvider.data_set_cap && selectedDataProvider.data_set_count && selectedDataProvider.data_set_count >= selectedDataProvider.data_set_cap) {
                note = (
                    <>
                    You're using all {selectedDataProvider.data_set_count} Data Sets. Contact us to get more.<br/>
                    </>
                )
            } else {
                note = (
                    <>
                    You're using {selectedDataProvider.data_set_count} of {selectedDataProvider.data_set_cap ? selectedDataProvider.data_set_cap : 'unlimited'} Data Sets.
                    <br/>
                </>
                )
            }
            return (
                <>
                    {note}
                    Learn more about data providers <a href='/data_providing_1p_3p' target='blank'>here</a>.
                </>
            )
        }

    }

    const dataSetFormBody = (
        <Form
        name="basic"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ data_provider: firstDataProvider?.id, data_set_type: 'stock' }}
        onFinish={onCreateDataSetSubmit}
        onFinishFailed={onCreateDataSetFailed}
        autoComplete="off"
        >
            
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            <Typography.Paragraph>
                Creation of a Data Set. You will get a Data Set ID that you can use to store signals in.
            </Typography.Paragraph>

            

            {((firstDataProvider && newDataSet === null) && (
                <>
                    {(selectedDataProvider && dataProviders && dataProviders.length > 1) && (
                        <Form.Item
                            label="Data Provider"
                            name="data_provider"
                            extra={<>{selectedProviderNote()}</>}
                            >
                                <Select defaultValue={firstDataProvider?.id} disabled={!dataProviders || dataProviders?.length < 2}>
                                    {dataProviders?.map((dataProvider) => (
                                        <Option value={dataProvider.id}>{dataProvider.name}</Option>
                                    ))}
                                    {/* <Option value="stock">Stocks</Option>
                                    <Option value="crypto">Crypto</Option>
                                    <Option value="futures">Futures</Option> */}
                                </Select>
                        </Form.Item>
                    )}

                    {(selectedDataProvider && dataProviders && dataProviders.length <= 1) && (
                        <Form.Item
                            label="Data Provider"
                            name="data_provider"
                            extra={<>{selectedProviderNote()}</>}

                        >
                            <Select defaultValue={firstDataProvider?.id} disabled={!dataProviders || dataProviders?.length < 2}>
                                {dataProviders?.map((dataProvider) => (
                                    <Option value={dataProvider.id}>{dataProvider.name}</Option>
                                ))}
                                {/* <Option value="stock">Stocks</Option>
                                <Option value="crypto">Crypto</Option>
                                <Option value="futures">Futures</Option> */}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item
                        label="Name"
                        name="name"
                    >
                        <Input disabled={!firstDataProvider} />
                    </Form.Item>
                    
                    
                    {/* <Form.Item
                        label="Type"
                        name="data_set_type"
                        extra={<>Learn more about data set types <a>here</a>.</>}
                        >
                            <Select defaultValue="stock">
                                <Option value="stock">Stocks</Option>
                                <Option value="crypto">Crypto</Option>
                                <Option value="futures">Futures</Option>
                                <Option value="index">Index</Option>
                                <Option value="bond">Bond</Option>
                                <Option value="economy">Economy</Option>
                                <Option value="other">Other</Option>
                            </Select>
                    </Form.Item> */}

                    
                   
                    <Form.Item name="terms" valuePropName="checked" wrapperCol={{ span: 16, offset: 6 }}
                        rules={[{ required: true, message: 'Terms must be accepted' }]}
                    >
                        <Checkbox disabled={!firstDataProvider}> Agree to <a href='/terms_and_conditions' target='blank'>Terms</a></Checkbox>
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
                        <Button type="primary" htmlType="submit" disabled={
                            isCreating || newDataSet !== null || !firstDataProvider || !dataSetTerms || !dataSetName
                            || (firstDataProvider.data_set_cap !== undefined && firstDataProvider.data_set_count !== undefined && firstDataProvider.data_set_cap !== null && firstDataProvider.data_set_count !== null && firstDataProvider.data_set_count >= firstDataProvider.data_set_cap)
                        }>
                            Create Data Set
                        </Button>
                    </Form.Item>
                </>
            ))}

            {(newDataSet && (
                <>
                    <Typography.Paragraph>Data Set Created!</Typography.Paragraph>
                    <>
                        <Typography.Paragraph>Data Set ID:</Typography.Paragraph>
                        <Typography.Paragraph>
                            <Typography.Text code>{newDataSet.id}</Typography.Text>
                            <CopyToClipboard text={newDataSet.id} />
                        </Typography.Paragraph>
                        <Typography.Paragraph>Name:</Typography.Paragraph>
                        <Typography.Paragraph><Link to={`/data_sets/${newDataSet.id}/`}>{newDataSet.name}</Link></Typography.Paragraph>
                        <Typography.Paragraph></Typography.Paragraph>
                    </>
                    

                    <Button type="primary" onClick={handleCancel}>
                        Okay
                    </Button>
                </>

            ))}

        </Form>
    )

    return (
            <Modal title="Create DataSet" open={isOpen} footer={null} onCancel={handleCancel}>
            {!firstDataProvider && dataProviderFormBody}
            {firstDataProvider && dataSetFormBody}
            {/* {formBody} */}
            </Modal>
    )

    
}
