import {createElement} from 'react';
import { Space, Tooltip } from 'antd';
import { Prompt as PromptType, Account as AccountType } from '../types/types'
import { PromptMarker } from './PromptMarker' 
import { MinusCircleOutlined, StarOutlined, EyeOutlined, LikeOutlined, MessageOutlined, UploadOutlined, CopyOutlined, FireOutlined, RightOutlined, EyeInvisibleOutlined, BookFilled, BookOutlined, CheckCircleFilled, StarFilled, DownloadOutlined, GlobalOutlined } from '@ant-design/icons';

import { IconText } from './IconText'
import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getSignals, getSignalsLoggedOut, getPrompts, getPromptsLoggedOut, createAccountPromptMark, deleteAccountPromptMark } from '../services/signalApi';


interface PromptStarMarketParams {
    prompt: PromptType
    include_text_in_count?: boolean
    onClick?: (prompt: PromptType) => void
}



export function PromptStarMarker({
  prompt,
  include_text_in_count=false,
  onClick
}: PromptStarMarketParams) {

  const account = useAppSelector(selectAccount)

  const onStar = (prompt: PromptType) => {
    console.log("onStarToLibrary", {account, prompt})
    if (account) {
        if (onClick) {
          onClick(prompt)
        }
        if (prompt.starred) {
            deleteAccountPromptMark({
                account_id: account?.id,
                prompt_id: prompt.id,
                mark_type: 'star'
            })
        } else {
            createAccountPromptMark({
                account_id: account?.id,
                prompt_id: prompt.id,
                mark_type: 'star'
            })
        }
    }
  }


  return <PromptMarker
    account={account}
    count_field='star_count'
    prompt_marked_field='starred'
    prompt={prompt}
    icon={StarOutlined}
    icon_marked={StarFilled}
    onClick={onStar}
    tooltip='Star'
    text='Star'
    tooltip_logged_out='Log in to Star'
    tooltip_marked='Starred'
    include_text_in_count={include_text_in_count}
  />

};
