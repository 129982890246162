import React, { useState } from 'react';
import { Form, Modal, Checkbox, Input, Typography, Button, Select, Alert } from 'antd';
import { findDataSetSubscriptionMethod } from '../services/signalApi'
import { selectAccount } from '../features/login/loginSlice'
import { DataProvider, DataSetSubscptionMethod } from '../types/types';

import { DiscordLink } from './DiscordLink'
import { DataSetSubscribeModal } from './DataSetSubscribeModal'
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { DataSet } from '../types/types';
import { Link } from 'react-router-dom';

const { Option, OptGroup } = Select;


interface DataSetModalParams {
    isOpen: boolean
    onCancel: () => void
    onCreate: () => void
}

interface NewDataSet {
    name: string
    id: string
}


export function FindDataSetModal({
    isOpen,
    onCancel,
    onCreate,
}: DataSetModalParams) {
    const [form] = Form.useForm();
    const code = Form.useWatch('code', form);

    const account = useAppSelector(selectAccount);
    const [inFlight, setInFlight] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    // const [isSubscribing, setIsSubscribing] = useState(false);
    const [subscribingDataSetMethod, setSubscribingDataSetMethod] = useState<DataSetSubscptionMethod | null>(null);
    const [subscribingDataSet, setSubscribingDataSet] = useState<DataSet | null>(null);

    // const [isCreating, setIsCreating] = useState(false);

    console.log('FindDataSetModal', {inFlight, code, message, subscribingDataSet, subscribingDataSetMethod})

    // const loadDataProviders = () => {
    //     setInFlight(true)
    //     getDataProviders().then((res) => {
    //         console.log("getDataProviders", {res})
    //         const response_data = res.data.results
    //         setInFlight(false)
    //         setDataProviders(response_data)
    //     }
    //     ).catch(() => {
    //         setDataProviders([])
    //     }).finally(() => {
    //         setInFlight(false)
    //     })
    // }

    // if (dataProviders === null && !inFlight) {
    //     loadDataProviders()
    // }

    const handleCancel = () => {
        form.resetFields()
        setMessage(null)
        setSubscribingDataSetMethod(null)
        onCancel()
    }

    const onFindDataSetFinish = (values: any) => {
        console.log('onFindDataSetFinish:', {values, account});
        // values.code

        if (account) {
            console.log("onFindDataSetFinish values", {values})
            setInFlight(true)
            setMessage(null)
            findDataSetSubscriptionMethod(values.code).then((res) => {
                console.log("findDataSetSubscriptionMethod", {res})
                if (res.status === 200 ) {
                    const response_data = res.data
                    setInFlight(false)
                    const methods = response_data.results
                    if (methods.length){
                        const method = methods[0]
                        setSubscribingDataSetMethod(method)
                    } else {
                        setMessage("Not Found")
                    }

                } else if (res.status === 400) {
                    const response_data = res.data
                    setMessage("Not Found")
                } else {
                    console.log("failed", {res})
                }
                // const data_set_id = response_data.id
                // const data_set_name = response_data.name
                // setNewDataSet({id: data_set_id, name: data_set_name})
            }
            ).catch((res) => {
                console.log("failed catch", {res})

            }).finally(() => {
                setInFlight(false)
            })
        }
        // onEditDataSet(values)
    };
    const onFindDataSetFailed = () => {

    }

    const handleSubscribeCancel = () => {
        setSubscribingDataSet(null)
    }

    const handleSubscribeComplete = () => {
        form.resetFields()
        setSubscribingDataSet(null)
        setSubscribingDataSetMethod(null)
        onCreate()

    }

    const handleSubscribeClick = () => {
        if (subscribingDataSetMethod) {
            setSubscribingDataSet(subscribingDataSetMethod?.data_set)
        }
    }

    if (subscribingDataSet && subscribingDataSetMethod){
        return (
            <DataSetSubscribeModal subscribingDataSet={subscribingDataSet} subscriptionMethod={subscribingDataSetMethod} isOpen={subscribingDataSet !== null} onCancel={handleSubscribeCancel} onCreate={handleSubscribeComplete} />
        )
    }

    



    return (
        <Modal title="Find DataSet" open={isOpen} footer={null} onCancel={handleCancel}>
            <Typography.Paragraph>If you've been given a promo code for access to a private dataset, enter it here to see details and subscribe.</Typography.Paragraph>
            <Typography.Paragraph>If you are looking to browse private Data Sets or are looking for something specific, please <Link to='/store'>visit the Store</Link> or <Link to='/contact_us'>contact us</Link>.</Typography.Paragraph>
            {message && (
                <>
                    <Alert message={message} type="error" />
                    <Typography.Paragraph></Typography.Paragraph>
                </>
            )}
            {subscribingDataSetMethod && (
                <>
                    <Typography.Paragraph>Found:</Typography.Paragraph>
                    <Typography.Paragraph>{subscribingDataSetMethod.data_set.name} by {subscribingDataSetMethod.data_set.data_provider.name}</Typography.Paragraph>
                    <Button type="primary" onClick={handleSubscribeClick}>Subscribe</Button>
                </>
            )}
            {(subscribingDataSetMethod === null || subscribingDataSetMethod === undefined )&& (
                <Form
                name="basic"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{}}
                onFinish={onFindDataSetFinish}
                onFinishFailed={onFindDataSetFailed}
                autoComplete="off"
                >
                    
                    {(inFlight && (
                        <Typography.Paragraph>Loading...</Typography.Paragraph>
                    ))}

                    <Form.Item
                        label="Code"
                        name="code"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
                        <Button type="primary" htmlType="submit" disabled={inFlight}>
                            Search
                        </Button>
                    </Form.Item>

                </Form>
            )}
            
        </Modal>
    )

    
}
