import React, { useState } from 'react';
import { Account as AccountType, AccountBio as AccountBioType } from '../../types/types'
import { getAccountBios, createAccountBio } from '../../services/signalApi'
import ReactMarkdown from 'react-markdown'
import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined, UploadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { Descriptions, Typography, Empty, Spin, Alert, Button, message, Space, Row, Statistic, Tag, Col, Tabs, Avatar, Dropdown, Card } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';
import ReactQuill from 'react-quill';
import { useEffect } from "react";
import 'react-quill/dist/quill.snow.css';
import { PageHeader } from '../../components/PageHeader'
import { SignalList } from '../../components/SignalList'
import { Loading } from '../../components/Loading'
import { CreateSignalModal } from '../../components/CreateSignalModal'
import { EditAccountBioModal } from '../../components/EditAccountBioModal'
import { useAppSelector } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice';
// import { DataSetSubscribeModal } from '../../components/DataSetSubscribeModal'
import { CopyToClipboard } from '../../components/CopyToClipboard'
// import { DataSetEditModal } from '../../components/DataSetEditModal'
import { PromptStream } from '../../components/PromptStream'
import { UserAvatar } from '../../components/UserAvatar'
import { IconText } from '../../components/IconText'


import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
// import Editor from "rich-markdown-editor";

// function MyEditor() {
//   const [editorState, setEditorState] = React.useState(
//     () => EditorState.createEmpty(),
//   );

//   return (
//     <>
//       Editor:
//       <Button onClick={() => {}}>Bold</Button>
//       <Editor editorState={editorState} onChange={setEditorState} />
//     </>
//   )
// }


export function PublicAccount() {
  const params = useParams()
  const account = useAppSelector(selectAccount)
  const username = params.username;

  // load account bio
  const [accountBio, setAccountBio] = useState<AccountBioType | null>(null);
  const [showEditBioModal, setShowEditBioModal] = useState(false);
  const [reloads, setReloads] = useState(0);

  // const [inFlight, setInFlight] = useState(false);
  // const [attemptedLoad, setAttemptedLoad] = useState(false);
  // const [showCreateSignalModal, setShowCreateSignalModal] = useState(false);
  // const [showEditDataSetModal, setShowEditDataSetModal] = useState(false);
  // const [dataset, setDataset] = useState<DataSetType | null>(null);
  // const [reloads, setReloads] = useState(0);
  // const [subscribingDataSet, setSubscribingDataSet] = useState<DataSetType | null>(null);
  // const [dashboards, setDashboards] = useState<DashboardType[] | null>(null);
  // const [activeTab, setActiveTab] = useState<string | null>(null);
  // let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (username === null || username === undefined) {
      document.title = 'PyroPrompts | User'
      document.getElementsByName("twitter:title")[0].setAttribute('content', `PyroPrompts Data Set`);
      document.getElementsByName("twitter:description")[0].setAttribute('content', `PyroPrompts Data Set`);
    } else {
      document.title = `PyroPrompts | User: ${username}`
      document.getElementsByName("twitter:title")[0].setAttribute('content', `PyroPrompts User: ${username}`);
      document.getElementsByName("twitter:description")[0].setAttribute('content',username ? username : `PyroPrompts Account`);
    }
  }, [username])

  useEffect(() => {
    if (username === null || username === undefined) {
      return
    }
    getAccountBios({
        ...(username ? {username} : {}),
    }).then((res) => {
        console.log("bios res", {res})
        if (res.status === 200 && res.data.results) {
          const response_data = res.data.results
          if (response_data && response_data.length > 0 ) {
            setAccountBio(response_data[0])
          }
        }
        
    }
    ).catch(() => {
      setAccountBio(null)
    })
  }, [JSON.stringify({account}), username, reloads])


  if (!username) {
    return <Typography.Paragraph>No Username</Typography.Paragraph>
  }

  const onshowEditBioModal = () => {
    setShowEditBioModal(true)
    // setTimeout(() => {
    //     inputRef.current!.focus({
    //         cursor: 'all',
    //       });
    // }, 200)

}

const onBioEdit = () => {
    console.log("PublicAccount.onBioEdit")
    setShowEditBioModal(false)
    setReloads(reloads + 1)
    //     onCreate()
    // }
}

const closeEditBioModalOpen = () => {
    setShowEditBioModal(false)
}

  // const loadDataSet = () => {
  //     setInFlight(true)
  //     getDataSet(id).then((res) => {
  //         setInFlight(false)
  //         setAttemptedLoad(true)
  //         console.log("dataset", {res})
  //         if (res.status === 200) {
  //           const response_data = res.data
  //           setDataset(response_data)
  //         } else {
  //           setDataset(null)

  //         }
  //     }
  //     ).catch(() => {
  //           setDataset(null)
  //     }).finally(() => {
  //           setInFlight(false)
  //     }).then(() => getDataSetDashboards({data_set_id: id, include_public: 1})).then((res) => {
  //       console.log(res)
  //       if (res.status === 200) {
  //         const results = res.data.results
  //         if (results) {
  //           setDashboards(results)
  //           setTab()
  //         }
  //       }
  //     })
  // }

  // if (dataset === null && !inFlight && !attemptedLoad) {
  //   console.log("triggering")
  //   loadDataSet()
  // }

  // const handleCreateSignal = () => {
  //   setShowCreateSignalModal(false)
  //   setReloads(reloads + 1)
  //   // forceUpdate()

  // }
  // const handleCreateSignalCancel = () => {
  //   setShowCreateSignalModal(false)
  // }

  // const showSubscribeModal = (subscribeDataSet: DataSetType) => {
  //   // setIsSubscribeModalVisible(true);
  //   console.log("showSubscribeModal dataset", {dataset})
  //   setSubscribingDataSet(dataset);
  // }

  // const handleCancel = () => {
  //   setSubscribingDataSet(null);
  // }

  // const handleSubscribeComplete = () => {
  //   setSubscribingDataSet(null);
  //   loadDataSet()
  // }



  const ownedAccount = (account && account.name === username)

  const right = () => {
    return (
      <>
        {ownedAccount && (
          <>
            <Button type="default" onClick={onshowEditBioModal}>Edit Bio</Button>
            {/* <Button type="default" onClick={() => {}}>Create Prompt</Button> */}
          </>
        )}

        {/* {account && dataset.subscribed && ( */}
        {/* <Button key="1" type="default" onClick={(e) => {}}> */}
        {/* <Button key="1" type="default" onClick={(e) => showSubscribeModal(dataset)}> */}
            {/* Unfollow */}
          {/* </Button> */}
        {/* )} */}

        {/* {account && !dataset.subscribed && ( */}
        {/* <Button key="1" type="primary" onClick={(e) => showSubscribeModal(dataset)}> */}
        {/* {!ownedAccount && (
            <Button key="1" type="primary" onClick={(e) => {}}>
              Follow
            </Button>
        )} */}

        {/* )} */}

        {/* {!account && (
          <Tooltip placement='left' title="Log in to subscribe">
            <Button key="1" type="primary" disabled={true}>
            Follow
            </Button>
          </Tooltip>
        )} */}
      </>
    )
  }

  const sub = () => {
    return (
      <>
        {/* {ownedAccount && (<Tag color="black">Me</Tag>)} */}
        {/* {dataset.is_public === 1 && (<Tag color="blue">Public</Tag>)}
        {dataset.is_public !== 1 && (<Tag color="purple">Private</Tag>)}
        {dataset.is_published !== 1 && (<Tag color="yellow">Unpublished</Tag>)}
        {ownedDataSet && (<Tag color="black">Mine</Tag>)} */}
      </>
    )
  }



  return (
    <div className="App">
      <div className="App-content">
        {/* Data Set: {dataset.name} */}
        <div className="App-page-content">
          <Row gutter={24} style={{marginTop: '24px'}}>
            <Col xs={24} md={8}>
              <Card
                  title={<Space size={'large'}>
                    {account && (
                      <UserAvatar account={account} />
                    )}
                    <span>{username}</span>
                  </Space>}
                  style={{marginBottom: '24px'}}
                  extra={right()}
                  // extra={<a href="#">Editas</a>}

                  // bordered={light ? true : false}
              >
                {accountBio && accountBio.bio ? (
                  <Typography.Paragraph style={{whiteSpace: 'pre-line'}}>{accountBio.bio}</Typography.Paragraph>

                ) : (
                  <Typography.Paragraph style={{whiteSpace: 'pre-line'}}>No bio...</Typography.Paragraph>

                )}
                {account && account.share_url && (
                  <Row>
                  <Col span={12}>
                      <CopyToClipboard text={account.share_url} tooltip='Copy Share URL' content={<IconText icon={UploadOutlined} text="Copy Share URL" />} key="list-vertical-share-o"/>
                  </Col>
                  <Col span={12}>
                  </Col>
                  </Row>
                )}
              </Card>
            </Col>
            <Col xs={24} md={16}>
              <PromptStream account_name={username} showCreate={ownedAccount}/>
            </Col>

          </Row>

            <EditAccountBioModal isOpen={showEditBioModal} onCancel={closeEditBioModalOpen} onCreate={onBioEdit} {...(accountBio ? {existingBio: accountBio} : {})}/>

          
        </div>
      </div>
    </div>
  );
}
