import { Button, Divider, Input, Radio, Typography } from 'antd';
import { ReactElement, useState, useEffect } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ReloadOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { DateTime } from 'luxon';

import { Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { Signal, DateFilter } from '../types/types';

import { PaginatedTable } from './PaginatedTable';
import { SignalDetailsModal } from './SignalDetailsModal';
import { SymbolLink } from './SymbolLink';

import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import {selectTheme} from '../features/lightDarkSwitch/lightDarkSwitchSlice'
import {getThemeGreen, getThemeRed} from '../themeRegistry'
import { getSignals, getSignalsLoggedOut } from '../services/signalApi';
import { SignalBrowserFilter } from './SignalBrowserFilter'


interface SignalQueryParams {
    include_public?: boolean
    context_filter?: string
}


interface SignalListParams {
    name?: string
    data_set_id?: string
    symbol?: string
    showButtons?: boolean
    addContextColumns?: boolean
    queryParams?: SignalQueryParams
    addButtons?: ReactElement
    defaultDateFilter?: DateFilter
    limit?: number
}


const getDateFilterValue = (dateFilter: DateFilter) => {
    const dateFilterValueMapping = {
        '30_days': 30,
        '7_days': 7,
        'today': 1,
    }
    const days = dateFilterValueMapping[dateFilter] || 7
    const dt = DateTime.now();
    return dt.minus({days}).plus({hours: 1}).toISODate()
}


export function SignalList({
    name,
    data_set_id,
    symbol,
    showButtons=true,
    addContextColumns=false,
    queryParams,
    addButtons,
    defaultDateFilter,
    limit,
}: SignalListParams) {
    const account = useAppSelector(selectAccount)
    const [reloadedLast, setReloadedLast] = useState('never');
    const [dateFilter, setDateFilter] = useState<DateFilter>(defaultDateFilter ? defaultDateFilter : '7_days');
    const [symbolFilter, setSymbolFilter] = useState<string | null>(null);
    const [detailSignal, setDetailSignal] = useState<Signal | null>(null);
    const selectedTheme = useAppSelector(selectTheme)
    // const light = useAppSelector(selectLight)

    const bullish_color = getThemeGreen(selectedTheme)
    const bearish_color = getThemeRed(selectedTheme)

    useEffect(() => {
        if (defaultDateFilter) {
            setDateFilter(defaultDateFilter)
        }
    }, [defaultDateFilter])


    const showSignalDetails = (signal: Signal) => {
        setDetailSignal(signal)
    }


    const content = () => {
        let columns: ColumnsType<Signal> = [
            {
              title: 'Symbol',
              dataIndex: 'symbol',
              key: 'symbol',
              render: (text: string, row: Signal) => {
                return <SymbolLink symbol={row.symbol} />
              },
              sorter: true,
              ...(addContextColumns ? {fixed: 'left'} : {})
            },
            {
              title: 'Value',
              dataIndex: 'value',
              key: 'value',
              sorter: true,
              sortDirections: ['descend', 'ascend'],
              render: (text: string, row: Signal) => {
                const color = row.value > 0 ? bullish_color : bearish_color
                return <Tooltip placement="top" title={`${row.value > 0 ? 'Positive' : 'Negative'} Value. See Data Set for Details.`}>
                    <span style={{color}}>{row.value > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}{Math.abs(parseFloat(text))}</span>
                </Tooltip>
                
              }
            //   defaultSortOrder: 'descend',
            },
            // {
            //   title: 'TTL Minutes',
            //   dataIndex: 'ttl_minutes',
            //   key: 'ttl_minutes',
            //   sorter: true,
            // },
            {
              title: 'Data Set',
              dataIndex: 'data_set_id',
              key: 'data_set_id',
              render: (text: string, row: Signal) => {
                return <Link to={`/data_sets/${row.data_set_id}/`}>{row.data_set.name}</Link>
              }
            //   render: (value: string, row: DataSet) => <a>{row.name}</a>
            },
            // {
            //   title: 'Data Provider',
            //   dataIndex: 'data_provider_id',
            //   key: 'data_provider_id',
            //   render: (text: string, row: DataSet) => {
            //     return <Link to={`/data_providers/${row.data_provider.id}/`}>{row.data_provider.name}</Link>
            //   }
            // //   render: (value: string, row: DataSet) => <a>{row.data_provider.name}</a>

            // },
            {
                title: 'Posted At',
                dataIndex: 'updated_date',
                key: 'updated_date',
                render: (value: string, row: Signal) => (
                    DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
  
                ),
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                // defaultSortOrder: 'descend',
            },
            {
                title: 'Action',
                key: 'id',
                dataIndex: 'id',

                // fixed: 'right',
                render: (value: string, row: Signal) => (
                    <Space>
                        <Tooltip placement="top" title="See Details">
                            <a onClick={() => {showSignalDetails(row)}}>
                            {/* <ArrowsAltOutlined /> */}
                                See more
                            </a>
                        </Tooltip>


                        {/* <Tooltip placement="top" title="Add to Clipboard">
                            <a><PlusCircleOutlined /></a>
                        </Tooltip>

                        <Tooltip placement="top" title="Send...">
                            <a><SendOutlined /></a>
                        </Tooltip> */}
                        
                    </Space>
  
                )
                // render: (value: string, row: Signal) => <a href={`https://www.tradingview.com/chart/?symbol=${row.symbol}`} target='blank'>See on Trading View</a>
                // render: (value: string, row: DataSet) => <a>Subscribe</a>,
            }

        ];
        
        const getPagination = (data: any) => {
            const paginationParams = {
                limit: data.pageSize,
                ...(data.current > 1 ? {offset: (data.current - 1) * data.pageSize} : {}),
                // offset: (data.current - 1) * data.pageSize,
            }
            console.log('SignalList getPagination', {data, paginationParams})
            return paginationParams
            // return {}
        }

        const getSorter = (data: any) => {
            if (data && data.field && data.order) {
                return {ordering: `${data.order === 'ascend' ? '' : '-'}${data.field}`}
            }
            return {}
        }

        const fetchData = (data: any) => {
            console.log("SignalList fetchData", {data})
            const getSignalsFn = account ? getSignals : getSignalsLoggedOut;
            return getSignalsFn({
                ...(data_set_id ? {data_set: data_set_id} : {}),
                ...(symbol ? {symbol: symbol} : {}),
                ...(!symbol && symbolFilter ? {symbol__icontains: symbolFilter} : {}),
                ...(queryParams ? queryParams : {}),
                // ...(data.filters ? getFilters(data.filters) : {}),
                ...(dateFilter ? {updated_date__gt: getDateFilterValue(dateFilter)} : {}),
                ...(limit === undefined && data.pagination ? getPagination(data.pagination) : {limit}),
                ...(data.sorter ? getSorter(data.sorter) : {ordering: '-updated_date'}),
                // ...(data.)
                include_nested_data_set: true,
            }).then((res) => {
                console.log("SignalList signals", {res})
                if (res.status === 200) {
                    return {
                        results: res.data.results || [],
                        count: res.data.count || 0
                    }
                } else {
                    return {
                        results: [],
                        count: 0
                    }
                }
            }).catch(() => {
                return {
                    results: [],
                    count: 0
                }
            })
        }

        // const updateColumnsFromData = (columns, data) => {
        const updateColumnsFromData = (columns: ColumnsType<Signal>, data: Signal[]) => {
            if (!addContextColumns) {
                return columns
            }
            const contextColumns = data.reduce((contextCols: string[], signal: Signal) => {
                if (!signal.context) {
                    return contextCols
                }
                const cols = Object.keys(signal.context)
                const colsToAdd: string[] = cols.filter((col) => !contextCols.includes(col))
                // console.log("contextColumns", {cols, colsToAdd, signal})
                return [...contextCols, ...colsToAdd]
            }, [])
            console.log("SignalList contextColumns", {contextColumns})
            const handleContextSort = (a: Signal, b: Signal, col: string) => {
                if (a.context === undefined || b.context === undefined) {
                    return 0
                }
                if (a.context[col] === undefined || b.context[col] === undefined || a.context[col] === null ||  b.context[col] === null) {
                    return 0
                }
                const a_val = a.context[col] || 0
                const b_val = b.context[col] || 0
                if (typeof a_val === 'number' && typeof b_val === 'number') {
                    return a_val - b_val
                }
                return 0
            }
            const new_columns: ColumnsType<Signal> = contextColumns.map((col: string) => ({
                title: col,
                key: col,
                dataIndex: `context__${col}`,
                // filters: [
                //     {
                //       text: 'Joe',
                //       value: 'Joe',
                //     },
                //     {
                //       text: 'Jim',
                //       value: 'Jim',
                //     },
                    
                // ],
                // specify the condition of filtering result
                // here is that finding the name started with `value`
                // onFilter: (value: string | number | boolean, record: Signal) => true,
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                // defaultSortOrder: 'descend',
                // sortDirections: ['descend'],

                // fixed: 'right',
                // // width: 180,
                render: (value: string, row: Signal) => (
                    <>{row.context[col] === null ? '-' : row.context[col]}</>
                )
            }))
            return [...columns, ...new_columns]
        }
        console.log("SignalList Returning...")

        const emptyDescription = () => {
            if (dateFilter !== '30_days'){
                return "No Signals. Consider broadening your time range."
            }
            if (!data_set_id) {
                return "No Signals. You may need to subscribe to some data sets."
            }
            return "No Signals Found"
        }

        return <PaginatedTable<Signal>
            columns={columns}
            rowKey={(signal) => signal.id}
            fetchData={fetchData}
            filterValues={{dateFilter, reloadTime: reloadedLast, ...(symbolFilter ? { symbolFilter } : {})}}
            updateColumnsFromData={updateColumnsFromData}
            emptyDescription={emptyDescription()}
            includePagination={limit === undefined}
        />
            
    }

    const head = (
        <Typography.Title level={2}>{name}</Typography.Title>
        // <Typography.Title level={1}>{name}</Typography.Title>
    )

    const triggerReload = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    const onSearch = (value: string) => {
        console.log("onSearch", {value})
        setSymbolFilter(value)
    }

    const buttons = <SignalBrowserFilter
        symbol={symbol}
        dateFilter={dateFilter}
        symbolFilter={symbolFilter}
        addButtons={addButtons}
        reloadData={triggerReload}
        setDateFilter={setDateFilter}
        setSymbolFilter={setSymbolFilter}
        style={{marginBottom: '6px', marginTop: '6px'}}
    />

    return (
        <>
            {name && head}
            {showButtons && buttons}
            {content()}
            <SignalDetailsModal onCancel={() => {setDetailSignal(null)}} signal={detailSignal}/>
        </>
    )
    
    
    
}
