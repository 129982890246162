import { Card, Col, Row } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { NamedWorkflow } from '../../types/types';
// import { PageHeader } from '@ant-design/pro-layout';

import { useAppSelector, useIsBigScreen } from '../../app/hooks';
import { DefinedWorkflowRunner } from '../../components/DefinedWorkflowRunner';
import { MarkdownContent } from '../../components/MarkdownContent';
import { selectAccount } from '../../features/login/loginSlice';


import 'draft-js/dist/Draft.css';

const regularStyles = {fontSize: '1.1rem', lineHeight: '1.8em'}

export function WorkflowExecute() {
    const params = useParams()
    const account = useAppSelector(selectAccount)
    const isBigScreen = useIsBigScreen()

    const [loadedWorkflow, setLoadedWorkflow] = useState<NamedWorkflow | null>(null)
    const workflowName = params.name;

    const onWorkflowLoad = (workflow: NamedWorkflow) => {
        setLoadedWorkflow(workflow)
        document.title = `PyroPrompts | ${workflow.workflow_form.form_title}`
    }


    return (
        <div className="App">
            <div className="App-content">
                {/* Data Set: {dataset.name} */}
                <div className="App-page-content" style={regularStyles}>
                    <Row gutter={84} style={isBigScreen ? {marginTop: '56px'} : {}}>
                        <Col xs={24} md={14}>
                            {loadedWorkflow && loadedWorkflow.marketing_markdown && (
                                <MarkdownContent markdown={loadedWorkflow.marketing_markdown} />
                            )}

                        </Col>
                        <Col xs={24} md={10}>
                            <Card
                                cover={
                                    loadedWorkflow && loadedWorkflow.img_path ?
                                            <img
                                                alt="example"
                                                src={`https://assets.pyroprompts.com/img/${loadedWorkflow.img_path}`}
                                            /> : undefined
                                }
                            >
                                {workflowName && (
                                    <DefinedWorkflowRunner workflowName={workflowName} onLoad={onWorkflowLoad} onComplete={() => {}} />
                                )}
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    );
}
