import type { MenuProps } from 'antd';
import { Avatar, Col, Descriptions, List, Row, Space, Tag, Tooltip, Typography } from 'antd';
import { createElement, ReactElement, useEffect, useState } from 'react';

// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useIsBigScreen } from '../app/hooks'

import type { RadioChangeEvent } from 'antd';

import { DateTime, Duration } from 'luxon';

import { Link } from "react-router-dom";
import { Signal, DateFilter } from '../types/types';
// import styles from './SignalList.module.css';
import { default as BearIcon } from '../images/bear';
import { default as BullIcon } from '../images/bull';
import { SignalBrowserFilter } from './SignalBrowserFilter';
import { SignalDetailsModal } from './SignalDetailsModal';
import { SymbolLink } from './SymbolLink';

import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getSignals, getSignalsLoggedOut } from '../services/signalApi';
import {selectTheme} from '../features/lightDarkSwitch/lightDarkSwitchSlice'
import {getThemeGreen, getThemeRed} from '../themeRegistry'

  
interface SignalQueryParams {
    include_public?: boolean
    context_filter?: string
}


interface SignalListParams {
    name?: string
    data_set_id?: string
    symbol?: string
    showButtons?: boolean
    addContextColumns?: boolean
    queryParams?: SignalQueryParams
    addButtons?: ReactElement
    defaultDateFilter?: DateFilter
    ordering?: string
    show_field_as_value?: string
    inverse_bull_bear?: boolean
    limit?: number
}


const getDateFilterValue = (dateFilter: DateFilter) => {
    const dateFilterValueMapping = {
        '30_days': 30,
        '7_days': 7,
        'today': 1,
    }
    const days = (dateFilterValueMapping[dateFilter] || 7)
    const dt = DateTime.now();
    return dt.minus({days}).plus({hours: 1}).toISODate()
}


export function SignalStream({
    name,
    data_set_id,
    symbol,
    showButtons=true,
    addContextColumns=false,
    queryParams,
    addButtons,
    defaultDateFilter,
    ordering="-updated_date",
    show_field_as_value,
    inverse_bull_bear,
    limit,
}: SignalListParams) {
    const account = useAppSelector(selectAccount)
    const [reloadedLast, setReloadedLast] = useState('never');
    const [dateFilter, setDateFilter] = useState<DateFilter>(defaultDateFilter ? defaultDateFilter : '7_days');
    const [symbolFilter, setSymbolFilter] = useState<string | null>(null);
    const [detailSignal, setDetailSignal] = useState<Signal | null>(null);
    const [signals, setSignals] = useState<Signal[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [signalsCount, setSignalsCount] = useState<number>(1);
    const selectedTheme = useAppSelector(selectTheme)
    const isBigScreen = useIsBigScreen()

    const pageSize = limit === undefined ? 20 : limit
    // const effectiveDateFilter = dateFilter ? dateFilter : defaultDateFilter;


    const showSignalDetails = (signal: Signal) => {
        setDetailSignal(signal)
    }

    const handleDateFilterRadioChange = (e: RadioChangeEvent) => {
        setDateFilter(e.target.value)
        
    }

    const handleDateFilterSelectChange = (value: DateFilter) => {
        setDateFilter(value)
    }


    console.log("SignalStream", {data_set_id, name, symbol, showButtons, queryParams, defaultDateFilter, ordering})

    // const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (defaultDateFilter) {
            setDateFilter(defaultDateFilter)
        }
    }, [defaultDateFilter])

    useEffect(() => {
        const getSignalsFn = account ? getSignals : getSignalsLoggedOut;
        setTimeout(() => {
        getSignalsFn({
            ...(data_set_id ? {data_set: data_set_id} : {}),
            ...(symbol ? {symbol: symbol} : {}),
            ...(!symbol && symbolFilter ? {symbol__icontains: symbolFilter} : {}),
            ...(dateFilter ? {updated_date__gt: getDateFilterValue(dateFilter)} : {}),
            include_nested_data_set: true,
            limit: pageSize,
            ...(page > 1 ? {offset: (page - 1) * pageSize}: {}),
            ...queryParams,
            ordering,
        }).then((res) => {
            console.log("signals", {res})
            const response_data = res.data.results
            setSignals(response_data || [])
            if (res.data.count) {
                setSignalsCount(res.data.count)
            }
        }
        ).catch(() => {
            setSignals([])
        }).finally(() => {
        })
    }, Math.floor(Math.random() * 1000))
    // }, 5000)
    }, [JSON.stringify({account}), reloadedLast, dateFilter, page, symbolFilter, ordering, data_set_id, JSON.stringify({queryParams}), limit])

    const reloadData = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    const head = (
        name ? <Typography.Title level={1}>{name}</Typography.Title> : <></>
    )

    // const up = <UpCircleOutlined  style={{ color: '#389e0d' }} />
    // const up = <ArrowUpOutlined  />
    const up = <BullIcon />
    // const down = <DownCircleOutlined  style={{ color: '#cf1322' }} />
    // const down = <ArrowDownOutlined />
    const down = <BearIcon />
    const flat = <MinusCircleOutlined style={{ color: '#1765ad' }} />


    const bullish_color = getThemeGreen(selectedTheme)
    const bearish_color = getThemeRed(selectedTheme)

    const getSignalAvatar = (signal: Signal) => {
        const context_field = show_field_as_value !== undefined ? show_field_as_value : 'forecast'
        const value = signal.context[context_field] || signal.context?.last_move || signal.value
        const bull_bear_value = (inverse_bull_bear && Number(value)) ? Number(value) * -1 : value
        const icon = bull_bear_value === 0 ? flat : bull_bear_value > 0 ? up : down
        const style = bull_bear_value === 0 ? {} : bull_bear_value > 0 ? { backgroundColor: bullish_color } : { backgroundColor: bearish_color }
        const text = bull_bear_value === 0 ? 'Sideways' : bull_bear_value > 0 ? 'Bullish' : 'Bearish'
        return <Tooltip placement="left" title={text}>
            <Avatar size='large' style={style} icon={icon} />
        </Tooltip>

    }

    const getSignalValue = (signal: Signal) => {
        if (show_field_as_value && signal.context && signal.context[show_field_as_value]) {
            return signal.context[show_field_as_value]
        }
        return signal.value
    }

    const IconText = ({ icon, text, onClick }: { icon: React.FC; text: string; onClick?: () => void }) => (
        <Space onClick={onClick ? onClick : undefined}>
          {createElement(icon)}
          {text}
        </Space>
      );
    const onSearch = (value: string) => {
        console.log("onSearch", {value})
        setSymbolFilter(value)
    }

    const menu = (signal: Signal): MenuProps['items'] => {
        return [
            {
                label: <a onClick={() => {showSignalDetails(signal)}}>See more</a>,
                key: '0',
            },
            // {
            //     label: <span>Add to Watchlist</span>,
            //     key: '1',
            // },
            // {
            //     label: <span>Add Label to Symbol</span>,
            //     key: '2',
            // },
            // {
            //     label: <span>Delete</span>,
            //     key: '3',
            // },
            // {
            //     label: <span>Share</span>,
            //     key: '4',
            // },

        ]
    }

    const buttons = () => {
        return <SignalBrowserFilter
            symbol={symbol}
            dateFilter={dateFilter}
            symbolFilter={symbolFilter}
            addButtons={addButtons}
            reloadData={reloadData}
            setDateFilter={setDateFilter}
            setSymbolFilter={setSymbolFilter}
        />
    }

    const contextElement = (signal: Signal) => {
        if (!addContextColumns || Object.keys(signal?.context || {}).length === 0) {
            return <></>
        }
        return <Descriptions title="Context" size='small'>
            {Object.keys(signal?.context || {}).map((context_key) => (
                <Descriptions.Item key={context_key} label={context_key}>{signal?.context[context_key] || ''}</Descriptions.Item>
                // <Typography.Text key={context_key}>{context_key}: {signal?.context[context_key] || ''}</Typography.Text>
            ))}
        </Descriptions>
    }


    const streamSignalsView = (keyedSignals: null | Signal[]) => {
        return <List
            className="signal-stream"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            itemLayout={'vertical'}
            // size="large"
            pagination={limit === undefined && signals && signalsCount > signals.length && signals.length > 0 ? ({
                onChange: (page) => {
                    console.log("pagination", {page});
                    setPage(page)
                },
                pageSize: pageSize,
                ...(signalsCount ? {total: signalsCount} : {})
            }): false}
            loading={keyedSignals === null}
            dataSource={keyedSignals === null ? [] : keyedSignals}
            header={
                showButtons ? buttons() : false
                // <div>Filters, buttons and stuff? Sorting. Context Columns to show? </div>
            }
            // footer={
            // <div>
            //     <b>freqsignals</b> footer part
            // </div>
            // }
            // size={'small'}
            // bordered={true}
            renderItem={(signal: Signal) => (
                <List.Item
                    key={signal.symbol}
                    // onClick={() => {showSignalDetails(signal)}}
                    actions={[
                        // <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                        // <IconText icon={EyeOutlined} text="Watch" key="list-vertical-star-o" />,
                        // createElement(EyeOutlined),

                        // <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                        // <IconText icon={MessageOutlined} text="2" key="list-vertical-message" onClick={() => {showSignalDetails(signal)}} />,
                        // <IconText icon={UploadOutlined} text="Share" key="list-vertical-star-o" />,
                        ...(isBigScreen ? [] : [
                            <a onClick={() => {showSignalDetails(signal)}}>See Details</a>
                        ]),
                        
                    ]}
                    {...(isBigScreen ? {extra: <a onClick={() => {showSignalDetails(signal)}}>See more</a>} : {})}
                >
                    <List.Item.Meta
                        // have avatar show dataset icon  or data provider ?
                        key={signal.id}
                        avatar={getSignalAvatar(signal)}
                        // avatar={<Avatar src={'https://joeschmoe.io/api/v1/random'} />}
                    // avatar={getSignalIcon(signal)}
                        title={
                        <Space>
                            {/* <span>By: <Link to={`/data_sets/${signal.data_set_id}/`}>{signal.data_set.name}</Link> · {signal.value}</span> */}
                            <SymbolLink symbol={signal.symbol} />
                            <Tooltip placement="right" title={`Signaled a value of ${signal.value} on ${signal.symbol} at ${DateTime.fromISO(signal.updated_date).toLocaleString(DateTime.DATETIME_MED)}, valid until ${DateTime.fromISO(signal.updated_date).plus({minutes: signal.ttl_minutes}).toLocaleString(DateTime.DATETIME_MED)}.${show_field_as_value ? ` Showing context field: ${show_field_as_value} set to ${getSignalValue(signal)}` : ''}`}>
                                <span style={{fontSize: "0.8rem", color: "#888"}}>{getSignalValue(signal)} | {DateTime.fromISO(signal.updated_date).toRelative()}</span>
                            </Tooltip>
                        </Space>}
                        description={<span>@{signal.data_set.data_provider.name} in <Link to={`/data_sets/${signal.data_set_id}/`}>{signal.data_set.name}</Link></span>}
                    />
                    {contextElement(signal)}
                    
                    {/* <Statistic title="Credits Remaining" value={1128} prefix={'B '} /> */}

                    {/* {signal.ttl_minutes} */}
                </List.Item>
            )}
        />

    }

    const content = () => {

        if (signals === null || signals.length === 0){
            return streamSignalsView(signals)
            
        }
        const keyedSignals = signals.map(v => ({key: v.id, ...v}))
        return streamSignalsView(keyedSignals)

        
    }




    return (
        <>
            {head}
            <Row>
            {/* <Col xs={{span: 24, offset: 0}} md={{span: 22, offset: 1}} lg={{span: 20, offset: 2}} xl={{span: 16, offset: 4}} xxl={{span: 12, offset: 6}}> */}
            <Col xs={{span: 24, offset: 0}}>
                    {content()}
                </Col>
            </Row>
            
            <SignalDetailsModal onCancel={() => {setDetailSignal(null)}} signal={detailSignal}/>

        </>
    )
    
    
}
