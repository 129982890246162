import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useMediaQuery } from 'react-responsive'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useIsXLargeScreen = () => useMediaQuery({ query: '(min-width: 1000px)' });
export const useIsBigScreen = () => useMediaQuery({ query: '(min-width: 768px)' });
export const useIsSmallScreen = () => useMediaQuery({ query: '(max-width: 540px)' });
