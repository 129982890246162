import { Col, Row, Typography, Space, Drawer, Button } from 'antd';
import Icon, { TwitterOutlined, RedditOutlined, GithubOutlined, YoutubeOutlined, InstagramOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { DateTime } from 'luxon';

import { useIsBigScreen } from '../app/hooks'
import { LightDarkSwitch } from '../features/lightDarkSwitch/LightDarkSwitch';
import { CopyToClipboard } from '../components/CopyToClipboard'
// import { default as DiscordIcon } from '../images/discord'
import { default as XSocialNetworkIcon } from '../images/x_logo'
const { Paragraph: P } = Typography


export function ConsentBanner() {
    // TODO - sort out stacking responsiveness
    // span={6} for 4 columns. 24 columns. 1 of 4 and 4 of 5 could work. Need content.
    const isBigScreen = useIsBigScreen()
    const [showBanner, setShowBanner] = useState<boolean>(false)

    useEffect(() => {
        const zone = DateTime.local().zoneName
        if (zone.includes('Europe')) {
            const cookies_setting = localStorage.getItem("COOKIE_APPROVAL")
            if (cookies_setting === null) {
                setShowBanner(true)
            }
        }
    }, [])

    const rejectCookies = () => {
        localStorage.setItem("COOKIE_APPROVAL", "reject")
        setShowBanner(false)
    }

    const acceeptCookies = () => {
        localStorage.setItem("COOKIE_APPROVAL", "accept")
        setShowBanner(false)
    }

    return (
        <Drawer

            placement={'bottom'}
            closable={false}
            open={showBanner}
            mask={false}
            height={isBigScreen ? '160px' : '300px'}

          >
            <Typography.Title level={3} style={{marginTop: 0}}>We value your privacy</Typography.Title>
            <Row>
                <Col xs={24} md={18}>
                    <Typography.Paragraph>
                        We use cookies to enhance your browsing experience and serve personalized ads or content, and to analyze our traffic. By clicking 'Accept All', you consent to our use of cookies.
                    </Typography.Paragraph>

                </Col>
                <Col xs={24} md={6}>
                    <Space style={{float: 'right'}}>
                        <Button onClick={rejectCookies}>Reject All</Button>
                        <Button type='primary' onClick={acceeptCookies} >Accept All</Button>
                    </Space>

                </Col>
            </Row>
          </Drawer>
    )
}
