import { useLocation } from "react-router-dom";
import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
export function About() {
    let location = useLocation();

    const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
    const waiting = <Tooltip title="Awaiting Data"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
    const blocked = <Tooltip title="Awaiting Data"  placement="left"><PlayCircleOutlined  style={{ color: '#d32029' }} /></Tooltip>
    const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />

  return (
    <div className="App">
      <div className="App-content">
        <Typography.Title level={1}>About</Typography.Title>
        <div className="App-page-content">
            <Typography.Paragraph>
            About (default view) at: {location.pathname}
            </Typography.Paragraph>
            <Timeline>
              <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
              <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
              <Timeline.Item color="red">
                <Typography.Paragraph>Solve initial network problems 1</Typography.Paragraph>
                <Typography.Paragraph>Solve initial network problems 2</Typography.Paragraph>
                <Typography.Paragraph>Solve initial network problems 3 2015-09-01</Typography.Paragraph>
              </Timeline.Item>
              <Timeline.Item>
                <Typography.Paragraph>Technical testing 1</Typography.Paragraph>
                <Typography.Paragraph>Technical testing 2</Typography.Paragraph>
                <Typography.Paragraph>Technical testing 3 2015-09-01</Typography.Paragraph>
              </Timeline.Item>
              <Timeline.Item color="gray">
                <Typography.Paragraph>Technical testing 1</Typography.Paragraph>
                <Typography.Paragraph>Technical testing 2</Typography.Paragraph>
                <Typography.Paragraph>Technical testing 3 2015-09-01</Typography.Paragraph>
              </Timeline.Item>
              <Timeline.Item color="gray">
                <Typography.Paragraph>Technical testing 1</Typography.Paragraph>
                <Typography.Paragraph>Technical testing 2</Typography.Paragraph>
                <Typography.Paragraph>Technical testing 3 2015-09-01</Typography.Paragraph>
              </Timeline.Item>
              <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
                <Typography.Paragraph>Custom color testing</Typography.Paragraph>
              </Timeline.Item>
            </Timeline>

        </div>
      </div>
    </div>
  );
}
