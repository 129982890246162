import { Empty } from 'antd';
import { useParams } from 'react-router-dom';
import { Dashboard as DashboardComponent } from '../../components/Dashboard';

export function Dashboard() {
  const params = useParams()
  const id = params.id;
  
  if (id === undefined) {
    return (
      <div className="App">
        <div className="App-content">
          <Empty />
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <div className="App-content">
        {/* Dashboard: {id} */}
        <div className="App-page-content">

          <DashboardComponent id={id} can_edit={true} />
          {/* <DashboardFeatureComponent id={id} can_edit={true} /> */}
        </div>
      </div>
    </div>
  );
}
