
import { useEffect, useState } from "react";
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { Button, Divider, Tooltip, Typography, Space, Table, Popconfirm, message, Input, List, Card } from 'antd';

import { PauseOutlined, ArrowsAltOutlined, ReloadOutlined, UpCircleOutlined, DownCircleOutlined, SaveOutlined, AudioOutlined, PauseCircleOutlined, PlayCircleOutlined, CheckCircleOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';

interface AudioRecorderParams {
    onComplete: (blog: Blob) => void
    style?: React.CSSProperties
}


export function AudioRecorder({
    onComplete,
    style,
}: AudioRecorderParams) {
    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        mediaRecorder
    } = useAudioRecorder();

    console.log("audiorecorder", {mediaRecorder})

    useEffect(() => {
        if (recordingBlob) {
            onComplete(recordingBlob)
        }

    }, [recordingBlob])
    return <div style={style ? style : ({})}>

        {isRecording && (
            <Space wrap>

                {mediaRecorder && (

                    <LiveAudioVisualizer
                        mediaRecorder={mediaRecorder}
                        barWidth={2}
                        gap={2}
                        width={140}
                        height={30}
                        fftSize={512}
                        maxDecibels={-10}
                        minDecibels={-80}
                        smoothingTimeConstant={0.4}
                      />
                )}
                {isPaused && (
                    <Button type="primary" onClick={togglePauseResume}>Paused Recording... {recordingTime} Resume</Button>
                )}
                {!isPaused && (
                    <Button type="primary" onClick={togglePauseResume}><PauseOutlined /> Recording... {recordingTime} Pause</Button>
                )}
            <Button type="primary" onClick={stopRecording}><SaveOutlined />Stop</Button>
            </Space>
        )}
        {!isRecording && (
            <Button type="primary" onClick={startRecording}><AudioOutlined /> Record</Button>
        )}
    </div>
    
}
