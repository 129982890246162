
import { Prompt as PromptType, Account as AccountType } from '../types/types'
import { IconText } from './IconText'

interface PromptMarkerParams {
  account: AccountType | undefined
  count_field: 'star_count' | 'save_count'
  prompt_marked_field: 'starred' | 'saved'
  prompt: PromptType
  icon: React.FC
  icon_marked?: React.FC
  text: string | undefined
  onClick?: (prompt: PromptType) => void
  tooltip?: string,
  tooltip_marked?: string,
  tooltip_logged_out?: string,
  disabled?: boolean
  include_text_in_count?: boolean
}

export function PromptMarker({
  account,
  count_field,
  prompt_marked_field,
  prompt,
  icon,
  icon_marked,
  text,
  onClick,
  tooltip,
  tooltip_marked,
  tooltip_logged_out,
  disabled = false,
  include_text_in_count = false,
}: PromptMarkerParams) {
  
  const use_icon_marked = icon_marked ? icon_marked : icon;
  const use_tooltip_marked = tooltip_marked ? tooltip_marked : tooltip;
  const use_tooltip_logged_out = tooltip_logged_out ? tooltip_logged_out : tooltip;
  const is_plural = (prompt[count_field] || 0) > 1
  const marked_text = include_text_in_count ? `${prompt[count_field]} ${text}${is_plural ? 's' : ''}` : `${prompt[count_field]}`
  
  return (
    <IconText
      icon={prompt[prompt_marked_field] ? use_icon_marked : icon}
      text={prompt[count_field] ? marked_text : text}
      onClick={onClick ? (() => onClick(prompt)) : () => {}}
      tooltip={account ? (prompt[prompt_marked_field] ? use_tooltip_marked : tooltip)  : use_tooltip_logged_out}
      disabled={!account}
    />
  )

};
