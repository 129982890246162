import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { Project as ProjectType, ProjectSnippet as ProjectSnippetType, PromptPack as PromptPackType } from '../../types/types'
import { getProjects, getProjectSnippets, getPromptPacks } from '../../services/signalApi';

export interface ProjectsState {
    projects: ProjectType[] | null;
    snippets: ProjectSnippetType[] | null;
    prompt_packs: PromptPackType[] | null;
    status: 'idle' | 'loading' | 'failed';
    prompt_pack_status: 'idle' | 'loading' | 'failed';
}

const initialState: ProjectsState = {
    projects: null,
    snippets: null,
    prompt_packs: null,
    status: 'idle',
    prompt_pack_status: 'idle',
}

export const loadProjectsAsync = createAsyncThunk(
  'projects/fetch',
  async () => {
    const response = await getProjects({
      ordering: '-created_date'
    }).then((projects_response) => {
        const projects_response_data = projects_response.data
        return getProjectSnippets({}).then((snips_response) => {
            const snips_response_data = snips_response.data
            return {
                projects: projects_response_data.results || [],
                snippets: snips_response_data.results || []
            }

        })
    })
    return response
  }
);

export const loadPromptPacksAsync = createAsyncThunk(
  'projects/fetch_prompt_packs',
  async () => {
    const response = await getPromptPacks({}).then((prompt_packs_response) => {
        const prompt_packs_response_data = prompt_packs_response.data
        return {
          prompt_packs: prompt_packs_response_data.results || [],
        }
    })
    return response
  }
);

export const projectslice = createSlice({
  name: 'projects',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log("clear")
      state.projects = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadProjectsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadProjectsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projects = action.payload.projects || []
        state.snippets = action.payload.snippets || []
      })
      .addCase(loadProjectsAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(loadPromptPacksAsync.pending, (state) => {
        state.prompt_pack_status = 'loading';
      })
      .addCase(loadPromptPacksAsync.fulfilled, (state, action) => {
        state.prompt_pack_status = 'idle';
        state.prompt_packs = action.payload.prompt_packs || []
      })
      .addCase(loadPromptPacksAsync.rejected, (state) => {
        state.prompt_pack_status = 'failed';
      })
  },
});

export const { clear } = projectslice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectProjects = (state: RootState) => state.projects.projects;
export const selectStatus = (state: RootState) => state.projects.status;
export const selectPromptPacks = (state: RootState) => state.projects.prompt_packs;
export const selectPromptPackStatus = (state: RootState) => state.projects.prompt_pack_status;

export default projectslice.reducer;
