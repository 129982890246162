import { Button, Form, Input, Select, Space, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { reloadAccount, selectAccount } from '../features/login/loginSlice';
import { triggerWorkflow, triggerWorkflowLoggedOut } from '../services/signalApi';
import { WorkflowTriggerFormSubmitTriggerArgs } from '../types/types';

import ReactGA from "react-ga4";

import { useAppDispatch, useAppSelector } from '../app/hooks';
const { Option, OptGroup } = Select;


interface WorkflowFormSubmitParams {
    workflow_id: string
    includeHead?: boolean
    includeCreditsTextIfNoCredits?: boolean
    credit_price: number | null
    enabled: boolean
    workflow_form: WorkflowTriggerFormSubmitTriggerArgs
    onSubmit: (workflowExecutionId?: string) => void
    allowAsDryRun?: boolean
}

export function WorkflowFormSubmit({
    workflow_id,
    includeHead=false,
    includeCreditsTextIfNoCredits=true,
    credit_price,
    enabled,
    workflow_form,
    onSubmit,
    allowAsDryRun=false,
}: WorkflowFormSubmitParams) {
    const account = useAppSelector(selectAccount)
    const dispatch = useAppDispatch()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [insufficientCreditsError, setInsufficientCreditsError] = useState<boolean>(false)

    const onWorkflowFormTrigger = (values: any) => {
        console.log("onWorkflowFormTrigger", {values})
        ReactGA.event("workflow_trigger", {workflow_id: workflow_id})
        if (!account) {
            setIsSubmitting(true)
            triggerWorkflowLoggedOut(workflow_id, values).then((res) => {
                setIsSubmitting(false)
                if (res.status === 200) {
                    onSubmit(res.data.workflow_execution_id)
                } else {
                    onSubmit()
                }
            })
        } else {
            setIsSubmitting(true)
            triggerWorkflow(account.id, workflow_id, values).then((res) => {
                setIsSubmitting(false)
                if (res.status === 200) {
                    onSubmit(res.data.workflow_execution_id)
                    console.log("triggering reload account")
                    dispatch(reloadAccount())
                } else {
                    console.log("failure!", {data: res.data})
                    if (res.data === "InsufficientFunds") {
                        setInsufficientCreditsError(true)
                    }
                    onSubmit()
                }
            })
        }


    }

    const formSubmitButton = () => {
        if (!enabled && allowAsDryRun) {
            return <Button type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
                {workflow_form.form_button_text ? workflow_form.form_button_text : 'Dry Run'}
            </Button>
        }
        if (!enabled ) {
            return <Tooltip placement='right' title="Workflow is Disabled. Enable to allow submission.">
                <Button type="primary" htmlType="submit" disabled={true}>
                    {workflow_form.form_button_text ? workflow_form.form_button_text : 'Run'}
                </Button>
            </Tooltip>
        }

        if (insufficientCreditsError || (credit_price !== null && credit_price > 0 && (account?.current_credits == null || account?.current_credits < credit_price))) {
            return <Tooltip placement='right' title="You don't have enough credits. Buy credits to run this Workflow.">
                <Button type="primary" htmlType="submit" disabled={true}>
                    {workflow_form.form_button_text ? workflow_form.form_button_text : 'Run'}
                </Button>
            </Tooltip>
        }

        return <Button type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
            {workflow_form.form_button_text ? workflow_form.form_button_text : 'Run'}
        </Button>
    }

    const formSubmitSubText = () => {
        if (includeCreditsTextIfNoCredits === true && enabled === false && (credit_price === null || credit_price === 0)) {
            return <Typography.Text style={{textAlign: 'right', fontSize: '0.8rem', color: '#888'}}>Disabled, no Credit Cost yet. <Link style={{color: 'unset'}} to='/store'>Learn about Credits.</Link></Typography.Text>

        }
        if (includeCreditsTextIfNoCredits === false && (credit_price === null || credit_price === 0)) {
            return <></>

        }
        if (credit_price === null || credit_price === 0) {
            return <Typography.Text style={{textAlign: 'right', fontSize: '0.8rem', color: '#888'}}>Free Play! <Link style={{color: 'unset'}} to='/store'>Learn about Credits.</Link></Typography.Text>

        }
        if (credit_price > 0 && (account?.current_credits == null || account?.current_credits < credit_price)) {
            return <Typography.Text style={{textAlign: 'right', fontSize: '0.8rem', color: '#888'}}>Costs {credit_price} Credit{credit_price !== 1 ? 's' : ''} to run. Buy Credits from the <Link to='/store'>store.</Link></Typography.Text>
        }
        return <Typography.Text style={{textAlign: 'right', fontSize: '0.8rem', color: '#888'}}>Costs {credit_price} Credit{credit_price !== 1 ? 's' : ''} to run Workflow</Typography.Text>
    }

    const initial_vals: {[key: string]: string} = {}
    return (
        <Form
            name="workflow_form_trigger"
            onFinish={onWorkflowFormTrigger}
            autoComplete="off"
            layout='vertical'
            initialValues={workflow_form.submission_params.reduce((p, c, i) => {
                if (c.default_value) {
                    p[c.field] = c.default_value

                }
                return p
            }, initial_vals)}
        >
            {includeHead && (
                <Typography.Title level={3} style={{marginTop: '0'}}>Execute Workflow</Typography.Title>
            )}
            {workflow_form.form_title && (
                <Typography.Title level={4} style={includeHead ? {} : {marginTop: '0'}}>{workflow_form.form_title}</Typography.Title>

            )}
            <Typography.Paragraph>{workflow_form.form_description || 'This workflow is configured to be triggered via this form.'}</Typography.Paragraph>

            {workflow_form.submission_params.map((submissionParam) => (
                <div key={`workflow-wrapper-form-trigger-${submissionParam.field}}`}>
                    {(submissionParam.field_type || 'input') === 'input' && (
                        <Form.Item
                            key={`workflow-form-trigger-${submissionParam.field}}`}
                            label={('label' in submissionParam && submissionParam.label != undefined) ? submissionParam.label : submissionParam.field}
                            name={submissionParam.field}

                        >
                            <Input defaultValue={submissionParam.default_value ? submissionParam.default_value : undefined} {...(submissionParam.placeholder ? {placeholder: submissionParam.placeholder} : {})} />
                        </Form.Item>

                    )}
                    {(submissionParam.field_type || 'input') === 'text_area' && (

                        <Form.Item
                            // {...trigger}
                            key={`workflow-form-trigger-${submissionParam.field}}`}
                            label={('label' in submissionParam && submissionParam.label != undefined) ? submissionParam.label : submissionParam.field}
                            name={submissionParam.field}

                        >
                            <Input.TextArea defaultValue={submissionParam.default_value ? submissionParam.default_value : undefined} style={{ height: 120 }} {...(submissionParam.placeholder ? {placeholder: submissionParam.placeholder} : {})} />
                        </Form.Item>
                    )}
                </div>
            ))}

            <Form.Item style={{marginBottom: 0}}>
                <div style={{float:'right'}}>
                    <Space direction='vertical'>
                        <div style={{float:'right'}}>
                            {formSubmitButton()}
                        </div>
                        {formSubmitSubText()}
                    </Space>

                </div>
            </Form.Item>
            {/* <Typography.Paragraph>TODO: Add historical runs/examples</Typography.Paragraph> */}
            {/* <Typography.Paragraph>TODO: Don't show cost for no-auth. allow-run, then say it could be delayed due to demand, then ask for email to send result.</Typography.Paragraph> */}
        </Form>
    )
}
