
import { Typography, Button, Tooltip } from 'antd';

import { MarkdownContent } from './MarkdownContent';
import { ReadOutlined, AlignLeftOutlined } from '@ant-design/icons';

import { useState } from 'react'



interface ToggleToMarkdownParams {
    markdown: string
}

export function ToggleToMarkdown({
    markdown,
}: ToggleToMarkdownParams) {

    const [showMarkdown, setShowMarkdown] = useState(false)

    if (showMarkdown) {
        return <div>
            <Tooltip title='Hide Formatting'>
                <Button icon={<AlignLeftOutlined />} onClick={() => setShowMarkdown(false)} style={{float: 'right'}} />
            </Tooltip>
            <MarkdownContent markdown={markdown} />
        </div>
    }
    return <div>
        <Tooltip title='Show with Markdown Formatting'>
            <Button icon={<ReadOutlined />} onClick={() => setShowMarkdown(true)} style={{float: 'right'}} />
        </Tooltip>

        <Typography.Paragraph style={{whiteSpace: 'pre-line'}}>{markdown}</Typography.Paragraph>
    </div>


}
