import { Button, Typography, Input, Form } from 'antd';
import { useState } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import ReactGA from "react-ga4";


import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { submitNewsletterEmail } from '../services/signalApi';

interface NewsLetterRegisterParams {
    sign_up_button_text?: string
}

const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export function NewsLetterRegister({
    sign_up_button_text='Get Updates'
}: NewsLetterRegisterParams) {
    console.log("NewsLetterRegister")
    const account = useAppSelector(selectAccount)
    // const [isLoading, setIsLoading] = useState(false);
    const [enteredEmail, setEnteredEmail] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [successState, setSuccessState] = useState<string | null>(null);
    const [errorState, setErrorState] = useState<string | null>(null);

    const submitEmail = () => {
        console.log("submitEmail", {enteredEmail})
        if (successState) {
            console.log("successful skip", {enteredEmail})
            return;
        }
        // setSuccessState(null)
        if (enteredEmail === null || enteredEmail === "") {
            setErrorState("Enter an email")
            return;
        } else if (errorState) {
            setErrorState(null)
        }

        const serchfind = regexp.test(enteredEmail);
        console.log("serchfind", {serchfind})
        if (!serchfind) {
            setErrorState("Enter a valid email")
            return
        } else if (errorState) {
            setErrorState(null)
        }
        if (enteredEmail) {
            setIsSubmitting(true)
            submitNewsletterEmail(enteredEmail).then((res) => {
                setSuccessState("Check your email to confirm!")
                ReactGA.event('generate_lead', {currency: 'USD', value: 1.00});
            }).catch((e) => {
                console.log(e)
                setErrorState("Something went wrong")
                ReactGA.event("error", {location: "newsletter_register"})
            }).finally(() => {
                setIsSubmitting(false)

            })
        }
    }


    return (
        <>
            <Form
                onFinish={submitEmail}
            >
                <Space.Compact style={{ width: '100%' }} size='large'>
                    <Input placeholder="email address" onChange={(e) => setEnteredEmail(e.target.value)} />
                    <Button type="primary" htmlType="submit" aria-disabled={isSubmitting || successState !== null}>{isSubmitting ? <><LoadingOutlined spin />&nbsp;Registering</> : <>{sign_up_button_text}</>}</Button>
                </Space.Compact>
                {errorState && (
                    <Typography.Text style={{color: 'red'}}>{errorState}</Typography.Text>
                )}
                {successState && (
                    <Typography.Text style={{color: 'green'}}>{successState}</Typography.Text>
                )}
            </Form>

        </>
    )
    
    
    
}
