import { BranchesOutlined, CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, EyeInvisibleOutlined, GlobalOutlined, UploadOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Card, Col, Divider, Dropdown, Empty, Form, Input, Row, Space, Typography } from 'antd';
import { useState } from 'react';
import ReactGA from "react-ga4";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { deletePrompt, getPrompts, getPromptsLoggedOut, updatePrompt } from '../../services/signalApi';
// import { PageHeader } from '@ant-design/pro-layout';
import { useEffect } from "react";

import 'react-quill/dist/quill.snow.css';
import { useAppDispatch, useAppSelector, useIsBigScreen } from '../../app/hooks';
import { Loading } from '../../components/Loading';
import { extractBracketContents, extractSingleLineBracketContents, replaceBracketContents, replaceBracketContentsWithNode } from '../../utils';

import { selectAccount } from '../../features/login/loginSlice';
// import { DataSetSubscribeModal } from '../../components/DataSetSubscribeModal'
import { CopyToClipboard } from '../../components/CopyToClipboard';
// import { DataSetEditModal } from '../../components/DataSetEditModal'
import { IconText } from '../../components/IconText';
import { UserAvatar } from '../../components/UserAvatar';
// import {populatePrompt} from '../../features/aiChat/aiChatSlice'
import { useQuery } from '@tanstack/react-query';
import 'draft-js/dist/Draft.css';
import { CreateWorkflowFromPromptModal } from '../../components/CreateWorkflowFromPromptModal';
import { EditPromptModal } from '../../components/EditPromptModal';
import { PromptSaveMarker } from '../../components/PromptSaveMarker';
import { PromptStarMarker } from '../../components/PromptStarMarker';
import { PromptTag } from '../../components/PromptTag';
import { COMMON_EMPTY_RESPONSE } from '../../utils';



export function Prompt() {
    const params = useParams()
    const account = useAppSelector(selectAccount)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isBigScreen = useIsBigScreen()
    const prompt_id = params.id;

    const [promptSetTokens, setPromptSetTokens] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const action = searchParams.get("action")

    const {
        data: { data: { count: loadedPromptsCount, results: loadedPrompts}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchPrompts,
        isFetched: isFetchedPrompts,
        isFetching: isFetchingPrompts,
    } = useQuery({
        queryKey: ['account', account?.id, 'prompt', prompt_id],
        staleTime: 1000 * 60 * 60,
        enabled: !!prompt_id,
        queryFn: () => {
            // const prompt_ids_needed = prompt_ids.filter((id) => id && !workflowPromptIds.includes(id))
            const getPromptsFn = account ? getPrompts : getPromptsLoggedOut;
            return getPromptsFn({id: prompt_id})
        }
    })

    const prompt = (loadedPrompts ? loadedPrompts[0] : null)

    useEffect(() => {
        if (prompt === null || prompt === undefined) {
            document.title = 'PyroPrompts | Prompt'
            document.getElementsByName("twitter:title")[0].setAttribute('content', `PyroPrompts Prompt`);
            document.getElementsByName("twitter:description")[0].setAttribute('content', `PyroPrompts Prompt`);
        } else {
            document.title = `PyroPrompts | Prompt: ${prompt.name}`
            document.getElementsByName("twitter:title")[0].setAttribute('content', `PyroPrompts Prompt: ${prompt.content}`);
            document.getElementsByName("twitter:description")[0].setAttribute('content', prompt && prompt.content ? prompt.content : `PyroPrompts Prompt`);
        }
    }, [JSON.stringify({prompt})])



    if (!prompt_id) {
        return <Typography.Paragraph>No Prompt</Typography.Paragraph>
    }

    const onClickDeletePrompt = () => {
        deletePrompt(prompt_id).then(() => {
            navigate('/')
        })
    }

    const onClickMakePublicPrompt = () => {
        console.log("make public")
        updatePrompt(prompt_id, {is_public: 1}).then(() => {
            refetchPrompts()
        })
    }

    const closeActionModals = () => {
        searchParams.delete("action")
        setSearchParams(searchParams)
    }

    const onPromptUpdated = () => {
        console.log("onPromptUpdated")
        searchParams.delete("action")
        setSearchParams(searchParams)
        refetchPrompts()
    }

    const handleCreateWorkflow = () => {
        // refetchPrompts()
    }


    if (isFetchingPrompts) {
        return (
            <div className="App">
                <div className="App-content">
                    <Loading message='Prompt' description='Loading Prompt'  style={{marginTop: '12px'}}/>

                </div>
            </div>
        );
    }

    if (prompt_id === undefined || prompt === undefined || prompt === null) {
        return (
            <div className="App">
                <div className="App-content">
                    <Empty description={"No Prompt Found"} image={Empty.PRESENTED_IMAGE_SIMPLE} />

                </div>
            </div>
        );
    }



    const ownedPrompt = (account && prompt && account.id === prompt.account.id)

    const right = () => {
        if (!ownedPrompt) {
            return <></>
        }
        const menu: MenuProps['items'] = [
            ...(!prompt.is_public ? [
                {
                    label: <a onClick={onClickMakePublicPrompt}>Make Public</a>,
                    key: '1',
                    icon: <GlobalOutlined />,
                },
            ] : []),
            {
                label: <a onClick={onClickDeletePrompt}>Delete</a>,
                key: '2',
                icon: <DeleteOutlined />,
            },
        ]
        return (
            <Dropdown menu={{items: menu}} trigger={['click']} placement="bottomRight">
                <EllipsisOutlined />
            </Dropdown>
        )
    }

    const sub = () => {
        return (
            <>
                <Space>
                    <UserAvatar account={prompt.account} />
                    <Link to={`/u/${prompt.account.name}/`}>{prompt.account.name}</Link>
                    {prompt.is_public ? <GlobalOutlined />: <EyeInvisibleOutlined />}
                    {/* <span>{DateTime.fromISO(prompt.created_date).toRelative()}</span> */}
                </Space>
                {/* {ownedAccount && (<Tag color="black">Me</Tag>)} */}
                {/* {dataset.is_public === 1 && (<Tag color="blue">Public</Tag>)}
        {dataset.is_public !== 1 && (<Tag color="purple">Private</Tag>)}
        {dataset.is_published !== 1 && (<Tag color="yellow">Unpublished</Tag>)}
        {ownedDataSet && (<Tag color="black">Mine</Tag>)} */}
            </>
        )
    }

    const openCreateWorkflowModal = () => {
        console.log("openCreateWorkflowModal", {prompt})
        searchParams.set("action", "create_workflow")
        setSearchParams(searchParams)
    }

    const tokens = prompt && prompt.content ? extractBracketContents(prompt.content) : []
    const whole_line_tokens = prompt && prompt.content ? extractSingleLineBracketContents(prompt.content) : []

    const setInRenderedPrompt = (token: string, value: string) => {
        setPromptSetTokens({...promptSetTokens, ...{[token]: value}})
    }

    const renderedPrompt = tokens && prompt.content && promptSetTokens ? replaceBracketContents(prompt.content, promptSetTokens) : prompt.content
    const htmlRenderedPrompt = tokens && prompt.content && promptSetTokens ? replaceBracketContentsWithNode(prompt.content, promptSetTokens, {}) : prompt.content

    return (
        <div className="App">
            <div className="App-content">
                {/* Data Set: {dataset.name} */}
                <div className="App-page-content">

                    {/* <PageHeader

              title={<Space size={'large'}><span>{prompt.name ? prompt.name : `Prompt by ${prompt.account.name}`}</span></Space>}
              right={right()}
              // sub={sub()}
            /> */}
                    <Row gutter={24} style={{marginTop: '12px'}}>
                        <Col xs={24} md={8}>
                            <Card
                                title={'Prompt Metadata'}
                                style={{marginBottom: '12px'}}
                                extra={right()}
                                // extra={<a href="#">Editas</a>}

                                // bordered={light ? true : false}
                            >
                                <Space direction='vertical' style={{width: '100%'}}>
                                    <Typography.Title level={3} style={{marginTop: 0}}>{prompt.name ? prompt.name : `Prompt by ${prompt.account.name}`}</Typography.Title>
                                    <Space>
                                        <UserAvatar account={prompt.account} />
                                        <Link to={`/u/${prompt.account.name}/`}>{prompt.account.name}</Link>
                                    </Space>
                                    <Space>
                                        {prompt.is_public ? <GlobalOutlined />: <EyeInvisibleOutlined />}
                                        {/* <span>{DateTime.fromISO(prompt.created_date).toRelative()}</span> */}
                                        <span>{prompt.is_public ? "Public": "Private"}</span>
                                    </Space>


                                    <Space wrap>
                                        {(prompt.tags || []).map((tag, i) => (
                                            <PromptTag tag={tag} key={tag.id} />
                                        ))}
                                    </Space>
                                    {prompt.description && (
                                        <Typography.Paragraph style={{whiteSpace: 'pre-line'}}>{prompt.description}</Typography.Paragraph>
                                    )}

                                    <Row>
                                        <Col span={12}>
                                            <PromptStarMarker prompt={prompt} onClick={() => setTimeout(() => refetchPrompts(), 200)} include_text_in_count={true} />
                                        </Col>
                                        <Col span={12}>
                                            <PromptSaveMarker prompt={prompt} onClick={() => setTimeout(() => refetchPrompts(), 200)} include_text_in_count={true} />
                                        </Col>
                                    </Row>
                                    {prompt.is_public === 1 && (
                                        <Row>
                                            <Col span={12}>
                                                <CopyToClipboard text={prompt.share_url} tooltip='Copy Share URL' onCopy={() => ReactGA.event("copy_prompt_url", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-page-side'})}   content={<IconText icon={UploadOutlined} text="Copy Share URL" />} key="list-vertical-share-o"/>
                                            </Col>
                                            <Col span={12}>
                                                <CopyToClipboard text={prompt.content || ''} tooltip='Copy Prompt'  onCopy={() => ReactGA.event("copy_prompt", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-page-side'})}  content={<IconText icon={CopyOutlined} text="Copy" />} key="list-vertical-copy-o"/>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col span={12}>
                                            <IconText
                                                icon={BranchesOutlined}
                                                text="Create Workflow"
                                                onClick={openCreateWorkflowModal}
                                                tooltip={account ? "Create a Workflow from Prompt" : "Log in to create a Workflow"}
                                                disabled={!account}
                                            />
                                        </Col>
                                    </Row>

                                    {/* <Row>
                      <Col span={12}>
                        <CopyToClipboard text={prompt.content || ''} tooltip='Copy Prompt'  onCopy={() => ReactGA.event("copy_prompt", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-page-side'})}  content={<IconText icon={CopyOutlined} text="Copy" />} key="list-vertical-copy-o"/>
                        </Col>
                        <Col span={12}>
                        <IconText icon={FireOutlined} text="Prompt" onClick={() => {
                          ReactGA.event("trigger_prompt", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-page-side'})
                          dispatch(populatePrompt({prompt: prompt.content || ''}))
                        }} key="list-vertical-prompt-o" />
                      </Col>
                    </Row> */}
                                </Space>
                            </Card>

                        </Col>
                        <Col xs={24} md={16}>
                            <Card
                                title={'Prompt'}
                                style={{marginBottom: '24px'}}
                                extra={
                                    <Space direction='horizontal'>
                                        {ownedPrompt && (
                                            <>
                                                <IconText icon={EditOutlined} text="Edit" onClick={() => {
                                                    searchParams.set("action", "edit")
                                                    setSearchParams(searchParams)
                                                }} key="list-vertical-edit-o" />
                                                <Divider type='vertical' />
                                            </>
                                        )}
                                        <CopyToClipboard text={prompt.content || ''} tooltip='Copy Prompt' onCopy={() => ReactGA.event("copy_prompt", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-page-card'})} content={<IconText icon={CopyOutlined} text="Copy" />} key="list-vertical-copy-o"/>
                                    </Space>
                                }
                                // extra={<a href="#">Editas</a>}

                                // bordered={light ? true : false}
                            >
                                <Typography.Paragraph style={{ fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{prompt.content}</Typography.Paragraph>
                            </Card>
                            {tokens && tokens.length >= 1 && prompt.content && (
                                <Card
                                    title={'Prompt Template'}
                                    style={{marginBottom: '12px'}}
                                    // extra={<a href="#">Editas</a>}

                                    // bordered={light ? true : false}
                                >
                                    <Row gutter={12}>
                                        <Col xs={24} md={10}>
                                            <Form
                                                // labelCol={{ span: 4 }}
                                                // wrapperCol={{ span: 20 }}
                                                initialValues={promptSetTokens}
                                                layout="vertical"
                                            >
                                                {tokens.map((token, i) => (
                                                    <Form.Item label={token} name={token} key={i}>
                                                        {whole_line_tokens.includes(token) ? (
                                                            <Input.TextArea
                                                                style={{ height: 120 }}
                                                                onChange={(e) => {setInRenderedPrompt(token, e.target.value)}}
                                                            />
                                                        ) : (
                                                            <Input onChange={(e) => {setInRenderedPrompt(token, e.target.value)}} />

                                                        )}
                                                    </Form.Item>
                                                ))}
                                            </Form>
                                        </Col>
                                        <Col xs={24} md={14}>
                                            {!isBigScreen && (
                                                <Divider />
                                            )}
                                            <Typography.Paragraph style={{marginBottom: '6px'}}>Filled Prompt</Typography.Paragraph>
                                            <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "0 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{htmlRenderedPrompt}</Typography.Paragraph>
                                            <Space wrap style={{float: 'right'}}>
                                                <a href={`https://chat.openai.com?m=${renderedPrompt || ''}`} target='_blank' style={{color: 'unset'}} rel="noreferrer">Start ChatGPT</a>
                                                <Divider type='vertical' />
                                                <CopyToClipboard text={renderedPrompt || ''} tooltip='Copy Prompt' onCopy={() => ReactGA.event("copy_prompt", {prompt_name: prompt.name, prompt_id: prompt.id, placement: 'prompt-page-template'})} content={<IconText icon={CopyOutlined} text="Copy" />} key="list-vertical-copy-o"/>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            )}


                        </Col>
                    </Row>

                    {prompt && (
                        <EditPromptModal key={`${prompt.id}-edit`} prompt={prompt} isOpen={ownedPrompt === true && action === 'edit'} onCancel={closeActionModals} onEdit={onPromptUpdated} />
                    )}

                    {prompt && (
                        <CreateWorkflowFromPromptModal key={`${prompt.id}-workflow`} prompt={prompt} isOpen={action === 'create_workflow'} onCancel={closeActionModals} onCreate={handleCreateWorkflow} />
                    )}

                </div>
            </div>
        </div>
    );
}
