import { CheckCircleOutlined, RightCircleOutlined, IssuesCloseOutlined, PlayCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { ApiIntegrationsList } from '../../features/apiIntegrationsList/ApiIntegrationsList';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectAccount } from '../../features/login/loginSlice'

export function Bots() {

    const account = useAppSelector(selectAccount);
    console.log("account", {account})


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Bots</Typography.Title>
                <div className="App-page-content">
                    <Typography.Paragraph>Manage Trading Bots</Typography.Paragraph>
                    {!account && (
                        <>
                            <Typography.Paragraph>Log In or Sign Up to manage bots. Bots allow you to</Typography.Paragraph>
                        </>
                    )}
                    {account && (
                        <Typography.Paragraph>Show Botlist</Typography.Paragraph>
                    )}
                </div>
            </div>
        </div>
    );
}
