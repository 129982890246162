import { Button } from 'antd';
import { DefinedWorkflowModal } from './DefinedWorkflowModal';
import { useState } from 'react';

interface DefinedWorkflowButtonParams {
    workflowName: string
    buttonText?: string
    icon?: React.ReactNode
    buttonType?: 'primary' | 'default' | 'text' | 'link'
    onComplete?: () => void
}

export function DefinedWorkflowButton({
    workflowName,
    buttonText='Edit Prompt',
    icon,
    buttonType='default',
    onComplete,
}: DefinedWorkflowButtonParams) {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const showModal = () => {
        setIsModalOpen(true)
    }

    const onModalComplete = () => {
        if (onComplete) {
            onComplete()
        }
    }

    return <>
        <div>
        <Button type={buttonType} icon={icon ? icon : undefined} onClick={showModal}>
            {buttonText}
        </Button>
        </div>
        <DefinedWorkflowModal workflowName={workflowName} isOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} onComplete={onModalComplete} />
    </>

}
