import { Descriptions, Typography, Empty } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect } from "react";

import { TradingViewChartWidget, TradingViewMiniChart, TradingViewSymbolInfo, TradingViewFundamentalData } from '../../components/TradingViewComponents'
import { SignalList } from '../../components/SignalList'
import { SymbolLink } from '../../components/SymbolLink'
import { PageHeader } from '../../components/PageHeader'
import { SignalSelectableBrowser } from '../../components/SignalSelectableBrowser'


export function Symbol() {
  const params = useParams()

  useEffect(() => {
    if (id === null) {
      document.title = 'PyroPrompts | Data Set'

    } else {
      document.title = `PyroPrompts | Symbol: ${id}`
    }
  }, [params])
  const id = params.id?.replace('-', '/');
  
  if (id === undefined) {
    return (
      <div className="App">
        <div className="App-content">
          
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <div className="App-content">
        <PageHeader
          title={id}
        />
        
        <Descriptions>
            <Descriptions.Item label="Symbol"><SymbolLink symbol={id} /></Descriptions.Item>
            {/* <Descriptions.Item label="Name">Foobar Data Provider</Descriptions.Item> */}
            {/* <Descriptions.Item label="Number of signals">500k</Descriptions.Item> */}
            {/* <Descriptions.Item label="Subscribers">243</Descriptions.Item> */}
            {/* <Descriptions.Item label="Quality">A</Descriptions.Item> */}
        </Descriptions>
        {/* <TradingViewSymbolInfo symbol={id} /> */}
        {/* <TradingViewMiniChart symbol={id} /> */}
        <SignalSelectableBrowser key={id} showButtons={true} symbol={id} addContextColumns={true}/>
        {/* <SignalList showButtons={true} symbol={id} addContextColumns={true}/> */}
        {/* <TradingViewChartWidget symbol={id} /> */}
        {/* <TradingViewFundamentalData symbol={id} /> */}

        {/* <div className="App-page-content"> */}
        {/* </div> */}
      </div>
    </div>
  );
}
