import { Avatar } from 'antd';
import { Account } from '../types/types'


export const hashcode = (str: string) => {
    let hash = 0
    let chr = 1
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export const get_avatar_color = (username: string) => {
  const s = hashcode(username)
//   console.log("colorstr", {username, s})
  const normalized_1 = (s % 10000) / 10000
  const normalized_2 = (s % 188) / 188
  const normalized_3 = (s % 256) / 256
  const normalized_4 = (s % 160) + 20
//   console.log("colorstr", {username, s, normalized_1, normalized_2, normalized_3})
  const color_1 = '#'+(0x1000000+(normalized_1) *0xffffff).toString(16).substring(0, 6)
  const color_2 = '#'+(0x1000000+(normalized_2) *0xffffff).toString(16).substring(0, 6)
  const color_3 = '#'+(0x1000000+(normalized_3) *0xffffff).toString(16).substring(0, 6)
//   console.log("colorstr", {username, s, normalized_1, normalized_2, normalized_3, normalized_4, color_1, color_2, color_3})

  return `linear-gradient(${normalized_4}deg, ${color_1} 10%, ${color_2} 48%, ${color_2} 52%, ${color_3} 90%`
  // return `linear-gradient(145deg, rgba(92,42,125,1) 10%, rgba(136,19,19,1) 50%, rgba(172,121,49,1) 90%`
}



interface UserAvatarParams {
  account: Account
}

export function UserAvatar({
    account,
}: UserAvatarParams){
    const username = account.name
    if (account.profile_pic_path) {
      return  (
          <Avatar style={{ backgroundSize: '50px 50px' }} src={`https://assets.pyroprompts.com/${account.profile_pic_path}`} />
      )
    }
    return  (
        <Avatar style={{background: get_avatar_color(username), backgroundSize: '50px 50px', backgroundPositionX: '-10px', backgroundPositionY: '-10px' }}>{username.substring(0, 1)}</Avatar>
    )
}
