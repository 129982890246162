import { Button, Form, Input, Modal, Select, Typography, message } from 'antd';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { selectAccount } from '../features/login/loginSlice';
import { createDashboard } from '../services/signalApi';
import { Dashboard } from '../types/types';

import { useAppSelector } from '../app/hooks';


interface DashboardModalParams {
    isOpen: boolean
    onCancel: () => void
    onCreate: () => void
    dashboardCopySource?: Dashboard
}


export function CreateDashboardModal({
    isOpen,
    onCancel,
    onCreate,
    dashboardCopySource,
}: DashboardModalParams) {
    const [form] = Form.useForm();
    const dashboardName = Form.useWatch('name', form);

    const account = useAppSelector(selectAccount);
    const [inFlight, setInFlight] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [newDashboard, setNewDashboard] = useState<Dashboard | null>(null);

    useEffect(() => {
        if (dashboardCopySource) {
            form.setFieldsValue({ name: `${dashboardCopySource.name} copy`})
        } else {
            form.setFieldsValue({ name: ''})

        }
      }, [JSON.stringify({dashboardCopySource: dashboardCopySource})]);

    console.log('CreateDashboardModal', {isCreating, inFlight, dashboardCopySource})

    const handleCancel = () => {
        form.resetFields()
        setNewDashboard(null)
        setIsCreating(false)
        onCancel()
    }

    const onCreateDashboardFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateDashboardSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateDashboard(values)
    };

    const onCreateDashboard = (values: any) => {
        if (account) {
            console.log("onCreateDashboard values", {values})
            setIsCreating(true)
            // TODO - allow starting with a template? wizard?
            createDashboard(
                account.id,
                values.name,
                dashboardCopySource ? dashboardCopySource.config : {widgets: []}
            ).then((res) => {
                console.log("created", {res})
                if (res.status === 201) {
                    const response_data = res.data
                    setIsCreating(false)
                    setNewDashboard(response_data)
                } else {
                    console.log("err", {res})
                    message.error(res.data)
                    setIsCreating(false)
                }
                
            }
            ).catch().finally(() => {
                setIsCreating(false)
                onCreate()
            })
        }
    }

    
    

    const dashboardFormBody = (
        <Form
        name="basic"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        onFinish={onCreateDashboardSubmit}
        onFinishFailed={onCreateDashboardFailed}
        autoComplete="off"
        >
            
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            <Typography.Paragraph>Give your Dashboard a name and then you can configure it in the next step.</Typography.Paragraph>

            

            {((newDashboard === null) && (
                <>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Name your dashboard' }]}
                    >
                        <Input />
                    </Form.Item>

                    

                    <Form.Item
                        wrapperCol={{ span: 16, offset: 6 }}
                    >
                        <Button type="primary" htmlType="submit" disabled={
                            isCreating || newDashboard !== null
                        }>
                            Create Dashboard
                        </Button>
                    </Form.Item>
                </>
            ))}

            {(newDashboard && (
                <>
                    <Typography.Paragraph>Dashboard Created: <Link to={`/dashboards/${newDashboard.id}/`}>{newDashboard.name}</Link></Typography.Paragraph>

                    <Button type="primary" onClick={handleCancel}>
                        Okay
                    </Button>
                </>

            ))}

        </Form>
    )

    return (
            <Modal title={ dashboardCopySource ? "Copy Dashboard" : "Create Dashboard" } open={isOpen} footer={null} onCancel={handleCancel}>
            {dashboardFormBody}
            {/* {formBody} */}
            </Modal>
    )

    
}
