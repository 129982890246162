import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { getDataProviders } from '../../services/signalApi';
import { DataProvider } from '../../types/types';


export interface DataProviderListState {
  providers: DataProvider[] | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: DataProviderListState = {
    providers: null,
    status: 'idle',
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadDataProvidersAsync = createAsyncThunk(
  'dataprovider/fetch',
  async () => {
    const response = await getDataProviders();
    // The value we return becomes the `fulfilled` action payload
    console.log(response)
    return response.data;
  }
);


export const dataProviderSlice = createSlice({
  name: 'dataprovider',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log("clear")
      state.providers = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadDataProvidersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadDataProvidersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log("fulfilled", {action})
        state.providers = action.payload
      })
      .addCase(loadDataProvidersAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { clear } = dataProviderSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectDataProviders = (state: RootState) => state.data_provider.providers;
export const selectStatus = (state: RootState) => state.data_provider.status;

export default dataProviderSlice.reducer;
