import { Button, Divider, Radio, Carousel, Popconfirm, Pagination, message, List, ConfigProvider, Empty, Typography, Avatar, Descriptions, Tag as AntTag, Collapse, Card } from 'antd';
import { useState, useEffect } from 'react';
// import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ArrowsAltOutlined, ReloadOutlined, UpCircleOutlined, DownCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, DeleteOutlined, CheckCircleOutlined, InfoCircleOutlined, IssuesCloseOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import { DateTime } from 'luxon';

import { Table, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { Workflow, WorkflowExecution, AiChat } from '../types/types';
// import styles from './WorkflowList.module.css';
import { PaginatedTable } from './PaginatedTable';
import { SymbolLink } from './SymbolLink';
import { CopyToClipboard } from './CopyToClipboard';
import { Loading } from './Loading';

import { useAppSelector } from '../app/hooks';
import { selectAccount } from '../features/login/loginSlice';
import { getWorkflowExecutions, getAiChats } from '../services/signalApi';
import { ToggleToMarkdown } from '../components/ToggleToMarkdown'

import { toTitleCase } from '../utils'
import { Tag } from '../views/Tag/Tag';

export function AiChatList({
    ai_chats
}: {
    ai_chats: AiChat[]
}) {
    return <ConfigProvider renderEmpty={() => <Empty description={"No Chats Executed"} image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
        <List
            className="ai-chat-list"
            itemLayout={'vertical'}
            dataSource={ai_chats}

            renderItem={(chat: AiChat) => (
                <List.Item
                    key={chat.id}
                >
                    <List.Item.Meta
                        key={chat.id}
                        description={

                            <Space direction='vertical' style={{width: '100%'}}>
                                <Typography.Text>{DateTime.fromISO(chat.created_date).toRelative()} - {DateTime.fromISO(chat.created_date).toLocaleString(DateTime.DATETIME_MED)}</Typography.Text>
                                {chat.platform && chat.model && (
                                    <Space wrap>
                                        <AntTag>{chat.platform} / {chat.model}</AntTag>
                                        {chat.total_tokens_consumed && chat.prompt_tokens_consumed && chat.completion_tokens_consumed && (
                                            <AntTag>{chat.prompt_tokens_consumed} + {chat.completion_tokens_consumed} = {chat.total_tokens_consumed} tokens</AntTag>
                                        )}
                                    </Space>
                                )}
                                <Collapse defaultActiveKey={[]} ghost collapsible="header">
                                    <Collapse.Panel header={'Prompt'} key="prompt"  extra={<CopyToClipboard text={chat.prompt || ''} />} >
                                        <Typography.Paragraph style={{whiteSpace: 'pre-line'}}>{chat.prompt}</Typography.Paragraph>
                                    </Collapse.Panel>
                                    <Collapse.Panel header={'Response'} key="response" extra={<CopyToClipboard text={chat.response || ''} />}>
                                        <ToggleToMarkdown markdown={chat.response || '-'} />

                                    </Collapse.Panel>
                                </Collapse>

                            </Space>

                        }
                    />
                </List.Item>
            )}
        />
    </ConfigProvider>
}

export function WorkflowExecutionDetail({
    execution
}: {execution: WorkflowExecution}) {
    const [showExecutionDetails, setShowExecutionDetails] = useState(false);
    const [loadedAiChats, setLoadedAiChats] = useState<AiChat[] | null>(null)

    useEffect(() => {
        if (showExecutionDetails && loadedAiChats === null) {
            getAiChats({source_workflow_execution_id: execution.id}).then((res) => {
                console.log("WorkflowExecutionsList Workflows", {res})
                if (res.status === 200) {
                    setLoadedAiChats(res.data.results || [])
    
                } else {
                    setLoadedAiChats([])
    
                }
            }).catch(() => {
                setLoadedAiChats([])
    
            })
        }
    }, [showExecutionDetails])

    return <>
        {showExecutionDetails && (
            <Space direction='vertical' style={{width: '100%'}}>
                <a onClick={() => {setShowExecutionDetails(false)}}>See less</a>
                {execution.result && (
                    <ToggleToMarkdown markdown={execution.result || '-'} />
                )}
                {loadedAiChats !== null && loadedAiChats.length > 0 && (
                    <AiChatList ai_chats={loadedAiChats} />
                )}
            </Space>

        )}
        {!showExecutionDetails && execution.status !== 'running' && (
            <a onClick={() => {setShowExecutionDetails(true)}}>See more</a>
        )}
    </>
}

interface WorkflowExecutionsListUiParams {
    workflowExecutions: WorkflowExecution[]
    show_workflow_link?: boolean
}

export function WorkflowExecutionsListUi({
    workflowExecutions,
    show_workflow_link=false,

}: WorkflowExecutionsListUiParams) {

    const check = <CheckCircleOutlined  style={{ color: '#8bbb11' }} />
    const waiting = <Tooltip title="Running"  placement="left"><IssuesCloseOutlined  style={{ color: '#e8b339' }} /></Tooltip>
    const blocked = <Tooltip title="Failed"  placement="left"><IssuesCloseOutlined  style={{ color: '#d32029' }} /></Tooltip>
    const optional = <RightCircleOutlined  style={{ color: '#3c9ae8' }} />

    const renderWorkflowExecutionParams = (workflow_execution: WorkflowExecution) => {
        const form_params = workflow_execution.form_params
        if (!form_params || form_params === undefined) {
            return "-"
        }
        return <>
            {Object.keys(form_params).map((param) => {
                const param_val = form_params[param] || ''
                return `${param}: ${param_val}`
            }).join(", ")}
        </>
    }

    const getExecutionIcon = (workflow_execution: WorkflowExecution) => {
        if (workflow_execution.status === 'complete') {
            return check
        }
        if (workflow_execution.status === 'running') {
            return optional
        }
        if (workflow_execution.status === 'failed') {
            return blocked
        }
        return waiting
    }


    const keyedWorkflowExecutions = workflowExecutions?.map((ex) => ({key: ex.id, ...ex})) || null
    return <ConfigProvider renderEmpty={() => <Empty description={"No Workflow Executions"} image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
        <List
            className="workflow-execution-list"
            itemLayout={'vertical'}
            loading={keyedWorkflowExecutions === null}
            dataSource={keyedWorkflowExecutions === null ? [] : keyedWorkflowExecutions}
            renderItem={(execution: WorkflowExecution) => (
                <List.Item
                    key={execution.id}
                >
                    <List.Item.Meta
                        key={execution.id}
                        avatar={getExecutionIcon(execution)}
                        title={
                            <>
                            <Space wrap>
                                <span>{toTitleCase(execution.status || 'Unconfirmed')} Workflow</span>
                            </Space>
                            </>
                        }
                        description={
                            <Space direction='vertical' style={{width: '100%'}}>
                                <Typography.Text>{DateTime.fromISO(execution.created_date).toRelative()} - {DateTime.fromISO(execution.created_date).toLocaleString(DateTime.DATETIME_MED)}</Typography.Text>
                                <>
                                    {renderWorkflowExecutionParams(execution)}
                                </>
                                {execution.status_message && (
                                    <Typography.Paragraph><InfoCircleOutlined /> {execution.status_message}</Typography.Paragraph>
                                )}
                                <WorkflowExecutionDetail execution={execution} />
                                {show_workflow_link && (
                                    <Typography.Paragraph><Link to={`/workflows/${execution.workflow_id}`}>Go to Workflow&nbsp;&#8594;</Link></Typography.Paragraph>
                                )}
                            </Space>
                        }
                    />
                </List.Item>
            )}
        />

        </ConfigProvider>
}


interface WorkflowListParams {
    workflow_id?: string
    workflow_execution_id?: string
    project_id?: string
    show_workflow_link?: boolean
    title_size?: 1 | 2 | 3 | 4 | 5

    // include link to workflow
    // list type? table vs list?
    // auto-show results? show chats?
}


export function WorkflowExecutionsList({
    workflow_id,
    workflow_execution_id,
    project_id,
    show_workflow_link=false,
    title_size=2,
}: WorkflowListParams) {
    console.log("WorkflowExecutionsList")
    const account = useAppSelector(selectAccount)
    const [loadedWorkflowExecutions, setLoadedWorkflowExecutions] = useState<WorkflowExecution[] | null>(null)
    const [reloadedLast, setReloadedLast] = useState('never');
    const [reloads, setReloads] = useState(0);
    const [totalWorkflowExecutions, setTotalWorkflowExecutions] = useState<number | null>(null)
    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);



    useEffect(() => {
        getWorkflowExecutions({
            ...(workflow_id ? { workflow_id }: {}),
            ...(project_id ? { workflow__project_id: project_id }: {}),
            ...(workflow_execution_id ? {id: workflow_execution_id} : {}),
            ...(pageLimit ? {limit: pageLimit} : {}),
            ...(page > 0 ? {offset: pageLimit * (page - 1)} : {}),
            // ...(data.pagination ? getPagination(data.pagination) : {}),
            ordering: '-created_date'
        }).then((res) => {
            console.log("WorkflowExecutionsList Workflows", {res})
            if (res.status === 200) {
                setTotalWorkflowExecutions(res.data.count || null)
                setLoadedWorkflowExecutions(res.data.results || [])

            } else {
                setTotalWorkflowExecutions(null)
                setLoadedWorkflowExecutions([])

            }
        }).catch(() => {
            setTotalWorkflowExecutions(null)
            setLoadedWorkflowExecutions([])

        })
    }, [account, reloadedLast, pageLimit, page])

    const triggerReload = () => {
        const dt = DateTime.now();
        setReloadedLast(dt.toISO())
    }

    const buttons = (
        <Space style={{marginBottom: '6px', float: 'right'}}>
            <Button type="default" onClick={triggerReload}><ReloadOutlined /></Button>
        </Space>
    )

    return (
        <div>
            {buttons}
            <Typography.Title level={title_size}>Executions</Typography.Title>
            <WorkflowExecutionsListUi workflowExecutions={loadedWorkflowExecutions || []} show_workflow_link={show_workflow_link} />
            {totalWorkflowExecutions && totalWorkflowExecutions > pageLimit && (
                <Pagination style={{float:'right'}} onChange={(page, pageSize) => {setPage(page); setPageLimit(pageSize)}} current={page} pageSize={pageLimit} total={totalWorkflowExecutions} />
            )}
        </div>
    )
    
    
    
}
