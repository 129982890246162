import { ReloadOutlined } from '@ant-design/icons';
import { Button, Typography, Tooltip } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Space, Table } from 'antd';
import { CreateDataSetModal } from '../../components/CreateDataSetModal';
import { FindDataSetModal } from '../../components/FindDataSetModal';
import {
    selectDataProviders
} from '../dataProviderList/dataProviderListSlice';
import { selectAccount } from '../login/loginSlice';
import {
    clear, loadDataSetsAsync, selectDataSets,
    selectStatus
} from './dataSetSlice';


import { DataProviderLink } from '../../components/DataProviderLink';
import { DataSetSubscribeModal } from '../../components/DataSetSubscribeModal';
import { DataSet } from '../../types/types';
import styles from './DataSetList.module.css';
// TODO - this shouldn't be a feature? should just manage state locally?
export interface DataSetListProps {
    subscribed?: boolean
    include_buttons?: boolean
}


export function DataSetList({
    subscribed,
    include_buttons = true,
}: DataSetListProps) {
    const datasets = useAppSelector(selectDataSets);
    const status = useAppSelector(selectStatus);
    const account = useAppSelector(selectAccount);
    const data_providers = useAppSelector(selectDataProviders);
    // const dataProviders = useAppSelector(selectDataProviders);
    // const [isSubscribeModalVisible, setIsSubscribeModalVisible] = useState(false);
    const [subscribingDataSet, setSubscribingDataSet] = useState<DataSet | null>(null);
    const [showCreateDataSet, setShowCreateDataSet] = useState(false);
    const [showFindDataSet, setShowFindDataSet] = useState(false);

    const dispatch = useAppDispatch();
    console.log("datasets", {datasets})
    console.log(status)

    const clear_click = () => {
        dispatch(clear())
    }

    const fetch_click = () => {
        dispatch(loadDataSetsAsync())
    }

    if (status === "idle" && datasets === null){
        dispatch(loadDataSetsAsync())
    }

    const showSubscribeModal = (dataset: DataSet) => {
        // setIsSubscribeModalVisible(true);
        console.log("showSubscribeModal dataset", {dataset})
        setSubscribingDataSet(dataset);
    }


    const handleCancel = () => {
        setSubscribingDataSet(null);
    }

    const handleCreateCancel = () => {
        setShowCreateDataSet(false)

    }

    const handleCreate = () => {
        dispatch(loadDataSetsAsync())
    }

    const createDataSetClick = () => {
        setShowCreateDataSet(true)
    }

    const handleSubscribeComplete = () => {
        setSubscribingDataSet(null);
        dispatch(loadDataSetsAsync())
    }

    const findDataSetClick = () => {
        setShowFindDataSet(true)
    }

    const handleFindCancel = () => {
        setShowFindDataSet(false)
    }

    const handleFindComplete = () => {
        setShowFindDataSet(false)
        dispatch(loadDataSetsAsync())
    }

    const head = (
        <Typography.Title level={2}>Data Sets</Typography.Title>
    )

    const content = () => {

        if (datasets === null || datasets.length === 0){
            return (
                <div>
                    <Typography.Paragraph>Nothing Loaded.</Typography.Paragraph>
                </div>
            )
        }

        if (Array.isArray(datasets) === false){
            return (
                <div>
                    <Typography.Paragraph>Nothing Loaded.</Typography.Paragraph>
                </div>
            )
        }

        console.log("datasets", {datasets})

        const keyedDataSets = datasets.map(v => ({key: v.id, ...v}))

        const columns = [
            {
              title: 'Data Set Name',
              dataIndex: 'name',
              key: 'name',
              render: (text: string, row: DataSet) => {
                return <Link to={`/data_sets/${row.id}/`}>{row.name}</Link>
              }
            //   render: (value: string, row: DataSet) => <a>{row.name}</a>
            },
            {
              title: 'Data Provider',
              dataIndex: 'data_provider_id',
              key: 'data_provider_id',
              render: (text: string, row: DataSet) => {
                return <>
                    <DataProviderLink data_provider={row.data_provider} />{account && row.data_provider.account_id === account.id ? ' (Me)': ''}
                </>
                // return <Link to={`/data_providers/${row.data_provider.id}/`}>{row.data_provider.name}</Link>
              }
            //   render: (value: string, row: DataSet) => <a>{row.data_provider.name}</a>

            },
            {
                title: 'Visibility',
                dataIndex: 'is_public',
                key: 'is_public',
                render: (value: string, row: DataSet) => <>{value ? 'Public' : 'Private'}</>
            },
            {
                title: 'Status',
                dataIndex: 'is_published',
                key: 'is_published',
                render: (value: string, row: DataSet) => <>{value ? 'Published' : 'Unpublished'}</>
            }
            // {
            //     title: 'Created Date',
            //     dataIndex: 'created_date',
            //     key: 'created_date',
            // },
          ];

        if (account) {
            columns.push({
                title: 'Subscription',
                dataIndex: 'subscribed',
                key: 'subscribed',
                render: (value: string, row: DataSet) => <a onClick={(e) => showSubscribeModal(row)}>{row.subscribed ? 'Subscribed' : 'Subscribe'}</a>
            })
        }
    
        return (            
            <Table
                dataSource={keyedDataSets}
                columns={columns}
                loading={status==="loading"}
                pagination={{ hideOnSinglePage: true }}
                size="middle"
                bordered={true}
            />
        )
    }

    const newButtonComponent = <Button type="primary" onClick={createDataSetClick} disabled={!!account?.data_set_cap && !!datasets && account?.data_set_cap <= datasets.length}>+ New</Button>

    const newButton = !!account?.data_set_cap && !!datasets && account?.data_set_cap <= datasets.length ?
        <Tooltip title={`At Data Set Cap of ${account.data_set_cap}`}>{newButtonComponent}</Tooltip> : newButtonComponent

    const buttons = (
        <Space style={{marginBottom: '6px'}}>
            {/* <Button type="primary" className={styles.button} onClick={fetch_click}>Reload</Button> */}
            <Button type="default" onClick={fetch_click}><ReloadOutlined /></Button>
            {newButton}
            {/* <Button type="primary" className={styles.button} onClick={createDataSetClick}>+ New</Button> */}
            <Button type="default" className={styles.button} onClick={findDataSetClick}>Find</Button>
            {/* <Button type="default">Show Subscribed</Button>
            <Button type="default">Show Provided</Button> */}
            {/* <Button type="default">Filter</Button> */}
            {/* <Button type="primary" onClick={clear_click}>Clear</Button> */}
        </Space>
    )


    const modals = (
        <>
            {subscribingDataSet && (
                <DataSetSubscribeModal subscribingDataSet={subscribingDataSet} isOpen={subscribingDataSet !== null} onCancel={handleCancel} onCreate={handleSubscribeComplete} />
            )}
            <CreateDataSetModal isOpen={showCreateDataSet} onCancel={handleCreateCancel} onCreate={handleCreate} />
            <FindDataSetModal isOpen={showFindDataSet} onCancel={handleFindCancel} onCreate={handleFindComplete} />

        </>
        
    )

    return (
        <>
            {head}
            {account && include_buttons && buttons}
            {/* {buttons} */}
            {content()}
            {modals}
        </>
    )

    
}
