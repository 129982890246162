import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import { CheckOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Avatar, Card, Col, Descriptions, List, Modal, Row, Space, Typography } from 'antd';
import { CopyToClipboard } from '../../components/CopyToClipboard';
import { AccountBillingMethod, AccountItemPurchase, CreditTransaction, FeatureFlag, PromptPack, CheckNewsletterResponseType } from '../../types/types';
import { toTitleCase } from '../../utils';
import { useQuery } from '@tanstack/react-query'

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLight } from '../../features/lightDarkSwitch/lightDarkSwitchSlice';
import { selectAccount, selectFeatureFlags } from '../../features/login/loginSlice';
import { loadPromptPacksAsync, selectPromptPacks, selectPromptPackStatus } from '../../features/projects/projectsSlice';
import { getAccountBillingMethod, getAccountItemPurchases, getCreditTransactions, getStripePortal, checkNewsletterSubscription } from '../../services/signalApi';

import { PromoCodeSubmitForm } from '../../components/PromoCodeSubmitForm';

import { WorkflowExecutionResult } from '../../components/WorkflowExecutionResult';


// drop-down to select new plan, once selected, gets price and shows link

// interface PlanInfo {
//     amount: number
//     id: string
//     name: string
//     recurring: null | 'year' | 'month'
// }

// interface PlanName {
//     subscriptionId: SubscriptionId
//     displayName: string
// }

// const planNames: PlanName[] = [
//     {subscriptionId: 'basic', displayName: 'Basic'},
//     {subscriptionId: 'pro_one_time', displayName: 'Pro One Month'},
//     {subscriptionId: 'pro_month', displayName: 'Pro Monthly'},
//     {subscriptionId: 'pro_year', displayName: 'Pro Yearly'},
//     {subscriptionId: 'elite_one_time', displayName: 'Elite One Month'},
//     {subscriptionId: 'elite_month', displayName: 'Elite Monthly'},
//     {subscriptionId: 'elite_year', displayName: 'Elite Yearly'}
// ]
// const planSubscriptionIds: SubscriptionId[] = planNames.map((plan) => plan.subscriptionId)


export function Account() {
    const location = useLocation();
    const account = useAppSelector(selectAccount);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const dispatch = useAppDispatch();
    const promptPacks = useAppSelector(selectPromptPacks);
    const promptPackStatus = useAppSelector(selectPromptPackStatus);
    const light = useAppSelector(selectLight)
    const [stripePortalUrl, setStripePortalUrl] = useState<string | null>(null);
    const [stripeSuccess, setStripeSuccess] = useState<boolean | null>(null);
    const [stripeUpgradeUrl, setStripeUpgradeUrl] = useState<string | null>(null);
    const [shownWorkflowExecutionId, setShownWorkflowExecutionId] = useState<string | null>(null)
    const [accountBillingMethod, setAccountBillingMethod] = useState<AccountBillingMethod | null>(null);
    const [accountItemPurchases, setAccountItemPurchases] = useState<AccountItemPurchase[] | null>(null);
    const [creditTransactions, setCreditTransactions] = useState<CreditTransaction[] | null>(null);
    

    // const [featureFlags, setFeatureFlags] = useState<FeatureFlag[] | null>(null);
    // const [selectedPlanInfo, setSelectedPlanInfo] = useState<PlanInfo | null>(null);
    // const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<SubscriptionId | null>(null);

    const showCreditTransactions = true
    const showPromptPacks = true
    const showFeatures = true
    const showBilling = true

    const {
        data: checkNewsletterResponse,
        isFetching: checkNewsletterFetching
        
    } = useQuery({
        queryKey: ['newsletter_subscription_check', account?.id],
        staleTime: 1000 * 60 * 15,
        queryFn: () => {
            if (account && account.id) {
                return checkNewsletterSubscription().then((res) => {
                    const data: CheckNewsletterResponseType = res.data
                    return data
                })
            }
            return {email: null, submitted: false, verified: false, unsubscribed: false, email_problem: false }
        }
    })

    useEffect(() => {
        if (account) {
            getAccountBillingMethod(account.id).then((res) => {
                console.log("getAccountBillingMethod", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results && response_data.results.length > 0) {
                        const results = response_data.results.filter((billing_method) => billing_method.billing_method !== "stripe")
                        if (results.length > 0) {
                            const accountBilling = response_data.results[0]
                            setAccountBillingMethod(accountBilling)
                        }
                        
                    }
                }
            })
            getAccountItemPurchases().then((res) => {
                console.log("getAccountItemPurchases", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results && response_data.results.length > 0) {
                        setAccountItemPurchases(response_data.results)
                    }
                }
                getCreditTransactions({}).then((res) => {
                    console.log("getCreditTransactions")
                    if (res.status === 200) {
                        const transactions_response_data = res.data
                        if (transactions_response_data.results && transactions_response_data.results.length > 0) {
                            setCreditTransactions(transactions_response_data.results)
                        }
                    }
                })
            })
        }
    }, [account?.id]);

    useEffect(() => {
        if (promptPackStatus === "idle" && promptPacks === null) {
            dispatch(loadPromptPacksAsync())
        }
    }, [promptPackStatus, JSON.stringify({ promptPacks })])

    const loadStripePortal = () => {
        if (account) {
            return getStripePortal(account.id).then((res) => {
                console.log("getStripePortal", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    setStripePortalUrl(response_data.url)
                }
            })
        }
        return new Promise(res => {
            return res(true)
        })
    }

    const loadAccountBilling = () => {
        if (account) {
            getAccountBillingMethod(account.id).then((res) => {
                console.log("getAccountBillingMethod", {res})
                if (res.status === 200) {
                    const response_data = res.data
                    if (response_data.results && response_data.results.length > 0) {
                        const results = response_data.results.filter((billing_method) => billing_method.billing_method !== "stripe")
                        if (results.length > 0) {
                            const accountBilling = response_data.results[0]
                            setAccountBillingMethod(accountBilling)
                        }

                    }
                }
            })
        }
        return new Promise(res => {
            return res(true)
        })
    }

    const onAddStripe = () => {
        loadStripePortal().then(loadAccountBilling)
    }

    const onAddLemonSqueezy = () => {

    }

    useEffect(() => {
        if (account && accountBillingMethod?.billing_method === 'stripe') {
            loadStripePortal()
        }
    }, [account?.id, accountBillingMethod?.billing_method])

    // useEffect(() => {
    //     if (account && selectedSubscriptionId) {
    //         const selectedPlan = getSubscriptionPlanFromSubscriptionId(selectedSubscriptionId)
    //         const selectedRecurring = getSubscriptionRecurringFromSubscriptionId(selectedSubscriptionId)
    //         getStripeCheckout(account.id, selectedPlan, selectedRecurring).then((res) => {
    //             console.log("getStripeCheckout", {res})
    //             if (res.status === 200) {
    //                 const response_data = res.data
    //                 setStripeUpgradeUrl(response_data.url)
    //                 setSelectedPlanInfo(response_data.price)
    //             }
    //         })
    //     }
    // }, [selectedSubscriptionId])

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        const urlSelectPlan = query.get('select_plan')
        const success = query.get('success')

        if (success === "1") {
            setStripeSuccess(true)
        }
    }, [window.location.search]);

    const showWorkflowExecutionResult = (workflow_execution_id: string) => {
        setShownWorkflowExecutionId(workflow_execution_id)
    }

    const upgradeStripePlanLink = () => {
        return <></>
        // if (
        //     selectedSubscriptionId !== null &&
        //     accountBillingMethod?.subscription_id &&
        //     accountBillingMethod.subscription_id !== selectedSubscriptionId
        //  ) {
        //     return <a {...(stripeUpgradeUrl === null ? {} : {target: 'blank', href: stripeUpgradeUrl})} style={{width: '100%'}} >Change Subscription on Stripe</a>
        // } else {
        //     return <></>
        // }
    }

    const stripeLink = () => {
        return <a {...(stripePortalUrl === null ? {} : {target: 'blank', href: stripePortalUrl})}>Manage Subscriptions on Stripe</a>

        // return <></>
        // if (selectedSubscriptionId === 'basic' || selectedSubscriptionId === null || selectedSubscriptionId === accountBillingMethod?.subscription_id) {
        //     return <a {...(stripePortalUrl === null ? {} : {target: 'blank', href: stripePortalUrl})}>Manage Subscriptions on Stripe</a>
        // }
        // const selectedRecurring = getSubscriptionRecurringFromSubscriptionId(selectedSubscriptionId)
        // const selectedPlan = getSubscriptionPlanFromSubscriptionId(selectedSubscriptionId)
        // const subscribedRecurring = getSubscriptionRecurringFromSubscriptionId(accountBillingMethod?.subscription_id || 'basic')
        // const subscribedPlan = getSubscriptionPlanFromSubscriptionId(accountBillingMethod?.subscription_id || 'basic')

        // if (selectedRecurring === 'one_time' && subscribedRecurring !== 'one_time' && selectedPlan !== 'elite') {
        //     return <Typography.Text>You already have a subscription.</Typography.Text>
        // }

        // return <a {...(stripeUpgradeUrl === null ? {} : {target: 'blank', href: stripeUpgradeUrl})} style={{width: '100%'}} >Confirm Subscription on Stripe</a>

    }

    const lemonSqueezyLink = () => {
        return <a target='blank' href="https://pyroprompts.lemonsqueezy.com/billing">Manage Subscriptions on LemonSqueezy</a>
        
    }

    const itemList = (accountItems: AccountItemPurchase[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="account-item-purchase-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={accountItems === null ? [] : accountItems}
            renderItem={(accountItem: AccountItemPurchase) => (
                <List.Item
                    key={accountItem.id}
                    // onClick={() => {showSignalDetails(signal)}}
                    // actions={[
                    //     ...(accountItem.item.multiple_disallowed === 1 ? [] : [
                    //         <Space>
                    //             <Input key={accountItem.quantity} type='number' defaultValue={accountItem.quantity} style={{width: '80px'}} onChange={(e) => {handleQuantitySelectionChange(accountItem, parseInt(e.target.value, 10))}} />
                    //             <Tooltip placement='right' title="Update Quantity">
                    //                 <Button disabled={!quantitySelectionChanged(accountItem)} icon={<CheckOutlined />} onClick={() => {updateCartItemQuantity(accountItem)}}/>

                    //             </Tooltip>
                    //         </Space>
                    //     ])
                        
                    // ]}
                    // extra={
                    //     <Tooltip placement='bottom' title="Remove from Cart">
                    //         <Button icon={<DeleteOutlined />} onClick={() => {deleteCartItem(accountItem)}}/>

                    //     </Tooltip>
                    //     // <span>${(accountItem.quantity * (accountItem.item.month_price_cents || 0) / 100).toFixed(2)}</span>
                    // }
                >
                    <List.Item.Meta
                        key={accountItem.id}
                        title={<span>{accountItem.quantity} x {accountItem.item.name}</span>}
                        description={
                            <>
                                <Typography.Paragraph>{accountItem.item.description}</Typography.Paragraph>
                                <Typography.Paragraph>Purchased On: {DateTime.fromISO(accountItem.created_date).toLocaleString(DateTime.DATETIME_MED)} | Expires: {accountItem.expiration_date ? DateTime.fromISO(accountItem.expiration_date).toLocaleString(DateTime.DATETIME_MED) : 'Never'}</Typography.Paragraph>
                                
                            </>
                        }
                    />

                </List.Item>
            )}
        />
    }

    const featureFlagList = (featureFlags: FeatureFlag[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="account-feature-flag-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            // itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={featureFlags === null ? [] : featureFlags}
            renderItem={(featureFlag: FeatureFlag) => (
                <List.Item
                    key={featureFlag.id}
                >
                    <List.Item.Meta
                        key={featureFlag.id}
                        avatar={<Avatar size='large' icon={<CheckOutlined style={{  }} />} />}
                        title={<span>{toTitleCase(featureFlag.code_name)}</span>}
                        description={featureFlag.description}
                    />

                </List.Item>
            )}
        />
    }

    const promptPackList = (promptPacks: PromptPack[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="account-prompt-pack-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            // itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={promptPacks === null ? [] : promptPacks}
            renderItem={(promptPack: PromptPack) => (
                <List.Item
                    key={promptPack.id}
                >
                    <List.Item.Meta
                        key={promptPack.id}
                        avatar={<Avatar size='large' icon={<CheckOutlined style={{  }} />} />}
                        title={<span>{toTitleCase(promptPack.name)}</span>}
                        description={
                            <>
                                <Typography.Paragraph>{promptPack.description}</Typography.Paragraph>
                                {promptPack.projects && (
                                    <>
                                        <Typography.Paragraph>Projects</Typography.Paragraph>
                                        <ul>
                                            {promptPack.projects.map((project) => (
                                                <li key={project.id}>{project.name}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                {promptPack.prompts && (
                                    <>
                                        <Typography.Paragraph>Prompts</Typography.Paragraph>
                                        <ul>
                                            {promptPack.prompts.map((prompt) => (
                                                <li key={prompt.id}>{prompt.name}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </>
                        }
                    />

                </List.Item>
            )}
        />
    }

    const creditTransactionList = (transactions: CreditTransaction[]) => {
        // const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
        // const [changedQuantity, setChangedQuantity] = useState(false)

        return <List
            className="account-credit-transaction-list"
            // itemLayout={ isBigScreen ? 'horizontal' : 'vertical'}
            // itemLayout={'vertical'}
            // size="large"
            pagination={false}
            bordered
            dataSource={transactions === null ? [] : transactions}
            renderItem={(transaction: CreditTransaction) => (
                <List.Item
                    key={transaction.id}
                >
                    <List.Item.Meta
                        key={transaction.id}
                        avatar={<Avatar size='large' icon={transaction.credits > 0 ? <PlusOutlined style={{  }} /> : <MinusOutlined />} />}
                        title={<span>{transaction.credits} {toTitleCase(transaction.transaction_type)}</span>}
                        description={<>
                            <Typography.Text>{DateTime.fromISO(transaction.transaction_date).toLocaleString(DateTime.DATETIME_MED)} - {transaction.description}</Typography.Text>
                            {transaction.workflow_execution_id && (
                                <Typography.Text> <a onClick={() => showWorkflowExecutionResult(transaction.workflow_execution_id || '')}>See Workflow Execution</a></Typography.Text>
                            )}
                        </>
                        }
                    />

                </List.Item>
            )}
        />
    }


    return (
        <div className="App">
            <div className="App-content">
                <Typography.Title level={1}>Account</Typography.Title>
                <div className="App-page-content">
                    <Space direction='vertical' style={{width: '100%'}}>

                        <Card
                            title={"Account Information"}
                        >
                            <Space direction='vertical' style={{width: '100%'}}>
                                <Descriptions column={24}>
                                    <Descriptions.Item span={24} label="Account ID"><Typography.Text code>{account?.id}</Typography.Text> <CopyToClipboard text={account?.id || ''} /></Descriptions.Item>
                                    <Descriptions.Item span={24} label="Username">{account ? <Link to={`/u/${account.name }/`}>{account.name}</Link> : <></>}</Descriptions.Item>

                                    {/* <Descriptions.Item span={24} label="Data Set Cap">{account?.data_set_cap}</Descriptions.Item> */}
                                    {/* <Descriptions.Item span={24} label="Dashboard Cap">{account?.dashboard_cap}</Descriptions.Item> */}
                                    {/* <Descriptions.Item span={24} label="Workflow Cap">{account?.workflow_cap}</Descriptions.Item> */}
                                    {/* <Descriptions.Item span={24} label="Daily AI Chat Limit">{account?.daily_ai_chat_cap || '-'}</Descriptions.Item> */}
                                    <Descriptions.Item span={24} label="Prompt Length Limit">{account?.prompt_length_cap || '-'}</Descriptions.Item>
                                    <Descriptions.Item span={24} label="Workflow Limit">{account?.workflow_cap || '-'}</Descriptions.Item>
                                    <Descriptions.Item span={24} label="Credits">{account?.current_credits || '-'}</Descriptions.Item>

                                </Descriptions>
                            </Space>
                        </Card>

                        <Card
                            title={"Email & Notification Preferences"}
                        >
                            <Space direction='vertical' style={{width: '100%'}}>
                                <Descriptions column={24}>
                                    {checkNewsletterResponse?.email && (
                                        <Descriptions.Item span={24} label="Email Address">
                                            {checkNewsletterResponse.email}
                                        </Descriptions.Item>
                                    )}
                                   
                                    <Descriptions.Item span={24} label="Newsletter Subscription">
                                        {checkNewsletterResponse?.submitted && checkNewsletterResponse.unsubscribed && (
                                            <span>Unsubscribed - <Link to='/newsletter'>Join Newsletter to Re-subscribe</Link></span>
                                        )}
                                        {checkNewsletterResponse?.submitted && checkNewsletterResponse.email_problem && (
                                            <span>There was a problem with your email - <Link to='/contact_us'>Contact Us</Link></span>
                                        )}
                                        {checkNewsletterResponse?.submitted && checkNewsletterResponse.verified && (
                                            <span>Subscribed</span>
                                        )}
                                        {checkNewsletterResponse?.submitted && !checkNewsletterResponse.verified && (
                                            <span>Pending Verification - <Link to='/newsletter'>Join Newsletter again for a new code</Link></span>
                                        )}
                                        {!checkNewsletterResponse?.submitted && (
                                            <span>No Subscription - <Link to='/newsletter'>Join Newsletter</Link></span>
                                        )}
                                    </Descriptions.Item>

                                    {/* <Descriptions.Item span={24} label="News and Updates">
                                        All - Monthly - Weekly - Only Essential - None, Never
                                    </Descriptions.Item>

                                    <Descriptions.Item span={24} label="Billing Notifications">
                                        Never - 1 day before - 3 days before
                                    </Descriptions.Item>

                                    <Descriptions.Item span={24} label="Application Notifications">
                                        Only my workflows
                                    </Descriptions.Item> */}
                                </Descriptions>
                            </Space>
                        </Card>

                        {showFeatures && (
                            <Card
                                title={"Features"}
                            >
                                {featureFlags && featureFlags?.length > 0 && (
                                    <Space direction='vertical' style={{width: '100%'}}>
                                        <Typography.Paragraph>Learn more about the features available on our <Link to={`/store`}>store</Link>.</Typography.Paragraph>
        
                                        {featureFlagList(featureFlags || [])}
                                    </Space>
                                
                                )}
        
                                {(featureFlags === null || featureFlags?.length == 0) && (
                                    <Typography.Paragraph>No features enabled. Learn more about the features available on our <Link to={`/store`}>store</Link>.</Typography.Paragraph>
                                )}
                            </Card>
                        )}

                        <Card
                            title={"Promo Code"}
                            // extra={<a href="#">Editas</a>}

                            // bordered={light ? true : false}
                        >
                            <Typography.Paragraph>Redeem a Promo Code for Free Stuff. We regularly send promo codes to our <Link to={`/newsletter`}>newsletter</Link> subscribers.</Typography.Paragraph>

                            <Row>
                                <Col xs={24} md={12} lg={8} xl={6}>
                                    <PromoCodeSubmitForm />

                                </Col>
                            </Row>
                                
                                

                        </Card>

                        {showBilling && (

                            <Card
                                title={"Billing"}
                                // extra={<a href="#">Editas</a>}

                                // bordered={light ? true : false}
                            >
                                <Typography.Paragraph>Learn more about the subscriptions available on our <Link to={`/store`}>store</Link>.</Typography.Paragraph>

                                <>
                                    <Typography.Paragraph>Payments can be made via:</Typography.Paragraph>
                                    <ul>
                                        <li><Typography.Text strong>LemonSqueezy: </Typography.Text>{accountBillingMethod?.billing_method === "lemonsqueezy" ? <>You are configured to use LemonSqueezy. {lemonSqueezyLink()}</> : <>Purchase something from the <Link to={`/store`}>store</Link> to get configured with LemonSqueezy.</>}</li>
                                        <li><Typography.Text strong>Popular Cryptocurrencies: </Typography.Text>Contact us if you would like to coordinate Cryptocurrency payment.</li>
                                        <li><Typography.Text strong>Invoice: </Typography.Text>Contact us if you would like to coordinate an invoice payment.</li>
                                        <li><Typography.Text strong>Stripe (Deprecated): </Typography.Text> Existing subscriptions using Stripe will continue to use Stripe. For future payments, use LemonSqueezy. It handles taxes and currency conversions so we can support more customers in more places around the world.</li>
                                    </ul>
                                    <Typography.Paragraph>Please contact us with any questions you have.</Typography.Paragraph>
                                </>

                                {accountBillingMethod?.billing_method === "stripe" && (
                                    <>
                                        <Space direction='vertical' style={{width: '100%'}}>
                                            {stripeSuccess && (
                                                <Alert type='success' message={"Your plan has been updated. Thank you for subscribing to PyroPrompts! Your change may take a moment to reflect here. Once the change is reflected here, your new access should be granted. If you encounter any issues or have any questions, please contact us."} />
                                            )}
                                            {accountItemPurchases && accountItemPurchases.length > 0 && (
                                                <>
                                                    {itemList(accountItemPurchases || [])}
                                                </>
                                            )}
                                            {(!accountItemPurchases || accountItemPurchases.length == 0) && (
                                                <Typography.Paragraph>Your purchased items will show here.</Typography.Paragraph>
                                            )}
                                        </Space>
                                    </>
                                )}
                            </Card>
                        )}

                        {showCreditTransactions && (
                            <Card
                                title={"Credit Transactions"}
                            >
                                {creditTransactions && creditTransactions?.length > 0 ? (
                                    <Space direction='vertical' style={{width: '100%'}}>
                                        <Typography.Paragraph>Your account currently has {account?.current_credits || 0} credits.</Typography.Paragraph>
                                        <Typography.Paragraph>This is a history of your credits. Buy more in the <Link to={`/store`}>store</Link>.</Typography.Paragraph>
        
                                        {creditTransactionList(creditTransactions || [])}
                                    </Space>
                                
                                ) : (
                                    <Typography.Paragraph>No credits transacted. Buy some in the <Link to={`/store`}>store</Link>.</Typography.Paragraph>

                                )}
        
                            </Card>
                        )}

                        {showPromptPacks && promptPacks && (
                            <Card
                                title={"Prompt Packs"}
                            >
                                {promptPacks && promptPacks?.length > 0 ? (
                                    <Space direction='vertical' style={{width: '100%'}}>
                                        <Typography.Paragraph>This is a list of Prompt Packs you have purchased, which include Prompts and Projects.</Typography.Paragraph>
                                        {promptPackList(promptPacks || [])}
                                    </Space>
                                ) : (
                                    <Typography.Paragraph>No prompt packs purchased. Check back for more details.</Typography.Paragraph>
                                )}
                            </Card>
                        )}
                    </Space> 
                </div>
            </div>
            <Modal open={!!shownWorkflowExecutionId} footer={null} onCancel={() => setShownWorkflowExecutionId(null)}>
                <WorkflowExecutionResult workflow_execution_id={shownWorkflowExecutionId || ''} />
            </Modal>
        </div>
    );
}
